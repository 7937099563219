import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {  getAccessToken } from "../../auth/accessToken";
import { useSendResetEmailMutation } from "../../graphql/generated/graphql";
import logo from "../Login/logo.png";

export default function RequestReset(props) {

  const [state, setState] = useState({
    email: "",
    
  });
  const history = useHistory();


  const [sendEmail] = useSendResetEmailMutation();

  const handleOnChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = async e => {
    e.preventDefault();
   
    const { email } = state;
    sendEmail({
        variables:{
            email: email,
        }
    }).then((info,err)=>{
        console.log(info)
        console.log(err)
        history.push("/");
        toast.success("Please check your email for the reset link")
    }).catch(err => {
          // if not error msg using react-toastify
          console.log(err);
    });
  };

  if (getAccessToken() !== "") {
    toast.error("Log out first before trying to sign in as another user.", {
      toastId: "already signed in",
    });
    history.push("/");
  }


  return (
    <div
      className="d-flex flex-row justify-content-center align-items-center vh-100"
      style={{ backgroundColor: "#aaa" }}
    >
      <form
        onSubmit={handleOnSubmit}
        className="border my-5 pt-5 px-5 pb-3 col-xs-10 col-sm-10 col-md-8 col-lg-7  col-xl-6"
        style={{ backgroundColor: "#fff", borderRadius: "20px" }}
      >
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
          </div>
          <div className="col-sm-12 col-md-12 d-flex  justify-content-center mb-5">
            <img
              src={logo}
              id="icon"
              alt="Java Group"
              height="84"
              width="160"
            />
          </div>

          <div className="col-sm-12 align-self-center justify-content-center d-flex col-md-12">
            {/* Username */}
            <div className="input-group w-50 mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="email"
                  style={{ width: "90px" }}
                >
                  Email:
                </span>
              </div>
              <input
                onChange={handleOnChange}
                value={state.email}
                name="email"
                type="email"
                className="form-control"
                aria-label="Email"
                aria-describedby="email"
              />
            </div>
            
          </div>
        </div>

        {/* Log in button */}
        <div className="row mx-auto d-flex flex-row justify-content-around mt-3">
         
            <button
              
              className="btn btn-danger px-5 mx-3"
              onClick={()=>{
                  history.push("/")
              }}
              
            >Cancel</button>
          <input
            type="submit"
            className="btn btn-primary mx-3"
            value="Send Reset Email"
            
          />
        </div>
        
      </form>
    </div>
  );
}
