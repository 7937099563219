import React, { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import {
  useAddUserToGroupMutation,
  GroupDocument,
  SessionDocument,
  useFuzzyUsersLazyQuery,
} from "../../../graphql/generated/graphql";
import Select from "react-select";
import { toast } from "react-toastify";

const customStyles = {
 
  menu: (provided, state) => ({
    ...provided,

    overflowY:"scroll",
    maxHeight:"150px",


}),
container: (provided, state) => ({
  ...provided,
  
  

  
}),



}


export default function AddRole(props) {
  const { sessId,isFacilitator, groupInfo, setModalIsOpen } = props;

  const MIN_CHARACTERS = 3; // Min number of characters to trigger search

  const [addUserToGroupMutation] = useAddUserToGroupMutation({
    refetchQueries: [
      {
        query: GroupDocument,
        variables: {
          id: groupInfo.id,
        },
      },
      {

        query: SessionDocument,
        variables: {
          id: sessId?sessId:null,
        },

      },
    ],
  });
  
  const [searchText, setSearchText] = useState("");
  
  const [options, setOptions] = useState([]);
  
  const [selectedUser, setSelectedUser] = useState({
    id: "",
    name: "",
    email: "",
  });

  const [executeQuery, { data, error, loading }] = useFuzzyUsersLazyQuery();

  const handleSubmit = e => {
    e.preventDefault();
    if (selectedUser.id !== "") {
      if (
        (isFacilitator &&
          groupInfo.facilitators.find(f => f.id === selectedUser.id)) ||
        (!isFacilitator &&
          groupInfo.members.find(f => f.id === selectedUser.id))
      )
        toast.error(
          "The selected user is already part of this group",
          "UserAlreadyInGroup"
        );
      else {
        addUserToGroupMutation({
          variables: {
            user: selectedUser.id,
            as: isFacilitator ? "facilitator" : "member",
            group: groupInfo.id,
          },
        })
          .then(res => {
            toast.success(
              `${
                isFacilitator ? "Facilitator" : "Member"
              } added successfully to group`,
              "Success"
            );

          })
          .catch(err => {
            console.log(err);
            toast.error("Ops, something went wrong", "error");
          })
          .finally(() => {
            setModalIsOpen(false);
          });
      }
    } else {
      toast.error("Please select a user", "UserNotSelected");
    }
  };

  useEffect(() => {
    if (searchText.trim().length >= MIN_CHARACTERS)
      executeQuery({ variables: { name: searchText } });
  }, [searchText]);

  useEffect(() => {
    let filterList = []
    if (data && data.usersByFuzzyName) {
      if(isFacilitator){
        filterList =data.usersByFuzzyName.filter(x=>(x.role === "ROLE_FACILITATOR"))
      }else{
        filterList =data.usersByFuzzyName.filter(x=>(x.role === "ROLE_MEMBER"))

      }
      
      console.log(filterList);
      if (searchText.trim().length >= MIN_CHARACTERS) {
        setOptions(
          filterList.map(x => ({
            id: x.id,
            name: x.nickname?x.nickname:`${x.firstName} ${x.lastName}`,
            email: x.email,
          }))
        );
      } else {
        setOptions([]);
      }
    }
  }, [data]);

  return (
    <div className="round-box box-shadow h-auto d-flex align-self-center pt-2 px-3" >
      <div className="p-5 ">
        <FaUserCircle
          className="mb-3"
          style={{ fontSize: "15em", color: "#aaa" }}
        />
        <div>


          <div className="form-group ">
            <label className="w-100 pt-2 my-2">Name</label>

            <Select

            styles={customStyles}
              options={options}
              value={selectedUser}
              onChange={opt => {
                console.log("a");
                setSelectedUser(opt);
              }}
              getOptionLabel={x => x.name+" "+ x.email}
              onInputChange={v => setSearchText(v)}
            />
          </div>

          <div className="form-group d-flex justify-content-center mt-5 pt-5">
            <button
              // disabled={data && !data.userByFirstName}
              className="btn btn-extra-radius px-5 bg-info text-white"
              onClick={handleSubmit}
            >
              Add {(isFacilitator && "Facilitator") || "Member"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
