import React, { useState, useEffect, useRef } from "react";
import SessionCardSmall from "../Session/SessionCardSmall";
import SessionCardMedium from "../Session/SessionCardMedium";
import moment from "moment";
import CurrentDayMarker from "./CurrentDayMarker";
import useWindowSize from "./../../hooks/useWindowSize";
import GenericModal from "../GenericModal";
import { useCloseSessionsQuery } from "../../graphql/generated/graphql";
import LoadingSpinner from "../LoadingSpinner";import matchSorter, { rankings, caseRankings } from "match-sorter";
import SearchBar from "../ThemeLibrary/SearchBar";

// We will probably need to query only the sessions from the current month or query a total of x sessions based on the current date
// Another option is to provide one or two date pickers to assist what sessions to show
// Third option is to dinamically change the displayed sessions based on user scroll, some details will have to be discussed (harder implementation)
export default function Schedule() {
  // Make sure that the sessions array is sorted by date
  const [sessions, setSessions] = useState();
  
  const { loading, data, error } = useCloseSessionsQuery();
  
  const [selectedSession, setSelectedSession] = useState();
  
  const windowSize = useWindowSize();
  const [searchText, setSearchText] = useState("", []);
    const handleOnChange = e => {
      setSearchText(e.target.value);
    };
    const filterBySearchText = () =>
      matchSorter(sessions, searchText, { keys: ["group.name"] });
      
      const handleOnCardClick = session => {
        setSelectedSession(session);
      };
      
      const currentDate = moment();
      
      // Modal stuff
      const [modalIsOpen, setModalIsOpen] = useState(false, []);
      const [hideShtuff, setHideShtuff] = useState(false, []);
      
      // Tells if current day marker should be rendered, if it is false, then the marker was already rendered
      let shouldRenderCurrentDayMarker = true; // (Alec) This variable could have a better name
      
      // Handles scrolling to Current day marker
      const scrollToRef = useRef(null);
      
      // For preventing the page to scroll again on every update
      const [shouldScroll, setShouldScroll] = useState(true, []);
      
      const handleThemeChange = newTheme => {
        const newSessions = [];
        let sessionToUpdate = sessions.find(
          session => session.id === selectedSession.id
          );
          sessionToUpdate = { ...sessionToUpdate, theme: newTheme };
          sessions.forEach(session => {
            if (session.id !== sessionToUpdate.id) {
              newSessions.push(session);
            } else {
              newSessions.push(sessionToUpdate);
            }
          });
          setSessions(newSessions);
          setSelectedSession(sessionToUpdate);
        };
        
        useEffect(() => {
          if (sessions)
          if (windowSize.width < 768) {
            setModalIsOpen(true);
          }
        }, [selectedSession, windowSize.width]);
        
        // Scrolls to the selected session
        useEffect(() => {
          // console.log("we scrollin? outer")
          console.log(loading)
          // console.log(data)
          // console.log(sessions)
          if (!loading && data && sessions){
            // console.log("we scrollin? mid")
            
            if (scrollToRef.current && shouldScroll) {
            let scr = async (scrollToRef)=> {
              let sc = scrollToRef.current
              setTimeout(()=>{
                console.log(sc)
                // console.log("we scrollin?")
                window.scrollTo({
                  behavior: "smooth",
                  top: sc.offsetTop - window.innerHeight / 5,
                });

              },100)

             } 
             scr(scrollToRef)
          
              setShouldScroll(true);
            }
          }
        }, [shouldScroll, loading, data, sessions]);
        
        // Selects one of the sessions based on current date
        useEffect(() => {
          if (sessions)
          for (const session of sessions) {
            if (
              currentDate.isSame(moment(session.date), "day") ||
              currentDate.isBefore(moment(session.date), "day")
              ) {
                setSelectedSession(session);
                break;
              }
            }
          }, [sessions]);
          
          // Sets the state based on the incoming data (also sorts it)
          useEffect(() => {
            if (!loading && data) {
              setSessions(
                [...data.closeSessions].sort((a, b) => {
                  return a.date < b.date ? -1 : 1;
                })
                );
              }
            }, [loading]);
            
            if (loading || !sessions) return <LoadingSpinner />;
            
            return (
              <div className="container">
      <div className="row">
        <div className={`col-12  col-md-7`}>
          <h3 className="my-4">Full Schedule</h3>
          {!modalIsOpen && !hideShtuff &&
          <div style={{top:60}} className={`position-sticky px-3 box-shadow d-flex border border-info round-box  justify-content-center bg-dark sticky-top mt-5`}>
          <SearchBar isSchedule={true} handleOnChange={handleOnChange} searchText={searchText} />

          </div>
          }
          {searchText!=="" && filterBySearchText().map(session => {
            let renderCurrentDayMarkerNow = false;
            if (
              shouldRenderCurrentDayMarker &&
              (currentDate.isSame(moment(session.date), "day") ||
              currentDate.isBefore(moment(session.date), "day"))
              ) {
                shouldRenderCurrentDayMarker = false;
                renderCurrentDayMarkerNow = true;
              }
              
              return (
                <div key={session.id}>
                {renderCurrentDayMarkerNow && (<>
                  <CurrentDayMarker refProp={scrollToRef} />
                </>
                  )}
                <SessionCardSmall
                  clickableCard={true}
                  session={session}
                  showButton={!shouldRenderCurrentDayMarker ? true : false}
                  handleOnCardClick={handleOnCardClick}
                  filled={shouldRenderCurrentDayMarker}
                  selected={
                    selectedSession ? session.id === selectedSession.id : false
                  }
                />
              </div>
            );
          })}
          {!searchText && sessions.map(session => {
            let renderCurrentDayMarkerNow = false;
            if (
              shouldRenderCurrentDayMarker &&
              (currentDate.isSame(moment(session.date), "day") ||
              currentDate.isBefore(moment(session.date), "day"))
              ) {
                shouldRenderCurrentDayMarker = false;
                renderCurrentDayMarkerNow = true;
              }
              
              return (
                <div key={session.id}>
                {renderCurrentDayMarkerNow && (<>
                  <CurrentDayMarker refProp={scrollToRef} />
                 
                </>
                  )}
                <SessionCardSmall
                  clickableCard={true}
                  session={session}
                  showButton={!shouldRenderCurrentDayMarker ? true : false}
                  handleOnCardClick={handleOnCardClick}
                  filled={shouldRenderCurrentDayMarker}
                  selected={
                    selectedSession ? session.id === selectedSession.id : false
                  }
                />
              </div>
            );
          })}
        </div>
        <div className="col-md-5 p-4 sidebar d-none d-md-block">
          <div className="h-100 d-flex flex-row align-items-center">
            {selectedSession && (
              <GenericModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
              >
                <SessionCardMedium
                  handleThemeChange={handleThemeChange}
                  renderVertically={false}
                  session={selectedSession}
                ></SessionCardMedium>
              </GenericModal>
            )}
            {selectedSession && (
              <SessionCardMedium
                handleThemeChange={handleThemeChange}
                renderVertically={true}
                special={true}
                setHideShtuff={setHideShtuff}
                session={selectedSession}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
