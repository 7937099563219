import React, { useState } from "react";
import GenericModal from "../GenericModal";
import ScaleQuestion from "./ScaleQuestion";
import ScaleQuestionAlt from "./ScaleQuestionAlt";
import VisualAnalogQuestion from "./VisualAnalogQuestion";
import VasExample from "./VasExample";

export default function QuestionModal({handleSubmit}) {
  const handleAnswer = (num, answer) => {
    let tmp = questions.filter(item => num == item.num);
    tmp[0].answer = answer;
    setQuestions(
      [...questions.filter(item => num != item.num), tmp[0]].sort(function (
        a,
        b
      ) {
        return a.num - b.num;
      })
    );
    console.log(tmp);
  };

  const [modalIsOpen, setModalIsOpen] = useState(true, []);
  const [alert, setAlert] = useState("", []);
  const [questions, setQuestions] = useState([
   
    {
      num: 1,
      body: "Social Connection",
       anchormin:"No Social Connection",
      anchormax:"Most Social Connection",
      answer: "",
      style: "",
    },
    {
      num: 2,
      body: "Loneliness",
      anchormin:"Not Lonely",
      anchormax:"Very Lonely",
      answer: "",
      style: "",
    },
    {
      num: 3,
      body: "Happiness",
      anchormin:"Not Happy",
      anchormax:"Very Happy",
      answer: "",
      style: "",
    },
  ]);
  const handleLocalSubmit = e => {
    let isMissing = false;
    questions
      .filter(question => question.answer != "")
      .map(question => {
        question.style = "";
        setQuestions([
          ...questions.filter(item => question.num != item.num),
          question,
        ]);
        console.log(question);
      });
    let tmp = questions.filter(question => question.answer == "");
    tmp.map(question => {
      question.style = "box-shadow border border-danger";
      setQuestions(
        [...questions.filter(item => question.num != item.num), question].sort(
          function (a, b) {
            return a.num - b.num;
          }
        )
      );
      console.log(question);
      isMissing = true;
    });
    if (isMissing) {
      setAlert(
        <div className="alert alert-danger" role="alert">
          Please answer the missing questions!
        </div>
      );
    } else {
      handleSubmit(questions,false)
      setModalIsOpen(0 == 1);
      //change that to false buuuut also send answers to the great Mango storage unit
    }
  };
  const handleCancel = () => {
    handleSubmit(questions, true)
      setModalIsOpen(0 == 1);
  }
  return (
    <GenericModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
      {questions && (
        <div style={{minWidth:900}} className="round-box  box-shadow">
          <div className="card-header justify-content-center">
            <h4 className="text-center">
              Please click on the lines below at the location <br /> that
              matches how you are feeling at this time.
            </h4>
          </div>
          <div className="card-body">
            {alert}
            <VasExample />
            {questions.map(question => (
              <VisualAnalogQuestion
                handleAnswer={handleAnswer}
                key={question.num}
                question={question}
              ></VisualAnalogQuestion>
            ))}
            <div className="row justify-content-around">
              <button
                type="button"
                onClick={handleLocalSubmit}
                className="btn btn-primary"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-danger"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </GenericModal>
  );
}
