import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

export default function FontSizeButtons(props) {
  const { fontSize, handleChangeFont } = props;

  return (
    <div
      className={`position-fixed d-flex flex-column-reverse`}
      style={{ zIndex: 1 }}
    >
      <button
        style={{
          width: "40px",
          height: "60px",
          fontSize: "1.15em",
        }}
        className={`d-flex align-items-center justify-content-center btn btn-extra-radius btn-info p-0 mb-1 ${
          fontSize <= 1.0 && "disabled"
        }`}
        onClick={() => handleChangeFont("-")}
      >
        <FaMinus className="d-flex" />
      </button>
      <button
        style={{
          width: "40px",
          height: "60px",
          fontSize: "1.15em",
        }}
        className={`d-flex align-items-center justify-content-center btn btn-extra-radius btn-info p-0 mt-1 mb-2 ${
          fontSize >= 1.4 && "disabled"
        }`}
        onClick={() => handleChangeFont("+")}
      >
        <FaPlus className="d-flex" />
      </button>
    </div>
  );
}
