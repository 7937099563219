import React from "react";
import { useSessionModifiedSubscription } from "../graphql/generated/graphql";

const TestSubscription = () => {
  const { loading, data, error } = useSessionModifiedSubscription({
    variables: {
      id: "5fd1a663afb6864eb06e4be1",
    },
  });

  return (
    <div className="container my-5">
      <pre className="h3">DATA: {data && JSON.stringify(data, null, 2)}</pre>
      <pre className="h3">
        LOADING: {data && JSON.stringify(loading, null, 2)}
      </pre>
      <pre className="h3">ERROR: {data && JSON.stringify(error, null, 2)}</pre>
    </div>
  );
};

export default TestSubscription;
