import React from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";

export default function BlackoutModal(props) {
  const { modalIsOpen, setModalIsOpen,addStyle, addClass } = props;
  const safetyStyle = addStyle?addStyle:""
  const safeClass = addClass?addClass:""
  const afterOpenModal = () => {};

  const closeModal = () => {
    setModalIsOpen(false);
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      className={`generic-modal specialmodal bg-zero box-shadow round-box  position-relative ${safeClass}`}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      htmlOpenClassName={"ReactModal__Blackout--open"}
      
      closeTimeoutMS={300}
      style={{ zIndex: 2000, border: 0, maxHeight:"98vh"}}
    >

      <div className="w-100 h-100 round-box content">{props.children}</div>
    </Modal>
  );
}
