import React, { useState, useEffect } from "react";
import { useRemoveUserFromSessionMutation } from "../../../graphql/generated/graphql";

const RemoveMemberFromSession = props => {
  const { sessionId, setModalIsOpen, action, members } = props;

  const [removeUserFromSessionMutation] = useRemoveUserFromSessionMutation();

  const [errMsg, setErrMsg] = useState("", []);

  const handleSubmit = e => {
    e.preventDefault();
    for (let i = 0; i < members.length; i++) {
      removeUserFromSessionMutation({
        variables: {
          user: members[i].id,
          as: "member",
          session: sessionId,
        },
      });
    }
    setModalIsOpen(false);
  };

  return (
    <div className="round-box box-shadow align-self-center p-5">
      <h4>Confirmation: Members to be deleted</h4>
      {members.map((member, i) => {
        return <li key={i}>{member.name}</li>;
      })}

      <div className="form-group d-flex justify-content-center mt-3">
        <button
          disabled={members.length === 0}
          className="btn btn-extra-radius px-5 bg-info text-white"
          onClick={handleSubmit}
        >
          Remove Member
        </button>
      </div>
    </div>
  );
};

export default RemoveMemberFromSession;
