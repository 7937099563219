import React,{useContext} from "react";
import ThemeBigCard from "../../components/ThemeBigCard";
import { GiSaveArrow } from "react-icons/gi";
import { UserContext } from "../../../../../context/UserContext";
const DiscussionQuestions = props => {
  const { discussions, onThemeCardClick, isRunningSession, currentPage } = props;
  const { state } = useContext(UserContext);
  return (
    <>
      <div className="d-flex mb-5" >
        <h3>{state.user.role == "ROLE_FACILITATOR"&&( currentPage?`${currentPage+3}.`:`7.`)} Discussion</h3>

        {!isRunningSession ? (
          <button
            className="ml-2 bg-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 1)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
        )}
      </div>

      <ThemeBigCard
        discussions={discussions}
        isJavaTips={false}
        onThemeCardClick={() => onThemeCardClick("question")}
      />
    </>
  );
};

export default DiscussionQuestions;
