import React, { useState } from "react";
import { toast } from "react-toastify";
import { useHandoverMasterMutation } from "../../graphql/generated/graphql";
import GenericModal from "../GenericModal";

const Handoff = props => {
  const { close, you, sessionId, facilitators } = props;

  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false, []);
  const [selected, setSelected] = useState(null, []);

  const [handoffMaster] = useHandoverMasterMutation();
  const [disabledButtons, setDisabledButtons] = useState([]);

  const approveUser = (id, name) => {
    setDisabledButtons([...disabledButtons, id]);

    handoffMaster({
      variables: {
        session: sessionId,
        newMaster: id,
      },
    })
      .then(res => {
        if (res.errors) {
          console.log(res.errors);
          toast.error("Oops, something went wrong");
        } else {
          toast.success(`${name} is now in control`);
        }
      })
      .catch(err => {
        toast.error("Oops, something went wrong");
        console.log("ummm why is dis wrong" + err);
      })
      .finally(() => {
        close();
        setTimeout(500, () => {
          setDisabledButtons(disabledButtons.filter(b => b !== id));
        });
      });
  };

  return (
    <div className="container p-5">
      <h1 className="text-center">Pass Session Control</h1>
      <h4 className="text-center">
        You can select a Co-facilitator to take over the control of this
        session.
      </h4>
      <div className="my-5 bg-clear card p-3">
        {!facilitators || facilitators.length === 0 ? (
          <h4 className="text-center">
            There are no Co-facilitators available to take over this session.
          </h4>
        ) : (
          facilitators
            .filter(f => f.id !== you)
            .map(u => (
              <div
                key={u.id}
                className="row w-100 mx-auto d-flex align-items-center my-2"
              >
                <div className="col-6 h4">
                  <span className="mr-3">
                    {u.firstName} {u.lastName}
                  </span>
                </div>
                <div className="col-3">
                  <button
                    disabled={
                      disabledButtons.find(b => b === u.id) ? true : false
                    }
                    className="btn btn-success w-100"
                    onClick={() => {
                      setSelected({ ...u });
                      setWarningModalIsOpen(true);
                    }}
                  >
                    Handoff
                  </button>
                </div>
              </div>
            ))
        )}
      </div>

      <GenericModal
        style={{ width: "100px !important" }}
        className={"col-6 w-25"}
        addClass={" bg-clear gray-filled"}
        modalIsOpen={warningModalIsOpen}
        setModalIsOpen={setWarningModalIsOpen}
      >
        <div className="round-box  box-shadow align-self-center p-5">
          <h5>Are you sure your co-facilitator is ready?</h5>
          <h5>
            Once you pass the control only the person you pass it to can give it
            back
          </h5>

          <div className={"row mt-4"}>
            <div className={"col d-flex justify-content-center"}>
              <button
                onClick={() => {
                  approveUser(
                    selected.id,
                    `${selected.firstName} ${selected.lastName}`
                  );
                  setWarningModalIsOpen(false);
                }}
                className={"btn btn-success w-25 mx-3 box-shadow "}
              >
                Yes
              </button>
              <button
                onClick={() => setWarningModalIsOpen(false)}
                className={"btn btn-danger  w-25 mx-3 box-shadow"}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </GenericModal>
    </div>
  );
};

export default Handoff;
