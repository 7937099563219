import React from "react";
import $ from "jquery";
import { Link, NavLink } from "react-router-dom";
import { MdExitToApp } from "react-icons/md";
import { FaListAlt, FaEdit } from "react-icons/fa";


export default function Navbar(props) {
  const { setDisplay } = props
  const handleDisplay = (choice)=>{
setDisplay(choice)

  }
  return (
    <div>
      <nav class="navbar navbar-dark  bg-dark ">
        <div className="container align-items-center justify-content-between">
          <p className="navbar-brand m-0 align-self-center">Admin Panel</p>
            <div className="navbar-nav row d-flex flex-row w-75 ml-auto   align-items-center justify-content-start">

            <span role={"button"} onClick={()=>{handleDisplay("user")}} className="col col-2 nav-item  nav-link">
                  <span className="mr-2"> <FaListAlt /></span>
           <span>  User lists</span>
              </span>
              
            <span role={"button"} onClick={()=>{handleDisplay("quote")}} className="col col-2 nav-item  nav-link">
             <span className="mr-2"> <FaEdit /></span>
           <span> Quote editor</span>
              </span>
            
            <span role={"button"} onClick={()=>{handleDisplay("theme")}} className="col col-2 nav-item  nav-link">
             <span className="mr-2"> <FaEdit /></span>
           <span> Add theme</span>
              </span>
            </div>
          <ul className="navbar-nav justify-content-right">
            <li className="nav-item">
              <Link className="nav-item nav-link" to="/logout">
                <span className="mr-2">
                  <MdExitToApp />
                </span>
                <span>Log out</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
