import React, { useContext, useState } from "react";

import { TokenRefreshLink } from "apollo-link-token-refresh";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  Observable,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import jwtDecode from "jwt-decode";
import { createUploadLink } from 'apollo-upload-client';
import { getAccessToken, setAccessToken } from "../../auth/accessToken";
import { getMainDefinition } from "@apollo/client/utilities";

// Inside of this file, we make use of a getter and setter to manipulate and read a token that is stored in memory as variable,
// we also keep the same token in state inside of the UserContext. The need of the first variable is due to the fact that after setting
// the state the value that we read right after might not be the one we just set because react "queus that update"
// By using a regular variable we are able to write and read the variable in sequence

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

const tokenRefreshLink = new TokenRefreshLink({
  accessTokenField: "accessToken",
  isTokenValidOrUndefined: () => {
    // const token = state.accessToken;
    const token = getAccessToken();

    // console.log("USING REFRESH TOKEN");
    if (!token) return true;

    try {
      const { exp } = jwtDecode(token);
      if (Date.now() >= exp * 1000) {
        return false;
      } else {
        return true;
      }
    } catch (err) {
      return false;
    }
  },
  fetchAccessToken: () => {
    return fetch(process.env.REACT_APP_REFRESH_TOKEN, {
      method: "POST",
      credentials: "include",
    });
  },
  handleFetch: accessToken => {
    setAccessToken(accessToken);
    // dispatch({
    //   type: SET_ACCESS_TOKEN,
    //   payload: { accessToken: accessToken },
    // });
  },
  handleError: err => {
    console.warn("Refresh token is invalid, try to sign in again.");
    // dispatch({ type: CLEAR });
    setAccessToken("");
  },
});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(operation => {
          // console.log("BEFORE", operation);
          // const definition = getMainDefinition(operation.query);
          // console.log("definition", definition);
          // const accessToken = state.accessToken;
          const accessToken = getAccessToken();

          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            });
          }
          // console.log("AFTER", operation);
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
  credentials: "include",
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WEBSOCKET,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => {
      return {
        token: `bearer ${getAccessToken()}`,
      };
    },
  },
});

// const subscriptionMiddleware = {
//   applyMiddleware(options, next) {
//     options.auth = () => getAccessToken();
//     next();
//   },
// };

// wsLink.subscriptionClient.use([subscriptionMiddleware]);

const link = split(
  // splits the operations to two different links based on type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_API, credentials: "include",}),
  httpLink
);

const client = new ApolloClient({
  link: ApolloLink.from([
    tokenRefreshLink,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) console.log(graphQLErrors);
      if (networkError) console.log(networkError);
    }),
    requestLink,
    link,
  ]),
  defaultOptions,
  cache: new InMemoryCache(),
});

const Apollo = props => {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default Apollo;
