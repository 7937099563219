import React, { useState, useEffect } from "react";
import Video, { LocalDataTrack,connect, createLocalTracks } from "twilio-video";
import { setTokenSourceMapRange } from "typescript";
import Participant from "./Participant";
import ParticipantSmall from "./ParticipantSmall";
import { toast } from "react-toastify";
import Slider from "@material-ui/core/Slider";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import data from "@iconify/icons-emojione-v1/wind-chime";


const useStyles = makeStyles(theme => ({
  root: {
    width: "95%",
    paddingTop:"0.3em"
    
  },
  margin: {
    height: "30%",
    paddingTop:"0%"  },
}));
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
  root: {
    width:"8vw",
    color: "grey",
    height: 8,
    paddingY: 10,
    
  },
  thumb: {
    height: "1rem",
    width: "1rem",
    backgroundColor: "lightGrey",
    border: "2px solid currentColor",
    marginTop: -4,
    marginLeft: -7,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
    //marginbottom:1
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);



const Room = ({ roomName, token, handleLogout, isSilent, setToken, sessionId, myRemoteMuteState, setParticipantList,myRemoteVidState,myRemotePalamiState, myRemoteRoomState, zoomData }) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [callVol, setCallVol] = useState(1);
  const [audio, setAudio] = useState({ name: "microphone" });
  const [dominantSpeaker, setDominantSpeaker] = useState();
  const [remoteParticipants, setRemoteParticipants] = useState("");
const [muteState, setMuteState] = useState(false)
const [vidState, setVidState] = useState(false)
const [state, setstate] = useState([])
const [wait, setWait] = useState(false)

  useEffect(() => {
    const participantConnected = participant => {
     
      setParticipants(prevParticipants => [
        ...prevParticipants.filter(p => p.identity !== participant.identity),
        participant,
      ]);
      // console.log(participant)
    };

    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };
    const dominantSpeakerChanged = participant => {
      setDominantSpeaker(participant);
    };
    
// const dataTrack =new LocalDataTrack()
// createLocalTracks({
//   audio: true,
//   video: { name: "camera"  },

// }).then(localTracks => {
//   return connect(token, {
//     name: roomName,
//     tracks: [...localTracks,dataTrack],
//     dominantSpeaker: true,
//   });
// }).then(room => {
//   setRoom(room);

//       room.on("dominantSpeakerChanged", dominantSpeakerChanged);
//       room.on("participantConnected", participantConnected);
//       room.on("participantDisconnected", participantDisconnected);
//       room.participants.forEach(participantConnected);
// });

    Video.connect(token, {
      name: roomName,
      audio: audio,
      video: { name: "camera" },
      dominantSpeaker: true,
    }).then(room => {
      setRoom(room);

      room.on("dominantSpeakerChanged", dominantSpeakerChanged);
      room.on("participantConnected", participantConnected);
      room.on("participantDisconnected", participantDisconnected);
      room.participants.forEach(participantConnected);
      
    }).catch(err=>{
      console.log(err)
    })

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, audio, token]);
  const handleMute = muteState => {
    if (muteState) {
      setMuteState(true)
       handleDataTrackState("audio",true)
      room.localParticipant.audioTracks.forEach(audioTrack => {
        audioTrack.track.disable();
      });
    } else {
      setMuteState(false)
       handleDataTrackState("audio",false)
      room.localParticipant.audioTracks.forEach(audioTrack => {
        audioTrack.track.enable();
      });
    }
  };
  const handleMuteVid = vidState => {
    if (vidState) {
      setVidState(true)
       handleDataTrackState("video",true)
      room.localParticipant.videoTracks.forEach(videoTrack => {
        videoTrack.track.disable();
      });
    } else {
      setVidState(false)
       handleDataTrackState("video",false)
      room.localParticipant.videoTracks.forEach(videoTrack => {
        videoTrack.track.enable();
      });
    }
  };
  const handleFullMute = flag => {
    if (!flag) {
      room.localParticipant.tracks.forEach(function (trackPublication) {
        if (trackPublication.track.kind == "audio") {
          trackPublication.track.disable();
        }
      });
    } else {
      room.localParticipant.tracks.forEach(function (trackPublication) {
        if (trackPublication.track.kind == "audio") {
          trackPublication.track.enable();
        }
      });
    }
  };
  //console.log(myRemoteMuteState)
const handleDataTrackState = (status,flag) =>{


   room.localParticipant.dataTracks.forEach(dataTrack=>{
     console.log(`${status} ${flag}`)
     dataTrack.track.send(`${status} ${flag}`)
   })

}





  useEffect(() => {
    if(room){

      if (isSilent ) {
        handleDataTrackState("audio",true)
        room.localParticipant.audioTracks.forEach(audioTrack => {
          audioTrack.track.disable();
        });
      } else if (!muteState&& !myRemoteMuteState) {
        
        handleDataTrackState("audio",false)
        room.localParticipant.audioTracks.forEach(audioTrack => {
          audioTrack.track.enable();
        });
      }
    }
  }, [isSilent]);
  useEffect(() => {
    if(room){
      console.log("remote mute state changed")

      if ( myRemoteMuteState) {
 handleDataTrackState("audio",true)
        room.localParticipant.audioTracks.forEach(audioTrack => {
          audioTrack.track.disable();
        });
      } else if (!muteState && !isSilent&& myRemoteMuteState !== null) {
 handleDataTrackState("audio",false)
        room.localParticipant.audioTracks.forEach(audioTrack => {
          audioTrack.track.enable();
        });
      }else if(!myRemoteMuteState && muteState){
        toast.error("You're muted")
      }
    }
  }, [myRemoteMuteState,room]);
  useEffect(() => {
    if(room){
      console.log("remote vid state changed")

      if ( myRemoteVidState) {
 handleDataTrackState("video",true)
       room.localParticipant.videoTracks.forEach(videoTrack => {
        videoTrack.track.disable();
        // console.log(videoTrack.track)
      });
      } else if (!vidState && myRemoteVidState !== null) {
 handleDataTrackState("video",false)
        room.localParticipant.videoTracks.forEach(videoTrack => {
        videoTrack.track.enable();
      });
      }
    }
  }, [myRemoteVidState, room]);
  const [displayStyleList, setDisplayStyleList] = useState([])
  useEffect(() => {
    
    // console.log(participants)
    if(participants ){
      setParticipantList([...participants.filter(p=> (p.state != "disconnected"))])
      
    }else if(participants.length == 0){
      setParticipantList([])

    }

  }, [participants, room, dominantSpeaker]);
  useEffect(() => {
 let tmp = []
 if(dominantSpeaker){
  participants
  .filter(participant => (participant.sid != dominantSpeaker.sid && participant.state !== "disconnected" ))
  .map(participant => {
tmp.push(<ParticipantSmall
    callVol={callVol}
    handleDataTrackState={handleDataTrackState}
    participants={participants}
    key={participant.sid}
    participant={participant}

    sessionId={sessionId}
    />)
  }
    
    )
 }else{
  participants.filter(p=> p.state !== "disconnected").map(participant => {
    tmp.push(
    <ParticipantSmall 
    

    sessionId={sessionId}
    handleDataTrackState={handleDataTrackState} 
    callVol={callVol} 
    participants={participants} 
    key={participant.sid} 
    participant={participant} 
    
    />
    )}
    )
 }
 setRemoteParticipants([...tmp])
  //  setRemoteParticipants(
  //     dominantSpeaker
  //     ? participants
  //     .filter(participant => (participant.sid != dominantSpeaker.sid && participant.state !== "disconnected" ))
  //     .map(participant => (
  //       <ParticipantSmall
  //       callVol={callVol}
  //       handleDataTrackState={handleDataTrackState}
  //       participants={participants}
  //       key={participant.sid}
  //       participant={participant}


  //       />
  //       ))
  //       : participants.filter(p=> p.state !== "disconnected").map(participant => (
  //         <ParticipantSmall 
          


  //         handleDataTrackState={handleDataTrackState} 
  //         callVol={callVol} 
  //         participants={participants} 
  //         key={participant.sid} 
  //         participant={participant} 
          
  //         />
  //         ))
  //         );
    
        }, [participants, dominantSpeaker]);
       
const classes = useStyles();
  const [value, setValue] = useState(100);
const handleChange = (event, newValue) => {
  if(newValue==0 && callVol !=0){
    toast.error("Warning: Muting the call does not mute you mic. If your mic is still on everyone else can still hear you.", {
     
      autoClose: 7000,
      
      })
  }
    setCallVol(newValue / 100);
   
  };
  useEffect(() => {
    if(myRemoteMuteState==true && myRemoteRoomState==true){
      setCallVol(0)
    }
    else{
      
      setCallVol(100/100)
    }
  }, [myRemoteMuteState, myRemoteRoomState])

  return (
    <div   className="row position-relative w-100 ">
{(myRemoteMuteState==false || myRemoteRoomState==false) &&
<div style={{width:"15vw !important"}} className={`col offset-9  align-self-bottom ml-1 mb-0 mt-1`}>

<div className={`row d-flex align-content-center`}>
<div className={`col-auto align-self-center pr-2`}>
Video Chat Volume
                </div>
<div className={`col pl-0`}>
<div className={classes.root}>
            <Grid className={classes.margin} container>
              <Grid item xs>
                <PrettoSlider
                  valueLabelDisplay="off"
                  aria-label="pretto slider"
                  value={callVol*100}
                  onChange={handleChange}
                  aria-labelledby="continuous-slider"
                />
              </Grid>
            </Grid>
          </div>
          </div>
          </div>
  </div>}
      <div className="col-12  p-0 d-flex  align-items-end justify-content-center">
        {room != "" && dominantSpeaker != undefined && (
          <Participant
            callVol={callVol}
            vidHead={"Now Speaking: "}
            hasMute={false}
            key={dominantSpeaker.sid}
            participant={dominantSpeaker}
            sessionId={sessionId}
            handleDataTrackState={handleDataTrackState}
            zoomData={zoomData}
          />
        )}
        {room ? (
          <Participant
          callVol={callVol}
            vidHead={"You"}
            hasMute={true}
            isSilent={isSilent}
            sessionId={sessionId}
            myRemoteMuteState={myRemoteMuteState}
            myRemoteVidState={myRemoteVidState}
            myRemotePalamiState={myRemotePalamiState}
            handleMute={handleMute}
            handleMuteVid={handleMuteVid}
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            zoomData={zoomData}
          />
        ) : (
          ""
        )}
      </div>

      <div className="row  m-1 col-12 d-flex justify-content-center">
        <div className={"col col-12 "}>
          <div className={"d-flex justify-content-center"}></div>
        </div>
        {remoteParticipants}
       
      </div>
    </div>
  );
};

export default Room;
