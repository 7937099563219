import React, { useState, useContext, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ReactDOM from 'react-dom';

import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { FaVolumeOff } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";
import { UserContext } from "./../../context/UserContext"
import { RunSessContext } from "./../../context/RunSessContext"

const useStyles = makeStyles(theme => ({
  root: {
    width: "95%",
    paddingTop:"0.6em"
    
  },
  margin: {
    height: "30%",
    paddingTop:"0%"  },
}));
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
  root: {
    color: "grey",
    height: 8,
    paddingY: 10,
    
  },
  thumb: {
    height: "1rem",
    width: "1rem",
    backgroundColor: "lightGrey",
    border: "2px solid currentColor",
    marginTop: -4,
    marginLeft: -7,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
    //marginbottom:1
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function SongPlayer({
  songurl,
  audioRef,
  isSyncFlag,
  handleMusicPlay,
  funcProp,
 
}) {
  const classes = useStyles();
  const [value, setValue] = useState(100);
  const [playButton, setPlayButton] = useState(
    <FaPlay style={{ fontSize: "1em", marginRight: 3 }}></FaPlay>
  );
  const {state}  = useContext(UserContext)
  const {state: sessState}  = useContext(RunSessContext)
  
  const handleChange = (event, newValue) => {
    console.log(audioRef.current.volume);
    setValue(newValue);
    audioRef.current.volume = newValue / 100;
  };
  useEffect(() => {
    if(sessState.hardMute==true){
      setValue(0);
      audioRef.current.volume = 0
      
      
    }else{
       setValue(100);
       audioRef.current.volume = 1
       
   }
    
    }, [sessState, audioRef.current])
    const handleMute = () => {
      if (audioRef.current.volume === 0) {
        console.log(audioRef);
        setValue(100);
        audioRef.current.volume = 1;
      } else {
        console.log(audioRef);
        setValue(0);
        audioRef.current.volume = 0;
      }
      // let isMuted = audioRef.current.muted
      // audioRef.current.muted = !isMuted
      // setValue(!isMuted?0:unMuteValue);
    };
    useEffect(() => {
      
      if(audioRef.current){
        
        ReactDOM.findDOMNode(audioRef.current).addEventListener("ended", function(){
          handleMusicPlay(false, funcProp);
          console.log("ended");
        });
        // ReactDOM.findDOMNode(audioRef.current).addEventListener("play", function(){
        //     console.log(sessState)
        //   console.log("fired play");
        //   if(sessState.hardMute==true){
        //      setValue(0);
        //      audioRef.current.volume = 0
             
             
        //     }else{
        //       audioRef.current.volume = 1
              
        //   }
        // });
    //     ReactDOM.findDOMNode(audioRef.current).addEventListener("playing", function(){
    //       console.log(sessState)
    //     console.log("fired playing");
    //     if(sessState.hardmute==true){
    //       setValue(0);
    //       audioRef.current.volume = 0
          
    //     }
    //  });
        

    }
}, [audioRef]);
  const handlePlay = () => {
    if (audioRef.current.paused ) {
      audioRef.current.play();
      setPlayButton(
        <FaPause style={{ fontSize: "1em", marginRight: 3 }}></FaPause>
      );
      if (isSyncFlag) {
        handleMusicPlay(true, funcProp);
      }
    } else {
      audioRef.current.pause();
      setPlayButton(
        <FaPlay style={{ fontSize: "1em", marginRight: 3 }}></FaPlay>
      );
      if (isSyncFlag) {
        handleMusicPlay(false, funcProp);
      }
    }
  };
  return (
    <div
      className={
        "row justify-content-center ml-1 d-flex my-3  bg-zero border border-white round-box box-shadow"
      }
    >
      <div className="col-8 pr-0">
        <div className={"row"}>
            {state.user.role=="ROLE_MEMBER"?<></>:
          <div
            role="button"
            className={`col  col-1 align-self-center align-items-center justify-content-center mb-1 pb-1 align-self-start text-success  rounded-circle `}
            onClick={handlePlay}
            
          >
            {state.user.role=="ROLE_MEMBER"?<></>:playButton}
          </div>
            }

          <div className={"col  w-50 pt-1 "}>
            <audio
              //   style={{width:"100%"}}
              className={"w-100 "}
              style={{backgroundColor:"rgba(0,0,0,0) !important"}}
              ref={audioRef}
              muted={sessState.hardmute}
              controls
              //autoPlay={false}
              controlsList="nodownload"
              src={"https://d30mg0zmvh01j5.cloudfront.net" + songurl}
            />
          </div>
        </div>
      </div>

      <div className={"col-4 pl-0 my-1"}>
        <div className={"row align-items-center justify-content-around"}>
          <div className={"col col-2 mr-3"}>

          <div
            role="button"
            className={` my-auto  pr-0 align-self-center rounded-circle ${
              audioRef.current != null && audioRef.current.volume <= 0.01
                ? "text-danger"
                : "text-success"
            }`}
            onClick={handleMute}
          >
            <FaVolumeOff
              style={{ fontSize: "2rem", paddingRight: 1 }}
            ></FaVolumeOff>
          </div>
          </div>
          <div className={"col col-7  pl-0  align-items-center"}>

          <div className={classes.root}>

            <Grid className={classes.margin} container>
              <Grid item xs>
                <PrettoSlider
                  valueLabelDisplay="off"
                  aria-label="pretto slider"
                  value={value}
                  onChange={handleChange}
                  aria-labelledby="continuous-slider"
                />
              </Grid>
            </Grid>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SongPlayer;
