import React, { useState } from "react";
import {
  FaRegHandshake,
  FaMapPin,
  FaQuoteRight,
  FaBookmark,
  FaWind,
  FaChalkboardTeacher,
  FaHandHoldingHeart
} from "react-icons/fa";

import {
  BsMusicNote,
  BsPeopleFill,
  BsHeartFill,
  BsTrashFill,
} from "react-icons/bs";
import {
  WiSunrise
} from "react-icons/wi";
import { HiThumbUp } from "react-icons/hi";
import { AiOutlineCloseSquare, AiOutlinePlaySquare } from "react-icons/ai";

export default function RolePicker(props) {
  const {
    sessionRole,
    handleAssignRoleToMember,
    person,
    setModalIsOpen,
    rolesAvailable,
    getRoleDict,
    isReflections
  } = props;

  const [selectedRole, setSelectedRole] = useState(sessionRole, []);
  

  const handleSelectRole = newRole => {
    setSelectedRole(newRole);
  };

  // const handleAssignRole = () => {
  //   setPersonRole(selectedRole);
  //   setModalIsOpen(false);
  // };

  const allRoles =  isReflections==true? [
   { name: "Read Serenity Saying", icon: <WiSunrise className="d-flex" /> },
  { name: "Read Guidelines", icon: <FaMapPin className="d-flex" /> },
  { name: "Choose Reflections Topic", icon: <FaBookmark className="d-flex" /> },
  { name: "Read Group Affirmation", icon: <HiThumbUp className="d-flex" /> },
  { name: "Read Reflection", icon: <FaChalkboardTeacher className="d-flex" /> },
  { name: "Share Gratitude", icon: <FaHandHoldingHeart className="d-flex" /> },
  { name: "None", icon: <AiOutlineCloseSquare className="d-flex" /> },
  
   
  ]:[
     { name: "Read welcome", icon: <FaRegHandshake className="d-flex" /> },
    { name: "Read guidelines", icon: <FaMapPin className="d-flex" /> },
    { name: "Choose theme", icon: <BsPeopleFill className="d-flex" /> },
    { name: "Read Getting Centered", icon: <BsMusicNote className="d-flex" /> },
    { name: "Read quote", icon: <FaQuoteRight className="d-flex" /> },
    { name: "Choose song", icon: <FaBookmark className="d-flex" /> },
    { name: "None", icon: <AiOutlineCloseSquare className="d-flex" /> },
    
];
  console.log(person)

  return (
    <div style={{ maxWidth: "600px" }} className="p-3 row mx-auto">
      {
        allRoles.map((role, i) => {
        let roleFlags = getRoleDict
         let inUse = roleFlags[role.name]
        return (
          <div key={i}  className={`col-6 px-2 py-3 person-role-card ${ inUse !== false&&role.name!==person.sessionRole ? "d-none" : "" }  `} >
            <div
              onClick={() => handleSelectRole(role.name)}
              className={`border round-box font-size-1 px-2 py-3 d-flex align-items-center clickable-card 
              ${
                role.name === sessionRole
                  ? " bg-primary text-white box-shadow"
                  : ""
              }
              ${
                selectedRole === role.name
                  ? " selected-card-bg selected-card box-shadow"
                  : inUse
                  ? "gray-filled-1 text-muted"
                  : ""
              }`}
            >
              <span className="ml-2 mr-3 font-size-5">{role.icon}</span>
              <h5 className="mb-0">{role.name}</h5>
            </div>
          </div>
        );
      })}
      <div className="d-flex col-12">
        <button
          className="btn btn-primary btn-extra-radius mx-auto mt-3 px-5 font-size-1"
          onClick={() => handleAssignRoleToMember(person.id, selectedRole)}
        >
          Offer Role
        </button>
      </div>
    </div>
  );
}
