import React, { useRef, Component } from 'react'
import { Media, Player, controls, utils } from 'react-media-player'


function MediaPlayer({song})  {

        const audioRef = useRef(null);
        const {
          PlayPause,
          CurrentTime,
          Progress,
          SeekBar,
          Duration,
          MuteUnmute,
          Volume,
          Fullscreen,
        } = controls
        const { keyboardControls } = utils
        const handleMutings = (targetAudio)=>{
            console.log( targetAudio.context.media)

        }
    return (
      <Media>
        {mediaProps => (
          <div
            className="media"
            onKeyDown={keyboardControls.bind(null, mediaProps)}
          >
             
            <Player ref={audioRef}  src={"https://d30mg0zmvh01j5.cloudfront.net"+song.url} className="media-player" />
            <div className="media-controls">
              <PlayPause className="btn btn-primary" />
              <CurrentTime  />
              <SeekBar style={{width:500}}/>
              <Duration />
              <MuteUnmute />
              <Volume style={{width:200}} />
              <button onClick={()=>{handleMutings(audioRef.current)}}>mute</button>
            </div>
          </div>
        )}
      </Media>
    )
  }

export default MediaPlayer