import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { CLEAR, UserContext } from "../../context/UserContext";
import { setAccessToken, setResetToken } from "./../../auth/accessToken";

import PrivateRoute from "./../PrivateRoute";
import Navbar from "../Navbar";
import LoginForm from "../Login/LoginForm";
import MemberProfile from "../MemberProfile/MemberProfile";
import MemberProfileEdit from "../MemberProfile/MemberProfile-edit";
import LogoutPage from "../Login/LogoutPage";
import Home from "../Home/Home";
import Schedule from "../Schedule/Schedule";
import ThemeLibrary from "../ThemeLibrary/ThemeLibrary";
import ResourceLibrary from "../Resource/ResourceLibrary";
import AdminContainer from "../Admin/AdminContainer";
import RunningSessionContainer from "../RunningSession/RunningSessionContainer";
import Manage from "../Manage/Manage";
import Group from "../Manage/Group";
import RegistrationForm from "../Registrations/RegistrationForm";
import ThemeContainer from "../RunningSession/Theme/ThemeContainer";
import PlaygroundContainer from "../DevPlayground/PlaygroundContainer"

import { ROLE_FACILITATOR, ROLE_MEMBER, ROLE_ADMIN,ROLE_MOD } from "../../auth/roles";
import { useLogoutMutation } from "../../graphql/generated/graphql";
import moment from "moment";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast,  cssTransition  } from "react-toastify";
import SwitchUser from "../SwitchUser";
import WaitingRoom from "../WaitingRoom";
import OptOut from "../OptOut";
import TestSubscription from "../TestSubscription";
import RequestReset from "../PasswordReset/RequestReset";
import ResetPasswordForm from "../PasswordReset/ResetPasswordForm";
import ErrorBoundary from "../ErrorBoundary";



const bounce = cssTransition({
  enter: "animate__bounceIn",
  exit: "animate__bounceOut"
});



export const Routes = () => {
  const [logout, { client }] = useLogoutMutation();

  const { dispatch, state } = useContext(UserContext);

  const logoutProcedure = async () => {
    await logout();
    await client.clearStore(); // Clears the apollo cache
    setAccessToken("");
    //setResetToken("");
    dispatch({ type: CLEAR });
  };

  // prettier-ignore
  const routesConfig = [

    // path: "/admin", roles: [ROLE_ADMIN], component: AdminContainer, exact: true },
    { path: "/home", roles: [ROLE_MEMBER, ROLE_FACILITATOR, ROLE_MOD], component: Home, exact: true },
    { path: "/theme_library", roles: [ROLE_FACILITATOR], component: ThemeLibrary, exact: true },
    { path: "/theme/:id", roles: [ROLE_FACILITATOR], component: ThemeContainer, exact: true },
    { path: "/resources", roles: [ROLE_FACILITATOR], component: ResourceLibrary, exact: true },
    { path: "/session/:id", roles: [ROLE_MEMBER, ROLE_FACILITATOR, ROLE_MOD], component: RunningSessionContainer, exact: true },
    { path: "/optout/:id", roles: [ROLE_MEMBER, ROLE_FACILITATOR, ROLE_MOD], component: OptOut, exact: true },
    { path: "/manage", roles: [ROLE_FACILITATOR, ROLE_MOD], component: Manage, exact: true },
    { path: "/group/:id", roles: [ ROLE_FACILITATOR, ROLE_MOD], component: Group, exact: true },
    { path: "/profile", roles: [ROLE_MEMBER, ROLE_FACILITATOR], component: MemberProfile, exact: true },
    { path: "/profile-edit", roles: [ROLE_MEMBER, ROLE_FACILITATOR], component: MemberProfileEdit, exact: true },
    { path: "/schedule", roles: [ROLE_MEMBER, ROLE_FACILITATOR, ROLE_MOD], component: Schedule, exact: true },
    { path: "/switchuser", roles: [ROLE_FACILITATOR], component: SwitchUser, exact: true },
    { path: "/waitingroom/:id", roles: [ROLE_MEMBER, ROLE_FACILITATOR], component: WaitingRoom, exact: true, },
    { path: "/test", roles: [ROLE_MEMBER, ROLE_FACILITATOR], component: TestSubscription, exact: true },
    { path: "/register", roles: [ROLE_FACILITATOR], component: RegistrationForm, exact: true },
    { path: "/playground", roles: [ROLE_FACILITATOR,ROLE_MEMBER], component: PlaygroundContainer, exact: true },
    // { path: "/", roles: [ROLE_USER, ROLE_FACILITATOR], component: OrderPage, exact: true }, // This is redirect
  ];

  const renderRoutes = routesConfig => {
    return routesConfig.map(({ path, roles, component, exact }) => (
      <PrivateRoute
        key={path}
        path={path}
        component={component}
        roles={roles}
        exact={exact}

      />
    ));
  };

  if (!state.accessToken) {
    logoutProcedure();
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          transition={bounce}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />

        <Switch>
          <Route path="/" exact component={LoginForm} />
          <Route path="/register" exact component={RegistrationForm} />
          <Route path="/recover_password" exact component={RequestReset} />
          <Route path="/reset/:id" exact component={ResetPasswordForm}/>
          <Route path="/optout/:id" exact component={OptOut}/>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    );
  }
  // A WILD CHUNKY IF CONDITION APPEARED
  // IT SAID -> PLEASE HELP ME LOSE SOME WEIGHT :'(
  if (
    state.accessToken &&
    state.user.role !== ROLE_ADMIN &&
    (!state.user.status ||
      (state.user.status == true && moment().isAfter(state.user.validUntil)))
  ) {
    let reason = !state.user.status ? "na" : "exp";
    logoutProcedure();
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={6000}
          hideProgressBar={false}
          newestOnTop={false}
          transition={bounce}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />

        <LoginForm reason={reason} />
      </div>
    );
  }
  if (state.accessToken && state.user.role == ROLE_ADMIN) {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={8000}
          hideProgressBar={false}
          newestOnTop={false}
          transition={bounce}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
      <Switch>
        <Route path="/login" component={LoginForm} />
        <Route path="/logout" component={LogoutPage} />

        <PrivateRoute
          path={"/admin"}
          component={AdminContainer}
          roles={ROLE_ADMIN}
          exact={true}
        />
        <Route path="/">
          <Switch>
            <PrivateRoute roles={ROLE_ADMIN}>
              <Redirect to="/admin" />
            </PrivateRoute>
          </Switch>
        </Route>
      </Switch>
       </>
    );
  }
  return (
    <Switch>
      {/* Not sure if the route below was ever being used */}
      {/* <Route path="/login" component={LoginForm} />  */}
      <Route path="/logout" component={LogoutPage} />
      {/*<Route path="/register" component={RegistrationForm} />*/}
      <Route path="/recover_password">
        <div>Recover password not implemented</div>
      </Route>
      <PrivateRoute
        path={"/admin"}
        component={AdminContainer}
        roles={ROLE_ADMIN}
        exact={true}
        
      />
      <Route path="/">
        <Navbar />

        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          transition={bounce}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
      <ErrorBoundary>

        <Switch>
          {renderRoutes(routesConfig)}

          <Route>
            <Redirect to="/home" />
          </Route>
        </Switch>

      </ErrorBoundary>

      </Route>
    </Switch>
  );
};
