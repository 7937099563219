import React, { useState } from "react";
import ThemeSelector from "./ThemeSelector"

export default function QuoteEditor(props) {
//const {themes} = props

//console.log(themes)
  return (
      <div className={`bg-cleard round-box box-shadow m-5 border useBorder col `}>

          <ThemeSelector />
      </div>
  );
}
