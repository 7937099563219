import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { setAccessToken } from "../auth/accessToken";
import {
  useSubUsersQuery,
  useSwitchUserMutation,
} from "../graphql/generated/graphql";
import { useHistory } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";
import { SET_ACCESS_TOKEN, UserContext } from "../context/UserContext";

const SwitchUser = () => {
  const { dispatch } = useContext(UserContext);

  const history = useHistory();

  const { error, loading, data } = useSubUsersQuery();

  const [switchUser, { client }] = useSwitchUserMutation();

  const [selectedUser, setSelectedUser] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnChange = e => {
    setSelectedUser(e.target.value);
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    setIsSubmitting(true);
    if (selectedUser) {
      switchUser({ variables: { user: selectedUser } })
        .then(res => {
          if (res.errors) toast.error("Oops, something went wrong!");
          else {
            const _user = data.subUsers.find(u => u.id === selectedUser);
            toast.success(
              `Successfully switched to user: ${_user.firstName} ${_user.lastName}`
            );
            client.clearStore();
            setAccessToken(res.data.switchUser.token);
            dispatch({
              type: SET_ACCESS_TOKEN,
              payload: { accessToken: res.data.switchUser.token },
            });
            history.push("/home");
          }
        })

        .catch(err => {
          console.log(err);
          toast.error("Oops, something went wrong!");
        })

        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    if (!loading)
      setSelectedUser(data.subUsers.length > 0 ? data.subUsers[0].id : "");
  }, [data]);

  if (loading) return <LoadingSpinner />;

  return (
    <div className="container my-5">
      <form
        className="d-flex justify-content-center align-items-center row"
        onSubmit={handleOnSubmit}
      >
        {data.subUsers.length === 0 && (
          <div className="alert alert-danger col-12">
            There are no co-members registered under this account
          </div>
        )}
        <div className="col-12 col-md-12 col-lg-6 px-3">
          <select
            disabled={isSubmitting}
            className="custom-select w-100"
            onChange={handleOnChange}
            value={selectedUser}
          >
            {data.subUsers.map(u => (
              <option value={u.id} key={u.id}>
                {u.firstName} {u.lastName} - {u.email}
              </option>
            ))}
          </select>
        </div>
        <div className="col-6 col-md-6 col-lg-3 px-3 mt-3 mt-lg-0">
          <button
            disabled={data.subUsers.length === 0 || isSubmitting}
            className="btn btn-success w-100"
            type="submit"
          >
            Switch to co-member
          </button>
        </div>
        <div className="col-6 col-md-6 col-lg-3 px-3 mt-3 mt-lg-0">
          <button
            disabled={isSubmitting}
            className="btn btn-warning w-100"
            onClick={() => history.push("/home")}
          >
            Cancel switch
          </button>
        </div>
      </form>
    </div>
  );
};

export default SwitchUser;
