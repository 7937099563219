import React, { useState, useEffect, useRef,useContext } from "react";
import { FaHandPaper, FaMicrophone } from "react-icons/fa";
import { FaVideo } from "react-icons/fa";
import {

  useEditSessionMemberPalamiStateMutation,
useSessionMixedUsersSubscription,
} from "../../../graphql/generated/graphql";
import { UserContext } from "../../../context/UserContext";
import data from "@iconify-icons/emojione-v1/wind-chime";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    backgroundColor:"rgba(0,0,0,0.5)",
    width: "10vw",
    height: "10vw",
  },
}));


const Participant = ({
  participant,
  vidHead,
  hasMute,
  handleMute,
  fullMute,
  isSilent,
  sessionId,
  handleMuteVid,
  myRemoteMuteState,
  myRemoteVidState,
  myRemotePalamiState,
  zoomData,
  callVol
}) => {
  const { data: sessionMixedUserSubscriptionData,error:sessionMixedUserSubscriptionError,loading: sessionMixedUserSubscriptionLoading} = useSessionMixedUsersSubscription({
    variables: { id: sessionId },
    
    
  });
  const classes = useStyles();
  const { state } = useContext(UserContext);
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [dataTracks, setDataTracks] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [isVidMuted, setIsVidMuted] = useState(false);
  const [
    editSessionMemberPalamiStateMutation
  ] = useEditSessionMemberPalamiStateMutation();
  const handlePalami = () => {

    editSessionMemberPalamiStateMutation({
      variables: {
        session: sessionId,
        member: state.user.id,
        palamiState: !myRemotePalamiState,
        role: state.user.role,
      },
    }).catch(err => {
      console.log(err);
    });
    
  };
  const [displayStyle, setDisplayStyle] = useState({
    audio: false,
    video: false
  })
  const videoRef = useRef();
  const audioRef = useRef();
const [avatar, setAvatar] = useState(<Avatar className={classes.large} />)
useEffect(() => {
  if(sessionMixedUserSubscriptionData && sessionMixedUserSubscriptionData.sessionMixedUsers){
    sessionMixedUserSubscriptionData.sessionMixedUsers.map(user =>{
      if(user.twilioName == participant.identity )
      {
        setAvatar(<Avatar className={classes.large}  src={"https://d30mg0zmvh01j5.cloudfront.net/"+user.profilePicUrl} />)
      }
    })
  }
  return () => {
    setAvatar(null)
  }
}, [sessionMixedUserSubscriptionData])
  const trackpubsToTracks = trackMap =>
    Array.from(trackMap.values())
      .map(publication => publication.track)
      .filter(track => track !== null);

  useEffect(() => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));
    setDataTracks(trackpubsToTracks(participant.dataTracks));

    const trackSubscribed = track => {
      if (track.kind === "video") {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else if (track.kind === "audio") {
        // console.log(track)
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
      else if (track.kind === "data") {
        setDataTracks(dataTracks => [...dataTracks, track]);
      }
      if (track.kind === 'data') {
        track.on('message', data => {
          // console.log(data)
          // console.log("we are hitting the track event statement")
          switch (data) {
            case "audio true":
                setDisplayStyle({...displayStyle, audio: true})
                break;
                
            case "audio false":
              setDisplayStyle({...displayStyle, audio: false})
              
              break;
              
            case "video true":
              setDisplayStyle({...displayStyle, video: true})
              
              break;
              
            case "video false":
              setDisplayStyle({...displayStyle, video: false})
              
              break;
          
            default:
              break;
          }
        });
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === "video") {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
     else if (track.kind === "data") {
      setDataTracks(dataTracks => dataTracks.filter(a => a !== track));
    }
    };

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      setDataTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  const [displayStyleLoc, setDisplayStyleLoc] = useState(Math.random())
  const [placeHolderEh, setPlaceHolderEh] = useState(false)
    useEffect(() => {
      // console.log("ReRender \n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n")
      setInterval(() => {
        setDisplayStyleLoc(Math.random()+1)
      }, 1000);
  
    }, [])
    useEffect(() => {
      const videoTrack = videoTracks[0];
      if (videoTrack) {
        //console.log(videoTracks[0].isEnabled)
        setPlaceHolderEh(!videoTracks[0].isEnabled)
        videoTrack.attach(videoRef.current);
        videoRef.current.style.transform = 'scale(-1, 1)';
        return () => {
          videoTrack.detach();
        };
      }
    }, [videoTracks]);
    useEffect(() => {
      const videoTrack = videoTracks[0];
      if (videoTrack) {
        //console.log(displayStyleLoc)
        setPlaceHolderEh(!videoTracks[0].isEnabled)
       
      }
    }, [displayStyleLoc]);
  useEffect(() => {
   //console.log(dataTracks)
  }, [dataTracks]);

  useEffect(() => {

    if (fullMute) {
      audioTracks.map(audioTrack => {
        audioTrack.detach(audioTrack);
      });
    }
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks, fullMute]);
  useEffect(() => {
   
    if (audioRef.current) {
      audioRef.current.volume = callVol
    
    }
  }, [callVol]);
 
//  console.log("silent "+isSilent)
//  console.log("remote "+myRemoteMuteState)
//  console.log("muted "+isMuted)
  // useEffect(() => {
  //   if (audioRef ){

  //     if (fullMute) {
  //      audioRef.volume = 0
  //      console.log(audioRef)
  //     }else{
  //       audioRef.volume = 1
  //     }
  //   }
  // },[fullMute,audioRef]);
  return (
    //keep an eye on this one...
    <div  className={`col d-flex flex-column justify-content-center h-100 ${
      zoomData.viewMode === "horizontal" ? "col-6" : "col-4"
    }`}>

      {hasMute ? (
        ""
      ) : (
        <div className={"mb-2 "}>
        <h5 className={"d-flex mt-1 pt-1 mb-0   justify-content-center"}>
          {vidHead}{" "}
          
        </h5>
        <h5 className={"d-flex my-0 pt-0 pb-0  justify-content-center"}>
         
          {participant.identity.substring(0, participant.identity.length - 7)}{" "}
        </h5>
        </div >
      )}
      {hasMute && (
        <div className={"row d-flex justify-content-center mb-1 mr-1 mt-4"}>
{/* swap us to match the buttons  */}
        <div role={"button"} onClick={() => {
                       if(!myRemoteMuteState && !isSilent) { handleMute(!isMuted);
                      setIsMuted(!isMuted);}
                    }} className={`${isMuted || isSilent || myRemoteMuteState ? "seethruboxOff" : "seethruboxOn"}  col col-xl-4   px-0 pt-1 mb-1  ml-3 d-flex justify-content-center align-items-center rounded box-shadow d-flex mt-2`}>
          <h6>
            {isMuted|| isSilent || myRemoteMuteState  ? "Mic is off" : "Mic is on"}
            
          </h6>
        </div>
        <div role={"button"} onClick={() => {
                    if(!myRemoteVidState) { handleMuteVid(!isVidMuted);
                      setIsVidMuted(!isVidMuted);}
                    }} className={`${isVidMuted || myRemoteVidState ? "seethruboxOff" : "seethruboxOn"} col col-xl-4  pt-1 ml-3 mb-1 px-0 justify-content-center d-flex align-items-center rounded box-shadow d-flex mt-2`}>
          <h6>
            
            {isVidMuted||myRemoteVidState ? " Cam is off" : " Cam is on"}
          </h6>
        </div>
        </div>
      )}
      <div className={`d-flex justify-content-center  ${placeHolderEh===false?"  ":" h-100 align-items-center "}`}>
        <video
          
          className={` ${placeHolderEh===false?"  ":" d-none "} p-2 mb-0 w-100  ${hasMute?"bg-dark mt-1":"dom-box-shadow mb-5 mt-1"} bg-dark card rounded-lg`}
          ref={videoRef}
          autoPlay={true}
          />
<div className={classes.root}>
        <div className={ ` ${placeHolderEh===true?"  ":" d-none "}  `}>
      
      {avatar}
    </div>
          </div>
        <audio ref={audioRef} autoPlay={true} muted={false} />
      </div >
          {hasMute&&
          ( <div className={` bg-dark rounded mt-1 px-1 box-shadow d-flex justify-content-around  `}>
            
          <button
           
                                className={`btn my-2 cam-control mx-1  ${
                                  
                                  !myRemotePalamiState
                                    ? "btn-outline-info text-white"
                                    : "btn-info"
                                }`}
                                onClick={() => handlePalami()}
                              >
                                <FaHandPaper/> {
                                  
                                  !myRemotePalamiState
                                    ? "raise hand"
                                    : "lower hand"
                                }
 </button>
            {hasMute  && myRemoteVidState === false &&(
                  <div
                 
                    role="button"
                    onClick={() => {
                      handleMuteVid(!isVidMuted);
                      setIsVidMuted(!isVidMuted);
                    }}
                    className={`my-2 cam-control mx-1 btn  ${
                      isVidMuted ? "text-white btn-danger " : "text-white btn-success"
                    } `}
                    
                  >
                    <FaVideo
                     
                    ></FaVideo> {isVidMuted ?"start cam":"stop cam"}
                  </div>
                )}
                {hasMute && isSilent ===  false && myRemoteMuteState === false &&(
                  <div
                 
                    role="button"
                    onClick={() => {
                      handleMute(!isMuted);
                      setIsMuted(!isMuted);
                    }}
                    className={`my-2 cam-control mx-1 btn ${
                      isMuted ? "text-white btn-danger " : "text-white btn-success"
                    } `}
                    
                  >
                    <FaMicrophone
                      
                    ></FaMicrophone> {isMuted ?"start mic":"stop mic"}
                  </div>
                )}
          </div>     
                )
              }
      {/* audioref mutes but it would have to render for everyone as muted  <button onClick={()=> {audioRef.current.volume = audioRef.current.volume==0?1:0}}>mute me</button> */}
    </div>
  );
};

export default Participant;
