import React, {useContext, useEffect, useState} from "react";
import {Link, NavLink, useLocation, useParams, useHistory} from "react-router-dom";
import {
  MdHome,
  MdLibraryBooks,
  MdLightbulbOutline,
  MdPeople,
  MdExitToApp,
  MdPerson,
  MdClose,
  MdCheck,
} from "react-icons/md";
import {HiSwitchVertical} from "react-icons/hi";
import {UserContext} from "../context/UserContext";
import {ROLE_ADMIN, ROLE_FACILITATOR, ROLE_MEMBER} from "../auth/roles";
import {useNotificationsLazyQuery,useLeaveSessionMutation, useMarkSeenNotificationsMutation ,useStopSessionMutation, useMyRequestsQuery, MyRequestsDocument, useAddUserToGroupMutation,  useDenyRequestMutation, useUnseenNotificationsQuery } from "../graphql/generated/graphql";
import {toast} from "react-toastify";
import { FaHandPaper, FaPlus, FaUserPlus, FaUserSlash } from "react-icons/fa";
import { BsAlarmFill, BsFillPersonCheckFill, BsFillPersonLinesFill, BsFillPersonPlusFill } from "react-icons/bs";
import logo from "./Login/logo.png";
import Jslogo from "./Login/JSClogo.png";
import GenericModal from "./GenericModal";
import Dropdown from 'react-bootstrap/Dropdown';
import { getNamedType } from "graphql";
import moment from "moment";

// In order to achieve reponsiveness in small screens, part of the navbar is hidden or displayed based on the default bootstrap breakpoints

export default function Navbar() {
  const [isCollapsed, setIsCollapsed] = useState(true, []);
  const [modalIsOpen, setModalIsOpen] = useState(false, []);
  const [addUserToGroup] = useAddUserToGroupMutation();
  const [denyRequest] = useDenyRequestMutation();
  const [markSeenNotifs] = useMarkSeenNotificationsMutation();
  const {state} = useContext(UserContext);

  const handleCollapse = () => setIsCollapsed(!isCollapsed);
  const [reqCount, setReqCount] = useState(0)

  const [unseenCount, setUnseenCount] = useState(0)
  const [reqs, setReqs] = useState([])
  const [unseen, setUnseen] = useState([])
  const [oldNotifications, setOldNotifications] = useState([])
  const { loading, error, data } = useMyRequestsQuery({skip: state.user.role !== "ROLE_FACILITATOR", pollInterval:10000})
  const { loading:unseenLoading, error:unseenError, data:unseenData } = useUnseenNotificationsQuery({ pollInterval:10000})
  const collapseNavbar = () => {
    setIsCollapsed(true);
  };
  const [executeQuery, { data:oldNotifData, error:oldNotifError, loading:oldNotifLoading }] = useNotificationsLazyQuery();
  const getName= (user)=>{
    if(user.nickname){
      return user.nickname
    }else{
      return `${user.firstName} ${user.lastName[0]}`
    }
  }

  const markSeen = () => {
   if(unseenCount > 0){ 
     let tmp = [...unseen.map(n=>{return n.id})]
    // console.log(tmp)
    markSeenNotifs({
      variables: {
        notifs: tmp
      }
    }).then(res=>{
      if(res.errors){
        console.log(res.errors[0].message)
      }
      else{
        //setUnseen([...unseen.map(n=>{return {...n, seenEh: true}})])  
      }
    })}
    setUnseen([])
  }

  const onClickAccept = (id ,group,  {username, groupName}) => {
    addUserToGroup({
      variables: {
        user: id,
        as: "member",
        group: group,
      }
    }).then(res=>{
      if(res.errors){
        console.log(res.errors[0].message)
      }
      else{
        setReqs([...reqs.filter(r=>r.groupId!=group||r.userId!=id)])
        setUnseen([...unseen.map(r=>{
          if(r.group.id==group&&r.user.id==id)
        {
          return {...r, actionedEh: true}
        }
        else{
          return {...r}
        }
        })])
        toast.success(`${username} was added to ${groupName}`, {toastID: "success"})
      }
    })
  };
  const onClickDeny = (id, group) => {
    // console.log(id)
    // console.log(group)
    denyRequest({
      variables: {
        user: id,
        group: group,
      },
     
    }).then(res=>{
      if(res.errors){
        console.log(res.errors[0].message)
      }
      else{
        setReqs([...reqs.filter(r=>r.groupId!=group||r.userId!=id)])
        setUnseen([...unseen.filter(r=>r.group.id!=group&&r.user.id!=id)])
        
        toast.success("Request was denied", {toastID: "success"})
      }
    })
  };
  // const [leaveSession] = useLeaveSessionMutation();
  useEffect(() => {
   if(data && data.myRequests){
    //   console.log(data.myRequests)
     let temp = []
     data.myRequests.map(g=>{

       g.inviteRequests.map(inv=>temp.push({groupId: g.id,groupName: g.name, userName: inv.nickname?inv.nickname:`${inv.firstName} ${inv.lastName[0]}.`, userId: inv.id }))
     }
     )
     setReqs([...temp])
     // console.log(reqs)
     setReqCount(reqs.length)
   }
  }, [data])
  const [nothingNewEh, setNothingNewEh] = useState(false)
  useEffect(() => {
   if(data && data.myRequests){

     setReqCount(reqs.length)
   }
  }, [reqs])
  useEffect(() => {
   if(unseenData && unseenData.unseenNotifications){

     setUnseenCount(unseen.filter(n=> n.seenEh == false).length)
   }
  }, [unseen])
  useEffect(() => {
    if(unseenData && unseenData.unseenNotifications){
     // console.log(unseenData)
      let tmp = [...unseenData.unseenNotifications.map(n=>(n.id))]
      setUnseen([...unseenData.unseenNotifications, ...unseen.filter(notif=> tmp.indexOf(notif.id)==-1)])
      
     // console.log(unseen)
      
    }
    // setNothingNewEh(!nothingNewEh)
  }, [unseenData])
  useEffect(()=>{
   // console.log("we here?")
    executeQuery({variables:{
      offset: unseen&&unseen.length>0?unseen.length-1:0,
      limit: unseen?10 - unseen.length:10
    }})
  },[unseen, nothingNewEh])
  useEffect(() => {
    if(oldNotifData && oldNotifData.notifications){
      // console.log("and here?")
      // console.log(oldNotifData.notifications)
      let tmp = [...unseen.map(n=>(n.id))]
     setOldNotifications([...oldNotifData.notifications.filter(notif=> tmp.indexOf(notif.id)==-1)])
   }
   
  }, [oldNotifData])
  const history = useHistory();
  //const location = useLocation();
  // const params = useParams();
  //const isRunningSession = location.pathname.split('/')[1] === "session";
  //const sessionId = location.pathname.split('/')[2];

  // const handleLeaveSession = () => {
  //   leaveSession({
  //     variables: {
  //       session: sessionId
  //     }
  //   })
  //     .then(res => {
  //       toast.success("left a session");
  //       history.push('/home');
  //     })
  //     .catch(err => {
  //       toast.error("Oops, failed to leave a session")
  //     })
  // }

  return (
    <>
      <nav style={{zIndex:9999}} className="navbar navbar-expand-lg navbar-dark bg-nav-clear text-white fixed-top">
        {/* <NavLink className="navbar-brand d-md-none" exact to="/">
          Java Group
        </NavLink> */}
        <NavLink
               className="rounded-circle bg-dark  dom-box-shadow2  position-fixed "
               
               style={{ boxShadow: "rgba(0, 162, 255, .75) 0px 0px  8px 5px ",left: "-3.4em",top:"-4em", width:"8em",height:"8em" }}
                to="/home"
         
                >
                
                          <img
              src={logo}
              className="position-absolute"
              style={{  transform: "translateY(195%) translateX(100%)", }}
              id="icon"
              alt="Java Group"
              className={``}
              width="55px"
              />
             
              </NavLink>
        <button
          className="navbar-toggler  ml-auto float-xs-right"
          type="button"
          data-toggle="collapse"
          aria-label="Toggle navigation"
          aria-expanded={!isCollapsed ? true : false}
          onClick={handleCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`${isCollapsed ? "collapse" : ""} navbar-collapse ml-3 w-100`}
          id="navbar"
        >
          <div className="navbar-nav d-flex flex-row justify-content-between w-100">
            <div className="ml-5 navbar-nav w-xs-100 w-md-auto">
              <NavLink
                className="nav-item nav-link"
                to="/home"
                onClick={collapseNavbar}
              >
                <span className="mr-2">
                  <MdHome/>
                </span>
                <span>Home</span>
              </NavLink>

              {[ROLE_FACILITATOR, ROLE_ADMIN].indexOf(state.user.role) !==
              -1 && (
                <NavLink
                  className="nav-item nav-link"
                  to="/theme_library"
                  onClick={collapseNavbar}
                >
                  <span className="mr-2">
                    <MdLibraryBooks/>
                  </span>
                  <span>Theme Library</span>
                </NavLink>
              )}

              {[ROLE_FACILITATOR, ROLE_ADMIN].indexOf(state.user.role) !==
              -1 && (
                <NavLink
                  className="nav-item nav-link"
                  to="/resources"
                  onClick={collapseNavbar}
                >
                  <span className="mr-2">
                    <MdLightbulbOutline/>
                  </span>
                  <span>Resources</span>
                </NavLink>
              )}
  
              {/* {[ROLE_FACILITATOR, ROLE_ADMIN].indexOf(state.user.role) !==
                -1 && (
                  <NavLink
                  className="nav-item nav-link"
                  to="/playground"
                  onClick={collapseNavbar}
                  >
                  <span className="mr-2">
                  <MdLightbulbOutline />
                  </span>
                  <span>dev</span>
                  </NavLink>
                )} */}

              {[ROLE_FACILITATOR, ROLE_ADMIN].indexOf(state.user.role) !==
              -1 && (
                <div className="nav-item nav-link pb-2 d-flex">
                  <NavLink
                className="nav-item nav-link nav-no-dec-nest pl-0 pb-2"
                to="/manage"
                onClick={collapseNavbar}
                >
                  <span className="mr-2">
                    <MdPeople/>
                  </span>
                  <span>Groups</span> 
                </NavLink>
                  
                  </div>
              )}

              {/* The content below will only display if the screen resolution is LESS than md */}
              <div className="navbar-nav d-md-none">
                {[ROLE_FACILITATOR].indexOf(state.user.role) !== -1 && (
                  <NavLink
                    className="nav-item nav-link"
                    to="/switchuser"
                    onClick={collapseNavbar}
                  >
                    <span className="mr-2">
                    <HiSwitchVertical/>
                  </span>
                  <span>Switch to Co-member</span>
                  </NavLink>
                )}

                {[ROLE_FACILITATOR].indexOf(state.user.role) !== -1 && (
                  <NavLink
                    className="nav-item nav-link"
                    to="/register"
                    onClick={collapseNavbar}
                  >
                   <span className="mr-2">
                    <FaPlus/>
                  </span>
                  <span>Add Co-member</span>
                  </NavLink>
                )}

                <NavLink
                  className="nav-item nav-link"
                  to="/profile"
                  onClick={collapseNavbar}
                >
                   <span className="mr-2" >
                  <MdPerson/>
                </span>
                <span>Profile - <span className={``}> {state.user.firstName} {state.user.lastName[0]}.</span></span>
                </NavLink>

                {/* {isRunningSession ? (
                  <span>Leave a session</span>
                ) : ( */}
                  <NavLink
                    className="nav-item nav-link"
                    to="/logout"
                    onClick={collapseNavbar}
                  >
                  <span className="mr-2">
                    <MdExitToApp/>
                  </span>
                    <span>Log out</span>
                  </NavLink>
                {/* )} */}
              </div>
            </div>

            {/* The content below will only display if the screen resolution is MORE than md */}
            <div className="navbar-nav d-none d-md-flex">
              {[ROLE_FACILITATOR].indexOf(state.user.role) !== -1 && (
                <NavLink
                  className="nav-item nav-link"
                  to="/switchuser"
                  onClick={collapseNavbar}
                >
                  <span className="mr-2">
                    <HiSwitchVertical/>
                  </span>
                  <span>Switch to Co-member</span>
                </NavLink>
              )}
              {[ROLE_FACILITATOR].indexOf(state.user.role) !== -1 && (
                <NavLink
                  className="nav-item nav-link"
                  to="/register"
                  onClick={collapseNavbar}
                >
                  <span className="mr-2">
                    <FaPlus/>
                  </span>
                  <span>Add Co-member</span>
                </NavLink>
              )}
              <div className="nav-item nav-link pl-0 pb-2 d-flex">
              <div
              className="nav-item nav-link nav-no-dec-nest pl-0 pb-2"
                
                onClick={collapseNavbar}
              >
              </div>
              {(unseenData|| oldNotifData) && (unseen||oldNotifications) && (
                <Dropdown  onClick={(e)=>{
                  let tmp = "true"
                  
                  let stupidBehaviourObj = e.target.getAttribute("aria-expanded") 
                  if(tmp == stupidBehaviourObj ){
                    
                    // setUnseen([])
                    markSeen()
                  }
                }}>
                    <div  onClick={()=>{
                      setUnseen([...unseen])
                    }}>
                      <Dropdown.Toggle  variant="none" className={`nav-item d-flex align-items-center nav-link nav-no-dec pl-0 `} id="dropdown-basic">
                        <span className="mr-2 mt-0" >
                          <MdPerson className="mb-1 mt-0"/>
                        </span>
                        <span>Profile - <span className={``}> {state.user.firstName} {state.user.lastName[0]}.</span></span>
      {unseenCount>0&&<span  className="badge ml-2 badge-pill badge-danger">{unseenCount}</span>}
      </Dropdown.Toggle>
                      </div>
    
      <Dropdown.Menu  style={{minWidth: "30em", maxHeight:"42vh", overflowY: "scroll"}} className={`bg-cleard  mt-2 muteMenuScroll useBorder text-white`}>
         <div style={{minWidth: "30em", maxHeight:"40vh", marginBottom: "10px" }} className={`mb-5 pb-5 `}> 
         <NavLink
              className="col-12  d-flex justify-content-end nav-item nav-link nav-no-dec-nest pl-0 pb-2"
                to="/profile"
                onClick={collapseNavbar}
              >
                <div className={`col-6 mx-auto  `} >
          <div className={`  rounded  bg-dark  my-2  mx-2 `} >
            
                <span className={`w-100 btn btn-info mr-3`}>

                <span className="mr-2 " >
                  <MdPerson/>
                </span>
                <span>Profile page</span>
                </span>
              </div>
              </div>
              </NavLink>
          <div className={`col-11 mx-auto  `} >
          <div className={`row  d-flex  rounded text-center bg-dark useBorder mt-2 pr-4 mx-2 pt-1`} >
            <h5 className={`col-12 text-center `}>
              Notifications
              </h5>
              </div>
              </div>
          {unseenData && unseen  &&  unseen.length > 0  &&  
          <div  >
         
        {unseen.map(notif=>{
          
        if(notif.type == "sessionStart") {
          return (
            <div key={`${notif.id}`} className={`col-12  `}>
              <div role="button" onClick={()=>{
                history.push(`/waitingroom/${notif.session.id}`)
              }} className={`round-box box-shadow d-flex row bg-dark useBorder  my-2 pr-4 mx-2 align-items-center`}>
                <div className="d-flex  col col-12 my-2  align-items-center">
                
        <BsAlarmFill/>
                  <span
                    className="font-size-1 mb-0 ml-1 col-9"
                    style={{ verticalAlign: "middle" }}
                  >
                  {notif.message}
                  <div className="d-flex  col col-12 pl-0  align-items-center">
         
    
         <span
           className=" mb-1 ml-0 "
           style={{ fontSize: "small" }}
         >
          {moment(notif.createdAt).fromNow()}
         </span>
        
       </div>
                  </span>
                  <span
                    className="font-size-1 mb-0 ml-1 col-2"
                    style={{ verticalAlign: "middle" }}
                  >
                <button className={`btn btn-info btn-extra-radius`}>Join</button>
                  </span>
                </div>
              </div>
            </div>
          )
        }
        if(notif.type == "groupReq") {
          return (<div key={`${notif.id}`} className={`col-12  `}>
          <div className={`round-box box-shadow d-flex row bg-dark useBorder  my-2 pr-4 mx-2 align-items-center`}>
            <div className="d-flex  col col-12 mt-2 mb-1  align-items-center">
             
            {notif.actionedEh?<BsFillPersonPlusFill/>:""}
              <span
                className="font-size-1 mb-0 ml-1 col-9"
                style={{ verticalAlign: "middle" }}
              >
                <strong>
                  {getName(notif.user)} {' '}
                  </strong>
                  {!notif.actionedEh?" wants to join ":" was added to "} <strong>{notif.group.name}{' '}
                  </strong>
                  <div className="d-flex  col col-12 pl-0  align-items-center">
         
    
              <span
                className=" mb-1 ml-0 "
                style={{ fontSize: "small" }}
              >
               {moment(notif.createdAt).fromNow()}
              </span>
             
            </div>
              </span>
              
             {!notif.actionedEh &&  <span
                className="d-flex justify-content-center  col-3 "
                
              >
                <div className="btn btn-success btn-extra-radius mr-2 py-2 my-2 d-flex justify-content-center align-items-center ">
    
                        <FaUserSlash
                         
                          // onClick={onClickAcceptButton}
                         onClick={()=>{
                           let username = getName(notif.user)
                           onClickAccept(notif.user.id, notif.group.id, {username: username, groupName: notif.group.name})
                         }}
                         
                         />
                </div>
    
              <div className="btn btn-danger  btn-extra-radius  py-2 my-2  d-flex justify-content-center align-items-center ">
     <FaUserSlash
                // onClick={onClickDeleteButton}
                onClick={()=>{
                  onClickDeny(notif.user.id, notif.group.id)
                }}
                
              /> 
               </div>
              </span>}
            </div>
            
          </div>
        </div>)}
        if(notif.type == "groupRes") {
          return ( 
          <div key={`${notif.id}`} className={`col-12  `}>
          <div className={`round-box box-shadow d-flex row bg-dark useBorder  my-2 pr-4 mx-2 align-items-center`}>
            <div className="d-flex  col col-12 my-2  align-items-center">
            <BsFillPersonCheckFill/>
    
              <span
                className="font-size-1 mb-0 ml-1 col-9"
                style={{ verticalAlign: "middle" }}
              >
               {notif.message.split("<strong>")[0]} <strong>{notif.group.name}{' '}
                  </strong> was accepted!
              </span>
              <div className="d-flex  col col-12 pl-0  align-items-center">
         
    
              <span
                className=" mb-1 ml-0 "
                style={{ fontSize: "small" }}
              >
               {moment(notif.createdAt).fromNow()}
              </span>
             
            </div>
            </div>
            
          </div>
        </div>)
        }


              })}
      <div className={`col col-11 border border-light mx-auto mt-3`}>

      </div>
              
       </div>
      }
      <div className={`pb-2 pt-2`}>

      {oldNotifications.map(notif=>{
        
      
          
        if(notif.type == "sessionStart") {
          return (
            <div key={`${notif.id}`} className={`col-12  `}>
              <div role="button" onClick={()=>{
               // console.log(moment().diff(moment(notif.createdAt), "minutes") )
                if(moment().diff(moment(notif.createdAt), "minutes")  <90){

                  history.push(`/waitingroom/${notif.session.id}`)
                }
              }} className={`round-box box-shadow d-flex row bg-dark useBorder  my-2 pr-4 mx-2 align-items-center`}>
                <div className="d-flex  col col-12 my-2  align-items-center">
                
                  <BsAlarmFill/>
                  <span
                    className="font-size-1 mb-0 ml-1 col-9"
                    style={{ verticalAlign: "middle" }}
                    >
                  {notif.message}
                  <div className="d-flex  col col-12 pl-0  align-items-center">
         
    
         <span
           className=" mb-1 ml-0 "
           style={{ fontSize: "small" }}
         >
          {moment(notif.createdAt).fromNow()}
         </span>
        
       </div>
                  </span>
                  <span
                    className="font-size-1 mb-0 ml-1 col-2"
                    style={{ verticalAlign: "middle" }}
                  >
               
               {moment().diff(moment(notif.createdAt), "minutes")  <90 &&
               <button className={`btn btn-info btn-extra-radius`}>Join</button>}
                  </span>
                </div>
              </div>
            </div>
          )
        }
        if(notif.type == "groupReq") {
          return (<div key={`${notif.id}`} className={`col-12  `}>
          <div className={`round-box box-shadow d-flex row bg-dark useBorder  my-2 pr-4 mx-2 align-items-center`}>
            <div className="d-flex  col col-12 mt-2 mb-1  align-items-center">
             
              {notif.actionedEh?<BsFillPersonPlusFill/>:<BsFillPersonLinesFill></BsFillPersonLinesFill>}
              <span
                className="font-size-1 mb-0 ml-1 col-9"
                style={{ verticalAlign: "middle" }}
                >
                <strong>
                  {getName(notif.user)} {' '}
                  </strong>
                  {!notif.actionedEh?" wants to join ":" was added to "} <strong>{notif.group.name}{' '}
                  </strong>
                  <div className="d-flex  col col-12 pl-0  align-items-center">
         
    
              <span
                className=" mb-1 ml-0 "
                style={{ fontSize: "small" }}
              >
               {moment(notif.createdAt).fromNow()}
              </span>
             
            </div>
              </span>
              
             {!notif.actionedEh &&  <span
                className="d-flex justify-content-center  col-2 "
                
              >
                <div className="btn btn-success btn-extra-radius mr-2 py-2 my-2 d-flex justify-content-center align-items-center ">
    
                        <FaUserPlus
                         
                          // onClick={onClickAcceptButton}
                         onClick={()=>{
                           let username = getName(notif.user)
                           onClickAccept(notif.user.id, notif.group.id, {username: username, groupName: notif.group.name})
                         }}
                         
                         />
                </div>
    
              <div className="btn btn-danger  btn-extra-radius  py-2 my-2  d-flex justify-content-center align-items-center ">
     <FaUserSlash
                // onClick={onClickDeleteButton}
                onClick={()=>{
                  onClickDeny(notif.user.id, notif.group.id)
                }}
                
              /> 
               </div>
              </span>}
            </div>
            
          </div>
        </div>)}
        if(notif.type == "groupRes") {
          return ( 
          <div key={`${notif.id}`} className={`col-12  `}>
          <div className={`round-box box-shadow d-flex row bg-dark useBorder  my-2 pr-4 mx-2 align-items-center`}>
            <div className="d-flex  col col-12 my-2  align-items-center">
            <BsFillPersonCheckFill/>
    
              <span
                className="font-size-1 mb-0 ml-1 col-9"
                style={{ verticalAlign: "middle" }}
              >
               {notif.message.split("<strong>")[0]} <strong>{notif.group.name}{' '}
                  </strong> was accepted!
              </span>
              <div className="d-flex  col col-12 pl-0  align-items-center">
         
    
              <span
                className=" mb-1 ml-0 "
                style={{ fontSize: "small" }}
              >
               {moment(notif.createdAt).fromNow()}
              </span>
             
            </div>
            </div>
            
          </div>
        </div>)
        }


              

            })}
      </div>
            </div>
      </Dropdown.Menu>
    </Dropdown>
                  )}

              </div>

              {/* {isRunningSession ? (
                <button onClick={handleLeaveSession} className="btn btn-warning text-white btn-extra-radius">
                  Leave a session
                </button>
              ) : ( */}
                <Link
                  className="nav-item nav-link"
                  to="/logout"
                  onClick={collapseNavbar}
                >
                <span className="mr-2">
                  <MdExitToApp/>
                </span>
                  <span>Log out</span>
                </Link>
              {/* )} */}
            </div>
          </div>
        </div>
        <GenericModal
         modalIsOpen={modalIsOpen}
         setModalIsOpen={setModalIsOpen}

         addClass={"bg-clear d-flex justify-content-center w-25 gray-filled"}
        >
          <div className="round-box  d-flex flex-column justify-content-center box-shadow text-align-center align-self-center p-5 col-12">
            <h3 className={`text-center`}>Choose an App</h3>
            <div onClick={()=>{
              setModalIsOpen(false)
              toast.success("Congratulations! That button did nothing. HOWEVER, one day it will grow up to be a big strong button with a purpose! ")
            }} role="button" className={`row d-flex flex-row justify-content-center my-2 py-2 bg-clearl round-box  box-shadow  `}>  <div className={`bg-white p-2 box-shadow round-box`}>  <img
              src={logo}
              
              id="icon"
              alt="Java Group"
              className={``}
              width="70px"
              /> </div><h2 className={`px-5 align-self-center  `}> Java Music </h2> 
                 </div>
            <div onClick={()=>{
              setModalIsOpen(false)
              toast.success("Congratulations! That button did nothing. HOWEVER, one day it will grow up to be a big strong button with a purpose! ")
            }} role="button" className={`row d-flex flex-row justify-content-center my-2 py-2 bg-clearl round-box  box-shadow  `}> <div className={`bg-white p-2 box-shadow round-box`}>
              
                <img
              src={Jslogo}
              className={` round-box`}
              id="icon"
              alt="Java Group"
             
               width="70px"
              />
              </div>
              <h2 className={`px-5 align-self-center  `}> 
                Java Social
               </h2> 
                 </div>
          </div>
        </GenericModal>
        
      </nav>
      {/* This Div should have the exact same height as the navbar to prevent the navbar from covering other elements */}
      <div style={{height: "56px"}}/>
    </>
  );
}
