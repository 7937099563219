import React, { useState, useEffect } from "react";
import matchSorter from "match-sorter";
import ThemeCard from "../ThemeLibrary/ThemeCard";
import { useThemesQuery } from "../../graphql/generated/graphql";
import ThemePreview from "../ThemeLibrary/ThemePreview";
import {
  SessionDocument,
  useChangeThemeMutation,
} from "../../graphql/generated/graphql";
import GenericModal from "./../GenericModal";
import ThemePicker from "./ThemePicker";
import moment from "moment";


// This is supposed to be used as the content of a modal
export default function HighlightedThemes(props) {
  const { displayType, seed, session, themesList, isReflections } = props;
  const [themeModalIsOpen, setThemeModalIsOpen] = useState(false, []);
  const [themePreviewIsOpen, setThemePreviewIsOpen] = useState(false, []);
  const [selectedTheme, setSelectedTheme] = useState(null, []);
  const [changeTheme] = useChangeThemeMutation({
    refetchQueries: [
      {
        query: SessionDocument,
        variables: {
          id: session.id,
        },
      },
    ],
  });
  const openThemePreview = async(theme) => {
    await setSelectedTheme(theme)
    setThemePreviewIsOpen(true);
    };
  const handleOnClickThemeSelection = () => {



        changeTheme({
             variables: {
               session: session.id,
               theme: selectedTheme.id,
             },
           })
             .then(res => console.log(res))
             .catch(err => console.log(err));
        setThemePreviewIsOpen(false);

    };
    const handleThemeSelection = newTheme => {
      changeTheme({
        variables: {
          session: session.id,
          theme: newTheme.id,
        },
      })
        .then(res => console.log(res))
        .catch(err => console.log(err));
  
      setThemeModalIsOpen(false);
    };

  // Fetch themes from DB
  const [themes, setThemes] = useState([], []);

  const { data, loading, error } = useThemesQuery();

  const makeChoices = themes => {
    //console.time('Execution Time');
    let filteredThemes = isReflections?[...themes.filter(t=>t.program=="reflections")]:[...themes.filter(t=>t.program=="music")]

 
    let skip = seed === 0 ? 10 : seed;
    let start = 0;
    let choices = [];
    for (let index = 0; index < 6; index++) {
      start = (start + skip) % 52;
      if (choices[start] === undefined && filteredThemes[start].name !== "CHRISTMAS"  && filteredThemes[start].name !== "FATHER'S DAY" && filteredThemes[start].name !== "MOTHER'S DAY") {
        choices[start] = filteredThemes[start];
      } else {
          let isCrash = true
        while (isCrash) {
          start++;
          if (choices[start] !== undefined || filteredThemes[start].name === "CHRISTMAS"   || filteredThemes[start].name === "FATHER'S DAY" || filteredThemes[start].name === "MOTHER'S DAY") {
            continue;
          } else {
            choices[start] = filteredThemes[start];
            isCrash = false
          }
        }
      }
    }
    choices = choices.filter(function (el) {
        return el != null;
      })
      return(choices)
  };


  const [readyToRender, setReadyToRender] = useState(false)
  useEffect(() => {
    console.time('Execution Time themes');
    console.time('Execution Time data');

    if(themesList !== undefined && themesList !== null && themesList.themes !== null  && themesList.themes !== undefined) {
      //console.log(themesList.themes)

      console.timeEnd('Execution Time themes');
      if(!isReflections)setThemes(makeChoices(themesList.themes));
      else setThemes(themesList.themes.filter(t=>t.program=="reflections"));
      setReadyToRender(true)
    }else if(data && data.themes && (themesList== undefined|| themesList== null|| themesList.themes !== null  || themesList.themes !== undefined) ){
      console.timeEnd('Execution Time data');
      if(!isReflections)setThemes(makeChoices(data.themes));
      else setThemes(data.themes.filter(t=>t.program=="reflections"));
      setReadyToRender(true)
    }
  }, [data,themesList]);



  return (
    <div
      className={`${displayType==="mem"?"":"p-3 mx-1 mb-3"} bg-zero useBorder border justify-content-center round-box box-shadow`}
      
    >
      <header  className="justify-content-center justify-text-center">
        <h4 className="text-center">Themes of the Day</h4>
        {displayType==="fac" && (
          <h6 className="text-center">Here is a list of theme suggestions the members see. Please get their input on theme selection</h6>

        )}
        {displayType==="mem" && (

        <h6 className="text-center align-self-center pt-4">Here is a list of theme suggestions to pick from for todays session</h6>
        )}
      </header>
      <main style={{maxWidth:700, minHeight: 90}}   className="container">
        <div  className="row d-flex justify-content-center ">


          {readyToRender && displayType==="mem" && themes!== undefined && themes.map(theme => (
            <ThemeCard
              handleThemeSelection={console.log}
              disabler={true}
              selectableTheme={false}
              theme={theme}
              key={theme.id}
            />
          ))}
          {readyToRender && displayType==="fac" && themes !== undefined  && themes.map(theme => (
           <div key={theme.id} role={"button"} className={"col col-auto rounded box-shadow text-center align-items-center bg-cleard border border-info text-light p-auto justify-content-center mx-1 my-2"}>
             <h6 onClick={()=>openThemePreview(theme)} className="text-center align-self-center pt-2">
               {theme.name}
               </h6>
             <p onClick={()=>openThemePreview(theme)} className="text-center align-self-center pt-2">
               {session && session.group.usedThemes.filter(used=> used.theme==theme.id).map(used=>{
                return "Last used: "+ moment(used.lastUsed).format("MMMM D, YYYY")
               })}
               {session && session.group.usedThemes.filter(used=> used.theme==theme.id).length == 0&& "Last used: never"}
               </p>
           </div>
          ))}
        </div>
          {displayType==="fac" && 
          <div  className="row d-flex justify-content-center mt-3 "><button
            onClick={() => setThemeModalIsOpen(true)}
            className="btn btn-info text-white px-5 btn-extra-radius"
          >
            See Full Theme List
          </button></div>}
      </main>
      <GenericModal
          modalIsOpen={themePreviewIsOpen}
          setModalIsOpen={setThemePreviewIsOpen}
        >
          <ThemePreview
            selectableTheme={true}
            handleOnClickThemeSelection={handleOnClickThemeSelection}
            theme={selectedTheme}
          />
        </GenericModal>
        <GenericModal
        modalIsOpen={themeModalIsOpen}
        setModalIsOpen={setThemeModalIsOpen}
      >
        {data && data.themes && <ThemePicker handleThemeSelection={handleThemeSelection} isReflections={isReflections} themes={data.themes}/>}
      </GenericModal>
    </div>
  );
}
