import React from "react";
import { MdSearch } from "react-icons/md";

export default function SearchBar(props) {
  const { handleOnChange, searchText } = props;

  return (
    <div className="d-inline-flex flex-row align-items-center my-3 px-3 pb-2 w-100 round-box search-bar shadow">
      <span style={{ fontSize: "1.5em" }}>
        <MdSearch className="mr-2" />
      </span>
      <div className="mt-2 w-100">
        <input
          className="border-0 w-100"
          onChange={handleOnChange}
          value={searchText}
        />
      </div>
    </div>
  );
}
