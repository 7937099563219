import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

export default function CurrentDayMarker(props) {
  return (
    <div
      ref={props.refProp}
      className="w-100 d-flex flex-row align-items-center current-day-marker"
    >
      <span className="icon text-primary" style={{ fontSize: "2em" }}>
        <MdKeyboardArrowRight />
      </span>
      <span
        className="font-weight-bold text-primary pt-1"
        style={{ fontSize: "1.5em" }}
      >
        Today
      </span>
    </div>
  );
}
