import React, { useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { FaVolumeOff } from "react-icons/fa";
import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa";

const useStyles = makeStyles(theme => ({
  root: {
    width: "70%",
    marginLeft: "5%",
  },
  margin: {
    height: theme.spacing(5),
  },
}));
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
  root: {
    color: "grey",
    height: 8,
    
  },
  thumb: {
    height: 17,
    width: 17,
    backgroundColor: "lightGrey",
    border: "2px solid currentColor",
    marginTop: -5,
    marginLeft: -7,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function SongPlayer({ song }) {
  const audioRef = useRef(null);
  const classes = useStyles();
  const [value, setValue] = useState(100);
  const [playButton, setPlayButton] = useState(<FaPlay style={{ fontSize: "1em",marginRight: 3, }}></FaPlay>)

  const handleChange = (event, newValue) => {
    console.log(audioRef.current.volume);
    setValue(newValue);
    audioRef.current.volume = newValue / 100;
  };
  const handleMute = () => {
    if (audioRef.current.volume == 0) {
      console.log(audioRef);
      setValue(100);
      audioRef.current.volume = 1;
    } else {
      console.log(audioRef);
      setValue(0);
      audioRef.current.volume = 0;
    }
    // let isMuted = audioRef.current.muted
    // audioRef.current.muted = !isMuted
    // setValue(!isMuted?0:unMuteValue);
  };
  const handlePlay = () => {
    if (audioRef.current.paused) {

      audioRef.current.play();
      setPlayButton(<FaPause style={{ fontSize: "1em",marginRight: 3, }}></FaPause>)
    } else {

      audioRef.current.pause();
      setPlayButton(<FaPlay style={{ fontSize: "1em",marginRight: 3, }}></FaPlay>)

    }
  };
  return (
    <div
      className={
        "row w-100 justify-content-center  d-flex my-3  bg-white shadow rounded"
      }
    >
      <div className="col-7 ">
        <div className={"row w-100"}>
          <div
            role="button"
            className={`col col-1 align-items-center justify-content-center mb-1  align-self-center text-success  rounded-circle `}
            onClick={handlePlay}
          >
              {(playButton)}

          </div>
          <div className={"col  "}>
            <audio
            //   style={{width:"100%"}}
              className={"w-100"}
              ref={audioRef}
              controls
              controlsList="nodownload"
              src={"https://d30mg0zmvh01j5.cloudfront.net" + song.url}
            />
          </div>
        </div>
      </div>

      <div className={"col-4 my-1"}>
        <div className={"row "}>
          <div
            role="button"
            className={`d-flex my-auto ml-1 mr-1 align-self-center bg-light  shadow-sm rounded-circle ${
              audioRef.current != null && audioRef.current.volume <= 0.01
                ? "text-danger"
                : "text-success"
            }`}
            onClick={handleMute}
          >
            <FaVolumeOff
              style={{ fontSize: "1.5em", paddingRight: 3 }}
            ></FaVolumeOff>
          </div>
          <div className={classes.root }>
            <Typography id="continuous-slider" gutterBottom></Typography>
            <Grid className={classes.margin} container>
              <Grid item xs>
                <PrettoSlider
                  valueLabelDisplay="off"
                  aria-label="pretto slider"
                  value={value}
                  onChange={handleChange}
                  aria-labelledby="continuous-slider"
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SongPlayer;
