import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  useAddUserToSessionMutation,
  useRemoveUserFromWaitingRoomMutation,
} from "../../graphql/generated/graphql";

const WaitingRoomMenu = props => {
  const { sessionId, waitingRoom } = props;

  const [removeUserFromWaitingRoom] = useRemoveUserFromWaitingRoomMutation();

  const [addUserToSession] = useAddUserToSessionMutation();

  const [disabledButtons, setDisabledButtons] = useState([]);

  const approveUser = (id, role) => {
    setDisabledButtons([...disabledButtons, id]);

    addUserToSession({
      variables: {
        user: id,
        session: sessionId,
        as: role === "ROLE_FACILITATOR" ? "facilitator" : "member",
      },
    })
      .then(res => {
        if (res.errors) {
          console.log(res.errors);
          //toast.error("Oops, something went wrong");
        } else {
          toast.success(
            `${role === "ROLE_FACILITATOR" ? "Facilitator" : "Member"} approved`
          );
        }
      })
      .catch(err => {
        toast.error("Oops, something went wrong");
        console.log("ummm why is dis wrong"+err);
      })
      .finally(() => {
        setTimeout(500, () => {
          setDisabledButtons(disabledButtons.filter(b => b !== id));
        });
      });
  };

  const rejectUser = id => {
    setDisabledButtons([...disabledButtons, id]);
    removeUserFromWaitingRoom({ variables: { user: id, session: sessionId } })
      .then(res => {
        if (res.errors) {
          toast.error("Oops, something went wrong");
        } else {
          toast.success("User removed from the waiting room");
        }
      })
      .catch(err => {
        toast.error("Oops, something went wrong");
        console.log(err);
      })
      .finally(() => {
        setTimeout(500, () => {
          setDisabledButtons(disabledButtons.filter(b => b !== id));
        });
      });
  };

  return (
    <div className="container p-5">
      <h1 className="text-center">Waiting Room</h1>
      <h4>
        Press approve to allow users into the session or reject to remove them
        from the waiting room
      </h4>
      <div className="my-5 bg-clear card p-3">
        {!waitingRoom || waitingRoom.length === 0 ? (
          <h4 className="text-center">
            Currently there are no users in the waiting room.
          </h4>
        ) : (
          waitingRoom.map(u => (
            <div
              key={u.id}
              className="row w-100 mx-auto d-flex align-items-center my-2"
            >
              <div className="col-6 h4">
                <span className="mr-3">
                {u.nickname?`${u.nickname}`:`${u.firstName} ${u.lastName}`}
                </span>
                <span className="badge badge-info">
                  {u.role === "ROLE_FACILITATOR" ? "FACILITATOR" : "MEMBER"}
                </span>
              </div>
              <div className="col-3">
                <button
                  disabled={
                    disabledButtons.find(b => b === u.id) ? true : false
                  }
                  className="btn btn-success w-100"
                  onClick={() => approveUser(u.id, u.role)}
                >
                  Approve
                </button>
              </div>
              <div className="col-3">
                <button
                  disabled={
                    disabledButtons.find(b => b === u.id) ? true : false
                  }
                  className="btn btn-danger w-100"
                  onClick={() => rejectUser(u.id)}
                >
                  Reject
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default WaitingRoomMenu;
