import React, {useContext} from "react";
import { BsBoxArrowUpRight, BsBoxArrowInDownLeft } from "react-icons/bs";
import { FaArrowsAltV, FaArrowsAltH, FaVideo, FaColumns, FaWindowMaximize } from "react-icons/fa";
import { MdRefresh,MdSwitchVideo, MdVideocamOff, MdPersonalVideo } from "react-icons/md";
import { FaExpandArrowsAlt, FaHandsHelping } from "react-icons/fa";
import { BsPeopleFill } from "react-icons/bs";
import { GoStop } from "react-icons/go";
import { VscSync, VscSyncIgnored } from "react-icons/vsc";
import { useStopSessionMutation } from "../../../graphql/generated/graphql";
import { UserContext } from "../../../context/UserContext";
export default function ZoomFloatingMenu(props) {
  const {
    zoomData,
    toggleZoomVisibility,
    setViewModeHorizontal,
    setViewModeFullscreen,
    isSyncOn,
    toggleIsSyncOn,
    numberOfMembersInWaitingRoom,
    setWaitingRoomModalOpen,
    setHandoffModalOpen,
    isSessionMaster,
    sessionId,
    forSessMaster
  } = props;

  const { state } = useContext(UserContext);
  const squareSize = "2.75em";
  const fontSize = "1.5em";
  const [stopSession] = useStopSessionMutation();
  const handleSessionEnd = () => {
    console.log("**** Session End ****");
    if ( state.user.role === "ROLE_FACILITATOR") {
      stopSession({
        variables: {
          session: sessionId,
        },
      })
        .then(res => console.log(res))
        .catch(err => console.log(err));
    } else {
      
    }
  };
  return (
    <div
      style={{
        bottom: ".15em",
        left: ".15em",
        zIndex: 1,
      }}
      className={`position-fixed d-flex flex-column-reverse`}
    >
      {isSessionMaster !== false &&<button
style={{
  width: squareSize,
  height:"5.5em",
}}
className={`btn btn-danger  p-0 my-1 btn-extra-radius d-flex flex-column justify-content-center align-items-center `}
onClick={()=>{handleSessionEnd()}}
>
<div className="text-center">
  <div>
<GoStop style={{ fontSize: fontSize }} />
 <br />
              End<br/>Meet
            </div>
 </div>

</button>}
{ isSessionMaster !== false &&forSessMaster&&<>     {/* <button
        style={{
          width: squareSize,
          height: "4em",
        }}
        className={`btn p-1 my-1 btn-extra-radius font-weight-bold d-flex justify-content-center align-items-center default-transition ${
          zoomData.showZoomContainer ? " btn-danger" : " btn-info"
        }`}
        onClick={toggleZoomVisibility}
      >
       
        {zoomData.showZoomContainer ? (
         "Close Video"
        ) : (
          "Open Video"
        )}
      </button> */}

{ zoomData.viewMode === "horizontal"?

<button
style={{
  width: squareSize,
  height:"4em",
}}
className={`btn btn-info  p-0 my-1 btn-extra-radius d-flex flex-column justify-content-center align-items-center `}
onClick={setViewModeFullscreen}
>
<div className="text-center">
  <div>
<FaWindowMaximize style={{ fontSize: fontSize }} />
 <br />
              Chat
            </div>
 </div>

</button>
:
 <button
        style={{
          width: squareSize,
          height:"4em",
        }}
        className={`btn btn-info  p-0 my-1 btn-extra-radius d-flex flex-column justify-content-center align-items-center `}
        onClick={setViewModeHorizontal}
      >
        <div className="text-center">
  <div>
        <FaColumns style={{ fontSize: fontSize }} />

 <br />
              Chat
            </div>
 </div>
      </button>

     




}


      <button
        style={{
          width: squareSize,
          height: "4em",
        }}
        className={`${
          isSyncOn ? "btn-success" : "btn-danger"
        } btn p-0 my-1 btn-extra-radius d-flex flex-column justify-content-center align-items-center`}
        onClick={() => toggleIsSyncOn()}
      >
        <div className="text-center">
          {isSyncOn && (
            <div>
              <VscSync />
              <br />
              ON
            </div>
          )}
          {!isSyncOn && (
            <div>
              <VscSyncIgnored />
              OFF
            </div>
          )}
        </div>
      </button>

      <button
        style={{
          width: squareSize,
          height: "3em",
        }}
        className={`${
          numberOfMembersInWaitingRoom === 0 ? "btn-info" : "btn-info"
        } btn p-0 my-1 btn-extra-radius d-flex flex-column justify-content-center align-items-center position-relative`}
        onClick={() => setWaitingRoomModalOpen()}
      >
        <div className="text-center">
          <BsPeopleFill />

          <div
            style={{
              right: "-5px",
              top: "-5px",
              borderRadius: "100%",
            }}
            className="badge badge-danger position-absolute"
          >
            {numberOfMembersInWaitingRoom}
          </div>
        </div>
        </button>
      {isSessionMaster !== false &&
            <button
                   style={{
                     width: squareSize,
                     height:"4.5em",
                   }}
                   className={`btn btn-info py-5  p-0 my-1 btn-extra-radius d-flex flex-column justify-content-center align-items-center `}
                   onClick={() => setHandoffModalOpen()}
                 >
                   <div className="text-center">
             <div>
                   <FaHandsHelping style={{ fontSize: fontSize }} />
           
            <br />
                         Pass
            <br />
                         Ctrl
                       </div>
            </div>
                 </button>}
                 </>
                 }
    </div>
  );
}
