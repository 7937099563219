import React, { useContext} from "react";
import QuestionModal from "../Question/QuestionModal";
import ResourceContainer from "../Resource/ResourceContainer";
import SessionContainer from "../Session/SessionContainer";
import StyledContentLoader from 'styled-content-loader'
import { UserContext } from "./../../context/UserContext";
import { GroupReqContainer } from "../Manage/GroupReqContainer";
import ErrorBoundary from "../ErrorBoundary";
export default function Home() {
  // THE CODE TO SEND EMAIL
  // const handleSend= () =>{
  //   fetch(process.env.REACT_APP_MAIL_API, {
  //             method: 'POST',
  //             body: JSON.stringify({

  //                     to: "alex.koumarianos@javasocial.club",
  //                     subject: "this is a test",
  //                     emailBody: "<p>thi</p><p>thi</p><p>thi</p><p>this is a test</p></p><p>right about now youre probably beggining to wonder how you got here...</p>"

  //             }),
  //             headers: {
  //               'Content-Type': 'application/json'
  //             }
  //           }).then((response) => response.json())
  //           .then((json) =>console.log(json)
  //           )}

  const { state } = useContext(UserContext);
  return (
    <div  className="container  my-3">
        
      <div className="row">
        <div className="col-sm-12 col-md-7">

      <ErrorBoundary>
   <SessionContainer />
      </ErrorBoundary>
        </div>
        <div className="col-sm-12 col-md-5">
          {state.user.role == "ROLE_FACILITATOR"&&<ResourceContainer />}
          {state.user.role !== "ROLE_FACILITATOR"&&<GroupReqContainer/>}
          
        </div>
      </div>
    </div>
  );
}
