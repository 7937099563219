import React, { useState } from "react";
import HorizontalScroller from "./../HorizontalScroller";
import ThemeCard from "./ThemeCard";

export default function HorizontalThemeList(props) {
  const {
    categoryTitle,
    categoryAlias,
    themes,
    enableViewAll,
    handleSelectCategory,
  } = props;

  // View all modal
  const [viewAllOpen, setViewAllOpen] = useState(false, []);

  const openViewAll = () => {
    setViewAllOpen(true);
  };
  const handleViewAllButtonClick = () => {
    handleSelectCategory({ title: categoryTitle, alias: categoryAlias });
  };

  return (
    <section className="my-4">
      <div className="d-flex flex-row w-100 align-items-center mb-2">
        <span className="h5 mb-0">{categoryTitle}</span>
        {enableViewAll && (
          <button
            onClick={handleViewAllButtonClick}
            className="mx-3 btn btn-sm py-0 btn-primary btn-extra-radius"
          >
            View all
          </button>
        )}
      </div>

      <div className={`bg-cleard useBorder box-shadow round-box p-2`}>
        <HorizontalScroller>
          {themes &&
            themes.map(theme => (
              <ThemeCard scroller={true} key={theme.id} theme={theme}></ThemeCard>
            ))}
        </HorizontalScroller>
      </div>
    </section>
  );
}
