import React, { useState, useContext, useEffect } from "react";
import { MdAdd } from "react-icons/md";
import { FiMessageCircle } from "react-icons/fi";
import GenericModal from "./../../GenericModal";
import RolePicker from "./RolePicker";
import PersonNotes from "./PersonNotes";
import { useEditSessionMemberRoleMutation } from "../../../graphql/generated/graphql";
import { UserContext } from "../../../context/UserContext";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow:"0px 0px 0px 0px rgba(0, 0, 0, 0.0) ",
    border: "none",
    backgroundColor:"rgba(0, 0, 0, 0.0)",
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    backgroundColor:"rgba(0,0,0,0.5)",
    width: "70px",
    height: "70px",
  },
}));
export default function PersonCard(props) {
  const {
    type,
    person,
    sessionRole,
    inSession,
    handleUserId,
    sessionId,
    sessionMasterId,
    isSessionMaster,
    sessionState,
    getRoleDict,
    isReflections,
    image
  } = props;
  const classes = useStyles();
  const [roleModalIsOpen, setRoleModalIsOpen] = useState(false, []);

  const [noteModalIsOpen, setNoteModalIsOpen] = useState(false, []);
  const [checked, setChecked] = useState(false, []);

  const [editSessionMemberRole] = useEditSessionMemberRoleMutation();
  const { state } = useContext(UserContext);
  useEffect(() => {
    
    return () => {
      console.log("cleanup on isle 5")
      setChecked(false)
    }
  }, [checked])
  const handleAssignRoleToMember = (id, role) => {
    editSessionMemberRole({
      variables: {
        session: sessionId,
        member: id,
        role: role,
      },
    });

    setRoleModalIsOpen(false);
  };
const getAttendance = ()=>{
  if(!inSession) return " bg-dark text-light"
  else return " bg-zero useBorder border text-light"
}
  if (type === "facilitator") {
    return (
      <div className={`person-card col-12 my-3`}>
        <div className={`round-box ${getAttendance()} box-shadow d-flex  justify-content-between align-items-center  mx-auto`}>
          <div
            className={`${
              type === "member" && "member-card-header"
            } align-items-center row pl-3`}
          >
            {/* <img
              className="rounded-circle mr-1 ml-0 ml-md-1"
              src="https://is1-ssl.mzstatic.com/image/thumb/Purple117/v4/f0/cc/cc/f0cccc81-2e9c-01dd-bb0b-55cdfa9b493b/source/512x512bb.jpg"
              alt="icon"
              width="70"
              height="70"
              style={{width: "70px", height: "70px" }}
            /> */}
  <Avatar className={classes.large}    src={"https://d30mg0zmvh01j5.cloudfront.net/"+image} ></Avatar>

            <span
              className="font-size-1 mb-0 ml-1"
              style={{ verticalAlign: "middle" }}
            >
               {person.nickname?`${person.nickname}`:`${person.firstName} ${person.lastName}`} {person.id==sessionMasterId?"- Facilitator":"- Co-facilitator"} 
            </span>
          </div>

          <div className="ml-2 mr-2">
            <div className={`${!inSession?"d-none":"d-flex"} p-1 rounded role-card-add`}>
              <input
                type="checkbox"
                value={checked}
                disabled={
                  !isSessionMaster || !sessionMasterId || !inSession || 
                  person.id === state.user.id ||
                  sessionMasterId === person.id
                }
                onChange={(e) =>
                  {handleUserId(
                    person.id,
                    `${person.firstName} ${person.lastName}`,
                    type,
                    e.target.value
                    )
                    setChecked(!checked)
                  }
                }
                className="session-preparation-checkbox"
                style={{ width: "1.75em", height: "1.75em" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`person-card  col-12 my-3`}>
        <div className={`round-box ${getAttendance()} box-shadow d-flex justify-content-between  align-items-center  mx-auto`}>
          <div
            className={`${
              type === "member" && inSession && "member-card-header"
            } align-items-center row pl-3`}
          >
            <Avatar className={classes.large}    src={"https://d30mg0zmvh01j5.cloudfront.net/"+image} ></Avatar>
            {/* <img
              className="rounded-circle mr-1 ml-0 ml-md-1"
              src="https://is1-ssl.mzstatic.com/image/thumb/Purple117/v4/f0/cc/cc/f0cccc81-2e9c-01dd-bb0b-55cdfa9b493b/source/512x512bb.jpg"
              alt="icon"
              width="70"
              height="70"
              style={{ width: "70px", height: "70px" }}
            /> */}

            <span
              className="font-size-1 mb-0 ml-1"
              style={{ verticalAlign: "middle" }}
            >
              {person.nickname?`${person.nickname}`:`${person.firstName} ${person.lastName}`}
            </span>
          </div>

          <div className={`${!inSession?"d-none":"d-flex"} member-card-content mb-3 mb-lg-0`}>
            <div className="w-100 mr-2 ml-0">
              <div
                role="button"
                className="rounded h-100 not-draggable role-card-add d-flex"
                onClick={() => setRoleModalIsOpen(true)}
              >
                <div className="font-size-1 my-auto px-3 w-100">
                  {(sessionRole === "" || sessionRole === "None") && (
                    <div className="d-flex align-items-center">
                      <MdAdd className="" style={{ fontSize: "1.5em" }} />
                      <span className="">{`${
                        sessionRole === "None" ? "None" : "Assign Role"
                      }`}</span>
                    </div>
                  )}
                  {sessionRole !== "" && sessionRole !== "None" && (
                    <span>{sessionRole}</span>
                  )}
                </div>
              </div>

              <GenericModal
                modalIsOpen={roleModalIsOpen}
                setModalIsOpen={setRoleModalIsOpen}
              >
                <RolePicker
                  setModalIsOpen={setRoleModalIsOpen}
                  handleAssignRoleToMember={handleAssignRoleToMember}
                  sessionRole={sessionRole}
                  person={person}
                  isReflections={isReflections}
                  getRoleDict={getRoleDict}
                />
              </GenericModal>
            </div>

            <>
              <div className="ml-auto mr-2">
                {person.notes.length !== 0 && (
                  <div
                    role="button"
                    className="round-box h-100 not-draggable role-card-add d-flex text-danger position-relative"
                    onClick={() => setNoteModalIsOpen(true)}
                  >
                    <FiMessageCircle
                      className="d-flex my-auto mx-2"
                      style={{ fontSize: "1.5em" }}
                    />{" "}
                    <div
                      style={{
                        right: "-5px",
                        top: "-5px",
                        borderRadius: "100%",
                      }}
                      className="badge badge-danger position-absolute"
                    >
                      {person.notes.length}
                    </div>
                  </div>
                )}
                {person.notes.length === 0 && (
                  <div
                    role="button"
                    className="round-box h-100 not-draggable role-card-add d-flex"
                    onClick={() => setNoteModalIsOpen(true)}
                  >
                    <FiMessageCircle
                      className="d-flex my-auto mx-2 "
                      style={{ fontSize: "1.5em" }}
                    />{" "}
                  </div>
                )}

                <GenericModal
                  modalIsOpen={noteModalIsOpen}
                  setModalIsOpen={setNoteModalIsOpen}
                >
                  <PersonNotes
                    person={person}
                    // TODO check this addPersonNote, we might not be using this function
                    addPersonNote={() => console.log("set person note")}
                    setNoteModalIsOpen={setNoteModalIsOpen}
                  />
                </GenericModal>
              </div>
            </>

            <div className="ml-2 ">
              <div className="d-flex p-1 rounded role-card-add">
                <input
                  type="checkbox"
                  value={checked}
                  disabled={
                    !isSessionMaster ||
                    person.id === state.user.id ||
                    sessionMasterId === person.id
                  }
                  onChange={(e) =>
                    {handleUserId(
                      person.id,
                      `${person.firstName} ${person.lastName}`,
                      type,
                      e.target.value
                    )
                    setChecked(!checked)
                  }
                  }
                  className="session-preparation-checkbox"
                  style={{ width: "1.75em", height: "1.75em" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
