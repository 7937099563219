import React from "react";
import {
  FaCheckCircle,
  FaQuestionCircle,
  FaBullhorn,
  FaPlayCircle,
  FaLightbulb,
} from "react-icons/fa";
import moment from "moment";
import ReactHtmlParser from 'react-html-parser'; 

export default function ResourceCard(props) {
  const { resource, bigVersion, isPH, handleOnClick } = props;

  const { id, category, title, thumb, text } = resource;
  // const { id, type, dateAdded, thumb, text } = resource;
  // TODO: after modifying the resource model, we will use the dateAdded from database data
  const dateAdded = "August 16, 2020";
const cardIsClicked=()=>{
  if(handleOnClick){
    handleOnClick(resource)
  }
}
  const newBadge =
    moment().diff(dateAdded, "days") < 7 ? (
      <span className="badge badge-primary">New</span>
    ) : (
      ""
    );

  let titleObj;

  switch (category) {
    case "blog":
      titleObj = (
        <div className="icon-text-smaller">
          <span>
            <FaBullhorn />
          </span>
          <span>Blog Post</span>
        </div>
      );
      break;
    case "faq":
      titleObj = (
        <div className="icon-text-smaller">
          <span>
            <FaQuestionCircle />
          </span>
          <span>FAQ</span>
        </div>
      );
      break;
    case "video":
      titleObj = (
        <div className="icon-text-smaller">
          <span>
            <FaPlayCircle />
          </span>
          <span>Video Feature</span>
        </div>
      );
      break;
    case "start":
      titleObj = (
        <div className="icon-text-smaller">
          <span>
            <FaCheckCircle />
          </span>
          <span>Getting Started</span>
        </div>
      );
      break;
    case "fg":
      titleObj = (
        <div className="icon-text-smaller">
          <span>
            <FaLightbulb />
          </span>
          <span>Facilitation Guide</span>
        </div>
      );
      break;
    case "wwg":
      titleObj = (
        <div className="icon-text-smaller">
          <div>
            <FaLightbulb />
          </div>
          <div>Working With Groups</div>
        </div>
      );
      break;
    case "r":
      titleObj = (
        <div className="icon-text-smaller">
          <div>
            <FaLightbulb />
          </div>
          <div>Resource</div>
        </div>
      );
      break;
    default:
      titleObj = (
        <div className="icon-text-smaller">
          <span>
            <FaLightbulb />
          </span>
          <span>{title}</span>
        </div>
      );
  }

  const isBigVersion = bigVersion === undefined ? false : bigVersion;

  return (
    <div onClick={cardIsClicked} className="mb-4 border bg-clear shadow round-box">
      <div className="row mx-auto">
        <div
          className={
            "px-0 d-flex " +
            (isBigVersion ? "col-lg-3 col-md-3 col-3" : "col-lg-4 col-4")
          }
        ><div  style={{ borderRadius: "15px 0 0 15px"}} className="w-100 bg-light align-items-center d-flex px-2">

          <img
           
            src={ "https://d30mg0zmvh01j5.cloudfront.net/assets/photos/thumb" + thumb}
            alt=""
            style={{ borderRadius: "15px 0 0 15px", maxWidth: "100%" }}
          ></img>
        </div>
        </div>

        <div
          className={
            "mt-2 px-2 d-flex flex-column " +
            (isBigVersion
              ? "col-lg-9 col-md-9 col-9"
              : "col-lg-8 col-md-8 col-8")
          }
        >
          <div className="d-flex flex-row justify-content-between w-100">
            <h4 className="text-light">{titleObj}</h4>
            <div className="text-right px-1">{newBadge}</div>
          </div>

          <div
            className="align-self-stretch my-2 text-wrap"
            style={{ wordBreak: "break-all" }}
          >
            {!isBigVersion && !isPH && <><div className="text-light font-weight-bold">{title}</div>
            <p className="mt-0 pt-0 mb-5">{text[0].split(">")[1].split("<")[0]}</p></>}
            {isBigVersion && !isPH && <><h1 className="text-light">{title}</h1>
            <div className="">{ReactHtmlParser(text[0])}</div></>}
            {isBigVersion && !isPH && text.slice(1).map(txt=>{
            console.log(ReactHtmlParser(txt))
            if(txt[1]=="h"){
              return <div className="my-4 ">{ReactHtmlParser(txt)}</div>

            }else{

              return <div className="my-2 ">{ReactHtmlParser(txt)}</div>
            }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
