import React, { useContext, useState, useEffect } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useHistory, Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { setResetToken, getResetToken, getAccessToken } from "../../auth/accessToken";
import { SET_PASS_RESET_TOKEN, UserContext } from "../../context/UserContext";
import { useResetPasswordMutation, useChangePasswordByResetMutation } from "../../graphql/generated/graphql";
import logo from "../Login/logo.png";

export default function ResetPasswordForm(props) {
  const { reason } = props;
  const [fields, setFields] = useState({
   loading: true,
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState(false);
  const [token, setToken] = useState(null);
  const [rgX, setRgX] = useState(null);
  const [inputStyle, setInputStyle] = useState(null);

  let isSubmitting = false;

  const history = useHistory();
  const { id: passReset } = useParams();

  const { state, dispatch } = useContext(UserContext);
  const [changePassword] = useChangePasswordByResetMutation();

  //const [login] = useLoginMutation();

  const handleOnChange = e => {
    if(rgX&& e.target.name=="password"){
      setRgX(checkRgX(e.target.value))
      setInputStyle("box-shadow border border-danger")
    }
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    
    if(fields.password.length > 0 && fields.password==fields.confirmPassword && checkRgX(fields.password).length <1){
      setInputStyle("box-shadow border border-success")
    }
    if(inputStyle && ( fields.password!==fields.confirmPassword || checkRgX(fields.password).length > 0)){
      setInputStyle("box-shadow border border-danger")
    }
  }, [fields])
  
  const [checkToken] = useResetPasswordMutation();
  const handlecheckToken = async ()=>{
    let expired = false;
    try {
      const passResetToken =  await checkToken({ variables: { passReset } }).then(async  res => {
        
        if (res.errors) {
          
          toast.error(`${res.errors[0].message}`);
          setResetToken("")
          setError(true)
          
        }
        return await res
      }).then( async res=>{
        
        if (res.data.resetPassword && res.data.resetPassword.token) {
          
          dispatch({
            type: SET_PASS_RESET_TOKEN,
            payload: { resetToken: res.data.resetPassword.token },
          });
          setResetToken(res.data.resetPassword.token);
          setToken(res.data.resetPassword.token)
          return await res.data.resetPassword.token
        }else{
          setError(true)
        }
      })
      
    } catch (err) {
      return expired
    }
  }
  useEffect( () => {
    if(passReset){
      handlecheckToken()  
      //setToken(getResetToken())
      // console.log(passReset)
    
}
}, [passReset])
  useEffect( () => {
    //console.log(token)
}, [token])
const checkRgX = (pass) =>{
  let errors = []
  const checklist = [
    {chk:/(?=.*\d)/,err:"Your password should have one or more number."},
    {chk:/(?=.*[a-z])/,err:"Your password should have one or more lowercase letter"},
    {chk:/(?=.*[A-Z])/,err:"Your password should have one or more uppercase letter"},
    {chk:/^.{6,20}$/,err:"Your password should be at least 6 charecters long"}
]
checklist.map(rule => {
  if (!rule.chk.test(pass)) {
    errors.push(rule.err)
  }
})
return errors
}
//  console.log(token)
  const handleOnSubmit = async () => {
    console.log(typeof getResetToken())
      const { confirmPassword, password } = fields;
      if (password === confirmPassword ) {
        // console.log(password)
        // console.log(confirmPassword)
        const pswPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if (pswPattern.test(password)) {
          setRgX(null)
          await changePassword({
            variables: {
              newPassword: password,
              token: token
            }
          }).then(res => {
            if(res.errors){
               toast.error(res.errors[0].message)
               return;
            }
               setResetToken("")
               toast.success("task failed successfully")
               history.push("/")
            }).catch(err => {
            toast.error("Oops, something went wrong... Password was not changed");
            return;
          })
        } else {
          toast.error("Oops, the given new password cannot be accepted");
          setRgX(checkRgX(password))
          return;
        }
      } else{
        toast.error("Oops, passwords do not match");
      }
   
  };

  if (getAccessToken() !== "") {
    toast.error("Log out first before trying to sign in as another user.", {
      toastId: "already signed in",
    });
    history.push("/");
  }
useEffect(() => {
    if(error){
        history.push("/")
    }
   
}, [error])

  return (
    <div
      className="d-flex flex-row justify-content-center align-items-center vh-100"
      style={{ backgroundColor: "#aaa" }}
    >
        {!error&&(
      <form
        onSubmit={handleOnSubmit}
        className="border my-5 pt-5 px-5 pb-3 col-xs-12 col-sm-11 col-md-11 col-lg-9 col-xl-6"
        style={{ backgroundColor: "#fff", borderRadius: "20px" }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-6 d-flex flex-row justify-content-center mb-5">
            <img
              src={logo}
              id="icon"
              alt="Java Group"
              height="84"
              width="160"
            />
          </div>

          <div className="col-sm-12 col-md-6">
            {/* Password */}
<div className="input-group mb-1">
<div className="input-group-prepend">
  <span
    className="input-group-text"
    id="password"
    style={{ width: "150px" }}
  >
    Password:
  </span>
</div>
<input
  onChange={handleOnChange}
  value={fields.password}
  name="password"
  type="password"
  className={`form-control ${inputStyle}`}
  aria-label="Password"
  aria-describedby="password"
/>
{rgX&&rgX.map(x=><span className={"d-block font-weight-light text-danger"}>{x}</span>) }
</div>
<div className="input-group mb-1">
<div className="input-group-prepend">
  <span
    className="input-group-text"
    id="password"
    style={{ width: "150px" }}
  >
    Confirm Password:
  </span>
</div>
<input
  onChange={handleOnChange}
  value={fields.confirmPassword}
  name="confirmPassword"
  type="password"
  className={`form-control ${inputStyle}`}
  aria-label="confirm password"
  aria-describedby="confirm password"
/>
</div>

            
          </div>
        </div>

        {/* Log in button */}
        <div className="row mx-auto d-flex flex-row justify-content-center mt-3">
          
          <button type={"button"} className="btn btn-primary mx-3" onClick={()=>handleOnSubmit()}>Reset</button>
          
         
        </div>
       
      </form>


        )}
    </div>
  );
}
