import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { state } = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={props => {
        const currentUser = state.user;
        if (!currentUser || !currentUser.id || !state.accessToken) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/signin", state: { from: props.location } }}
            />
          );
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(currentUser.role) === -1) {
          // role not authorized so redirect to home page
          return <Redirect to={{ pathname: "/home" }} />;
        }

        // authorized so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
