import React, { useState } from "react";
import moment from "moment";
import logo from "./../Login/logo.png";
import Jslogo from "./../Login/JSClogo.png";
import GenericModal from "../GenericModal";
import SessionCardMedium from "./SessionCardMedium";

export default function SessionCardSmall(props) {
  const {
    showButton,
    handleOnButtonClick,
    handleOnCardClick,
    clickableCard,
    session,
    filled,
    selected,
    fallbackThemesList,
  } = props;

  const { date, theme } = session;

  const displayButton = showButton === undefined ? true : showButton;

  const isClickableCard = clickableCard === undefined ? false : clickableCard;

  // Modal stuff
  const [modalIsOpen, setModalIsOpen] = useState(false, []);
console.log(session)
  // If showButton is true and a handleOnButtonClick function is provided then call that function onClick
  const onButtonClick = e => {
    // Open modal
    setModalIsOpen(true);
    if (displayButton && handleOnButtonClick) {
      // this function is received as prop
      handleOnButtonClick(session);
    }
  };

  // If the card is clickable and a handleOnCardClick function is provided then call that function onClick
  const onCardClick = e => {
    if (isClickableCard && handleOnCardClick) {
      // this function is received as prop
      handleOnCardClick(session);
    }
  };

  return (
    <div
      className={
        "border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2" +
        (isClickableCard ? " clickable-card" : "") +
        (filled ? " gray-filled" : "") +
        (selected ? " selected-card" : "")
      }
      onClick={onCardClick}
    >
      <div className="pl-3 py-2 row">
        <div className={`col-1 pl-0 d-flex align-items-center`}>
        <img
              src={session.program=="music"?logo:Jslogo}
              
              id="icon"
              alt="Java Group"
              className={``}
              width="65px"
              />
        </div>
        <div className={`${displayButton ? "col-8" : "col-11"} pl-4`}>
          <h5>
            <span className="mr-3 text-capitalize">{session.group.name}</span>
            {session.status === "joinable" && (
              <span
                className={`badge ${
                  session.status === "joinable"
                    ? "badge-success"
                    : "badge-danger"
                }`}
              >
                {session.status === "joinable" ? "In-progress" : "Pending"}
              </span>
            )}
          </h5>
          <p className="mb-0 font-weight-light">
            <span className="d-block d-lg-inline">
              {moment(date).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
            <span className="d-none d-lg-inline"> - </span>
            <span className="text-capitalize">
              {theme ? theme.name.toLowerCase() : "No theme chosen"}
            </span>
          </p>
        </div>
        {displayButton && (
          <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
            <button
              onClick={onButtonClick}
              className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius"
            >
              Open
            </button>
            <GenericModal
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
            >
              <SessionCardMedium
                isPopup={true}
                renderVertically={false}
                session={session}
                fallbackThemesList={fallbackThemesList}
              />
            </GenericModal>
          </div>
        )}
      </div>
    </div>
  );
}
