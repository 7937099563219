import React, { useEffect, useState } from "react";
import GenericModal from "../../GenericModal";
import {  ThemesDocument,useEditQuoteInThemeMutation,useAddQuoteMutation,useRemoveQuoteFromThemeMutation }from "../../../graphql/generated/graphql";

export default function ThemeCard(props) {
  const { theme, selectableTheme, handleThemeSelection, disabler } = props;

  const [themePreviewIsOpen, setThemePreviewIsOpen] = useState(false, []);
  const [deleteWarning, setDeleteWarning] = useState(false, []);
  const [selectedQuote, setSelectedQuote] = useState({id:"",quote:"",author:""});
  const [addEdit, setAddEdit] = useState(false, []);
  const [isActive, setIsActive] = useState(false, []);
  const [editQuote] = useEditQuoteInThemeMutation();
  const [addQuote] = useAddQuoteMutation();
  const [removeQuote] = useRemoveQuoteFromThemeMutation();
  const handleOnChange = e => {
    setSelectedQuote({ ...selectedQuote, [e.target.name]: e.target.value });
 };
  const handleDeleteModal = (q)=>{
    setSelectedQuote(q)
    setDeleteWarning(true)
}
  const  handleDeleteQuote = (q)=>{
    removeQuote({
        variables: {
            quote: q.id,
            theme: theme.id,
        },refetchQueries: [{ query: ThemesDocument }],
      })
        .then(res => {
          console.log(res.errors);
        })
        .catch(err => {
          console.log(err);
        });
    
    
    
    
    setSelectedQuote({id:"",quote:"",author:""})

}
  const  handleAddEditQuote = (q)=>{
      console.log(selectedQuote)
    if(selectedQuote.id=="new"){
        addQuote({
            variables: {
                quote: q.quote,
                author: q.author,
                theme: theme.id,
            },refetchQueries: [{ query: ThemesDocument }],
          })
            .then(res => {
              console.log(res.errors);
            })
            .catch(err => {
              console.log(err);
            });
    }else{
        console.log(q)
        editQuote({
            variables: {
                quoteId: q.id,
                quote: q.quote,
                author: q.author,
                theme: theme.id,
            },refetchQueries: [{ query: ThemesDocument }],
          })
            .then(res => {
              console.log(res.errors);
            })
            .catch(err => {
              console.log(err);
            });
    }
    
    
    setSelectedQuote({id:"",quote:"",author:""})

}
const handleEditModal = (q)=>{
    setSelectedQuote(q)
    setAddEdit(true)
    
}
const handleAddModal = ()=>{
    setSelectedQuote({id:"new",quote:"",author:""})
      setAddEdit(true)

  }
  const [style, setStyle] = useState("theme-card-disabled");
const [useTheme, setUseTheme] = useState(theme)
  const openThemePreview = () => {
    if (disabler) {
      setIsActive(!isActive);
      return false;
    }
    setUseTheme(theme)
    setThemePreviewIsOpen(true);
  };
  const handleTablet = () => {
    if (disabler) {
      setStyle("tcA");
      console.log("here start");
      breath("theme-card-disabled");
      setIsActive(true);

      return false;
    }
  };
  const getURL = url => {
    return url.slice(0, 14) + "/thumb/" + url.slice(15);
  };
  const breath = look => {
    setStyle("tcA");
    setTimeout(function () {
      console.log("wait");
      setStyle(look);
    }, 3000);
  };

  const handleOnClickThemeSelection = () => {
    if (selectableTheme && handleThemeSelection) {
      console.log(theme);
      handleThemeSelection(theme);
      setThemePreviewIsOpen(false);
    }
  };
if(!theme){
    return(<div>no</div>)
}
  return (
    <div className="p-2" key={theme.id}>
      {!disabler && (
        <div
          className="position-relative text-center d-flex align-items-center justify-content-center theme-card"
          role="button"
        >
          <img
            className="not-draggable round-box"
            src={
              "https://d30mg0zmvh01j5.cloudfront.net" + getURL(theme.imageUrl)
            }
            height={150}
            width={200}
            onClick={openThemePreview}
            alt=""
          />
          <h5 className="position-absolute text-white my-auto mx-auto">
            {theme.name}
          </h5>{" "}
        </div>
      )}
      {disabler && (
        <div
          onTouchEnd={handleTablet}
          className={`position-relative text-center d-flex align-items-center justify-content-center  ${style} `}
          role="button"
        >
          <img
            className="not-draggable round-box"
            src={
              "https://d30mg0zmvh01j5.cloudfront.net" + getURL(theme.imageUrl)
            }
            height={150}
            width={200}
            alt=""
          />
          <div className="text-white px-2 theme-card-disabled-div round-box position-absolute text-white my-auto mx-auto">
            <h5 className="text-white my-auto mx-auto round-box">
              {theme.name}
            </h5>

            <p className={"m-0 " + style ? "show" : "show"}>Theme last Used:</p>
            <p className="m-0 show">{" 9 November 1989 "}</p>
          </div>
        </div>
      )}

      {/* Theme Preview modal */}

      <GenericModal
        modalIsOpen={themePreviewIsOpen}
        setModalIsOpen={setThemePreviewIsOpen}
        
      >
        <div  style={{maxHeight:800,overflow:"auto"}}  className={`bg-cleard d-flex flex-column round-box box-shadow m-5 border useBorder`}>
     
     { theme.quotes.map(q=>(
         
         <div className={`bg-cleard rounded box-shadow m-5 border useBorder`}>
         <div className={` rounded p-2`}>
         {q.quote}
            
          
          </div>
         <div className={` rounded   p-2 `}>
          {q.author}
         
             
          
          </div>
          <div className="row d-flex justify-content-around mb-2">
          <button onClick={()=> handleDeleteModal(q)} className={`col col-2 btn btn-danger`}>Delete</button>
          <button onClick={()=> handleEditModal(q)} className={` col col-2 btn btn-info`}>Edit</button>
          </div>
          </div>
    
    ))}
    <button onClick={()=> handleAddModal()}  className={`btn btn-success mx-5 mb-5`}>Add New Quote</button>
 </div>
      </GenericModal>
      <GenericModal
      style={{width:"100px !important"}}
      className={"col-6 w-25"}
      addClass={" bg-clear gray-filled"}
        modalIsOpen={deleteWarning}
        setModalIsOpen={setDeleteWarning}
      >
          <div className="round-box col-12 box-shadow  align-self-center p-5">
             <div className={"row d-flex justify-content-center"}>
                <h5>
              Are you sure you want to delete this quote? 
              </h5>
              </div>
             
         
         
            <div className={"row mt-4"}>
            <div className={"col d-flex justify-content-center"}>
              <button onClick={()=>{
                handleDeleteQuote(selectedQuote)
                setDeleteWarning(false)
                
                }} className={"btn btn-success w-25 mx-3 box-shadow "}>
                Yes
              </button>
              <button onClick={()=>{ 
                 setDeleteWarning(false)
              }} className={"btn btn-danger  w-25 mx-3 box-shadow"}>
                No
              </button>
            </div>
           
            </div>
          </div>
      </GenericModal>
      <GenericModal
      style={{width:"100px !important"}}
      className={""}
      addClass={"w-50 bg-clear gray-filled"}
        modalIsOpen={addEdit}
        setModalIsOpen={setAddEdit}
      >
          <div className="round-box box-shadow  p-5">
             <div className={" d-flex flex-column justify-content-center"}>
             <div className="form-group row">
              <label>Quote</label>
              <textarea
                name="quote"
                type="text-area"
                className={`form-control`}
                value={selectedQuote.quote}
                placeholder="Quote"
                onChange={handleOnChange}
                >
                </textarea>
            </div>
            <div className="form-group row">
              <label>Author </label>
              <input
                name="author"
                type="text"
                className={`form-control `}
                value={selectedQuote.author}
                placeholder="Author"
                onChange={handleOnChange}
                />
            </div>
              </div>
             
         
         
            <div className={"row mt-4"}>
            <div className={"col d-flex justify-content-center"}>
              <button onClick={()=>{
                               handleAddEditQuote(selectedQuote)
                               setAddEdit(false)
                               
                            }} className={"btn btn-success w-25 mx-3 box-shadow "}>
                Confirm
              </button>
              <button onClick={()=>{ 
                    setAddEdit(false)

              }} className={"btn btn-danger  w-25 mx-3 box-shadow"}>
                Deny
              </button>
            </div>
           
            </div>
          </div>
      </GenericModal>

    </div>
  );
}
