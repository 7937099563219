import React, { useState, useContext } from "react";

import ThemeSongCard from "./components/ThemeSongCard";
import MusicController from "../MusicController";
import SyncMusicController from "../SyncMusicController";
import { useAddJavaTipToThemeMutation } from "../../../graphql/generated/graphql";
import { UserContext } from "../../../context/UserContext";
import { toast } from "react-toastify";

export default function ThemeModalContent(props) {
  const {
    theme,
    modalContent: { content, id, position },
    onThemeCardClick,
    playedSongs,
    setModalIsOpen,
    themeName,
    songPlaying,
    handleMusicPlay,
    isSessionMaster,
    songLocation,
  } = props;

  const { state } = useContext(UserContext);

  // when the song is chosen in the song lists, the song id will be set and when the btn is clicked
  // the lyrics of the song found by the id will be displayed
  const [selectedSongId, setSelectedSongId] = useState(null, []);

  const [addJavaTipToTheme] = useAddJavaTipToThemeMutation();

  const [notes, setNotes] = useState("", []);

  const handleNoteSubmit = e => {
    e.preventDefault();
    addJavaTipToTheme({
      variables: {
        theme: theme.id,
        author: state.user.id,
        body: notes,
      },
    })
      .then(res => {
        if (res.errors) {
          toast.error("Oops, something went wrong");
          console.log(res.errors);
        } else {
          toast.success("Note added successfully");
        }
      })
      .catch(err => {
        toast.error("Oops, something went wrong");
        console.log("ERR", err);
      })
      .finally(() => {
        setModalIsOpen(false);
      });
  };

  switch (content) {
    case "question":
      return (
        <div className="round-box box-shadow p-5">
          <h4 className="mt-3">Discussion questions</h4>
          <ul>
            {theme.discussions.map(question => (
              <li key={question.id} className="font-size-5 mt-3">
                {question.question}
              </li>
            ))}
          </ul>
        </div>
      );
    case "reflection":
      return (
        <div className="round-box box-shadow p-5">
          <h4 className="mt-3">Reflection</h4>
          <ul>
            {theme.reflection.map(question => (
              <li key={question.id} className="font-size-5 mt-3">
                {question.question}
              </li>
            ))}
          </ul>
        </div>
      );

    case "quote":
      const quoteData = theme.quotes.find(quote => quote.id === id);
      return (
        <div
          className="round-box box-shadow p-5"
          style={{ maxWidth: "900px", minWidth: "700px" }}
        >
          <div style={{ fontSize: "3rem" }} className="pb-3">
            "{quoteData.quote}"
          </div>
          <h4 style={{ fontSize: "1.5rem" }} className="pt-3">
            {quoteData.author}
          </h4>
        </div>
      );

    case "themePhoto":
      return (
        <div className="round-box box-shadow" style={{ width: "700px" }}>
          <img
            src={"https://d30mg0zmvh01j5.cloudfront.net" + theme.imageUrl}
            alt="img"
            className="w-100"
          />
        </div>
      );

    case "songs":
      return (
        <div
          className="round-box box-shadow container p-3"
          style={{ width: "700px", maxHeight: "80vh", overflowY: "auto" }}
        >
          <h2 className="text-center">Choose a song</h2>
          <div className="row">
            {theme.songs.map(
              song =>
                !Object.keys(playedSongs).includes(song.id) && (
                  <ThemeSongCard
                    key={song.id}
                    song={song}
                    cssClassName="w-100 col-12 col-md-6 my-2"
                    setSelectedSongId={setSelectedSongId}
                    selectedSongId={selectedSongId}
                    playedSongs={playedSongs}
                  />
                )
            )}
            <button
              className="bg-info mx-auto my-3 px-5 py-2 btn-extra-radius text-white border-0"
              onClick={
                selectedSongId === null
                  ? undefined
                  : () => onThemeCardClick("song", selectedSongId)
              }
            >
              Done
            </button>
          </div>
        </div>
      );

    case "song":
      const songData = theme.songs.find(song => song.id === id);
      console.log(songData)
      const getURL = (url)=>{
        return url.slice(0,14)+"/400/"+url.slice(15) 
      }
      const lyrics = songData.lyrics.split("<br>");
      return (
        <div
          className="round-box box-shadow p-5 container"
          style={{ maxWidth: "1000px", minWidth: "900px", maxHeight: "80vh", overflowY: "auto" }}
        >
          <div className="row">
            <div className="col-12 col-md-6 text-center">
              <h4>{songData.title}</h4>
              <span>{songData.imageUrl}</span>
              <img
                src={"https://d30mg0zmvh01j5.cloudfront.net" + getURL(theme.imageUrl)}
                width={400}
                height={400}
                alt=""
                className="round-box w-100 my-3"
              />
              {(songLocation !== "" && songLocation !== undefined) ||
              handleMusicPlay !== undefined ? (

                <SyncMusicController
                  songLocation={songLocation}
                  title={songData.title}
                  handleMusicPlay={handleMusicPlay}
                  songPlaying={songPlaying}
                  isSessionMaster={isSessionMaster}
                  isSync={true}
                  musicUrl={
                    songData.url
                  }
                  />
                 
              ) : (               <>
           
                <MusicController
                  title={songData.title}
                  handleMusicPlay={handleMusicPlay}
                  songPlaying={songPlaying}
                  isSessionMaster={isSessionMaster}
                  isSync={true}
                  musicUrl={
                     songData.url
                  }
                /> </>
              )}
            </div>

            <div className="col-12 col-md-6">
              {lyrics.map(line => {
                return (
                  <div className={line == "" ? "row mb-4" : ""}>{line}</div>
                );
              })}
            </div>
          </div>
        </div>
      );

    case "addNote":
      const positionLabels = [
        "Discussion and Sharing",
        "Discussion Question",
        "Quote",
        "Sensory Cue",
        "Songs",
      ];
      return (
        <form
          onSubmit={() => handleNoteSubmit}
          className="round-box box-shadow pt-4"
          style={{ width: "700px" }}
        >
          <div className="container">
            <h5>
              {theme.name} &gt; {positionLabels[position]}
            </h5>
            <div className="form-group">
              <textarea
                className="form-control my-5"
                rows="5"
                placeholder="add a note here..."
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
            </div>
          </div>
          <button
            onClick={handleNoteSubmit}
            className="w-100 bg-info text-center py-3 text-white"
            style={{ borderRadius: "0 0 15px 15px" }}
          >
            Add
          </button>
        </form>
      );

    default:
      setModalIsOpen(false);
      return <></>;
  }
}
