import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  root: {
    width: "80%",
  },
  margin: {
    height: theme.spacing(6),
  },
}));
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const PrettoSlider = withStyles({
  root: {
    color: "#007bff",
    height: 15,
  },
  // thumb: {
  //   height: 24,
  //   width: 24,
  //   backgroundColor: "#007bff",
  //   border: "2px solid currentColor",
  //   marginTop: -8,
  //   marginLeft: -12,
  //   "&:focus, &:hover, &$active": {
  //     boxShadow: "inherit",
  //   },
  // },
  thumb: {
    height: 0,
    width:0,
    backgroundColor: "#007bff",
    border: "0px solid currentColor",
    marginTop: 0,
    marginLeft: 0,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    color: "#005bff",
    height: 15,
    borderRadius: 4,
  },
  rail: {
    height: 15,
    borderRadius: 4,
  },
})(Slider);

export default function VisualAnalogQuestion(props) {
  const { handleAnswer, question } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    
    setValue(newValue===0?1:newValue);
    console.log(newValue);
    handleAnswer(question.num, newValue===0?1:newValue);
  };
  return (
    <div className={"my-3 box-shadow shadow-lg"}>
      <div className={"card bg-clear " + question.style}>
        <div className="card-header bg-dark">
          {question.num}. {question.body}
        </div>
        <div className={"col col-12 "}>
          <div className={"row justify-content-around align-items-center mb-1"}>
            <div className={"col ml-1 align-items-center mb-1"}>

            {question.anchormin}
            </div>
            <div className={classes.root+" col col-8"}>
              <Typography id="continuous-slider" gutterBottom></Typography>
              <Grid className={classes.margin} container>
                <Grid item xs>
                  <PrettoSlider
                    valueLabelDisplay="off"
                    aria-label="pretto slider"
                    value={value}
                    onChange={handleChange}
                    aria-labelledby="continuous-slider"
                  />
                </Grid>
              </Grid>
            </div>
            <div className={"col mr-1 align-items-center mb-1"}>
              
            {question.anchormax}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
