import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import PreparationContainer from "./Preparation/PreparationContainer";
import ReviewContainer from "./Review/ReviewContainer";
import MultiSectionContainer from "./MultiSectionContainer";
import ZoomContainer from "./ZoomUi/ZoomContainer";
import ZoomFloatingMenu from "./ZoomUi/ZoomFloatingMenu";
import FontSizeButtons from "./FontSizeButtons";
import VideoChat from "./Twilio/VideoChat";

import RunningSessionThemeContainer from "./Theme/RunningSessionThemeContainer";

import {
  useEditRunningSessionMutation,
  useSessionFacilitatorsSubscription,
  useSessionMembersSubscription,
  useSessionModifiedSubscription,
  useSessionQuery,
  useSessionSessionMembersLazyQuery,
  useSessionSessionMembersQuery,
  useSessionWaitingRoomSubscription,
  useAddUserToSessionMutation,
  useAddQuestionnaireMutation,
  useAddAnswerMutation,
  useQuestionnaireUserSessionQuery,
  useCheckQuestionnaireExistsQuery,
  useEditQuestionnaireMutation,


} from "../../graphql/generated/graphql";

import { UserContext } from "../../context/UserContext";
import { VideoChatCluster } from "./PageContent";
import LoadingSpinner from "../LoadingSpinner";
import GenericModal from "../GenericModal";
import WaitingRoomMenu from "./WaitingRoomMenu";
import { toast } from "react-toastify";
import moment from "moment";
import { MdLocationSearching } from "react-icons/md";
import Handoff from "./Handoff";
import ErrorBoundary from "../ErrorBoundary";


export default function RunningSessionContainer(props) {
  const { id } = useParams();
  const audioRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [playedSongs, setPlayedSongs] = useState({});

  const [sessionData, setSessionData] = useState(null);

  const { state } = useContext(UserContext);

  const [waitingRoom, setWaitingRoom] = useState([]);
  const [isSilent, setIsSilent] = useState(false);

  const [isWaitingRoomModalOpen, setIsWaitingRoomModalOpen] = useState();
  const [isHandoffModalOpen, setIsHandoffModalOpen] = useState();

  // Session Master, move to states:
  const [isSessionMaster, setIsSessionMaster] = useState(false);
  const [sessionMasterId, setSessionMasterId] = useState("");

  // Offline state modal
  const [showOfflineModal, setShowOfflineModal] = useState(null);
  const [showDontRefresh, setShowDontRefresh] = useState(false);
  const [connection, setConnection] = useState({ isDisconnected: false })

  

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          fetch('https://httpbin.org/get', {
            mode: 'no-cors',
          })
            .then(() => {
              // On reconnection we will not set the showOfflineModal to true
              // instead we will set it to "reconnected"
              if (showOfflineModal === true) {
                setShowOfflineModal("reconnected");
                clearInterval(webPing);
              }
              setShowDontRefresh(false);
              setConnection({ isDisconnected: false });
            }).catch(() => {
              // ONLY if the showOfflineModal is null, then and only then
              // should you set the state:
              if (showOfflineModal === null) {
                setShowOfflineModal(true);
              }
              setInterval(() => {
                setShowDontRefresh(true);
              }, 10000);
              setConnection({ isDisconnected: true })
            })
        }, 2000);
      return;
    }
    // ONLY if the showOfflineModal is null, then and only then
    // should you set the state:
    if (showOfflineModal === null) {
      setShowOfflineModal(true);
    }
    setInterval(() => {
      setShowDontRefresh(true);
    }, 10000);
    return setConnection({ isDisconnected: true });
  }

  useEffect(() => {
    // handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    }
  })

  const [modalSync, setModalSync] = useState({
    contentType: "",
    contentId: "",
    songPlaying: "",
    songUrl: "",
  });

  // Gets all data on the session
  const { loading, error, data, refetch } = useSessionQuery({
    variables: {
      id: id,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    console.log(error);
  }

  const {
    loading: followSessionLoading,
    error: followSessionError,
    data: followSessionData,
  } = useSessionModifiedSubscription({
    variables: {
      id: id,
    },
  });

  useEffect(() => {

    // const isSessionMaster = followSessionData
    //   ? followSessionData.sessionModified.status !== "joinable"
    //     ? state.user.role === "ROLE_FACILITATOR"
    //     : followSessionData.sessionModified.sessionMaster === state.user.id
    //   : state.user.role === "ROLE_FACILITATOR";

    let temp = false;
    if (followSessionData) {
      if (followSessionData.sessionModified.status !== "joinable") {
        temp = state.user.role === "ROLE_FACILITATOR";
      } else {
        temp = followSessionData.sessionModified.sessionMaster === state.user.id
      }
    } else {
      temp = state.user.role === "ROLE_FACILITATOR"
    }

    setIsSessionMaster(temp);
  }, [followSessionData])

  const {
    data: sessionMembersSubscriptionData,
  } = useSessionMembersSubscription({ variables: { id: id } });
  // console.log("smsd", sessionMembersSubscriptionData);

  const {
    data: sessionFacilitatorsSubscriptionData,
  } = useSessionFacilitatorsSubscription({
    variables: { id: id },
    skip: state.user.role === "ROLE_MEMBER",
  });
  //console.log("sf", sessionFacilitatorsSubscriptionData);

  const {
    data: waitingRoomSubscriptionData,
  } = useSessionWaitingRoomSubscription({
    variables: { id: id },
    skip: !isSessionMaster,
  });
  // console.log("wrsd", waitingRoomSubscriptionData);
  // Mutation to update the session status and currentScreen
  const [editRunningSession] = useEditRunningSessionMutation();

  const [isSyncOn, setIsSyncOn] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const [pleaseDontBootMe, setPleaseDontBootMe] = useState("");
  const [addUserToSession] = useAddUserToSessionMutation();

  const [fontSize, setFontSize] = useState(1.0);
  const [answersPre, setAnswersPre] = useState(null);
  const [answersPost, setAnswersPost] = useState(null);

  const handleChangeFont = operation => {
    if (operation === "+") {
      if (fontSize < 1.4) {
        setFontSize(fontSize + 0.2);
      }
    } else {
      if (fontSize > 1.0) {
        setFontSize(fontSize - 0.2);
      }
    }
  };
  const [userList, setUserList] = useState([])
  const toggleIsSyncOn = () => {
    if (state.user.role === "ROLE_FACILITATOR") {
      if (!isSyncOn) {
        editRunningSession({
          variables: {
            id: id,
            videoChatStatus: zoomData.viewMode,
          },
        });
      }
      setIsSyncOn(!isSyncOn);
    }
  };
  const [blastMessage, setBlastMessage] = useState(isSessionMaster ? "" : "empty")
  const blastOutMessage = () => {
    editRunningSession({
      variables: {
        id: id,
        globalMessage: state.user.firstName + " - facilitator says: " + blastMessage,
      },
    }).then(() => {
      toast.dark(state.user.firstName + " - facilitator says: " + blastMessage, { autoClose: 7000, })
      setTimeout(() => {

        editRunningSession({
          variables: {
            id: id,
            globalMessage: "empty",
          },
        })
      }, 1000)
      setBlastMessage("")
    })
  }
  // this here is where we can start from for mute
  const [zoomData, setZoomData] = useState({
    showZoomContainer: true,
    viewMode: "horizontal",
    sessionNumber: "sessionToken",
  });

  const toggleZoomVisibility = () => {
    setZoomData({
      ...zoomData,
      showZoomContainer: true,
    });
  };

  const setViewModeHorizontal = () => {
    setZoomData({ ...zoomData, viewMode: "horizontal" });
  };

  const setViewModeFullscreen = () => {
    setZoomData({ ...zoomData, viewMode: "fullscreen" });
  };

  const handleGoToPage = {
    next: () => {
      if (isSessionMaster) {
        setCurrentPage(currentPage + 1);
      }
      if (followSessionData.sessionModified.sessionMaster == null) {
        toast.error("Make sure you start the session when you're ready to accept members")
        // console.log(data)
        // console.log(followSessionData)
      }
      // console.log(data.session)

    },
    prev: () => {
      if (isSessionMaster) {
        setCurrentPage(currentPage - 1);
      }
    },
  };
  // then add the list of chat participants here with muteState
  useEffect(() => {
    if (isSessionMaster)
      if (isSyncOn) {
        editRunningSession({
          variables: {
            id: id,
            videoChatStatus: zoomData.viewMode
          },
        });
      }
  }, [zoomData]);
  // check page here?
  useEffect(() => {
    if (isSessionMaster)
      if (isSyncOn) {
        editRunningSession({
          variables: {
            id: id,
            currentScreen: currentPage,
          },
        });
      }
  }, [currentPage, isSyncOn]);
  // also add the list of chat participants here with muteState
  useEffect(() => {
    if (followSessionData && followSessionData.sessionModified) {
      const { status } = followSessionData.sessionModified;
      setPleaseDontBootMe(status !== "joinable" ? "" : pleaseDontBootMe);
    }
  }, [followSessionData]);
  //if(data)console.log(data)
  useEffect(() => {
    if (isSessionMaster) {
      if (followSessionData) {
        const { currentScreen } = followSessionData.sessionModified;
        if (currentPage === currentScreen && currentPage == 0) {
          //  console.log("here")
          refetch();
        }
      }
    }
  }, [followSessionData]);

  useEffect(() => {
    if (!isSessionMaster) {
      if (followSessionData) {
        // Set current page conditionally based on the incoming current screen
        // const { currentScreen } = followSessionData.session;
        // console.log(followSessionData)
        const { currentScreen } = followSessionData.sessionModified;
        const { status } = followSessionData.sessionModified;
        if (
          status === "joinable" &&
          status !== pleaseDontBootMe &&
          state.user.role === "ROLE_FACILITATOR"
        ) {
          if (sessionFacilitatorsSubscriptionData) {
            if (
              !sessionFacilitatorsSubscriptionData.sessionFacilitators.find(
                u => u.id === state.user.id
              )
            ) {
              addUserToSession({
                variables: {
                  user: state.user.id,
                  session: id,
                  as: "facilitator",
                },
              })
                .then(res => {
                  if (res.errors) {
                    console.log(res.errors);
                    toast.error("Oops, something went wrong");
                  } else {
                  }
                })
                .catch(err => {
                  toast.error("Oops, something went wrong");
                  console.log(err);
                });
            }
          }
          setPleaseDontBootMe(status);
        }
        if (currentPage !== currentScreen) setCurrentPage(currentScreen);
        if (currentPage === currentScreen && currentPage == 0) {
          //console.log("here")
          refetch();
        }

        // Set zoom data based on the incoming video chat status
        // const {videoChatStatus} = followSessionData.session;
        const { videoChatStatus } = followSessionData.sessionModified;
        const newZoomData = { ...zoomData };

        newZoomData.showZoomContainer = true;
        newZoomData.viewMode = videoChatStatus;

        if (
          (zoomData.viewMode !== newZoomData.viewMode ||
            zoomData.showZoomContainer !== true)
        ) {
          setZoomData({
            ...zoomData,
            showZoomContainer: true,
            viewMode: newZoomData.viewMode == " " ? "horizontal" : newZoomData.viewMode,
          });
        }
        if (
          (
            (
              ((currentScreen == 6 || currentScreen == 14) && program == "music") ||
              (currentScreen == 16 && program == "reflections")
            )
            && state.user.role == "ROLE_MEMBER")
        ) {
          setZoomData({
            ...zoomData,
            showZoomContainer: true,
            viewMode: "fullscreen",
          });
        }

        const {
          globalMessage
        } = followSessionData.sessionModified;
        if (globalMessage != blastMessage && (globalMessage !== "empty" && globalMessage != "")) {
          setBlastMessage(globalMessage)
          toast.dark(globalMessage, { autoClose: 5000, })
        } else if (globalMessage != blastMessage && globalMessage == "empty") {
          setBlastMessage("")

        }

        const {
          songPlaying,
          contentId,
          contentType,
          songUrl,
        } = followSessionData.sessionModified;
        // console.log(`data: ${(songPlaying, contentId, contentType)}`);
        if (contentType == "silence") {
          setIsSilent(!isSilent);
        } else if (
          contentType != "silence" &&
          (modalSync.songPlaying !== songPlaying ||
            modalSync.contentId !== contentId ||
            modalSync.contentType !== contentType)
        ) {
          let regex = new RegExp("song*");
          let regexMo = new RegExp("modal*");

          // console.log(contentId);
          if (
            songPlaying &&
            (contentType.length > 4 && regex.test(contentType) && !regexMo.test(contentType))
          ) {
            // console.log(regexMo.test(contentType))
            // console.log(contentType);
            // console.log("get silenced ");
            if (currentPage != 1) setIsSilent(true);

          } else {
            //console.log(contentType);
            setIsSilent(false);
          }
          //console.log("me?");
          setModalSync({
            songPlaying,
            contentId,
            contentType,
            songUrl,
          });
        } else {
          console.log("NOT CHANGE");
        }
      }
    }
  }, [followSessionData]);
  const [themesList, setThemesList] = useState(null);
  const [program, setProgram] = useState(null);
  useEffect(() => {
    //console.log(location.state)
    if (location.state && !themesList) {
      setThemesList(location.state.themes);
    }
  }, [location, themesList]);
  useEffect(() => {
    //console.log(location.state)
    if (data && data.session) {
      setProgram(data.session.program);
    }
  }, [data]);

  useEffect(() => {
    if (isSessionMaster) {
      if (waitingRoomSubscriptionData) {
        const users = waitingRoomSubscriptionData.sessionWaitingRoom;
        let shouldToast = false;
        if (users.length == 0) console.log("zero");
        let flag = false;
        if (users.length !== waitingRoom.length) flag = true;
        else
          for (let i = 0; i < users.length; i++) {
            if (waitingRoom[i].id !== users[i]) {
              flag = true;
              shouldToast = true;
              break;
            }
          }

        if (flag) {
          setWaitingRoom(users);
          if (users.length > waitingRoom.length) {
            toast.success("Someone is in the waiting room");
            if (audioRef != null && audioRef.current != null)
              audioRef.current.play();
          }
        }
      }
    }
  }, [waitingRoomSubscriptionData]);
  const [waitForRes, setWaitForRes] = useState(false)
  useEffect(() => {
    if (!isSessionMaster && data) {
      if (state.user.role === "ROLE_FACILITATOR") {
        // Needs to implement watch facilitators subscription
        if (sessionFacilitatorsSubscriptionData) {
          // console.log(data)
          if (
            !sessionFacilitatorsSubscriptionData.sessionFacilitators.find(
              u => u.id === state.user.id
            )
          ) {
            if (data.session.group.facilitators.find(
              u => u.id === state.user.id
            ) && !waitForRes) {
              setWaitForRes(true)
              addUserToSession({
                variables: {
                  user: state.user.id,
                  session: id,
                  as: "facilitator",
                },
              })
                .then(res => {
                  if (res.errors) {
                    console.log(res.errors);
                    toast.error("Oops, something went wrong");
                  } else {
                    setWaitForRes(false)
                  }
                })
                .catch(err => {
                  toast.error("Oops, something went wrong");
                  console.log(err);
                });
            } else if (!waitForRes) {

              toast.error("You were removed from the session");
              history.push("/home");
            }
          }
        }
      } else {
        if (sessionMembersSubscriptionData) {
          if (
            !sessionMembersSubscriptionData.sessionMembers.find(
              u => u.id === state.user.id
            )
          ) {
            toast.error("You were removed from the session");
            history.push("/home");
          }
        }
      }
    }
  }, [sessionMembersSubscriptionData, sessionFacilitatorsSubscriptionData, data]);
  const [bigMans, setBigMans] = useState("wait");
  const [sessionFix, setSessionFix] = useState({ facilitators: [], id: "", theme: {} });

  const handleSetQuestionnairId = (stage, id) => {
  }
  useEffect(() => {
    if (
      data &&
      data.session &&
      data.session !== null &&
      data.session !== undefined
    ) {
      setSessionFix({ ...data.session })
      setBigMans(data.session.sessionMembers);
    }
  }, [data]);
  // This is a workaround for updating the list of users in other facilitators list, since not all of the information is available
  const [
    executeSessionSessionMembersQuery,
    { data: sessionSessionMembersData },
  ] = useSessionSessionMembersLazyQuery();
  useEffect(() => {
    executeSessionSessionMembersQuery({
      variables: {
        id: id,
      },
    });
  }, [sessionMembersSubscriptionData]);

  //  FOR FEEDBACK
  // temporarily hold the previous modal content. used for feedback
  const initialModalContent = {
    page: currentPage,
    contentType: "",
    contentId: "",
    songPlaying: "",
  };
  const [previousModalContent, setPreviousModalContent] = useState(
    initialModalContent
  );

  useEffect(() => {
    setPreviousModalContent(initialModalContent);
  }, [currentPage]);

  const pageNames = ["Preparation", "Opening", "Theme", "Closing", "Review"];

  const handleNavClick = page => {
    if (data && program && program == "music") {
      if (page === 0) {
        setCurrentPage(0);
      } else if (page === 1) {
        setCurrentPage(1);
      } else if (page === 2) {
        setCurrentPage(5);
      } else if (page === 3) {
        setCurrentPage(10);
      } else {
        setCurrentPage(14);
      }
    } else {
      if (page === 0) {
        setCurrentPage(0);
      } else if (page === 1) {
        setCurrentPage(1);
      } else if (page === 2) {
        setCurrentPage(7);
      } else if (page === 3) {
        setCurrentPage(11);
      } else {
        setCurrentPage(16);
      }
    }

  };

  const currentPageActive = () => {
    if (data && program && program == "music") {
      if (currentPage === 0) {
        return 0;
      } else if (currentPage >= 1 && currentPage <= 4) {
        return 1;
      } else if (currentPage >= 5 && currentPage <= 9) {
        return 2;
      } else if (currentPage >= 10 && currentPage <= 13) {
        return 3;
      } else {
        return 4;
      }
    } else {
      if (currentPage === 0) {
        return 0;
      } else if (currentPage >= 1 && currentPage <= 6) {
        return 1;
      } else if (currentPage >= 7 && currentPage <= 10) {
        return 2;
      } else if (currentPage >= 11 && currentPage <= 15) {
        return 3;
      } else {
        return 4;
      }

    }
  };
  const sessionTriggered = () => {
    if (isSyncOn) {
      editRunningSession({
        variables: {
          id: id,
          currentScreen: currentPage,
        },
      });
    }
  };
  // Syncing music for preparation/opening/closing
  const handleMusicPlay = (isMusicPlaying, contentType) => {
    if (isSessionMaster) {
      if (isSessionMaster && isSyncOn) {
        if (isMusicPlaying || contentType == "close modal") {
          // call mutation to sync which modal is open
          editRunningSession({
            variables: {
              id: data.session.id,
              contentType: contentType,
            },
          })
            .then(res => {
              let regex = new RegExp("song*");
              let regexW = new RegExp("Wind*");


              if (contentType !== "silence" && (contentType.length > 4 && (regex.test(contentType) || regexW.test(contentType)))) {

                setIsSilent(isMusicPlaying);
              }
              //console.log(isMusicPlaying, contentType, res);
            })
            .catch(err => console.log(err));
        } else {
          // call mutation to sync which modal is open
          editRunningSession({
            variables: {
              id: data.session.id,
              contentType: "",
            },
          })
            .then(res => {
              if (contentType !== "silence") {
                setIsSilent(isMusicPlaying);
              }
              // console.log(isMusicPlaying, contentType, res);
            })
            .catch(err => console.log(err));
        }
      }
    }
  };
  const [addQuestionnaire] = useAddQuestionnaireMutation();
  const [editQuestionnaire] = useEditQuestionnaireMutation();
  const [addAnswer] = useAddAnswerMutation();
  const { data: findQuestionnairePre } = useQuestionnaireUserSessionQuery({
    variables: {
      member: state.user.id,
      session: id,
      stage: "pre"
    },
  });
  const { data: findQuestionnairePost } = useQuestionnaireUserSessionQuery({
    variables: {
      member: state.user.id,
      session: id,
      stage: "post"
    },
  });
  const { data: chkPre } = useCheckQuestionnaireExistsQuery({
    variables: {
      member: state.user.id,
      session: id,
      stage: "pre"
    },
    skip: state.user.role === "ROLE_FACILITATOR"

  });
  const { data: chkPost } = useCheckQuestionnaireExistsQuery({
    variables: {
      member: state.user.id,
      session: id,
      stage: "post"
    },
    skip: state.user.role === "ROLE_FACILITATOR"

  });
  useEffect(() => {

    if (state.user.role !== "ROLE_FACILITATOR" && chkPre) {
      if (chkPre.checkQuestionnaireExists === false && answersPre === null) {
        //setpre(questionnaireUserSession)
        setAnswersPre("wait")
        // console.log(chkPre)
        // console.log("checkQuestionnaireExistsPre")
        addQuestionnaire({
          variables: {
            member: state.user.id,
            session: id,
            stage: "pre",
            isDone: false,
            optOut: false

          },
        }).then(res => {
          setAnswersPre({ ...res.data.addQuestionnaire })
        }).catch(err => {
          console.log(err)
        })
      }
      else {
        //make questionnaire; setpre(res.data.q.id)
        if (findQuestionnairePre) setAnswersPre({ ...findQuestionnairePre.questionnaireUserSession })
        // console.log("NO checkQuestionnaireExistsPre")
        // console.log(answersPre)
        // console.log(chkPre)





      }
    }
  }, [chkPre, findQuestionnairePre])
  useEffect(() => {

    if (state.user.role !== "ROLE_FACILITATOR" && chkPost) {
      if (chkPost.checkQuestionnaireExists === false && answersPost === null) {
        setAnswersPost("wait")
        addQuestionnaire({
          variables: {
            member: state.user.id,
            session: id,
            stage: "post",
            isDone: false,
            optOut: false

          },
        }).then(res => {
          setAnswersPost({ ...res.data.addQuestionnaire })
        }).catch(err => {
          console.log(err)
        })
      }
      else {
        //make questionnaire; setpre(res.data.q.id)
        if (findQuestionnairePost) setAnswersPost({ ...findQuestionnairePost.questionnaireUserSession })






      }
    }
  }, [chkPost, findQuestionnairePost])
  // const handleEditMuteState= (user)=>{
  //   editSessionMemberMuteStateMutation({
  //     variables: {
  //       session: data.session.id,
  //       member: user.id,
  //       muteState: !user.muteState,
  //       role: user.role,

  //     },
  //   }).catch(err=>{console.log(err)})
  // }


  let simpleMode = state.user.role == "ROLE_MEMBER"
  const handleContextAnswersPre = (ans, optOut) => {
    if (!optOut) {
      ans.map(an => {
        addAnswer({
          variables: {
            member: state.user.id,
            session: id,
            stage: "pre",
            number: an.num,
            body: an.body,
            value: an.answer,
          },
        }).catch(err => {
          console.log(err)
        })
      })
      editQuestionnaire({
        variables: {
          member: state.user.id,
          session: id,
          stage: "pre",
          isDone: true,
          optOut: false


        },
      }).catch(err => {
        console.log(err)
      })

    }
    else {
      editQuestionnaire({
        variables: {
          member: state.user.id,
          session: id,
          stage: "pre",
          isDone: true,
          optOut: true

        },
      }).then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    }
    setAnswersPre({
      ...answersPre,
      isDone: true,
      optOut,
      answers: ans,
    });
  };
  const handleContextAnswersPost = (ans, optOut) => {
    if (!optOut) {
      ans.map(an => {
        addAnswer({
          variables: {
            member: state.user.id,
            session: id,
            stage: "post",
            number: an.num,
            body: an.body,
            value: an.answer,
          },
        }).catch(err => {
          console.log(err)
        })
      })
      editQuestionnaire({
        variables: {
          member: state.user.id,
          session: id,
          stage: "post",
          isDone: true,
          optOut: false


        },
      }).catch(err => {
        console.log(err)
      })

    }
    else {
      editQuestionnaire({
        variables: {
          member: state.user.id,
          session: id,
          stage: "post",
          isDone: true,
          optOut: true

        },
      }).catch(err => {
        console.log(err)
      })
    }

    setAnswersPost({
      ...answersPost,
      isDone: true,
      optOut,
      answers: ans,
    });
  };
  const [zoomFlag, setZoomFlag] = useState(false)
  useEffect(() => {
    if (isSessionMaster !== null && data && data.session && (state.user.role == "ROLE_MEMBER" || sessionMembersSubscriptionData)) {
      setZoomFlag(true)
      //  console.log(zoomFlag)
    }
    // console.log(zoomFlag)
    // console.log(zoomData)
  }, [isSessionMaster, data, sessionMembersSubscriptionData])

  // Set the sessionMasterId
  useEffect(() => {
    if (data && data.session && data.session.sessionMaster) {
      setSessionMasterId(data.session.sessionMaster.id || "");
    }
  }, [data])

  if (loading) {
    return <LoadingSpinner />;
  }
  // if(!loading && data && (data.session === null|| data.session === undefined)){

  //   return (<div>{history.push("/home")}{toast.error("Uh-oh, looks like that address was wrong")
  //   }</div>)
  // }
  return (
    <main >
      {/* Navbar */}
      <nav
        style={{ top: "56px" }}
        className="w-100 d-flex flex-row running-session-navbar not-draggable bg-white fixed-top"
      >
        {pageNames.map((page, i) => (
          <div
            role="button"
            className={
              "w-100 text-center border py-1" +
              (currentPageActive() === i ? " active" : "")
            }
            key={i}
            onClick={isSessionMaster ? () => handleNavClick(i) : undefined}
          >
            {page}
          </div>
        ))}
      </nav>
      <audio
        hidden
        ref={audioRef}
        volume={0.6}
        autoPlay={false}
        src={"https://d30mg0zmvh01j5.cloudfront.net/assets/songs/DING.wav"}
      ></audio>
      {/* This empty div works as a spacer to ensure the navbar and the content do not overlap */}
      <div style={{ height: "34px" }} />

      {/* Font size adjustment buttons located at the top left part of the screen */}
      <FontSizeButtons
        fontSize={fontSize}
        handleChangeFont={handleChangeFont}
      />

      {/* Zoom container that will be rendered conditionally  */}
      <div
        style={{ fontSize: `${fontSize}rem` }}
        className={`${zoomData.showZoomContainer &&
          (zoomData.viewMode === "horizontal" ||
            zoomData.viewMode === "fullscreen")
          ? "d-flex flex-row-reverse"
          : "d-block"
          }`}
      >
        {/*Zoom Container*/}
        {
          (zoomData.viewMode === "horizontal" ||
            zoomData.viewMode === "fullscreen") &&
          (
            <>
              {zoomData.showZoomContainer &&

                zoomData.viewMode === "horizontal" && <div className="w-50" />}
              <div
                style={{
                  overflow: "hidden",
                  height: "100vh",
                  right: 0,
                  top: 0,
                }}
                className={`position-fixed zoom-container  ${zoomData.viewMode === "horizontal" ? "w-50" : "w-100"
                  }`}
              >
                {zoomFlag === true && (
                  <ErrorBoundary>
                    <ZoomContainer
                      members={
                        isSessionMaster
                          ? sessionMembersSubscriptionData.sessionMembers
                          : null
                      }
                      bigMansMembers={!bigMans || bigMans == "wait" ? [] : bigMans}
                      facilitators={sessionFix.facilitators}
                      master={isSessionMaster}
                      masterId={followSessionData && followSessionData.sessionModified.sessionMaster}
                      zoomData={zoomData}
                      handleMusicPlay={handleMusicPlay}
                      setIsSilent={setIsSilent}
                      session={data.session}
                      sessionId={sessionFix.id}
                      currentPage={currentPage}
                      isSilent={isSilent}
                      isSyncOn={isSyncOn}
                      modalSync={modalSync}
                      setUserList={setUserList}
                      blastOutMessage={blastOutMessage}
                      setBlastMessage={setBlastMessage}
                      blastMessage={blastMessage}
                      previousModalContent={previousModalContent}
                      VideoChatCluster={VideoChatCluster}
                    />
                  </ErrorBoundary>
                )}
              </div>
            </>
          )}
        {/*                          Java Music Session                                */}
        {data && program !== null && program == "music" && <section
          style={{ fontSize: `${fontSize}rem` }}
          className={`container bg-zero ${zoomData.showZoomContainer && zoomData.viewMode === "horizontal"
            ? "zoom-horizontal w-50"
            : ""
            }
          ${zoomData.showZoomContainer && zoomData.viewMode === "fullscreen"
              ? "d-none"
              : ""
            }
          ${!zoomData.showZoomContainer && "standard"}`}
        >
          {/* <button onClick={() => window.location.reload(false)}>refresh</button> */}
          {currentPage === 0 &&
            (sessionFacilitatorsSubscriptionData ||
              state.user.role !== "ROLE_FACILITATOR") && (
              <ErrorBoundary>
                <PreparationContainer
                  handleMusicPlay={handleMusicPlay}
                  zoomData={zoomData}
                  handleGoToPage={handleGoToPage}
                  session={data.session}
                  themesList={themesList}
                  sessionFacilitators={
                    state.user.role === "ROLE_FACILITATOR"
                      ? sessionFacilitatorsSubscriptionData.sessionFacilitators
                      : []
                  }
                  sessionTriggered={sessionTriggered}
                  modalSync={modalSync}
                  isSessionMaster={isSessionMaster}
                  isSyncOn={isSyncOn}
                  setIsSilent={setIsSilent}
                  status={
                    followSessionData && followSessionData.sessionModified.status
                  }
                  handleContextAnswers={handleContextAnswersPre}
                  answers={answersPre}
                  sessionMasterId={sessionMasterId}
                />
              </ErrorBoundary>
            )}

          {currentPage >= 1 && currentPage <= 4 && (
            <ErrorBoundary>
              <MultiSectionContainer
                zoomData={zoomData}
                isOpening={true}
                handleGoToPage={handleGoToPage}
                currentPage={currentPage}
                modalSync={modalSync}
                setIsSilent={setIsSilent}
                handleMusicPlay={handleMusicPlay}
                isSessionMaster={isSessionMaster}
                isSyncOn={isSyncOn}
                handleMusicPlay={handleMusicPlay}
              />
            </ErrorBoundary>
          )}

          {currentPage >= 5 && currentPage <= 9 && (
            <ErrorBoundary>
              <RunningSessionThemeContainer
                isSyncOn={isSyncOn}
                isSessionMaster={isSessionMaster}
                modalSync={modalSync}
                zoomData={zoomData}
                setIsSilent={setIsSilent}
                data={sessionFix.theme}
                sessionId={sessionFix.id}
                playedSongs={playedSongs}
                setPlayedSongs={setPlayedSongs}
                handleGoToSessionPage={handleGoToPage}
                currentPage={currentPage}
                previousModalContent={previousModalContent}
                setPreviousModalContent={setPreviousModalContent}
              />
            </ErrorBoundary>
          )}

          {currentPage >= 10 && currentPage <= 13 && (
            <ErrorBoundary>
              <MultiSectionContainer
                zoomData={zoomData}
                isOpening={false}
                handleGoToPage={handleGoToPage}
                currentPage={currentPage}
                modalSync={modalSync}
                handleMusicPlay={handleMusicPlay}
                setIsSilent={setIsSilent}
                isSessionMaster={isSessionMaster}
                isSyncOn={isSyncOn}
                handleMusicPlay={handleMusicPlay}
              />
            </ErrorBoundary>
          )}

          {currentPage >= 14 && (
            <ErrorBoundary>
              <ReviewContainer
                isSessionMaster={isSessionMaster}
                session={data.session}
                zoomData={zoomData}
                handleGoToPage={handleGoToPage}
                handleContextAnswers={handleContextAnswersPost}
                answers={answersPost}
                sessionMaster={
                  followSessionData
                    ? followSessionData.sessionModified.sessionMaster
                    : ""
                }
                status={
                  followSessionData && followSessionData.sessionModified.status
                }
              />
            </ErrorBoundary>
          )}
        </section>}
        {/*     
       
       
       END - Java Music Session      
       
       
       */}



        {/*         
         
         Java Social Session             
         
         
         */}
        {data && program !== null && program == "reflections" && <section
          style={{ fontSize: `${fontSize}rem` }}
          className={`container bg-zero ${zoomData.showZoomContainer && zoomData.viewMode === "horizontal"
            ? "zoom-horizontal w-50"
            : ""
            }
          ${zoomData.showZoomContainer && zoomData.viewMode === "fullscreen"
              ? "d-none"
              : ""
            }
          ${!zoomData.showZoomContainer && "standard"}`}
        >
          {/* <button onClick={() => window.location.reload(false)}>refresh</button> */}
          {currentPage === 0 &&
            (sessionFacilitatorsSubscriptionData ||
              state.user.role !== "ROLE_FACILITATOR") && (
              <ErrorBoundary>
                <PreparationContainer
                  handleMusicPlay={handleMusicPlay}
                  zoomData={zoomData}
                  handleGoToPage={handleGoToPage}
                  session={data.session}
                  isReflections={true}
                  themesList={themesList}
                  sessionFacilitators={
                    state.user.role === "ROLE_FACILITATOR"
                      ? sessionFacilitatorsSubscriptionData.sessionFacilitators
                      : []
                  }
                  sessionTriggered={sessionTriggered}
                  modalSync={modalSync}
                  isSessionMaster={isSessionMaster}
                  isSyncOn={isSyncOn}
                  setIsSilent={setIsSilent}
                  status={
                    followSessionData && followSessionData.sessionModified.status
                  }
                  handleContextAnswers={handleContextAnswersPre}
                  answers={answersPre}
                  sessionMasterId={sessionMasterId}
                />
              </ErrorBoundary>
            )}

          {currentPage >= 1 && currentPage <= 6 && (
            <ErrorBoundary>
              <MultiSectionContainer
                zoomData={zoomData}
                isOpening={true}
                isReflections={true}
                handleGoToPage={handleGoToPage}
                currentPage={currentPage}
                modalSync={modalSync}
                setIsSilent={setIsSilent}
                handleMusicPlay={handleMusicPlay}
                isSessionMaster={isSessionMaster}
                isSyncOn={isSyncOn}
                userList={userList}
                master={followSessionData.sessionModified.sessionMaster}
                handleMusicPlay={handleMusicPlay}
              />
            </ErrorBoundary>
          )}

          {currentPage >= 7 && currentPage <= 10 && (
            <ErrorBoundary>
              <RunningSessionThemeContainer
                isSyncOn={isSyncOn}
                isSessionMaster={isSessionMaster}
                modalSync={modalSync}
                zoomData={zoomData}
                setIsSilent={setIsSilent}
                data={sessionFix.theme}
                sessionId={sessionFix.id}
                playedSongs={playedSongs}
                setPlayedSongs={setPlayedSongs}
                handleGoToSessionPage={handleGoToPage}
                currentPage={currentPage}
                userList={userList}
                previousModalContent={previousModalContent}
                setPreviousModalContent={setPreviousModalContent}
              />
            </ErrorBoundary>
          )}

          {currentPage >= 11 && currentPage <= 15 && (
            <ErrorBoundary>
              <MultiSectionContainer
                zoomData={zoomData}
                isOpening={false}
                isReflections={true}
                handleGoToPage={handleGoToPage}
                currentPage={currentPage}
                modalSync={modalSync}
                handleMusicPlay={handleMusicPlay}
                setIsSilent={setIsSilent}
                isSessionMaster={isSessionMaster}
                isSyncOn={isSyncOn}
                handleMusicPlay={handleMusicPlay}
              />
            </ErrorBoundary>
          )}

          {currentPage >= 16 && (

            <ErrorBoundary>
              <ReviewContainer
                isSessionMaster={isSessionMaster}
                session={data.session}
                zoomData={zoomData}
                handleGoToPage={handleGoToPage}
                handleContextAnswers={handleContextAnswersPost}
                answers={answersPost}
                sessionMaster={
                  followSessionData
                    ? followSessionData.sessionModified.sessionMaster
                    : ""
                }
                status={
                  followSessionData && followSessionData.sessionModified.status
                }
              />
            </ErrorBoundary>
          )}
        </section>}

      </div>

      {/* Bottom left menu containing the icons to control the zoom ui */}
      {state.user.role == "ROLE_FACILITATOR" && (
        <ZoomFloatingMenu
          isSessionMaster={followSessionData
            ? followSessionData.sessionModified.status == "joinable" : false}
          forSessMaster={isSessionMaster}
          zoomData={zoomData}
          isSyncOn={isSyncOn}
          toggleIsSyncOn={toggleIsSyncOn}
          toggleZoomVisibility={toggleZoomVisibility}
          setViewModeHorizontal={setViewModeHorizontal}
          setViewModeFullscreen={setViewModeFullscreen}
          setWaitingRoomModalOpen={() => setIsWaitingRoomModalOpen(true)}
          setHandoffModalOpen={() => setIsHandoffModalOpen(true)}
          sessionId={id}
          numberOfMembersInWaitingRoom={
            waitingRoomSubscriptionData
              ? waitingRoomSubscriptionData.sessionWaitingRoom.length
              : 0
          }
        />
      )}

      {/* Waiting Room modal */}
      <GenericModal
        modalIsOpen={isWaitingRoomModalOpen}
        setModalIsOpen={setIsWaitingRoomModalOpen}
      >
        <WaitingRoomMenu
          sessionId={id}
          waitingRoom={
            waitingRoomSubscriptionData &&
            waitingRoomSubscriptionData.sessionWaitingRoom
          }
        />
      </GenericModal>
      {/* Handoff modal */}
      <GenericModal
        modalIsOpen={isHandoffModalOpen}
        setModalIsOpen={setIsHandoffModalOpen}
      >
        <Handoff
          sessionId={id}
          close={() => setIsHandoffModalOpen(false)}
          you={state.user.id}
          facilitators={
            sessionFacilitatorsSubscriptionData &&
            sessionFacilitatorsSubscriptionData.sessionFacilitators
          }
        />
      </GenericModal>
      {/* Offline status */}

      <GenericModal
        modalIsOpen={(showOfflineModal === true || showOfflineModal === "reconnected")}
        setModalIsOpen={() => {
          setShowOfflineModal(null);
        }}
        keepAlive={true}
      >
        <div className={`container p-3`}>
          <div className={`row`}>
            <div className={`col col-12 text-center`}>
              <p className={`display-4`}>
                {showOfflineModal === true ? "Reconnecting..." : "Network Connected"}
              </p>

              {showOfflineModal === true &&
                <>
                  <p className={`lead`}>It seems like your network was disconnected, we are waiting for the network to reconnect...</p>
                  {showDontRefresh && 
                    <button className={`btn btn-danger`} onClick={() => {
                      setShowOfflineModal(null);
                    }}>
                      Don't Refresh
                    </button>
                  }
                  
                </>
              }
              {showOfflineModal === "reconnected" &&
                <>
                  <p className={`lead`}>You've been reconnected! You should refresh the page just so there are no errors:

                  </p>
                  <button className={`btn btn-info btn-lg w-50`} onClick={() => {
                    window.location.reload(false);
                  }}>
                    Refresh Page
                  </button>
                  <br /><br />
                  <button className={`btn btn-danger`} onClick={() => {
                      setShowOfflineModal(null);
                    }}>
                      Don't Refresh
                    </button>
                </>
              }
            </div>
          </div>
        </div>
      </GenericModal>
    </main>
  );
}
