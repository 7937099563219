import React,{useContext} from "react";
import { UserContext } from "../../../../../context/UserContext";
import { GiSaveArrow } from "react-icons/gi";

const SensoryCues = props => {
  const { themePhoto, isRunningSession, onThemeCardClick } = props;
  const { state } = useContext(UserContext); 
  const getURL = (url)=>{
    return url.slice(0,14)+"/400/"+url.slice(15) 
  }
  return (
    <>
      <div className="d-flex mb-5">
        <h3>{state.user.role == "ROLE_FACILITATOR"&&"9."} Theme photo</h3>

        {!isRunningSession ? (
          <button
            className="ml-2 bg-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 3)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
        )}
      </div>

      <div className="cues-container d-flex justify-content-center">
        <div className="position-relative">
          <img
            // TODO replace with data from db
            src={"https://d30mg0zmvh01j5.cloudfront.net" + getURL(themePhoto)}
            alt="themePhoto"
            width="400"
            height="400"
            style={{ filter: "brightness(75%)" }}
            className="round-box m-2 clickable-card not-draggable gray-filled"
            onClick={() => onThemeCardClick("themePhoto")}
          />
          <h5 className="sensory-img">Theme photo</h5>
        </div>
      </div>
    </>
  );
};

export default SensoryCues;
