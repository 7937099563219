import React, { useEffect, useState } from "react";
import {
  MeDocument,
  useEditUserMutation,
  useChangePasswordMutation,
  useMeQuery
} from "../../graphql/generated/graphql";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

export default function MemberProfile() {
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    nickname: "",
    ageGroup: "",
    phone: "",
    email: "",
    country: "",
    postalCode: "",
    living: "",
    parentUser: null,
    currentPassword: "",
    newPassword: "",
    repeat: ""
  });
const [isChangePassword,setIsChangePassword] = useState(false)
  const history = useHistory();

  const { data, error, loading } = useMeQuery();
  const [style, setStyle] = useState(
    {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      address: "",
      dateOfBirth: "",
      curr:"",
      new:"",
      re:""
    }
  )
  const [updateProfile] = useEditUserMutation();
  const [changePassword] = useChangePasswordMutation();

  const loadData = () => {

    setProfile({
      ...profile,
      ...data.me,
      currentPassword: profile.currentPassword,
    newPassword: profile.newPassword,
    repeat: profile.repeat
    });
  };

  const errorStyle ="box-shadow border border-danger"
  const handleOnChange = e => {
    console.log(e.target.name)
    console.log(typeof e.target.value)
    setProfile({ ...profile, [e.target.name]: e.target.value });
    console.log(profile)
    setStyle({ ...style, [e.target.name]: profile[e.target.name]!==""&&e.target.value===""?"box-shadow border border-danger":"" });
  };
  const handleAutoNick = () => {
    setProfile({
    ...profile,
    nickname:
     `${profile.firstName.charAt(0).toUpperCase()}${profile.firstName.slice(1)} ${profile.lastName.charAt(0).toUpperCase()}.`
  });
};

  const handleSubmit = e => {
    e.preventDefault();

    const { currentPassword, newPassword, repeat } = profile;
    if(isChangePassword && ( !currentPassword || !newPassword  || !repeat)){
      setStyle(
        {
          curr:currentPassword===""?errorStyle:"",
          new:newPassword===""?errorStyle:"",
          re:repeat===""?errorStyle:"",
        }
      )
      toast.error(`Oops, it seems you missed something.`);
      return;
    }
    if (isChangePassword) {

      if (!currentPassword) {
        toast.error("Oops, Check your current password");
        return;
      } else if(currentPassword === newPassword) {
        toast.error("Oops, Check your current password and new password cannot be same");
        setStyle({curr:style.curr,new:errorStyle,re:style.errorStyle})
        return;
      } else if (newPassword === repeat && newPassword) {
        const pswPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
        if (pswPattern.test(newPassword)) {
          changePassword({
            variables: {
              currentPassword: currentPassword,
              newPassword: newPassword
            }
          }).then(res => {
            if(res.errors){
               toast.error("Oops, something went wrong... Password was not changed")
               return;
            }
               

            setProfile({
             ...profile,
              dateOfBirth: moment(data.me.dateOfBirth).format("YYYY-MM-DD"),
              currentPassword: "",
              newPassword: "",
              repeat: "",
            })

          }).catch(err => {
            toast.error("Oops, something went wrong... Password was not changed");
            return;
          })
        } else {
          toast.error("Oops, the given new password cannot be accepted");
          setStyle({curr:style.curr,new:errorStyle,re:style.errorStyle,newLable:["New password should be at least 6 charecters long and must contain:","one or more uppercase letter","one or more lowercase letter ","one or more number."]})
          return;
        }
      } else {
        setStyle({curr:style.curr,new:errorStyle,re:errorStyle})
        toast.error("Oops, Check the password. The new passwords do not match or received them empty here");
        return;
      }
    }
if(profile.firstName=== ""||
profile.lastName=== ""||

profile.email=== ""
){
  setStyle({firstName:profile.firstName=== ""? errorStyle:"",
  lastName: profile.lastName=== ""?errorStyle:"",

  email: profile.email=== ""?errorStyle:"",
 
  new:"",
  re:""})
  toast.error(`Oops, it seems you missed something.`);
  return;
}
console.log(profile)
    updateProfile({
      variables: {
        id: data.me.id,
        firstName: profile.firstName,
        lastName: profile.lastName,
        nickname: profile.nickname,
        phone: profile.phone,
        email: profile.email,
        postalcode: profile.postalcode,
        living: profile.living,
        ageGroup: profile.ageGroup,
        country: profile.country,
        
      },
      refetchQueries: [{ query: MeDocument }],
    })
      .then(res => {
        if(res.errors)
          { toast.error("Oops, something went wrong...")
          }else{

            isChangePassword?toast.success("Successfully password updated!"):toast.success("Successfully updated profile!");
            history.push("/profile")
          }
      })
      .catch(err => {
        toast.error("Oops, something went wrong...");
      });
  };

  useEffect(() => {
    if (!loading) {
      loadData();
    }
  }, [data, loading]);

  if (error) {
    return (
      <div>
        <h1 className="text-center">Oops, something went wrong...</h1>
      </div>
    );
  }

  return (
    <div className="container-fluid container py-5 my-5 memberProfileCard round-box box-shadow border">
      <form onSubmit={handleSubmit}>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-6 col-md-12">
            <div className="w-100 d-flex justify-content-center my-3">
              <img
                src="https://i.pinimg.com/originals/a6/58/32/a65832155622ac173337874f02b218fb.png"
                alt="image1"
                className="img-responsive rounded-circle"
                style={{ minWidth: "200px", maxWidth: "200px" }}
              />
            </div>
            <div className="form-group w-100 text-center">
              {/* TODO This has to be implemented in the backend as well */}
              {/* <label className="btn btn-primary disabled" htmlFor="changePhoto">
                Change a photo
                <input
                  id="changePhoto"
                  type="file"
                  className="d-none"
                  disabled
                />
              </label> */}
              {data && data.me.parentUser === null && <button
            onClick={(e) => {
              e.preventDefault();
              //console.log(data)
              if(isChangePassword){ setProfile({
                ...profile,
                dateOfBirth: moment(data.me.dateOfBirth).format("YYYY-MM-DD"),
                currentPassword: "",
                newPassword: "",
                repeat: "",
               } )
              
               setStyle({firstName:profile.firstName=== ""? errorStyle:"",
               lastName: profile.lastName=== ""?errorStyle:"",
            
               email: profile.email=== ""?errorStyle:"",
             
               
               new:"",
               re:""})
              }
              //console.log(profile)
              setIsChangePassword(!isChangePassword)}}
            className="btn btn-dark mr-2 px-5 btn-extra-radius"
          >
           {isChangePassword?"Change info":"Change Password"}
          </button>
            }
            </div>
          </div>
          {data && !isChangePassword &&
         
            <div className="col-lg-6 col-md-12">

            <div className="form-group">
              <label>First name</label>
              <input
                name="firstName"
                type="text"
                className={`form-control ${style.firstName}`}
                value={profile.firstName}
                placeholder="John"
                onChange={handleOnChange}
                />
            </div>
            <div className="form-group">
              <label>Last name: </label>
              <input
                name="lastName"
                type="text"
                className={`form-control ${style.lastName}`}
                value={profile.lastName}
                placeholder="Doe"
                onChange={handleOnChange}
                />
            </div>
            <div className="form-group">
              <label>Nickname: </label>
              <div className={`d-flex `}>
                <input
                name="nickname"
                type="text"
                className={`form-control col-10 `}
                value={profile.nickname}
                placeholder="Doe"
                onChange={handleOnChange}
                /><button className={` btn col-2 btn-info`} onClick={(e)=>{e.preventDefault() 
                  handleAutoNick()}}>Auto</button>
                </div>
            </div>
            
            <div className="form-group">
              <label>Phone Number: </label>
              <input
                name="phone"
                type="tel"
                className={`form-control ${style.phone}`}
                value={profile.phone}
                placeholder="123-456-7890"
                onChange={handleOnChange}
                />
            </div>
            {profile.parentUser === null && <div className="form-group">
              <label>Email Address: </label>
              <input
                name="email"
                type="email"
                className={`form-control ${style.email}`}
                value={profile.email}
                placeholder="john.doe@domain.com"
                onChange={handleOnChange}
                />
            </div>}
            <div className="form-group">
              <label>Country: </label>
              <select
              onChange={handleOnChange}
              name={"country"}
              value={profile.country}
              className={`form-control`}
            >
              <option value="">Country</option>
              <option value="Canada">{countryToFlag("CA")} Canada</option>
              <option value="United States">{countryToFlag("US")} United States</option>
              {countries.map((country, i) => (
                <option key={i} value={country.label}>
                  {countryToFlag(country.code)} {country.label}
                </option>
              ))}
            </select>
            </div>
            <div className="form-group">
              <label>Postalcode: </label>
              <input
                name="postalcode"
                type="text"
                className={`form-control ${style.address}`}
                placeholder="160 Kendal Ave, Toronto, ON M5R 1M3"
                value={profile.postalcode}
                onChange={handleOnChange}
                />
            </div>
            <div className="form-group">
              <label>Age Range: </label>
              <select
              onChange={handleOnChange}
              name={"ageGroup"}
              value={profile.ageGroup}
              className={`form-control `}
            >
              <option value="Canada">Age Range (optional)</option>
              {ageRange.map((r, i) => (
                <option key={i} value={r}>
                  {r}
                </option>
              ))}
            </select>
            </div>
            <div className="form-group">
              <label>Living Arrangement: </label>
              <select
              onChange={handleOnChange}
              name={"living"}
              value={profile.living}
              className={`form-control`}
            >
               <option value="">Living Arrangement (optional) </option>
              
              {livingArr.map((l, i) => (
                <option key={i} value={l}>
                  {l}
                </option>
              ))}
            </select>
            </div>

            </div>
            }
            {data && data.me.parentUser === null && isChangePassword && (
               <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <label>Password</label>
                <input
                  name="currentPassword"
                  type="password"
                  className={`form-control mb-4 ${style.curr}`}
                  placeholder="Current Password"
                  value={profile.currentPassword}
                  onChange={handleOnChange}
                  />
                  <label className={``} >New Password</label>
                  {style.newLable&&(style.newLable.map(p=><span className={"d-block font-weight-light text-danger"}>{p}</span>))}

                <input
                  name="newPassword"
                  type="password"
                  className={`form-control  mt-2 ${style.new}`}
                  placeholder="New Password"
                  value={profile.newPassword}
                  onChange={handleOnChange}
                  />
                  <label className={` mt-4`}>Repeat Password</label>
                <input
                  name="repeat"
                  type="password"
                  className={`form-control  mt-2 ${style.re}`}
                  placeholder="Repeat"
                  value={profile.repeat}
                  onChange={handleOnChange}
                />
              </div>
              </div>
            )}

        </div>

        <div className="row mt-5 d-flex justify-content-center">
          <button
            onClick={() => history.push("/profile")}
            className="btn btn-warning mr-2 px-5 btn-extra-radius"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-success ml-2 px-5 btn-extra-radius"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}


function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

const livingArr = [
  "Long term care home",
  "Home - independent",
  "Home - with caretaker",
  
];
const ageRange = [
  "< 60",
  "60 - 64",
  "64 - 69",
  "70 - 74",
  "74 - 79",
  "80 - 84",
  "84 - 89",
  "90+",
 
];
const countries = [
  { code: 'AD', label: 'Andorra' },
  { code: 'AE', label: 'United Arab Emirates' },
  { code: 'AF', label: 'Afghanistan' },
  { code: 'AG', label: 'Antigua and Barbuda' },
  { code: 'AI', label: 'Anguilla' },
  { code: 'AL', label: 'Albania' },
  { code: 'AM', label: 'Armenia' },
  { code: 'AO', label: 'Angola' },
  { code: 'AQ', label: 'Antarctica' },
  { code: 'AR', label: 'Argentina' },
  { code: 'AS', label: 'American Samoa' },
  { code: 'AT', label: 'Austria' },
  { code: 'AU', label: 'Australia',  suggested: true },
  { code: 'AW', label: 'Aruba' },
  { code: 'AX', label: 'Alland Islands' },
  { code: 'AZ', label: 'Azerbaijan' },
  { code: 'BA', label: 'Bosnia and Herzegovina' },
  { code: 'BB', label: 'Barbados' },
  { code: 'BD', label: 'Bangladesh' },
  { code: 'BE', label: 'Belgium' },
  { code: 'BF', label: 'Burkina Faso' },
  { code: 'BG', label: 'Bulgaria' },
  { code: 'BH', label: 'Bahrain' },
  { code: 'BI', label: 'Burundi' },
  { code: 'BJ', label: 'Benin' },
  { code: 'BL', label: 'Saint Barthelemy' },
  { code: 'BM', label: 'Bermuda' },
  { code: 'BN', label: 'Brunei Darussalam' },
  { code: 'BO', label: 'Bolivia' },
  { code: 'BR', label: 'Brazil' },
  { code: 'BS', label: 'Bahamas' },
  { code: 'BT', label: 'Bhutan' },
  { code: 'BV', label: 'Bouvet Island' },
  { code: 'BW', label: 'Botswana' },
  { code: 'BY', label: 'Belarus' },
  { code: 'BZ', label: 'Belize' },
  { code: 'CA', label: 'Canada', suggested: true },
  { code: 'CC', label: 'Cocos (Keeling) Islands' },
  { code: 'CD', label: 'Congo, Democratic Republic of the' },
  { code: 'CF', label: 'Central African Republic' },
  { code: 'CG', label: 'Congo, Republic of the' },
  { code: 'CH', label: 'Switzerland' },
  { code: 'CI', label: "Cote d'Ivoire" },
  { code: 'CK', label: 'Cook Islands' },
  { code: 'CL', label: 'Chile' },
  { code: 'CM', label: 'Cameroon' },
  { code: 'CN', label: 'China' },
  { code: 'CO', label: 'Colombia' },
  { code: 'CR', label: 'Costa Rica' },
  { code: 'CU', label: 'Cuba' },
  { code: 'CV', label: 'Cape Verde' },
  { code: 'CW', label: 'Curacao' },
  { code: 'CX', label: 'Christmas Island' },
  { code: 'CY', label: 'Cyprus' },
  { code: 'CZ', label: 'Czech Republic' },
  { code: 'DE', label: 'Germany' },
  { code: 'DJ', label: 'Djibouti' },
  { code: 'DK', label: 'Denmark' },
  { code: 'DM', label: 'Dominica' },
  { code: 'DO', label: 'Dominican Republic' },
  { code: 'DZ', label: 'Algeria' },
  { code: 'EC', label: 'Ecuador' },
  { code: 'EE', label: 'Estonia' },
  { code: 'EG', label: 'Egypt' },
  { code: 'EH', label: 'Western Sahara' },
  { code: 'ER', label: 'Eritrea' },
  { code: 'ES', label: 'Spain' },
  { code: 'ET', label: 'Ethiopia' },
  { code: 'FI', label: 'Finland' },
  { code: 'FJ', label: 'Fiji' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)' },
  { code: 'FM', label: 'Micronesia, Federated States of' },
  { code: 'FO', label: 'Faroe Islands' },
  { code: 'FR', label: 'France' },
  { code: 'GA', label: 'Gabon' },
  { code: 'GB', label: 'United Kingdom' },
  { code: 'GD', label: 'Grenada' },
  { code: 'GE', label: 'Georgia' },
  { code: 'GF', label: 'French Guiana' },
  { code: 'GG', label: 'Guernsey' },
  { code: 'GH', label: 'Ghana' },
  { code: 'GI', label: 'Gibraltar' },
  { code: 'GL', label: 'Greenland' },
  { code: 'GM', label: 'Gambia' },
  { code: 'GN', label: 'Guinea' },
  { code: 'GP', label: 'Guadeloupe' },
  { code: 'GQ', label: 'Equatorial Guinea' },
  { code: 'GR', label: 'Greece' },
  { code: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { code: 'GT', label: 'Guatemala' },
  { code: 'GU', label: 'Guam' },
  { code: 'GW', label: 'Guinea-Bissau' },
  { code: 'GY', label: 'Guyana' },
  { code: 'HK', label: 'Hong Kong' },
  { code: 'HM', label: 'Heard Island and McDonald Islands' },
  { code: 'HN', label: 'Honduras' },
  { code: 'HR', label: 'Croatia' },
  { code: 'HT', label: 'Haiti' },
  { code: 'HU', label: 'Hungary' },
  { code: 'ID', label: 'Indonesia' },
  { code: 'IE', label: 'Ireland' },
  { code: 'IL', label: 'Israel' },
  { code: 'IM', label: 'Isle of Man' },
  { code: 'IN', label: 'India' },
  { code: 'IO', label: 'British Indian Ocean Territory' },
  { code: 'IQ', label: 'Iraq' },
  { code: 'IR', label: 'Iran, Islamic Republic of' },
  { code: 'IS', label: 'Iceland' },
  { code: 'IT', label: 'Italy' },
  { code: 'JE', label: 'Jersey' },
  { code: 'JM', label: 'Jamaica' },
  { code: 'JO', label: 'Jordan' },
  { code: 'JP', label: 'Japan' },
  { code: 'KE', label: 'Kenya' },
  { code: 'KG', label: 'Kyrgyzstan' },
  { code: 'KH', label: 'Cambodia' },
  { code: 'KI', label: 'Kiribati' },
  { code: 'KM', label: 'Comoros' },
  { code: 'KN', label: 'Saint Kitts and Nevis' },
  { code: 'KP', label: "Korea, Democratic People's Republic of" },
  { code: 'KR', label: 'Korea, Republic of' },
  { code: 'KW', label: 'Kuwait' },
  { code: 'KY', label: 'Cayman Islands' },
  { code: 'KZ', label: 'Kazakhstan' },
  { code: 'LA', label: "Lao People's Democratic Republic" },
  { code: 'LB', label: 'Lebanon' },
  { code: 'LC', label: 'Saint Lucia' },
  { code: 'LI', label: 'Liechtenstein' },
  { code: 'LK', label: 'Sri Lanka' },
  { code: 'LR', label: 'Liberia' },
  { code: 'LS', label: 'Lesotho' },
  { code: 'LT', label: 'Lithuania' },
  { code: 'LU', label: 'Luxembourg' },
  { code: 'LV', label: 'Latvia' },
  { code: 'LY', label: 'Libya' },
  { code: 'MA', label: 'Morocco' },
  { code: 'MC', label: 'Monaco' },
  { code: 'MD', label: 'Moldova, Republic of' },
  { code: 'ME', label: 'Montenegro' },
  { code: 'MF', label: 'Saint Martin (French part)' },
  { code: 'MG', label: 'Madagascar' },
  { code: 'MH', label: 'Marshall Islands' },
  { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of' },
  { code: 'ML', label: 'Mali' },
  { code: 'MM', label: 'Myanmar' },
  { code: 'MN', label: 'Mongolia' },
  { code: 'MO', label: 'Macao' },
  { code: 'MP', label: 'Northern Mariana Islands' },
  { code: 'MQ', label: 'Martinique' },
  { code: 'MR', label: 'Mauritania' },
  { code: 'MS', label: 'Montserrat' },
  { code: 'MT', label: 'Malta' },
  { code: 'MU', label: 'Mauritius' },
  { code: 'MV', label: 'Maldives' },
  { code: 'MW', label: 'Malawi' },
  { code: 'MX', label: 'Mexico' },
  { code: 'MY', label: 'Malaysia' },
  { code: 'MZ', label: 'Mozambique' },
  { code: 'NA', label: 'Namibia' },
  { code: 'NC', label: 'New Caledonia' },
  { code: 'NE', label: 'Niger' },
  { code: 'NF', label: 'Norfolk Island' },
  { code: 'NG', label: 'Nigeria' },
  { code: 'NI', label: 'Nicaragua' },
  { code: 'NL', label: 'Netherlands' },
  { code: 'NO', label: 'Norway' },
  { code: 'NP', label: 'Nepal' },
  { code: 'NR', label: 'Nauru' },
  { code: 'NU', label: 'Niue' },
  { code: 'NZ', label: 'New Zealand' },
  { code: 'OM', label: 'Oman' },
  { code: 'PA', label: 'Panama' },
  { code: 'PE', label: 'Peru' },
  { code: 'PF', label: 'French Polynesia' },
  { code: 'PG', label: 'Papua New Guinea' },
  { code: 'PH', label: 'Philippines' },
  { code: 'PK', label: 'Pakistan' },
  { code: 'PL', label: 'Poland' },
  { code: 'PM', label: 'Saint Pierre and Miquelon' },
  { code: 'PN', label: 'Pitcairn' },
  { code: 'PR', label: 'Puerto Rico' },
  { code: 'PS', label: 'Palestine, State of' },
  { code: 'PT', label: 'Portugal' },
  { code: 'PW', label: 'Palau' },
  { code: 'PY', label: 'Paraguay' },
  { code: 'QA', label: 'Qatar' },
  { code: 'RE', label: 'Reunion' },
  { code: 'RO', label: 'Romania' },
  { code: 'RS', label: 'Serbia' },
  { code: 'RU', label: 'Russian Federation' },
  { code: 'RW', label: 'Rwanda' },
  { code: 'SA', label: 'Saudi Arabia' },
  { code: 'SB', label: 'Solomon Islands' },
  { code: 'SC', label: 'Seychelles' },
  { code: 'SD', label: 'Sudan' },
  { code: 'SE', label: 'Sweden' },
  { code: 'SG', label: 'Singapore' },
  { code: 'SH', label: 'Saint Helena' },
  { code: 'SI', label: 'Slovenia' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen' },
  { code: 'SK', label: 'Slovakia' },
  { code: 'SL', label: 'Sierra Leone' },
  { code: 'SM', label: 'San Marino' },
  { code: 'SN', label: 'Senegal' },
  { code: 'SO', label: 'Somalia' },
  { code: 'SR', label: 'Suriname' },
  { code: 'SS', label: 'South Sudan' },
  { code: 'ST', label: 'Sao Tome and Principe' },
  { code: 'SV', label: 'El Salvador' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)' },
  { code: 'SY', label: 'Syrian Arab Republic' },
  { code: 'SZ', label: 'Swaziland' },
  { code: 'TC', label: 'Turks and Caicos Islands' },
  { code: 'TD', label: 'Chad' },
  { code: 'TF', label: 'French Southern Territories' },
  { code: 'TG', label: 'Togo' },
  { code: 'TH', label: 'Thailand' },
  { code: 'TJ', label: 'Tajikistan' },
  { code: 'TK', label: 'Tokelau' },
  { code: 'TL', label: 'Timor-Leste' },
  { code: 'TM', label: 'Turkmenistan' },
  { code: 'TN', label: 'Tunisia' },
  { code: 'TO', label: 'Tonga' },
  { code: 'TR', label: 'Turkey' },
  { code: 'TT', label: 'Trinidad and Tobago' },
  { code: 'TV', label: 'Tuvalu' },
  { code: 'TW', label: 'Taiwan, Province of China' },
  { code: 'TZ', label: 'United Republic of Tanzania' },
  { code: 'UA', label: 'Ukraine' },
  { code: 'UG', label: 'Uganda' },
  { code: 'US', label: 'United States', suggested: true  },
  { code: 'UY', label: 'Uruguay' },
  { code: 'UZ', label: 'Uzbekistan' },
  { code: 'VA', label: 'Holy See (Vatican City State)' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines' },
  { code: 'VE', label: 'Venezuela' },
  { code: 'VG', label: 'British Virgin Islands' },
  { code: 'VI', label: 'US Virgin Islands' },
  { code: 'VN', label: 'Vietnam' },
  { code: 'VU', label: 'Vanuatu' },
  { code: 'WF', label: 'Wallis and Futuna' },
  { code: 'WS', label: 'Samoa' },
  { code: 'XK', label: 'Kosovo' },
  { code: 'YE', label: 'Yemen' },
  { code: 'YT', label: 'Mayotte' },
  { code: 'ZA', label: 'South Africa' },
  { code: 'ZM', label: 'Zambia' },
  { code: 'ZW', label: 'Zimbabwe' },
];