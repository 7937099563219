import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FcInfo } from 'react-icons/fc'
import { 
  ThemeDocument,
   useThemesQuery,
   useThemeQuery,
    useAddThemeMutation,
    useAddDiscussionToThemeMutation,
    useAddReflectionToThemeMutation,
    useAddQuoteMutation,
    useEditDiscussionFromThemeMutation,
    useEditReflectionFromThemeMutation,
    useEditQuoteInThemeMutation,
    useRemoveDiscussionMutation,
    useRemoveReflectionMutation,
    useRemoveQuoteFromThemeMutation,
    useEditThemeMutation
   }from "../../../graphql/generated/graphql";


// 607b0fdac6bb026ff004076f
const AddTheme = () => {
    const { data, loading, error } = useThemesQuery();
    const [id, setId] = useState("")
    const { data: themeData, loading:themeLoading, error:themeError } = useThemeQuery({
      variables:{
        id:id
      },
      skip: id==""
    });
    const [addTheme] = useAddThemeMutation();
    const [editTheme] = useEditThemeMutation();

    const [addDiscussion] = useAddDiscussionToThemeMutation();
    const [addReflection] = useAddReflectionToThemeMutation();
    const [addQuote] = useAddQuoteMutation();

    const [editDiscussion] = useEditDiscussionFromThemeMutation();
    const [editReflection] = useEditReflectionFromThemeMutation();
    const [editQuote] = useEditQuoteInThemeMutation();

    const [removeDiscussion] = useRemoveDiscussionMutation();
    const [removeReflection] = useRemoveReflectionMutation();
    const [removeQuote] = useRemoveQuoteFromThemeMutation();


    const [pictures, setPictures] = useState([])
    const [name, setName] = useState("")
    const [theme, setTheme] = useState("")
    const prefixImg = "/assets/photos/"
    const [imageUrl, setImageUrl] = useState("")
    const [newImageUrl, setNewImageUrl] = useState("")
    const [reflection, setReflection] = useState({id:"",question:""})
    
    const [discussion, setDiscussion] = useState({id:"",question:""})
    const [quote, setQuote] = useState({  id:"", quote:"",  author:""})
    
    
    const [reflections, setReflections] = useState([])
    const [quotes, setQuotes] = useState([])
    const [discussions, setDiscussions] = useState([])
useEffect(() => {
 if(themeData && themeData.theme){
   setTheme(themeData.theme)
   setImageUrl(themeData.theme.imageUrl)
   setName(themeData.theme.name)
   setDiscussions([...themeData.theme.discussions])
   setQuotes([...themeData.theme.quotes])
   setReflections({...themeData.theme.reflection[0]})
   console.log(theme)
 }
}, [themeData])


   const handleAddTheme= ()=>{
     if(name!=""&&imageUrl!=""){
       addTheme({
         variables:{
           name:name,
           imageUrl:`${prefixImg}${imageUrl}.jpg`,
           program: "reflections",
           category:"reflections",
          }
        }).then(res=>{
          if(res.errors){
            console.log(res.errors[0])
          }
          else{
            setId(res.data.addTheme.id)
            console.log(res)
          }
        })
      }
      else{
         console.log("here")
         toast.error("Make sure you have a name set and photo selected before you try again.")
       }
   }
   const handleEditTheme= ()=>{
     if(name!=""){
       editTheme({
         variables:{
           id,
           name:name,
           imageUrl: newImageUrl==""?theme.imageUrl:`${prefixImg}${newImageUrl}.jpg`,
          },
          refetchQueries:[{
            query:ThemeDocument,
            variables:{
              id
            }
          }]
        }).then(res=>{
          if(res.errors){
            console.log(res.errors[0])
          }
          else{
            
            setNewImageUrl("")
            console.log(res)
          }
        })
      }
      else{
         console.log("here")
         toast.error("Make sure you have a name set and photo selected before you try again.")
       }
   }
   const handleAddReclection= ()=>{
     console.log(reflection)
       if(reflection.question!=""){

if(reflection.id == "")
{       
   addReflection({
          variables:{
            theme:id,
            question:reflection.question,
           
          }, refetchQueries:[{
            query: ThemeDocument,
            variables:{
              id:id
            }
          }]
        }).then(res=>{
          if(res.errors){
            console.log(res.errors[0])
          }
          else{
            setReflection({id:"",question:""})
            console.log(res)
          }
        })
       }
       else{
        console.log("we here?")
        editReflection({
          variables:{
            theme:id,
            id:reflection.id,
            question:reflection.question,
           
          }, refetchQueries:[{
            query: ThemeDocument,
            variables:{
              id:id
            }
          }]
        }).then(res=>{
          if(res.errors){
            console.log(res.errors[0])
          }
          else{
           
            setReflection({id:"",question:""})
            console.log(res)
          }
        })
      }
      }
     
   }
   const handleAddQuote= ()=>{
       if(quote.author!=""&&quote.quote!=""){

if(quote.id == "")
      {  addQuote({
          variables:{
            theme:id,
            author:quote.author,
            quote:quote.quote
          }, refetchQueries:[{
            query: ThemeDocument,
            variables:{
              id:id
            }
          }]
        }).then(res=>{
          if(res.errors){
            console.log(res.errors[0])
          }
          else{
            setQuote({id:"",quote:"",author:""})
            console.log(res)
          }
        })
      }else{
        editQuote({
          variables:{
            theme:id,
            quoteId:quote.id,
            author:quote.author,
            quote:quote.quote
          }, refetchQueries:[{
            query: ThemeDocument,
            variables:{
              id:id
            }
          }]
        }).then(res=>{
          if(res.errors){
            console.log(res.errors[0])
          }
          else{
            setQuote({id:"",quote:"",author:""})
            console.log(res)
          }
        })
      }
       }
   }
   const handleAddQuestion= ()=>{
       if(discussion.question!==""){

if(discussion.id == "")
        {
          discussion.question.split("?").map(q=>{
            if(q.length > 3){

              addDiscussion({
              variables:{
                theme:id,
                question:q+"?",
              }, refetchQueries:[{
                query: ThemeDocument,
                variables:{
                  id:id
                }
              }]
            }).then(res=>{
              if(res.errors){
                console.log(res.errors[0])
              }
              else{
                console.log(res)
              }
            })
          }
        })
        setDiscussion({id:"",question:""})
       }else{
        editDiscussion({
          variables:{
            theme:id,
            id: discussion.id,
            question:discussion.question,
          }, refetchQueries:[{
            query: ThemeDocument,
            variables:{
              id:id
            }
          }]
        }).then(res=>{
          if(res.errors){
            console.log(res.errors[0])
          }
          else{
            setDiscussion({id:"",question:""})
            console.log(res)
          }
        })
       }
      }
   }
const [themesList, setThemesList] = useState([])
    useEffect(() => {
      if(data&& data.themes){
        let temp = []
        let tempL = []
        data.themes.map(theme=>{
            temp.push({imageUrl: theme.imageUrl, name:theme.imageUrl.split("/")[3].split(".")[0]})
            tempL.push({name:theme.name, id:theme.id, program: theme.program})
        })
        setThemesList([...tempL])
        setPictures([...new Set([...temp])])
        console.log(pictures)
      
      }
    }, [data])
    useEffect(() => {
      console.log(imageUrl)
    }, [imageUrl])
    const getURL = url => {
        return url.slice(0, 14) + "/thumb/" + url.slice(15);
      };

      const handleDelete = (type, objId)=>{
        switch (type) {
          case "q":
            removeQuote({
              variables:{
                theme:id,
                quote:objId
               
              }
            }).then(res=>{
              if(res.errors){
                console.log(res.errors[0])
              }
              else{
                setQuotes([...quotes.filter(q=>q.id!=objId)])
                console.log(res)
              }
            })
            break;
          case "r":
            removeReflection({
              variables:{
                theme:id,
                id:objId
               
              }
            }).then(res=>{
              if(res.errors){
                console.log(res.errors[0])
              }
              else{
                setReflections([])
                console.log(res)
              }
            })
            break;
          case "d":
            removeDiscussion({
              variables:{
                theme:id,
                id:objId
               
              }
            }).then(res=>{
              if(res.errors){
                console.log(res.errors[0])
              }
              else{
                setDiscussions([...discussions.filter(d=>d.id!=objId)])
                console.log(res)
              }
            })
            break;
        
          default:
            break;
        }
      }




    return (
        <div className={``}>
           {id=="" && <>
            <div className={`mb-3`}>
            <div className={`d-flex my-2 row w-75 useBorder rounded box-shadow`}>
            <div className="col  d-flex justify-content-center col-12" >
               <h4>Edit Existing theme</h4>
                </div>
            <div className={`m-3 col-12`} >
                Edit Existing Theme: {' '}
                <select
              onChange={(e)=>setId(e.target.value)}
              name={"Theme"}
              value={id}
              
            >
              <option value="">Theme</option>
              
              {themesList.map((t, i) => (
                <option key={i} value={t.id}>
                  {t.name} - {t.program=="music"?"Java music":"Java Social"}
                </option>
              ))}
            </select>
                </div>
            <div className="col align-items-center d-flex mb-1 justify-content-start mx-3 col-12" >
               
                <FcInfo className="mr-1"></FcInfo> Use this box only if you want to add/edit content to an existing theme.  
                </div>
                </div>
            
               <div className={`row w-75 my-2 `}>
               <div className={`col  useBorder pb-2 rounded box-shadow col-12 `}>
                 <h1 className={`text-center`}>Add new theme</h1>
            <div className={`rounded shadow offset-3 col-6 bg-clear my-1 useBorder`}>Theme Name: {name}</div>
            <div className={`rounded shadow offset-3  col-6 bg-clear my-1 useBorder`}>imageUrl: {imageUrl}</div>
            <div className={`rounded shadow offset-3  col-6 bg-clear my-1 useBorder`}>
             <h5>
                Step 3: 
               </h5> 
              <FcInfo></FcInfo> Once you have entered a name and selected a photo click the 'Create' button to add content <br/>
           <button className={`btn btn-success my-2 ${name==""||imageUrl==""?" disabled ":""} `} onClick={()=>{handleAddTheme()}}>Create</button>
           </div> 
                 </div> 
                 </div> 
            <div className={`d-flex my-2 row w-75 useBorder rounded box-shadow`}>
            <div className="col  d-flex justify-content-center col-12" >
               <h4>Step 1: Set a name</h4>
                </div>
            <div className={`m-3 col-12`} >
                 Theme Name: <input type="text" value={name} placeholder={"Theme name"} onChange={(e)=>setName(e.target.value.toUpperCase())}></input>
                </div>

                </div>
            
            </div>
        <div className={`d-flex `}>
        <div className={`row w-75 useBorder rounded box-shadow`}>
            <div className={` w-100 d-flex justify-content-center`}>
            <div className={` h4  my-2 `}>
                Step 2: Choose Image
                </div>
                </div>
                <div className={`m-3 col-12`} >
                 Image Name: <input type="text" value={imageUrl} placeholder={"eg. ASERVICE"} onChange={(e)=>setImageUrl(e.target.value.toUpperCase())}></input>
                </div>
                <div className={`col my-2 `}>
                   <div
          className={` position-relative text-center d-flex align-items-center justify-content-center theme-card`}
          role="button"
          >
          <img
            className={`not-draggable round-box`}
            src={
                "https://d30mg0zmvh01j5.cloudfront.net" + getURL(`${prefixImg}${imageUrl}.jpg`)
            }
            height={150}
            width={200}
            
            alt=""
            />
        </div> 
          <h5 className={` mx-4 text-white `}>
            
          </h5>{" "}
                </div>
            {/* <div style={{maxHeight:"30vw", overflowY:"scroll",overflowX:"hidden"}}  className={`row mx-2 mb-3`}>
            {pictures&& pictures.map(pic=>(
                
                <div key={pic.imageUrl+Math.random()} className={`col my-2 `}>
                   <div
          className={` position-relative text-center d-flex align-items-center justify-content-center theme-card`}
          role="button"
          >
          <img
            className={`${imageUrl==pic.imageUrl?" border box-shadow border-info ":""} not-draggable round-box`}
            src={
                "https://d30mg0zmvh01j5.cloudfront.net" + getURL(pic.imageUrl)
            }
            height={150}
            width={200}
            onClick={()=>setImageUrl(pic.imageUrl)}
            alt=""
            />
        </div> 
          <h5 className={`${imageUrl==pic.imageUrl?" text-info ":""} mx-4 text-white `}>
            {pic.name}
          </h5>{" "}
                </div>
                
                ))}
                </div> */}
        </div>
        </div>
       
           </>
           }
           {id!=="" && <>
            <div className={`mb-3`}>
                <h1>Add/Edit Theme Content <button onClick={()=>{
                  setId("")
                  setName("")
                  setImageUrl("")
              }} className={`btn btn-sm btn-info`}>select different theme</button></h1>
            <div>ID: {id}</div>
            <div>Theme Name: {name}</div>
            <div>imageUrl: {imageUrl}</div>
            <div className={`d-flex my-2 row w-75 useBorder rounded box-shadow`}>
            <div className="col  d-flex justify-content-center col-12" >
               <h4>Edit theme name/image</h4>
                </div>

                <div className={`col col-12 m-3 d-flex justify-content-start align-items-center`} >
                <span className={`col col-3`}>
                New Theme Name:
                 </span>
                
                
                  <input type="text" value={name} placeholder={"eg. ASERVICE"} onChange={(e)=>setName(e.target.value.toUpperCase())}></input> 
                 {name != theme.name && <span className={`col col-6`}>
                    <button className={`btn btn-success  mx-2 `}  onClick={()=>{handleEditTheme()}}> Confirm Edit</button>
                   <button className={`btn btn-danger  `}  onClick={()=>{
                   setName(theme.name)
                 }}> Cancel Changes</button>
                 </span>}
                </div>
                
                <div className={`col col-12 m-3 d-flex justify-content-start align-items-center`} >
                <span className={`col col-3`}>
                New Image:
                 </span>
                
                
                  <input type="text" value={newImageUrl} placeholder={"eg. ASERVICE"} onChange={(e)=>setNewImageUrl(e.target.value.toUpperCase())}></input>
                  {newImageUrl != ""&& <span className={`col col-6`}>
                    <button className={`btn btn-success  mx-2 `}  onClick={()=>{handleEditTheme()}}> Confirm Edit</button>
                   <button className={`btn btn-danger  `}  onClick={()=>{
                   setNewImageUrl("")
                 }}> Cancel Changes</button>
                 </span>}
                </div>
                <div className={`col col-12 m-3 d-flex justify-content-start align-items-center`} >
                <div className={`col col-3  my-2 `}>
                   <div
          className={` position-relative text-center d-flex align-items-center justify-content-center `}
          
          >
          <img
            className={`not-draggable round-box`}
            src={
                "https://d30mg0zmvh01j5.cloudfront.net" + getURL(newImageUrl==""?imageUrl:`${prefixImg}${newImageUrl}.jpg`)
            }
            height={150}
            width={200}
            
            alt=""
            />
        </div> 
                </div>
               </div>
               <div className="col align-items-center d-flex mb-1 justify-content-start mx-3 col-12" >
               
               <FcInfo className="mr-2"></FcInfo> Once you start editing the image name this box will clear and the new image will only show up when you enter a valid name.  
               </div>
                </div>
           
            <div className={`d-flex my-2 row w-75 useBorder rounded box-shadow`}>
            <div className="col  d-flex justify-content-center col-12" >
               <h4>Add content</h4>
                </div>

            <div className={`col col-12 m-3 d-flex justify-content-start align-items-center`} >
               <span className={`col col-3`}>
                   Reflection Reading:
                 </span>
               <span className={`col col-6`}>
                    <textarea className={`w-100`} type="text" value={reflection.question} placeholder={"Reflection Reading"} onChange={(e)=>setReflection({...reflection,question:e.target.value})}></textarea> 
                 </span>
               <span className={`col col-3`}>
                    <button className={`btn ${reflection.id == ""?"btn-success":"btn-info"} my-2 `}  onClick={()=>{handleAddReclection()}}> {reflection.id == ""?"Add to theme":"Confirm Edit"}</button>
                    {reflection.id==""?"":<button className={`btn btn-danger my-2 `}  onClick={()=>{
                   setReflection({id: "", question: ""})
                 }}> Cancel Changes</button>}
                 </span>
                </div>
              
            <div className={`col col-12 m-3 d-flex justify-content-start align-items-center`} >
                <span className={`col col-3`}>
                Quote:
</span>
               <span className={`col col-6`}>
                 <textarea className={`w-100`} type="text" value={quote.quote} placeholder={"Quote"} onChange={(e)=>setQuote({...quote,quote:e.target.value})}></textarea>
                 <input type="text" value={quote.author} placeholder={"Author"} onChange={(e)=>setQuote({...quote,author:e.target.value})}></input>
                 
                </span>
                <span className={`col col-3`}>
                 <button className={`btn ${quote.id == ""?"btn-success":"btn-info"} my-2 `}  onClick={()=>{handleAddQuote()}}>  {quote.id==""?"Add to theme":"Confirm Edit"}</button>
                 {quote.id==""?"":<button className={`btn btn-danger my-2 `}  onClick={()=>{
                   setQuote({id: "", quote: "", author: ""})
                 }}> Cancel Changes</button>}
</span>
               </div>
                
            <div className={`col col-12 m-3 d-flex justify-content-start align-items-center`} >
                 <span className={`col col-3`}>
                 Discussion Questions:
                 </span>
               <span className={`col col-6`}>
                  <input type="text" className={`w-100`} value={discussion.question} placeholder={"Discussion Question"} onChange={(e)=>setDiscussion({...discussion,question:e.target.value})}></input> 
                 </span>
                 <span className={`col col-3`}>
                  <button className={`btn ${discussion.id == ""?"btn-success":"btn-info"} my-2 `}  onClick={()=>{handleAddQuestion()}}> {discussion.id == ""?"Add to theme":"Confirm Edit"}</button>
                  {discussion.id==""?"":<button className={`btn btn-danger my-2 `}  onClick={()=>{
                   setDiscussion({id: "", question: ""})
                 }}> Cancel Changes</button>}
</span>

                 </div>
               
                </div>
           
            </div>
            <div className={`d-flex `}>
        <div className={`row w-75 useBorder rounded box-shadow`}>
            <div className={` w-100 d-flex justify-content-center`}>
            <div className={` h4  my-2 `}>
               Content
                </div>
                </div>
            <div className={`col col-12  mx-3`}>
              <h5>Quotes</h5>
            {quotes && quotes.map(q=>(
              
              <div key={q.id} className={`row text-wrap my-2 `}>
               
                <div className={`col col-7 my-2 `}>
                <div className={`col  `}>
                   {q.quote}
                </div>
                <div className={`col  `}>
                  - {q.author}
                </div>
                </div>
                <div className={`col col-3 my-2 `}>
                  <button className={`btn btn-success my-2 mx-2 `}  onClick={()=>setQuote({...q})}>Edit</button>
                  <button className={`btn btn-danger my-2 ref-edit`}  onClick={()=>handleDelete("q",q.id)}>Delete</button>
</div>
                </div>
                
                ))}
                </div>
                <br/>
            <div  className={`col col-12  mb-3 mx-3`}>
                <h5>Reflection Reading</h5>
            {reflections && reflections.id &&
                  <div  className={`row text-wrap my-2 `}>
               
                <div className={`col col-7 my-2 `}>
                <div className={`col  `}>
                <div className={`ref-edit`} dangerouslySetInnerHTML={{__html: reflections.question}} /></div>
                </div>
                <div className={`col col-3 my-2 `}>
                  <button className={`btn btn-success my-2 mx-2 `}  onClick={()=>setReflection({...reflections})}>Edit</button>
                  <button className={`btn btn-danger my-2 `}  onClick={()=>handleDelete("r",reflections.id)}>Delete</button>
</div>
                </div>
                
              }
                </div>
            <div className={` col col-12 mb-3  mx-3`}>
              <h5>Discussion Questions</h5>
            {discussions && discussions.map(d=>(
                <div key={d.id} className={`row text-wrap my-2 `}>
               
                <div className={`col col-7 my-2 `}>
                <div className={`col  `}>
                  {d.question} </div>
                </div>
                <div className={`col col-3 my-2 `}>
                  <button className={`btn btn-success my-2 mx-2 `}  onClick={()=>setDiscussion({...d})}>Edit</button>
                  <button className={`btn btn-danger my-2 `}  onClick={()=>handleDelete("d",d.id)}>Delete</button>
</div>
                </div>
                
                ))}
                </div>
        </div>
        </div>
       
           </>
           }
        </div>
    )
}

export default AddTheme
