import React, { useState } from "react";
import StyledContentLoader from "styled-content-loader";
import {
  MdHome,
  MdLibraryBooks,
  MdLightbulbOutline,
  MdPeople,
  MdExitToApp,
  MdPerson,
} from "react-icons/md";
import SessionCardSmall from "./Session/SessionCardSmall";
import SessionCardLarge from "./Session/SessionCardLarge";
import { HiSwitchVertical } from "react-icons/hi";
const App = ({ loading, type }) => {
  const [isCollapsed, setIsCollapsed] = useState(false, []);
  const handleCollapse = () => setIsCollapsed(!isCollapsed);

  const collapseNavbar = () => {
    setIsCollapsed(true);
  };
  if (type === "home") {
    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          {/* <NavLink className="navbar-brand d-md-none" exact to="/">
          Java Group
        </NavLink> */}
          
          <div
            className={` w-100`}
            id="navbar"
          >
            <div className="navbar-nav d-flex flex-row justify-content-between w-100">
              <div className="navbar-nav d-none d-lg-flex">
                <div className="mr-2">
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>
                <div className="mr-2">
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>

                <div className="mr-2">
                  {" "}
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>

                {/* {[ROLE_FACILITATOR, ROLE_ADMIN].indexOf(state.user.role) !==
                -1 && (
                <NavLink
                  className="nav-item nav-link"
                  to="/playground"
                  onClick={collapseNavbar}
                >
                  <div className="mr-2">
                    <MdLightbulbOutline />
                  </div>
                  <div>dev</div>
                </NavLink>
              )} */}

                {/* The content below will only display if the screen resolution is LESS than md */}
                
              </div>

              {/* The content below will only display if the screen resolution is MORE than md */}
              <div className="navbar-nav d-none d-lg-flex">
                <div className="mr-2">
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>
                <div className="mr-2">
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>

                <div className="mr-2">
                  {" "}
                  <StyledContentLoader isLoading={true}>
                    <div className="mr-2">
                      <MdHome />
                    </div>
                    <div>Home</div>
                  </StyledContentLoader>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* This span should have the exact same height as the navbar to prevent the navbar from covering other elements */}
        <div style={{ height: "56px" }} />

        <div className={"container my-3"}>
          <div className="row">
            <div className="col-sm-12 col-md-7">
              
              <div className={" "}>
              <h4 className="mb-3 ">Sessions</h4>
                <div className={" "}>
                  <div
                    style={{ height: "47vh", width: "100%" }}
                    className="position-relative round-box d-flex align-items-end justify-content-center bg-clear"
                  >
                    {/* <img src={theme.image} alt={theme.name} /> */}
                    <div
                      style={{ height: "90%", width: "90%" }}
                      className=" round-box my-3 align-self-center   "
                    >
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div
                          style={{ height: "100%", width: "100%" }}
                          className=" round-box bg-white  "
                        />{" "}
                      </StyledContentLoader>
                    </div>
                    <div
                      style={{
                        backgroundColor: "rgba(0,0,0,.5)",
                        borderRadius: "0 0 15px 15px",
                      }}
                      className="position-absolute  text-white my-auto mx-auto w-100 p-3"
                    >
                      <div className="py-2 ml-1 row">
                        <div className={"col-9"}>
                          <h5>
                            <div className="mr-3 text-capitalize"></div>

                            <StyledContentLoader isLoading={true}>
                              {" "}
                              <div
                                className={`badge  badge-success
                    `}
                              >
                                joinable
                              </div>
                            </StyledContentLoader>
                          </h5>
                          <div className="mb-0 font-weight-light">
                            <StyledContentLoader isLoading={true}>
                              {" "}
                              <div className="d-block d-lg-inline">
                                {"MMMM Do YYYY, h:mm:ss a"}
                              </div>{" "}
                            </StyledContentLoader>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={" "}>
                  <div
                    className={
                      "border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2"
                    }
                  >
                    <div className="pl-3  py-2d-flex row">
                    <div className={"col-9"}>
                        <div className={"row px-3 my-2"}>
                          <h5>
                            {" "}
                            <StyledContentLoader isLoading={true}>
                              <div className="mr-3 text-capitalize">
                                hgjkghjkgjhyk
                              </div>
                            </StyledContentLoader>
                          </h5>{" "}
                          <StyledContentLoader isLoading={true}>
                            <div className={`mt-1 mb-1 badge badge-success`}>
                              joinable
                            </div>
                          </StyledContentLoader>
                        </div>
                        <div className="mb-0 font-weight-light">
                          <StyledContentLoader isLoading={true}>
                            <div className="d-block d-lg-inline">
                              MMMM Do YYYY, h:mm:ss a
                            </div>
                          </StyledContentLoader>
                        </div>
                      </div>

                      <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                        <StyledContentLoader isLoading={true}>
                          {" "}
                          <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                            Open
                          </button>
                        </StyledContentLoader>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={""}>
                  <div
                    className={
                      "border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2"
                    }
                  >
                    <div className="pl-3 py-2d-flex row">
                    <div className={"col-9"}>
                        <div className={"row px-3 my-2"}>
                          <h5>
                            {" "}
                            <StyledContentLoader isLoading={true}>
                              <div className="mr-3 text-capitalize">
                                hgjkghjkgjhyk
                              </div>
                            </StyledContentLoader>
                          </h5>{" "}
                          <StyledContentLoader isLoading={true}>
                            <div className={`mt-1 mb-1 badge badge-success`}>
                              joinable
                            </div>
                          </StyledContentLoader>
                        </div>
                        <div className="mb-0 font-weight-light">
                          <StyledContentLoader isLoading={true}>
                            <div className="d-block d-lg-inline">
                              MMMM Do YYYY, h:mm:ss a
                            </div>
                          </StyledContentLoader>
                        </div>
                      </div>

                      <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                        <StyledContentLoader isLoading={true}>
                          {" "}
                          <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                            Open
                          </button>
                        </StyledContentLoader>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={""}>
                  <div className={"border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2" }>
                    <div className="pl-3 py-2d-flex row">
                      <div className={"col-9"}>
                        <div className={"row px-3 my-2"}>
                          <h5>
                            {" "}
                            <StyledContentLoader isLoading={true}>
                              <div className="mr-3 text-capitalize">
                                hgjkghjkgjhyk
                              </div>
                            </StyledContentLoader>
                          </h5>{" "}
                          <StyledContentLoader isLoading={true}>
                            <div className={`mt-1 mb-1 badge badge-success`}>
                              joinable
                            </div>
                          </StyledContentLoader>
                        </div>
                        <div className="mb-0 font-weight-light">
                          <StyledContentLoader isLoading={true}>
                            <div className="d-block d-lg-inline">
                              MMMM Do YYYY, h:mm:ss a
                            </div>
                          </StyledContentLoader>
                        </div>
                      </div>

                      <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                        <StyledContentLoader isLoading={true}>
                          {" "}
                          <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                            Open
                          </button>
                        </StyledContentLoader>
                      </div>
                    </div>
                  </div>
                </div>
                

                <div>
                  <div className="d-flex flex-row justify-content-center my-4">
                    <StyledContentLoader isLoading={true}>
                      {" "}
                      <button className="btn btn-primary btn-extra-radius px-4">
                        See All Sessions
                      </button>
                    </StyledContentLoader>
                  </div>
                </div>
              </div>
            </div>
              <div className="col-sm-12 col-md-5">
              <div>
      <div className={""}>
      <h4 className="mb-3">Resources</h4>
      <div className="mb-4 border shadow round-box">
      <div className="row mx-auto">
        <div
          className={
            "px-0 d-flex px-0 d-flex col-lg-4 col-4"
          }
        >
         <div
                      style={{ height: "100%", width: "100%",borderRadius: "15px 0 0 15px" }}
                      className="  my-3 align-self-center   "
                    >
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div
                          style={{ height: "100%", width: "100%",borderRadius: "15px 0 0 15px" }}
                          className="bg-white py-5 "
                        />{" "}
                      </StyledContentLoader>
                    </div>
        </div>

        <div
          className={
            "mt-2 px-2 d-flex flex-column col-lg-8 col-md-8 col-8"
          }
        >
          <div className="d-flex flex-row justify-content-between">
            <StyledContentLoader isLoading={true}><h4 className="text-info">ffghdfghfgdhs</h4> </StyledContentLoader>
            
          </div>

          <StyledContentLoader isLoading={true}><p
            className="align-self-stretch mt-1 mb-2  mr-5 text-wrap"
            style={{ wordBreak: "break-all" }}
          >
           hgkfkjfhgjfghjfghjfghj
          </p> </StyledContentLoader>
          <StyledContentLoader isLoading={true}><p
            className="align-self-stretch  mr-5 text-wrap"
            style={{ wordBreak: "break-all" }}
          >
           hgkfkjfhgjfghjfghjfghjfghj
          </p> </StyledContentLoader>
        </div>
      </div>
    </div>
 </div>
    </div>
        </div>
          </div>
        </div>
      </>
    );
};
if(type === "sessSmall"){
    return(
        <div className={""}>
                  <div className={"border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2" }>
                    <div className="pl-3 py-2d-flex row">
                      <div className={"col-9"}>
                        <div className={"row px-3 my-2"}>
                          <h5>
                            {" "}
                            <StyledContentLoader isLoading={true}>
                              <div className="mr-3 text-capitalize">
                                hgjkghjkgjhyk
                              </div>
                            </StyledContentLoader>
                          </h5>{" "}
                          <StyledContentLoader isLoading={true}>
                            <div className={`mt-1 mb-1 badge badge-success`}>
                              joinable
                            </div>
                          </StyledContentLoader>
                        </div>
                        <div className="mb-0 font-weight-light">
                          <StyledContentLoader isLoading={true}>
                            <div className="d-block d-lg-inline">
                              MMMM Do YYYY, h:mm:ss a
                            </div>
                          </StyledContentLoader>
                        </div>
                      </div>

                      <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                        <StyledContentLoader isLoading={true}>
                          {" "}
                          <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                            Open
                          </button>
                        </StyledContentLoader>
                      </div>
                    </div>
                  </div>
                </div>
    )
}
if(type === "sessLarge"){
    return(
                      <div className={" "}>
        <div
          style={{ height: "47vh", width: "100%" }}
          className="position-relative round-box d-flex align-items-end justify-content-center bg-clear"
        >
          {/* <img src={theme.image} alt={theme.name} /> */}
          <div
            style={{ height: "90%", width: "90%" }}
            className=" round-box my-3 align-self-center   "
          >
            {" "}
            <StyledContentLoader isLoading={true}>
              <div
                style={{ height: "100%", width: "100%" }}
                className=" round-box bg-white  "
              />{" "}
            </StyledContentLoader>
          </div>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,.5)",
              borderRadius: "0 0 15px 15px",
            }}
            className="position-absolute  text-white my-auto mx-auto w-100 p-3"
          >
            <div className="py-2 ml-1 row">
              <div className={"col-9"}>
                <h5>
                  <div className="mr-3 text-capitalize"></div>

                  <StyledContentLoader isLoading={true}>
                    {" "}
                    <div
                      className={`badge  badge-success
          `}
                    >
                      joinable
                    </div>
                  </StyledContentLoader>
                </h5>
                <div className="mb-0 font-weight-light">
                  <StyledContentLoader isLoading={true}>
                    {" "}
                    <div className="d-block d-lg-inline">
                      {"MMMM Do YYYY, h:mm:ss a"}
                    </div>{" "}
                  </StyledContentLoader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
if(type === "sessCont"){
    return(
        <div className={" "}>
        <h4 className="mb-3 ">Sessions</h4>
          <div className={" "}>
            <div
              style={{ height: "47vh", width: "100%" }}
              className="position-relative round-box d-flex align-items-end justify-content-center bg-clear"
            >
              {/* <img src={theme.image} alt={theme.name} /> */}
              <div
                style={{ height: "90%", width: "90%" }}
                className=" round-box my-3 align-self-center   "
              >
                {" "}
                <StyledContentLoader isLoading={true}>
                  <div
                    style={{ height: "100%", width: "100%" }}
                    className=" round-box bg-white  "
                  />{" "}
                </StyledContentLoader>
              </div>
              <div
                style={{
                  backgroundColor: "rgba(0,0,0,.5)",
                  borderRadius: "0 0 15px 15px",
                }}
                className="position-absolute  text-white my-auto mx-auto w-100 p-3"
              >
                <div className="py-2 ml-1 row">
                  <div className={"col-9"}>
                    <h5>
                      <div className="mr-3 text-capitalize"></div>

                      <StyledContentLoader isLoading={true}>
                        {" "}
                        <div
                          className={`badge  badge-success
              `}
                        >
                          joinable
                        </div>
                      </StyledContentLoader>
                    </h5>
                    <div className="mb-0 font-weight-light">
                      <StyledContentLoader isLoading={true}>
                        {" "}
                        <div className="d-block d-lg-inline">
                          {"MMMM Do YYYY, h:mm:ss a"}
                        </div>{" "}
                      </StyledContentLoader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={" "}>
            <div
              className={
                "border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2"
              }
            >
              <div className="pl-3  py-2d-flex row">
              <div className={"col-9"}>
                  <div className={"row px-3 my-2"}>
                    <h5>
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div className="mr-3 text-capitalize">
                          hgjkghjkgjhyk
                        </div>
                      </StyledContentLoader>
                    </h5>{" "}
                    <StyledContentLoader isLoading={true}>
                      <div className={`mt-1 mb-1 badge badge-success`}>
                        joinable
                      </div>
                    </StyledContentLoader>
                  </div>
                  <div className="mb-0 font-weight-light">
                    <StyledContentLoader isLoading={true}>
                      <div className="d-block d-lg-inline">
                        MMMM Do YYYY, h:mm:ss a
                      </div>
                    </StyledContentLoader>
                  </div>
                </div>

                <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                  <StyledContentLoader isLoading={true}>
                    {" "}
                    <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                      Open
                    </button>
                  </StyledContentLoader>
                </div>
              </div>
            </div>
          </div>
          <div className={""}>
            <div
              className={
                "border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2"
              }
            >
              <div className="pl-3 py-2d-flex row">
              <div className={"col-9"}>
                  <div className={"row px-3 my-2"}>
                    <h5>
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div className="mr-3 text-capitalize">
                          hgjkghjkgjhyk
                        </div>
                      </StyledContentLoader>
                    </h5>{" "}
                    <StyledContentLoader isLoading={true}>
                      <div className={`mt-1 mb-1 badge badge-success`}>
                        joinable
                      </div>
                    </StyledContentLoader>
                  </div>
                  <div className="mb-0 font-weight-light">
                    <StyledContentLoader isLoading={true}>
                      <div className="d-block d-lg-inline">
                        MMMM Do YYYY, h:mm:ss a
                      </div>
                    </StyledContentLoader>
                  </div>
                </div>

                <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                  <StyledContentLoader isLoading={true}>
                    {" "}
                    <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                      Open
                    </button>
                  </StyledContentLoader>
                </div>
              </div>
            </div>
          </div>
          <div className={""}>
            <div className={"border my-3 container bg-clear box-3d box-shadow round-box py-1 py-lg-2" }>
              <div className="pl-3 py-2d-flex row">
                <div className={"col-9"}>
                  <div className={"row px-3 my-2"}>
                    <h5>
                      {" "}
                      <StyledContentLoader isLoading={true}>
                        <div className="mr-3 text-capitalize">
                          hgjkghjkgjhyk
                        </div>
                      </StyledContentLoader>
                    </h5>{" "}
                    <StyledContentLoader isLoading={true}>
                      <div className={`mt-1 mb-1 badge badge-success`}>
                        joinable
                      </div>
                    </StyledContentLoader>
                  </div>
                  <div className="mb-0 font-weight-light">
                    <StyledContentLoader isLoading={true}>
                      <div className="d-block d-lg-inline">
                        MMMM Do YYYY, h:mm:ss a
                      </div>
                    </StyledContentLoader>
                  </div>
                </div>

                <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
                  <StyledContentLoader isLoading={true}>
                    {" "}
                    <button className="btn px-3 px-lg-4 btn-sm btn-info btn-extra-radius">
                      Open
                    </button>
                  </StyledContentLoader>
                </div>
              </div>
            </div>
          </div>
          

          <div>
            <div className="d-flex flex-row justify-content-center my-4">
              <StyledContentLoader isLoading={true}>
                {" "}
                <button className="btn btn-primary btn-extra-radius px-4">
                  See All Sessions
                </button>
              </StyledContentLoader>
            </div>
          </div>
        </div>
    )
}
}

export default App;
