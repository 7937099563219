import React, {useState} from "react";
import moment from "moment";
import GenericModal from "../../GenericModal";
import Select from "react-select";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    overflowY:"scroll",
    maxHeight:"150px",
  }),
  container: (provided, state) => ({
    ...provided,
    color:"#000",  
  }),
}

export default function NewMemberLine(props) {
  const { user, handleChangeStatus, hasApprove, hasDeny,editRole } = props;
  const {
    id,
    firstName,
    lastName,
    email,
    createdAt,
    role,
    status,
    parentUser,
    validUntil,
  } = user;
  const options = [
    {value:"ROLE_MEMBER",label:"Member"},
    {value:"ROLE_FACILITATOR",label:"Facilitator"},
    {value:"ROLE_MOD",label:"Moderator"}
  ]

  const [searchText, setSearchText] = useState("");
  const [selectedRole, setSelectedRole] = useState(role)
  const [confirmMod, setConfirmMod] = useState(false)
  const [roleModalIsOpen, setRoleModalIsOpen] = useState(false)
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)
  const handleEditRole = (isNew)=>{
     editRole(id,selectedRole,isNew)
     setRoleModalIsOpen(false)
  }
  console.log(validUntil);
  const handleApprove = e => {
    e.preventDefault()
    console.log("hi");
    handleChangeStatus(id, true, moment().add(1, "year"));
    
  };
  const handleDeny = e => {
    e.preventDefault()
    console.log("hi");
    handleChangeStatus(id, false, null);
  };
  return (
    <tr>
      <th scope="row">{role}</th>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{email}</td>
      <td>{moment(createdAt).format("DD MMM YYYY")}</td>
      {/* <td>{validUntil}</td> */}
      <td>
        {validUntil != null ? moment(validUntil).format("DD MMM YYYY") : "N/A"}
      </td>
      <td>
        <div className="btn-group w-100">
          {hasDeny && hasDeny === true && !parentUser && (
            <button className="btn btn-info" onClick={()=>setRoleModalIsOpen(true)}>
             {hasApprove&&hasDeny?"Activate":"Role"}
            </button>
          )}
          {hasDeny && hasDeny === true && parentUser && (
            <button disabled className="btn btn-info" >
              Subuser
            </button>
          )}
          {hasApprove && hasApprove === true && hasDeny !== true && (
            <button className="btn btn-success" onClick={handleApprove}>
              Activate
            </button>
          )}
          {hasDeny && hasDeny === true && (
            <button className="btn btn-danger" onClick={handleDeny}>
              Cancel
            </button>
          )}
        </div>
      </td>
      <GenericModal
       style={{width:"300px !important"}}
       className={"col-6 w-25"}
       addClass={"  col-6 d-flex  align-items-center  gray-filled"}
        modalIsOpen={roleModalIsOpen}
        setModalIsOpen={setRoleModalIsOpen}
      >
<div style={{minHeight:250}} className="round-box bg-clear d-flex flex-column justify-content-center  box-shadow my-3 p-5">
<Select

            styles={customStyles}
              options={options}
              
              onChange={opt => {
                console.log("a");
                setSelectedRole(opt.value);
              }}
              
              // getOptionLabel={x => x.split("_")[1]}
              // onInputChange={v => setSearchText(selectedRole.split("_")[1])}
            />
<div className={"row mt-4"}>
        <div className={"col d-flex justify-content-center"}>
              <button onClick={()=>{
                setWarningModalIsOpen(true)
                }} className={"btn btn-success w-25 mx-3 box-shadow "}>
                Change
              </button>
        <button onClick={()=>{ 
                setWarningModalIsOpen(false)
                setRoleModalIsOpen(false)
              }} className={"btn btn-danger  w-25 mx-3 box-shadow"}>
                Cancel
              </button>
              </div>
</div>
          </div>
 </GenericModal>
 <GenericModal
      style={{width:"100px !important"}}
      className={"col-6 w-25"}
      addClass={" bg-clear gray-filled"}
        modalIsOpen={warningModalIsOpen}
        setModalIsOpen={setWarningModalIsOpen}
      >
          <div className="round-box col-12 box-shadow  align-self-center p-5">
             <div className={"row d-flex justify-content-center"}>
                <h5>
              Are you sure you want to give {firstName} {lastName} {selectedRole} privilages? 
              </h5>
              </div>
              {selectedRole=="ROLE_MOD"&&
              <>
                <div className={"row text-danger d-flex justify-content-center"}>
              <div className={"col-12"}>Warning: You are about to make {firstName} {lastName} a moderator this gives them an inordinate amount of privilage...</div>
              </div>
            <div className={"row mt-2 text-danger d-flex justify-content-center"}> 
            <div className={"col-12 justify-content-center align-items-center d-flex"}>
              <input
                  type="checkbox"
                  value={confirmMod}
                  onChange={(e) =>{
                    
                    setConfirmMod(!confirmMod)
                  }
                  }
                  className="mr-3"
                  style={{ width: "1.75em", height: "1.75em" }}
                /> Please check to confirm this change
                </div>
                </div></>

              }
         
         
            <div className={"row mt-4"}>
            <div className={"col d-flex justify-content-center"}>
              <button onClick={()=>{
                handleEditRole((hasApprove&&hasDeny))
                setWarningModalIsOpen(false)
                setRoleModalIsOpen(false)
                }} className={"btn btn-success w-25 mx-3 box-shadow "}>
                Yes{(hasApprove&&hasDeny)&& " and Active"}
              </button>
              <button onClick={()=>{ 
                setWarningModalIsOpen(false)
                setRoleModalIsOpen(false)
              }} className={"btn btn-danger  w-25 mx-3 box-shadow"}>
                No
              </button>
            </div>
           
            </div>
          </div>
      </GenericModal>
    </tr>
  );
}
