import React from "react";
import ThemeBigCard from "../../components/ThemeBigCard";
import { GiSaveArrow } from "react-icons/gi";

const TopThemePage = props => {
  const {
    themePhoto,
    isNoteOpen,
    themeTitle,
    isRunningSession,
    onThemeCardClick,
  } = props;

  return (
    <>
      <div className={`d-flex justify-content-center w-100`}>
        <div
          // TODO this div tag might be redundant
          className={` pr-3 pl-2 ${
            isNoteOpen ? "w-75" : "w-100"
          }`}
        >
          <div className="container position-relative text-center">
            <img
              className="img-fluid  theme-image mb-3 shadow round-box"
              src={
                "https://d30mg0zmvh01j5.cloudfront.net/assets/photos/" +
                themeTitle.toUpperCase() +
                ".jpg"
              }
              alt=""
              style={{ filter: "brightness(75%)",maxHeight:700, width:"auto" }}
            />
            <h1
              className="position-absolute align-self-center text-white round-box box-shadow px-2"
              style={{ left: "50%", transform: "translateX(-50%)", bottom: "3%", backgroundColor:"rgb(110, 110, 110, 0.7)" }}
            >
              {themeTitle}
            </h1>
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="d-flex my-2">
          {/* <h3>Discussion and Sharing</h3> */}

          {/* {!isRunningSession ? (
            <button
              className="ml-2 bg-info btn-extra-radius border-0 px-3 text-white align-items-center"
              onClick={() => onThemeCardClick("addNote", "_", 0)}
            >
              <GiSaveArrow className="mr-2" />
              <span>Add Note</span>
            </button>
          ) : (
            <></>
          )} */}
        </div>
        {/* <ThemeBigCard isJavaTips={true} /> */}
      </div>
    </>
  );
};

export default TopThemePage;
