import React,  {useState,useEffect, useContext} from "react";
import GroupSummaryCard from "./GroupSummaryCard";
import { Link } from "react-router-dom";
import { useMyGroupsQuery, useMyManagedGroupsQuery } from "../../graphql/generated/graphql.tsx";
import LoadingSpinner from "../LoadingSpinner";
import { UserContext } from "../../context/UserContext";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import ErrorBoundary from "../ErrorBoundary";

export default function Manage() {
  const { loading, error, data: myGroupsData } = useMyGroupsQuery();
  const { loading: myManagedGroupsLoading, error: myManagedGroupsError, data: myManagedGroups } = useMyManagedGroupsQuery();
  const [myGroups,setMyGroups] = useState([])
  const [isFiltered,setIsFiltered] = useState(false)  
  const [ready,setReady] = useState(false)  
  const { state } = useContext(UserContext);
  const [maxCount, setMaxCount] = useState(null)
  // const [reRender,setReRender] = useState(false)
  // useEffect(() => {
  //   setReRender(true)
    
  //   return () => {
  //     setReRender(false)
  //   }
  // }, [])
  useEffect(() => {
    if(myManagedGroups && myManagedGroups.myManagedGroups){
      setMaxCount(myManagedGroups.myManagedGroups.length)
      
    }
    if(myManagedGroups && myManagedGroups.myManagedGroups && isFiltered){
      setMyGroups(myManagedGroups.myManagedGroups)
      setReady(true)
      
    }
    else if(myGroupsData&&myGroupsData.myGroups){
      console.log(myGroupsData)
      setMyGroups(myGroupsData.myGroups)
      setReady(true)

    }
   
  }, [myGroupsData, myManagedGroups, isFiltered])
  if (loading || ready===false) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-between my-4">
        <h4 className="">{isFiltered?"My Managed Groups":"My Groups"}</h4>

        {ready&& (!myManagedGroups || maxCount<5) &&
        <Link className="btn btn-primary btn-extra-radius px-5" to="/group/new">
          Add Group
        </Link>}
        {myManagedGroups &&  maxCount>4 &&
        <button onClick={()=>{toast.error("You have reached your group limit.")}} className="disabled btn btn-primary btn-extra-radius px-5" >
          Add Group
        </button>}
      </div>
      <div className="row d-flex bg-zero border useBorder py-5 px-2 round-box box-shadow flex-row justify-content-center">
        {ready && (isFiltered?!myManagedGroupsLoading:!loading) && myGroups.length > 0 &&
         <>
         <div className="btn-group w-100  d-flex flex-row justify-content-center">
         <button className={`btn btn-info ${isFiltered?"btn-outline-info bg-cleard text-white ":""} `} onClick={()=>setIsFiltered(false)}>Invited</button>
         <button className={`btn btn-info ${!isFiltered?"btn-outline-info bg-cleard text-white ":""} `} onClick={()=>setIsFiltered(true)}>Managed { maxCount&&maxCount+"/5"}</button>
 </div>
<ErrorBoundary>
  
          {isFiltered && myGroups.map(group => (
            <GroupSummaryCard
              key={group.id}
              clickableCard={true}
              group={group}
            />
          ))}
</ErrorBoundary>
<ErrorBoundary>

          {myGroups.filter(group=> group.owner.id !== state.user.id).map(group => (
            <GroupSummaryCard
              key={group.id}
              clickableCard={true}
              group={group}
            />
          ))}
</ErrorBoundary>

         </>
          }
        {(isFiltered?!myManagedGroupsLoading:!loading) && !myGroups.length > 0 && (
          <div className={"d-flex mt-5 p-3 align-self-center justify-content-center"}>
            <div className={"col col-12 m-4 p-3 round-box box-shadow align-self-center"}>
              <div className={""}>
                <h2 className={"text-center"}>Hey Rockstar!</h2>
              </div>
              <div>
                <h4 className={"text-center"}>
                  It looks like you're not linked to any groups.
                </h4>
              </div>
              <div>
                <h4 className={"text-center"}>
                That's okay, we can make one!
                </h4>
              </div>
              <div>
                <h4 className={"text-center"}>
                
                </h4>
              </div>
              <div>
                <h4 className={"text-center"}>
                 Click add group or on this here
                  plus sign below.
                </h4>
              </div>
              <div className={"row d-flex align-items-center justify-content-center "}>
                <Link
                style={{
                  height:50,
                  width:50,
                  
                }}
                  className="btn align-items-center shadow justify-content-center  position-relative btn btn-extra-radius btn-outline-primary p-0 mb-1 btn-extra-radius "
                  to="/group/new"
                >
                         <FaPlus size={30} style={{bottom:"50%",left:"50%", transform: "translateX(-50%) translateY(50%)",  }} className="position-absolute" />
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
