import React from "react";

export default function SessionHighlightsCard(props) {
  const { session } = props;
  return (
    <div className="container border useBorder round-box">
      <div
        className="row py-2 bg-clearl"
        style={{  borderRadius: "15px 15px 0 0" }}
      >
        <h5 className="text-center w-100">Session Highlight</h5>
      </div>

      <div className="row my-3 px-2">
        Here are some things that the group experienced today.
      </div>

      <div>Quotes</div>
      <div className="row mb-3">
        {session.theme.quotes.map(quote => (
          <span
            key={quote.id}
            style={{ background: "#00eaff" }}
            className="btn btn-sm btn-extra-radius py-0 m-2"
          >
            {quote.author}
          </span>
        ))}
      </div>

      <div>Photo for Discussion</div>
      <div className="row mb-3">
        <span
          style={{ background: "#a6ff9e" }}
          className="btn btn-sm btn-extra-radius py-0 m-2"
        >
          Theme Photo
        </span>
        {session.theme.sensoryCues.map(cue => (
          <span
            key={cue.id}
            style={{ background: "#a6ff9e" }}
            className="btn btn-sm btn-extra-radius py-0 m-2"
          >
            {cue.title}
          </span>
        ))}
      </div>

      <div>Songs</div>
      <div className="row mb-3">
        {session.theme.songs.map((song, i) => (
          <span
            key={i}
            style={{ background: "#d79eff" }}
            className="btn btn-sm btn-extra-radius py-0 m-2"
          >
            {song.title}
          </span>
        ))}
      </div>
    </div>
  );
}
