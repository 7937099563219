import React,{useContext} from "react";
import { UserContext } from "../../../../../context/UserContext";
import ThemeSharingCard from "../../components/ThemeSharingCard";
import { GiSaveArrow } from "react-icons/gi";

import sharing from "./sharing.json"
import { FaHandPaper } from "react-icons/fa";

const Sharing = props => {
  const { isRunningSession, onThemeCardClick,currentPage, userList } = props;
  const { state } = useContext(UserContext);
  const sharings = [
    {
        "id":1,
        "content": "Now we will move on to sharing."
    },
    {
        "id":2,
        "content": "Is there anyone who did not get a chance to share last week that would like to share now? "
    },
    {
        "id":3,
        "content": "Or does anyone have a desire to share first? If you would like to volunteer please click the raise hand button."
    }
]
  return (
    <>
      <div className="d-flex">
        <h3>{state.user.role == "ROLE_FACILITATOR"&& (`${currentPage+3}.`)} Sharing</h3>

        {!isRunningSession ? (
          <button
            className="ml-2 bg-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 2)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
        )}
      </div>
      {sharings.map(sharing => (
        <ThemeSharingCard
          key={sharing.id}
          sharing={sharing.content}
          onThemeCardClick={() => onThemeCardClick("sharing", `${sharing.id}`)}
        />
      ))}
    {userList && userList.filter(u=> u.palamiState == true).length >0 &&
      <div className={`round-box box-shadow col bg-dark col-6 d-flex flex-column justify-content-center border useBorder`}>
      <div className={`row`} >
        <h6  className={`col col-12 mt-2  d-flex justify-content-center `}>

        Volunteered to share:
        </h6>
        </div>
         <div className={`row d-flex justify-content-center `} >
      {userList.filter(u=> u.palamiState == true).map(user=>{
        return(<div className={`round-box bg-clear box-shadow mx-1 my-2 py-3 col col-6 d-flex justify-content-center align-content-center border useBorder`}>
          <span className={`mx-3 my-auto round-box`}>
          {user.name.split(" ")[0]}  {user.name.split(" ")[1][0]}.
          </span> 
          <h6 className={`mx-3 bg-info my-auto  round-box py-2 px-3`}>
            <FaHandPaper />
          </h6> 
        </div>)
      })}</div>
      </div>
    }
    </>
  );
};

export default Sharing;
