import React, { useEffect, useState, useContext } from "react";
import TopThemePage from "./sections/TopThemePage";
import DiscussionQuestions from "./sections/DiscussionQuestions";
import Reflection from "./sections/Reflection";
import Quotes from "./sections/Quotes";
import SensoryCues from "./sections/ThemePhoto";
import Songs from "./sections/Songs";
import {
  useEditRunningSessionMutation,
  useFollowSessionQuery,
} from "../../../graphql/generated/graphql";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import GenericModal from "../../GenericModal";
import ThemeModalContent from "./ThemeModalContent";
import CommentBox from "../../CommentBox";
import { UserContext } from "../../../context/UserContext";
import { useLocation } from "react-router-dom";
import Sharing from "./sections/Sharing";

const RunningSessionThemeContainer = props => {
  const {
    sessionId,
    data,
    zoomData,
    handleGoToSessionPage,
    currentPage,
    modalSync,
    isSessionMaster,
    isSyncOn,
    setIsSilent,
    previousModalContent,
    setPreviousModalContent,
    playedSongs, setPlayedSongs,
    userList
  } = props;

  const [modalContent, setModalContent] = useState({
    content: "",
    id: "",
    position: "",
  });

  const { state } = useContext(UserContext);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  


  const [isNoteOpen, setIsNoteOpen] = useState(false);

  // Mutation to update the session status and currentScreen
  const [editRunningSession] = useEditRunningSessionMutation();

  const onThemeCardClick = (content, id, position, songUrl) => {
    setModalIsOpen(true);
    setModalContent({ content, id, position });

    if (content === "song") {
      // in the theme songs page, those of songs that have been played need to be grayed out. by using object, we keep track of only an unique id of played song.
      setPlayedSongs({
        ...playedSongs,
        [id]: true,
      });
    }

    setPreviousModalContent({
      page: currentPage,
      contentType: content,
      contentId: id,
    });

    if (isSessionMaster && isSyncOn) {
      // call mutation to sync which modal is open
      editRunningSession({
        variables: {
          id: sessionId,
          contentType: content,
          contentId: id,
          songUrl: songUrl,
        },
      });
    }
  };

  useEffect(() => {
    if (!isSessionMaster) {
      if (
        modalSync.contentType !== modalContent.content ||
        modalSync.contentId !== modalContent.id
      ) {
        // open modal according to the content in the modal sync object
        onThemeCardClick(modalSync.contentType, modalSync.contentId);
      }
    }
  }, [modalSync, isSyncOn]);

  useEffect(() => {
    if (isSessionMaster && isSyncOn) {
      if (!modalIsOpen) {
        // call mutation to clear the modal stuff
        setIsSilent(false)
        editRunningSession({
          variables: {
            id: sessionId,
            songPlaying: "",
            contentType: "",
            contentId: "",
            songUrl: "",
          },
        });
      }
    }
  }, [modalIsOpen, isSyncOn]);

  useEffect(() => {
    if (zoomData.viewMode !== "fullscreen" && isNoteOpen) {
      setIsNoteOpen(false);
    }
  }, [zoomData]);

  // stores when the song is stored. this will be used in music controller to play music in member side as soon as the session master plays a song.
  const [songLocation, setSongLocation] = useState("");

  // used to play music when the session master(primary facilitator) plays music
  useEffect(() => {
    if (!isSessionMaster) {
      const playingSong = modalSync.songPlaying;
      if (playingSong !== "") {
        setIsSilent(true)
        // console.log("Song Playing:", modalSync.songPlaying);
        // console.log("Location", modalSync.songUrl);
        setSongLocation(modalSync.songUrl);
      } else {
        setIsSilent(false)
        // console.log("Song Stop !!!");
        setSongLocation("");
      }
    }
  }, [modalSync.songPlaying]);
// console.log(currentPage)
  // update running session when the session master(primary facilitator) plays/stops music
  const handleMusicPlay = (isMusicPlaying, title) => {
    if (isSessionMaster) {
      if (isMusicPlaying) {
        setPreviousModalContent({
          ...previousModalContent,
          ["songPlaying"]: title,
        });
        // facilitator clicked the play button
        if(title!=="open modal"&&title!== "close modal"){
          // console.log("I'm the one who knocks")
          setIsSilent(true)
        }
        editRunningSession({
          variables: {
            id: sessionId,
            songPlaying: title,
          },
        });
      } else {
        // facilitator stops the music
        setIsSilent(false)
        editRunningSession({
          variables: {
            id: sessionId,
            songPlaying: "",
          },
        });
      }
    }
  };

  return (
    <div className="theme-container mt-3">
    {state.user.role == "ROLE_FACILITATOR"&&(  <button
        style={{
          transition: ".5s all ease-in-out",
          top: "105px",
          right: "1vw",
          zIndex: 1,
        }}
        className={`notes-button position-fixed btn-extra-radius btn ${
          isNoteOpen ? "btn-danger px-4 px-lg-5" : "btn-info"
        }`}
        onClick={() => setIsNoteOpen(!isNoteOpen)}
      >
        <div className="d-flex justify-content-center align-items-center">
          {isNoteOpen ? (
            <>
              <MdKeyboardArrowRight
                className="d-flex"
                style={{ fontSize: "1.5em" }}
              />
              <span style={{ fontSize: ".9em" }}>Hide notes</span>
            </>
          ) : (
            <>
              <MdKeyboardArrowLeft
                className="d-flex"
                style={{ fontSize: "1.5em" }}
              />
              <span style={{ fontSize: ".9em" }}>Show notes</span>
            </>
          )}
        </div>
      </button>)}

      <div className={`note-transition d-flex flex-column position-relative ${isNoteOpen ? "w-75" : "w-100"}`}>
         {data.program == "music" &&( 
        <div className="mb-5">
          {/**************************** MAIN CONTENT COMES HERE ****************************/}
          
           {currentPage == 5 && (
            <TopThemePage  themeTitle={data.name} isRunningSession={true} />
          )}

          {currentPage == 6 && (
            <DiscussionQuestions
            style={{minHeight: 9000}}
              discussions={data.discussions}
              isRunningSession={true}
              onThemeCardClick={console.log}
            />
          )}

          {currentPage == 7 && (
            <Quotes
              quotes={data.quotes}
              isRunningSession={true}
              onThemeCardClick={onThemeCardClick}
            />
          )}

          {currentPage == 8 && (
            <SensoryCues
              themePhoto={data.imageUrl}
              isRunningSession={true}
              onThemeCardClick={onThemeCardClick}
            />
          )}

          {currentPage == 9 && (
            <Songs
              songPlaying={modalSync.songPlaying}
              isRunningSession={true}
              onThemeCardClick={onThemeCardClick}
              playedSongs={playedSongs}
              songs={data.songs}
              role={state.user.role}
            />
          )}
        </div>
         )}
         {data.program == "reflections" &&( 
        <div className="mb-5">
          {/**************************** MAIN CONTENT COMES HERE ****************************/}
          

          {currentPage ==7 && (
            <Quotes
            quotes={data.quotes}
            currentPage={currentPage}
            isRunningSession={true}
            onThemeCardClick={onThemeCardClick}
            />
            )}
          {currentPage == 8 && (
            <Reflection
            style={{minHeight: 9000}}
            reflection={data.reflection}
            isRunningSession={true}
            onThemeCardClick={console.log}
            />
            )}

          {currentPage ==9 && (
            <Sharing
            currentPage={currentPage}
            isRunningSession={true}
            onThemeCardClick={onThemeCardClick}
            userList={userList}
            />
            )}

            {currentPage == 10 && (
              <DiscussionQuestions
              style={{minHeight: 9000}}
              discussions={data.discussions}
              currentPage={currentPage}
              isRunningSession={true}
              onThemeCardClick={console.log}
              />
              )}


        </div>
         )}
{currentPage !== 6 && 
        <GenericModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
          <ThemeModalContent
            songPlaying={modalSync.songPlaying}
            modalContent={modalContent}
            theme={data}
            onThemeCardClick={onThemeCardClick}
            playedSongs={playedSongs}
            setModalIsOpen={setModalIsOpen}
            themeName={data.name}
            handleMusicPlay={handleMusicPlay}
            isSessionMaster={isSessionMaster}
            songLocation={songLocation}
          />
        </GenericModal>}
        <div
          style={{
            overflow: isNoteOpen ? "scroll" : "hidden",
            height: "100vh",
            right: 0,
            top: 0,
          }}
          className={`pb-5 position-fixed note-transition ${
            isNoteOpen ? "w-25 pr-3" : "w-0"
          }`}
        >
          <div className="d-flex flex-column justify-content-center">
            <div className="w-100" style={{ height: "150px" }}>
              {/*
                as we do not want to render the Java tips under note toggled btn, the space needs to be filled with
                an empty box
              */}
            </div>

            {data.javaTips.map(item => {

              return (
                <CommentBox
                  key={item.id}
                  content={item.body}
                  author={item.author}
                  isSessionNote={item.author != null}
                  noteId={item.id}
                  isRunningSession={true}
                />
              );
            })}
          </div>
        </div>

        {state.user.role === "ROLE_FACILITATOR" && (
          <div style={{bottom:0}} className="d-flex position-absolute px-0 align-items-center mt-auto mb-2 col col-12 ">
            <button
              onClick={handleGoToSessionPage.prev}
              className="btn btn-primary py-1 px-5 ml-5 mr-auto  font-size-2 shadow"
            >
              Prev
            </button>
            <button
              onClick={handleGoToSessionPage.next}
              className="btn btn-primary  py-1 px-5 ml-auto mr-5 font-size-2 shadow"
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RunningSessionThemeContainer;
