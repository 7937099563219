import React, { useRef, useEffect, useState, useContext } from "react";

import { FaAngleDown,FaArrowAltCircleDown,FaArrowAltCircleUp, FaAngleUp } from "react-icons/fa";
import useWindowSize from "./../../hooks/useWindowSize";

import CommentBox from "../CommentBox";

import { UserContext } from "../../context/UserContext";
import GenericModal from "../GenericModal";
import { toast } from "react-toastify";
import { CgArrowDownR } from "react-icons/cg";

export default function SectionContainer(props) {
  const {
    title,
    content,
    hasTips,
    tips,
    currentPage,
    handlePrev,
    handleNext,
    zoomData,
    handleGoToPage,
    handleMusicPlay,
    modalSync,
    isSessionMaster,
    isReflections
  } = props;

  const windowSize = useWindowSize();

  const { state } = useContext(UserContext);
  const [contentModal, setContentModal] = useState(false)
  const [showScroll, setShowScroll] = useState(false)
  const [clientHeight, setClientHeight] = useState(0)

  const scrollableDivRef = useRef(null);
const getBehaviour = () => {
  if(!isReflections ){
    if(currentPage!==2 && currentPage!==12 ){
      return true
    }else{
      return false
    }
  }else{
    if(currentPage!==14 ){
      return true
    }else{
      return false
    }

  }
}
  const handleModal = ()=>{
if(getBehaviour()){    handleMusicPlay(!contentModal, "open modal")
    setContentModal(true)}

  }
  useEffect(() => {
    if (!isSessionMaster) {

      if (modalSync.contentType !== "" && modalSync.contentType !== " " && modalSync.contentType !== "silence") {
        if(modalSync.contentType == "open modal"){
          console.log("content type: "+modalSync.contentType)
          
          setContentModal(true)
        }
        else if(modalSync.contentType == "close modal"){
         
          setContentModal(false)
        }
      }
    }
  }, [modalSync.contentType]);
  useEffect(() => {
    if (!contentModal &&getBehaviour()) {
      if(currentPage==1){
        
      }
       handleMusicPlay(true, "close modal")
    }
  }, [contentModal]);
  const scrollToTop = () => {
    if (scrollableDivRef && scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({ behavior: "smooth", top: 0 });
    }
  };
  const handleScrollDown = () => {
    // console.log(scrollableDivRef)
    if (scrollableDivRef && scrollableDivRef.current) {
      //console.log("poop++")
     scrollableDivRef.current.scrollTo({ behavior: "smooth", top: scrollableDivRef.current.scrollTop+150 });
      ///console.log(scrollableDivRef.current.scrollTop/scrollableDivRef.current.clientHeight)
    }
  };
  const handleScrollUp = () => {
    console.log("poop")
    if (scrollableDivRef && scrollableDivRef.current) {
      //console.log("poop++")
     scrollableDivRef.current.scrollTo({ behavior: "smooth", top: scrollableDivRef.current.scrollTop-150 });
      //console.log(scrollableDivRef.current.scrollTop/scrollableDivRef.current.clientHeight)
    }
  };
  
console.log(window.innerHeight)
  useEffect(() => {
    scrollToTop();
  }, [currentPage]);
  useEffect(() => {
    if (scrollableDivRef && scrollableDivRef.current) {
      setClientHeight(scrollableDivRef.current.children[0].children[0].clientHeight)
     // console.log(clientHeight)
      // console.log(window.innerHeight)
      // console.log(scrollableDivRef.current.children[0].children[0].clientHeight)
    }
  }, [scrollableDivRef,window.innerHeight]);
  useEffect(() => {
    if (scrollableDivRef && scrollableDivRef.current) {
     if( clientHeight > (window.innerHeight*.75)){
      setShowScroll(true)
     }else{
       setShowScroll(false)
     }
     
    }
  }, [clientHeight,window.innerHeight]);

  if (zoomData.showZoomContainer && zoomData.viewMode === "vertical") {
    return (
      <div className="section-panel">
        {/* Height of global navbar */}
        <div style={{ minHeight: "56px" }}></div>
        {/* Height of running session navbar */}
        <div style={{ minHeight: "34px" }}></div>

        <div className="row mx-auto">
          {handlePrev && (
            <div className="col-1 text-center d-flex align-items-center justify-content-center">
              <FaAngleUp
                role="button"
                onClick={handlePrev}
                className="d-flex mutilsection-container-arrow"
              />
            </div>
          )}
          {!handlePrev && <div className="col-1" />}

          <main
            className="col-10"
            style={{
              overflowY: "auto",
              maxHeight: `${
                windowSize.height - 90 - windowSize.height * 0.6
              }px`,
            }}
          >
            <div className="row mx-auto">
              <div className={`${hasTips ? "col-8" : "col-12"} row mx-auto`}>
                <h2 className="mb-4 mt-3">
                  {currentPage+2}. {title}
                </h2>
                <div className="mutilsection-container-content w-100">
                  {content && content}
                </div>
              </div>
              <div
                className={`tips-panel ${
                  hasTips ? "col-4" : "col-8"
                } d-flex flex-column justify-content-center`}
              >
                {hasTips &&
                  tips &&
                  tips.map(tip => (
                    <CommentBox isTip={true} key={tip.id} content={tip.tip} />
                  ))}
              </div>
            </div>
          </main>

          {handleNext && (
            <div className="col-1 text-center d-flex align-items-center justify-content-center">
              <FaAngleDown
                onClick={handleNext}
                role="button"
                className="d-flex mutilsection-container-arrow"
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="section-panel">
      <div className="h-100 d-flex flex-column">
        {/* Height of global navbar */}
        <div style={{ minHeight: "56px" }}></div>
        {/* Height of running session navbar */}
        <div style={{ minHeight: "34px" }}></div>
        <main
          ref={scrollableDivRef}
          onClick={handleModal}
          style={{ overflowY: "auto", maxHeight: "100%" }}
          className={`${getBehaviour()?"round-box box-shadow border useBorder px-1 pb-4":""}  mt-4 `}
        >
          <div className="row mx-auto">
            <div className={`${hasTips ? "col-8" : "col-12"} row mx-auto`}>
              <h2 className="mb-4 mt-3">
              {state.user.role== "ROLE_FACILITATOR"&& `${currentPage +3}.`}  {title}
              </h2>
              <div className="mutilsection-container-content w-100">
                {content && content}
              </div>
            </div>
            <div
              className={`tips-panel ${
                hasTips ? "col-4" : "col-8"
              } d-flex flex-column justify-content-center`}
            >
              {hasTips &&
                tips &&
                tips.map(tip => (
                  <CommentBox isTip={true} key={tip.id} content={tip.tip} />
                ))}
            </div>
          </div>
        </main>
{getBehaviour() &&
<GenericModal
modalIsOpen={contentModal}
setModalIsOpen={
  setContentModal
  }
>
<main
 className={`${getBehaviour()?"round-box box-shadow border useBorder px-1 pb-4":""}`}
         
          style={{ overflowY: "auto", maxHeight: "100%" }}
        >
          <div className="row mx-auto">
            <div className={`${hasTips ? "col-8" : "col-12"} row mx-auto`}>
              <h2 className="mb-4 mt-3">
               {state.user.role== "ROLE_FACILITATOR"&& `${currentPage +3}.`} {title}
              </h2>
              <div className="mutilsection-container-content w-100">
                {content && content}
              </div>
            </div>
            <div
              className={`tips-panel ${
                hasTips ? "col-4" : "col-8"
              } d-flex flex-column justify-content-center`}
            >
              {hasTips &&
                tips &&
                tips.map(tip => (
                  <CommentBox isTip={true} key={tip.id} content={tip.tip} />
                ))}
            </div>
          </div>
        </main>
</GenericModal>
}

{state.user.role === "ROLE_MEMBER" && showScroll == true && (
  <div className="text-center d-flex pt-3 align-items-center mt-auto mb-2">
     <button
   className="btn btn-primary py-1 px-5 ml-5  mr-auto font-size-2 shadow"
   onClick={handleScrollDown}
 >
   <FaArrowAltCircleDown/>
   {" "}
   scroll
 </button>
     <button
   className="btn btn-primary py-1 px-5 ml-auto mr-5 font-size-2 shadow"
   onClick={handleScrollUp}
 >
   <FaArrowAltCircleUp/>
   {" "}
   scroll
 </button>
  </div>
)}
        {state.user.role === "ROLE_FACILITATOR" && (
          <div className="text-center d-flex align-items-center mt-auto mb-2">
            {handlePrev && (
              <button
                className="btn btn-primary py-1 px-5 ml-5 mr-auto font-size-2 shadow"
                onClick={handlePrev}
              >
                Prev
              </button>
            )}
            {!handlePrev && (
              <button
                className="btn btn-primary py-1 px-5 ml-5 mr-auto font-size-2 shadow"
                onClick={() => handleGoToPage.prev()}
              >
                Prev
              </button>
            )}

            {handleNext && (
              <button
                className="btn btn-primary py-1 px-5 ml-auto mr-5 font-size-2 shadow"
                onClick={handleNext}
              >
                Next
              </button>
            )}
            {!handleNext && (
              <button
                className="btn btn-primary py-1 px-5 ml-auto mr-5 font-size-2 shadow"
                onClick={() => handleGoToPage.next()}
              >
                Next
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
