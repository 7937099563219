import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type using Date ISO String format */
  DateISO: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userByName?: Maybe<User>;
  userByFirstName?: Maybe<User>;
  usersByFuzzyName?: Maybe<Array<Maybe<User>>>;
  users?: Maybe<Array<Maybe<User>>>;
  subUsers?: Maybe<Array<Maybe<User>>>;
  answer?: Maybe<Answer>;
  answerList?: Maybe<Array<Maybe<Answer>>>;
  questionnaire?: Maybe<Questionnaire>;
  questionnaireUserSession?: Maybe<Questionnaire>;
  questionnaires?: Maybe<Array<Maybe<Questionnaire>>>;
  checkQuestionnaireExists?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Group>;
  groups?: Maybe<Array<Maybe<Group>>>;
  publicGroups?: Maybe<Array<Maybe<Group>>>;
  groupsByTag?: Maybe<Array<Maybe<Group>>>;
  myGroups?: Maybe<Array<Maybe<Group>>>;
  myManagedGroups?: Maybe<Array<Maybe<Group>>>;
  myRequests?: Maybe<Array<Maybe<Group>>>;
  community?: Maybe<Community>;
  communities?: Maybe<Array<Maybe<Community>>>;
  resource?: Maybe<Resource>;
  resources?: Maybe<Array<Maybe<Resource>>>;
  theme?: Maybe<Theme>;
  themes?: Maybe<Array<Maybe<Theme>>>;
  themePhotos?: Maybe<Array<Maybe<Theme>>>;
  song?: Maybe<Song>;
  songs?: Maybe<Array<Maybe<Song>>>;
  session?: Maybe<Session>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  closeSessions?: Maybe<Array<Maybe<Session>>>;
  futureSessions?: Maybe<Array<Maybe<Session>>>;
  myFutureSessions?: Maybe<Array<Maybe<Session>>>;
  amIApproved?: Maybe<Scalars['Boolean']>;
  me?: Maybe<User>;
  checkPasswordExist?: Maybe<Scalars['Boolean']>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  unseenNotifications?: Maybe<Array<Maybe<Notification>>>;
  errorLogs?: Maybe<Array<Maybe<ErrorLog>>>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserByNameArgs = {
  first?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
};


export type QueryUserByFirstNameArgs = {
  firstName?: Maybe<Scalars['String']>;
};


export type QueryUsersByFuzzyNameArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryAnswerArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type QueryQuestionnaireUserSessionArgs = {
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage?: Maybe<Scalars['String']>;
};


export type QueryCheckQuestionnaireExistsArgs = {
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGroupsByTagArgs = {
  tags: Array<Maybe<Scalars['String']>>;
};


export type QueryCommunityArgs = {
  id: Scalars['ID'];
};


export type QueryResourceArgs = {
  id: Scalars['ID'];
};


export type QueryThemeArgs = {
  id: Scalars['ID'];
};


export type QuerySongArgs = {
  id: Scalars['ID'];
};


export type QuerySessionArgs = {
  id: Scalars['ID'];
};


export type QueryCloseSessionsArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFutureSessionsArgs = {
  nth: Scalars['Int'];
};


export type QueryMyFutureSessionsArgs = {
  nth: Scalars['Int'];
};


export type QueryAmIApprovedArgs = {
  session: Scalars['ID'];
};


export type QueryCheckPasswordExistArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  addUser?: Maybe<User>;
  editUser?: Maybe<User>;
  addProfilePic?: Maybe<User>;
  editRole?: Maybe<User>;
  deleteUser?: Maybe<User>;
  addAnswer?: Maybe<Questionnaire>;
  editAnswer?: Maybe<Questionnaire>;
  deleteAnswer?: Maybe<Questionnaire>;
  removeAnswer?: Maybe<Questionnaire>;
  addQuestionnaire?: Maybe<Questionnaire>;
  editQuestionnaire?: Maybe<Questionnaire>;
  deleteQuestionnaire?: Maybe<Questionnaire>;
  addNoteForUser?: Maybe<User>;
  editNoteFromUser?: Maybe<User>;
  deleteNote?: Maybe<User>;
  removeNoteFromUser?: Maybe<User>;
  addGroup?: Maybe<Group>;
  editGroup?: Maybe<Group>;
  deleteGroup?: Maybe<Group>;
  addUserToGroup?: Maybe<Group>;
  addTagToGroup?: Maybe<Group>;
  removeUserFromGroup?: Maybe<Group>;
  removeTagFromGroup?: Maybe<Group>;
  denyRequest?: Maybe<Group>;
  requestInvite?: Maybe<Group>;
  addCommunity?: Maybe<Community>;
  editCommunity?: Maybe<Community>;
  deleteCommunity?: Maybe<Community>;
  addUserToCommunity?: Maybe<Community>;
  removeUserFromCommunity?: Maybe<Community>;
  addElement?: Maybe<Resource>;
  addResource?: Maybe<Resource>;
  editResource?: Maybe<Resource>;
  deleteResource?: Maybe<Resource>;
  addTheme?: Maybe<Theme>;
  editTheme?: Maybe<Theme>;
  deleteTheme?: Maybe<Theme>;
  addSongToTheme?: Maybe<Theme>;
  removeSongFromTheme?: Maybe<Theme>;
  addQuote?: Maybe<Theme>;
  editQuoteInTheme?: Maybe<Theme>;
  removeQuoteFromTheme?: Maybe<Theme>;
  addSong?: Maybe<Song>;
  editSong?: Maybe<Song>;
  deleteSong?: Maybe<Song>;
  addQuoteToTheme?: Maybe<Theme>;
  editQuote?: Maybe<Theme>;
  deleteQuote?: Maybe<Theme>;
  removeQuote?: Maybe<Theme>;
  addDiscussionToTheme?: Maybe<Theme>;
  editDiscussionFromTheme?: Maybe<Theme>;
  deleteDiscussion?: Maybe<Theme>;
  removeDiscussion?: Maybe<Theme>;
  addReflectionToTheme?: Maybe<Theme>;
  editReflectionFromTheme?: Maybe<Theme>;
  deleteReflection?: Maybe<Theme>;
  removeReflection?: Maybe<Theme>;
  addJavaTipToTheme?: Maybe<Theme>;
  editJavaTip?: Maybe<Theme>;
  deleteJavaTip?: Maybe<JavaTip>;
  removeJavaTip?: Maybe<Theme>;
  addSensoryCueToTheme?: Maybe<Theme>;
  editSensoryCue?: Maybe<Theme>;
  deleteSensoryCue?: Maybe<SensoryCue>;
  removeSensoryCue?: Maybe<SensoryCue>;
  addSession?: Maybe<Session>;
  addSessions?: Maybe<Session>;
  editSession?: Maybe<Session>;
  deleteSession?: Maybe<Session>;
  handoverMaster?: Maybe<Session>;
  addUserToSession?: Maybe<Session>;
  removeUserFromSession?: Maybe<Session>;
  changeTheme?: Maybe<Session>;
  changeDateTime?: Maybe<Session>;
  addUserToWaitingRoom?: Maybe<Session>;
  removeUserFromWaitingRoom?: Maybe<Session>;
  editRunningSession?: Maybe<Session>;
  startSession?: Maybe<Session>;
  stopSession?: Maybe<Session>;
  addMemberFeedback?: Maybe<Session>;
  editSessionMemberRole?: Maybe<Session>;
  editSessionMemberMuteState?: Maybe<Session>;
  editAllMuteState?: Maybe<Session>;
  editSessionMemberVidState?: Maybe<Session>;
  editSessionMemberPalamiState?: Maybe<Session>;
  editSessionMemberRoomState?: Maybe<Session>;
  editAllRoomState?: Maybe<Session>;
  editAllVidState?: Maybe<Session>;
  leaveSession?: Maybe<Scalars['Boolean']>;
  login?: Maybe<LoginResponse>;
  resetPassword?: Maybe<ResetResponse>;
  sendResetEmail?: Maybe<Scalars['Boolean']>;
  revokeRefreshTokenForUser?: Maybe<Scalars['Boolean']>;
  switchUser?: Maybe<LoginResponse>;
  logout?: Maybe<Scalars['Boolean']>;
  changePassword?: Maybe<Scalars['Boolean']>;
  changePasswordByReset?: Maybe<Scalars['Boolean']>;
  addNotification?: Maybe<Notification>;
  editNotification?: Maybe<Notification>;
  markSeenNotifications?: Maybe<Scalars['Boolean']>;
  deleteNotification?: Maybe<Notification>;
  addErrorLog?: Maybe<ErrorLog>;
  addUserMessageToErrorLog?: Maybe<ErrorLog>;
};


export type MutationAddUserArgs = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateISO']>;
  optOut?: Maybe<Scalars['Boolean']>;
};


export type MutationEditUserArgs = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateISO']>;
  optOut?: Maybe<Scalars['Boolean']>;
};


export type MutationAddProfilePicArgs = {
  pic: Scalars['Upload'];
};


export type MutationEditRoleArgs = {
  id: Scalars['ID'];
  role: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationAddAnswerArgs = {
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  number: Scalars['Int'];
  body: Scalars['String'];
  value: Scalars['Int'];
};


export type MutationEditAnswerArgs = {
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
  number: Scalars['Int'];
  body: Scalars['String'];
  value: Scalars['Int'];
};


export type MutationDeleteAnswerArgs = {
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
};


export type MutationRemoveAnswerArgs = {
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
};


export type MutationAddQuestionnaireArgs = {
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  optOut: Scalars['Boolean'];
  isDone?: Maybe<Scalars['Boolean']>;
};


export type MutationEditQuestionnaireArgs = {
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  optOut?: Maybe<Scalars['Boolean']>;
  isDone?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type MutationAddNoteForUserArgs = {
  author: Scalars['ID'];
  user: Scalars['ID'];
  content: Scalars['String'];
};


export type MutationEditNoteFromUserArgs = {
  id: Scalars['ID'];
  user: Scalars['ID'];
  content: Scalars['String'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['ID'];
  user: Scalars['ID'];
};


export type MutationRemoveNoteFromUserArgs = {
  id: Scalars['ID'];
  user: Scalars['ID'];
};


export type MutationAddGroupArgs = {
  name: Scalars['String'];
  startDate: Scalars['String'];
  frequency: Scalars['String'];
  room: Scalars['String'];
  program: Scalars['String'];
  isPublic: Scalars['Boolean'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationEditGroupArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateISO']>;
  frequency?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};


export type MutationAddUserToGroupArgs = {
  user: Scalars['ID'];
  as: Scalars['String'];
  group: Scalars['ID'];
};


export type MutationAddTagToGroupArgs = {
  tag: Scalars['String'];
  group: Scalars['ID'];
};


export type MutationRemoveUserFromGroupArgs = {
  user: Scalars['ID'];
  as: Scalars['String'];
  group: Scalars['ID'];
};


export type MutationRemoveTagFromGroupArgs = {
  tag: Scalars['String'];
  group: Scalars['ID'];
};


export type MutationDenyRequestArgs = {
  user: Scalars['ID'];
  group: Scalars['ID'];
};


export type MutationRequestInviteArgs = {
  user: Scalars['ID'];
  group: Scalars['ID'];
};


export type MutationAddCommunityArgs = {
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  maxUsers: Scalars['Int'];
  maxGroups: Scalars['Int'];
};


export type MutationEditCommunityArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  maxUsers?: Maybe<Scalars['Int']>;
  maxGroups?: Maybe<Scalars['Int']>;
};


export type MutationDeleteCommunityArgs = {
  id: Scalars['ID'];
};


export type MutationAddUserToCommunityArgs = {
  id: Scalars['ID'];
  as: Scalars['String'];
  User: Scalars['ID'];
};


export type MutationRemoveUserFromCommunityArgs = {
  id: Scalars['ID'];
  as: Scalars['String'];
  User: Scalars['ID'];
};


export type MutationAddElementArgs = {
  id: Scalars['ID'];
  tag: Scalars['String'];
  content: Scalars['String'];
};


export type MutationAddResourceArgs = {
  category: Scalars['String'];
  thumb: Scalars['String'];
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  link: Scalars['String'];
};


export type MutationEditResourceArgs = {
  id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  link?: Maybe<Scalars['String']>;
};


export type MutationDeleteResourceArgs = {
  id: Scalars['ID'];
};


export type MutationAddThemeArgs = {
  name: Scalars['String'];
  category: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  program: Scalars['String'];
};


export type MutationEditThemeArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
};


export type MutationDeleteThemeArgs = {
  id: Scalars['ID'];
};


export type MutationAddSongToThemeArgs = {
  song: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveSongFromThemeArgs = {
  song: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddQuoteArgs = {
  quote: Scalars['String'];
  author: Scalars['String'];
  theme: Scalars['ID'];
};


export type MutationEditQuoteInThemeArgs = {
  quoteId: Scalars['ID'];
  quote: Scalars['String'];
  author: Scalars['String'];
  theme: Scalars['ID'];
};


export type MutationRemoveQuoteFromThemeArgs = {
  quote: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddSongArgs = {
  title: Scalars['String'];
  lyrics: Scalars['String'];
  url: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationEditSongArgs = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  lyrics?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationDeleteSongArgs = {
  id: Scalars['ID'];
};


export type MutationAddQuoteToThemeArgs = {
  theme: Scalars['ID'];
  author?: Maybe<Scalars['String']>;
  quote: Scalars['String'];
};


export type MutationEditQuoteArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
  author?: Maybe<Scalars['String']>;
  quote?: Maybe<Scalars['String']>;
};


export type MutationDeleteQuoteArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveQuoteArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddDiscussionToThemeArgs = {
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
};


export type MutationEditDiscussionFromThemeArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
};


export type MutationDeleteDiscussionArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveDiscussionArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddReflectionToThemeArgs = {
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
};


export type MutationEditReflectionFromThemeArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
};


export type MutationDeleteReflectionArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveReflectionArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddJavaTipToThemeArgs = {
  theme: Scalars['ID'];
  author?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
};


export type MutationEditJavaTipArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
  body?: Maybe<Scalars['String']>;
};


export type MutationDeleteJavaTipArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveJavaTipArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddSensoryCueToThemeArgs = {
  theme: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  imageUrl: Scalars['String'];
};


export type MutationEditSensoryCueArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
};


export type MutationDeleteSensoryCueArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationRemoveSensoryCueArgs = {
  id: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddSessionArgs = {
  date: Scalars['DateISO'];
  group: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationAddSessionsArgs = {
  date: Scalars['DateISO'];
  group: Scalars['ID'];
  theme: Scalars['ID'];
  freq: Scalars['String'];
};


export type MutationEditSessionArgs = {
  id: Scalars['ID'];
  date?: Maybe<Scalars['DateISO']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteSessionArgs = {
  id: Scalars['ID'];
};


export type MutationHandoverMasterArgs = {
  session: Scalars['ID'];
  newMaster: Scalars['ID'];
};


export type MutationAddUserToSessionArgs = {
  user: Scalars['ID'];
  as: Scalars['String'];
  session: Scalars['ID'];
};


export type MutationRemoveUserFromSessionArgs = {
  user: Scalars['ID'];
  as: Scalars['String'];
  session: Scalars['ID'];
};


export type MutationChangeThemeArgs = {
  session: Scalars['ID'];
  theme: Scalars['ID'];
};


export type MutationChangeDateTimeArgs = {
  group: Scalars['ID'];
  date: Scalars['DateISO'];
};


export type MutationAddUserToWaitingRoomArgs = {
  user: Scalars['ID'];
  session: Scalars['ID'];
};


export type MutationRemoveUserFromWaitingRoomArgs = {
  user: Scalars['ID'];
  session: Scalars['ID'];
};


export type MutationEditRunningSessionArgs = {
  id: Scalars['ID'];
  currentScreen?: Maybe<Scalars['Int']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  globalMessage?: Maybe<Scalars['String']>;
};


export type MutationStartSessionArgs = {
  session: Scalars['ID'];
};


export type MutationStopSessionArgs = {
  session: Scalars['ID'];
};


export type MutationAddMemberFeedbackArgs = {
  session: Scalars['ID'];
  user: Scalars['ID'];
  emotion: Scalars['String'];
  currentScreen?: Maybe<Scalars['Int']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
};


export type MutationEditSessionMemberRoleArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  role: Scalars['String'];
};


export type MutationEditSessionMemberMuteStateArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  muteState: Scalars['Boolean'];
  room: Scalars['Boolean'];
  role: Scalars['String'];
};


export type MutationEditAllMuteStateArgs = {
  session: Scalars['ID'];
  muteState: Scalars['Boolean'];
};


export type MutationEditSessionMemberVidStateArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  vidState: Scalars['Boolean'];
  role: Scalars['String'];
};


export type MutationEditSessionMemberPalamiStateArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  palamiState: Scalars['Boolean'];
  role: Scalars['String'];
};


export type MutationEditSessionMemberRoomStateArgs = {
  session: Scalars['ID'];
  member: Scalars['ID'];
  roomState: Scalars['Boolean'];
  muteState: Scalars['Boolean'];
  role: Scalars['String'];
};


export type MutationEditAllRoomStateArgs = {
  session: Scalars['ID'];
};


export type MutationEditAllVidStateArgs = {
  session: Scalars['ID'];
  vidState: Scalars['Boolean'];
};


export type MutationLeaveSessionArgs = {
  session: Scalars['ID'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  passReset: Scalars['String'];
};


export type MutationSendResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationRevokeRefreshTokenForUserArgs = {
  id: Scalars['ID'];
};


export type MutationSwitchUserArgs = {
  user: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationChangePasswordByResetArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationAddNotificationArgs = {
  owner: Scalars['ID'];
  type: Scalars['String'];
  user?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  session?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
};


export type MutationEditNotificationArgs = {
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  session?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  seenEh?: Maybe<Scalars['Boolean']>;
  actionedEh?: Maybe<Scalars['Boolean']>;
};


export type MutationMarkSeenNotificationsArgs = {
  notifs: Array<Maybe<Scalars['ID']>>;
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationAddErrorLogArgs = {
  error?: Maybe<Scalars['String']>;
  componentStack?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userMessage?: Maybe<Scalars['String']>;
};


export type MutationAddUserMessageToErrorLogArgs = {
  id: Scalars['ID'];
  userMessage?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
  sessionModified?: Maybe<RunningSessionSubscriptionPayload>;
  sessionWaitingRoom?: Maybe<Array<Maybe<MiniUser>>>;
  sessionMembers?: Maybe<Array<Maybe<MiniUser>>>;
  sessionFacilitators?: Maybe<Array<Maybe<MiniUser>>>;
  sessionMixedUsers?: Maybe<Array<Maybe<MiniUserMixed>>>;
};


export type SubscriptionSessionModifiedArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionWaitingRoomArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionMembersArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionFacilitatorsArgs = {
  id: Scalars['ID'];
};


export type SubscriptionSessionMixedUsersArgs = {
  id: Scalars['ID'];
};

export type Error = {
  __typename?: 'Error';
  path?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['DateISO']>;
  address?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Note>>>;
  parentUser?: Maybe<Scalars['ID']>;
  groupAsFacilitator?: Maybe<Array<Maybe<Group>>>;
  groupAsMember?: Maybe<Array<Maybe<Group>>>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateISO']>;
  validUntil?: Maybe<Scalars['DateISO']>;
  optOut?: Maybe<Scalars['Boolean']>;
};

export type Answer = {
  __typename?: 'Answer';
  id: Scalars['ID'];
  number: Scalars['Int'];
  body: Scalars['String'];
  value: Scalars['Int'];
  errors?: Maybe<Array<Maybe<Error>>>;
  createdAt: Scalars['DateISO'];
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  id: Scalars['ID'];
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  answers?: Maybe<Array<Maybe<Answer>>>;
  optOut: Scalars['Boolean'];
  isDone?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  createdAt: Scalars['DateISO'];
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['ID'];
  author?: Maybe<User>;
  content: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type UsedThemes = {
  __typename?: 'UsedThemes';
  theme: Scalars['ID'];
  lastUsed: Scalars['DateISO'];
};

export type Group = {
  __typename?: 'Group';
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['DateISO'];
  frequency: Scalars['String'];
  room: Scalars['String'];
  program: Scalars['String'];
  owner?: Maybe<User>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  facilitators?: Maybe<Array<Maybe<User>>>;
  inviteRequests?: Maybe<Array<Maybe<User>>>;
  members?: Maybe<Array<Maybe<User>>>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isPublic?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
  usedThemes?: Maybe<Array<Maybe<UsedThemes>>>;
};

export type Community = {
  __typename?: 'Community';
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  maxUsers: Scalars['Int'];
  maxGroups: Scalars['Int'];
  members?: Maybe<Array<Maybe<User>>>;
  facilitators?: Maybe<Array<Maybe<User>>>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type Element = {
  __typename?: 'Element';
  tag: Scalars['String'];
  content: Scalars['String'];
};

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['ID'];
  category: Scalars['String'];
  thumb: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Array<Maybe<Scalars['String']>>>;
  link?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type Theme = {
  __typename?: 'Theme';
  id: Scalars['ID'];
  name: Scalars['String'];
  category: Scalars['String'];
  program: Scalars['String'];
  imageUrl: Scalars['String'];
  songs?: Maybe<Array<Maybe<Song>>>;
  discussions?: Maybe<Array<Maybe<Discussion>>>;
  reflection?: Maybe<Array<Maybe<Reflection>>>;
  quotes?: Maybe<Array<Maybe<Quote>>>;
  sensoryCues?: Maybe<Array<Maybe<SensoryCue>>>;
  javaTips?: Maybe<Array<Maybe<JavaTip>>>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type Song = {
  __typename?: 'Song';
  id: Scalars['ID'];
  title: Scalars['String'];
  lyrics: Scalars['String'];
  url: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type Quote = {
  __typename?: 'Quote';
  id: Scalars['ID'];
  author: Scalars['String'];
  quote: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};


export type Discussion = {
  __typename?: 'Discussion';
  id: Scalars['ID'];
  question: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type Reflection = {
  __typename?: 'Reflection';
  id: Scalars['ID'];
  question: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type JavaTip = {
  __typename?: 'JavaTip';
  id: Scalars['ID'];
  author?: Maybe<User>;
  body: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type SensoryCue = {
  __typename?: 'SensoryCue';
  id: Scalars['ID'];
  title: Scalars['String'];
  body: Scalars['String'];
  imageUrl: Scalars['String'];
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
};

export type MemberFeedback = {
  __typename?: 'MemberFeedback';
  createdBy?: Maybe<User>;
  emotion?: Maybe<Scalars['String']>;
  currentScreen?: Maybe<Scalars['Int']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
};

export type SessionMember = {
  __typename?: 'SessionMember';
  id: Scalars['ID'];
  user?: Maybe<User>;
  nickname?: Maybe<Scalars['String']>;
  sessionRole?: Maybe<Scalars['String']>;
  muteState?: Maybe<Scalars['Boolean']>;
  vidState?: Maybe<Scalars['Boolean']>;
  palamiState?: Maybe<Scalars['Boolean']>;
  roomState?: Maybe<Scalars['Boolean']>;
  twilioName?: Maybe<Scalars['String']>;
  feedback?: Maybe<Array<Maybe<MemberFeedback>>>;
};

export type SessionFacilitator = {
  __typename?: 'SessionFacilitator';
  id: Scalars['ID'];
  user?: Maybe<User>;
  nickname?: Maybe<Scalars['String']>;
  sessionRole?: Maybe<Scalars['String']>;
  muteState?: Maybe<Scalars['Boolean']>;
  vidState?: Maybe<Scalars['Boolean']>;
  palamiState?: Maybe<Scalars['Boolean']>;
  roomState?: Maybe<Scalars['Boolean']>;
  twilioName?: Maybe<Scalars['String']>;
};

export type RunningSessionSubscriptionPayload = {
  __typename?: 'RunningSessionSubscriptionPayload';
  sessionMaster?: Maybe<Scalars['ID']>;
  currentScreen?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
  globalMessage?: Maybe<Scalars['String']>;
};

export type MiniUser = {
  __typename?: 'miniUser';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  muteState?: Maybe<Scalars['Boolean']>;
  vidState?: Maybe<Scalars['Boolean']>;
  palamiState?: Maybe<Scalars['Boolean']>;
  roomState?: Maybe<Scalars['Boolean']>;
  twilioName?: Maybe<Scalars['String']>;
};

export type MiniUserMixed = {
  __typename?: 'miniUserMixed';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  muteState?: Maybe<Scalars['Boolean']>;
  vidState?: Maybe<Scalars['Boolean']>;
  palamiState?: Maybe<Scalars['Boolean']>;
  roomState?: Maybe<Scalars['Boolean']>;
  twilioName?: Maybe<Scalars['String']>;
  profilePicUrl?: Maybe<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['ID'];
  date: Scalars['DateISO'];
  group: Group;
  program: Scalars['String'];
  theme: Theme;
  facilitators?: Maybe<Array<Maybe<SessionFacilitator>>>;
  members?: Maybe<Array<Maybe<User>>>;
  errors?: Maybe<Array<Maybe<Error>>>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateISO'];
  waitingRoom?: Maybe<Array<Maybe<User>>>;
  sessionMembers?: Maybe<Array<Maybe<SessionMember>>>;
  sessionMaster?: Maybe<User>;
  currentScreen?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
  globalMessage?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token: Scalars['String'];
};

export type ResetResponse = {
  __typename?: 'ResetResponse';
  token: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  owner: User;
  type: Scalars['String'];
  user?: Maybe<User>;
  group?: Maybe<Group>;
  session?: Maybe<Session>;
  message: Scalars['String'];
  seenEh?: Maybe<Scalars['Boolean']>;
  actionedEh?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  createdAt: Scalars['DateISO'];
  updatedAt: Scalars['DateISO'];
};

export type ErrorLog = {
  __typename?: 'ErrorLog';
  id: Scalars['ID'];
  error?: Maybe<Scalars['String']>;
  componentStack?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userMessage?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  createdAt: Scalars['DateISO'];
  updatedAt: Scalars['DateISO'];
};


export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type SessionSessionFacilitatorsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionSessionFacilitatorsQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'nickname' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState' | 'twilioName'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl'>
      )> }
    )>>> }
  )> }
);

export type AddAnswerMutationVariables = Exact<{
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  number: Scalars['Int'];
  body: Scalars['String'];
  value: Scalars['Int'];
}>;


export type AddAnswerMutation = (
  { __typename?: 'Mutation' }
  & { addAnswer?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  )> }
);

export type AddDiscussionToThemeMutationVariables = Exact<{
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
}>;


export type AddDiscussionToThemeMutation = (
  { __typename?: 'Mutation' }
  & { addDiscussionToTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { discussions?: Maybe<Array<Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id' | 'question'>
    )>>> }
  )> }
);

export type AddErrorLogMutationVariables = Exact<{
  error?: Maybe<Scalars['String']>;
  componentStack?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userMessage?: Maybe<Scalars['String']>;
}>;


export type AddErrorLogMutation = (
  { __typename?: 'Mutation' }
  & { addErrorLog?: Maybe<(
    { __typename?: 'ErrorLog' }
    & Pick<ErrorLog, 'id'>
  )> }
);

export type AddGroupMutationVariables = Exact<{
  name: Scalars['String'];
  startDate: Scalars['String'];
  frequency: Scalars['String'];
  room: Scalars['String'];
  program: Scalars['String'];
  isPublic: Scalars['Boolean'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type AddGroupMutation = (
  { __typename?: 'Mutation' }
  & { addGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type AddJavaTipToThemeMutationVariables = Exact<{
  theme: Scalars['ID'];
  author?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
}>;


export type AddJavaTipToThemeMutation = (
  { __typename?: 'Mutation' }
  & { addJavaTipToTheme?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id'>
    & { javaTips?: Maybe<Array<Maybe<(
      { __typename?: 'JavaTip' }
      & Pick<JavaTip, 'id'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )> }
);

export type AddMemberFeedbackMutationVariables = Exact<{
  session: Scalars['ID'];
  user: Scalars['ID'];
  emotion: Scalars['String'];
  currentScreen?: Maybe<Scalars['Int']>;
  songPlaying?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
}>;


export type AddMemberFeedbackMutation = (
  { __typename?: 'Mutation' }
  & { addMemberFeedback?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type AddNoteForUserMutationVariables = Exact<{
  user: Scalars['ID'];
  author: Scalars['ID'];
  content: Scalars['String'];
}>;


export type AddNoteForUserMutation = (
  { __typename?: 'Mutation' }
  & { addNoteForUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type AddNotificationMutationVariables = Exact<{
  owner: Scalars['ID'];
  type: Scalars['String'];
  user?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  session?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
}>;


export type AddNotificationMutation = (
  { __typename?: 'Mutation' }
  & { addNotification?: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'type' | 'message' | 'seenEh' | 'actionedEh' | 'createdAt'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id'>
    )>, session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id'>
    )> }
  )> }
);

export type AddProfilePicMutationVariables = Exact<{
  pic: Scalars['Upload'];
}>;


export type AddProfilePicMutation = (
  { __typename?: 'Mutation' }
  & { addProfilePic?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type AddQuestionnaireMutationVariables = Exact<{
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  optOut: Scalars['Boolean'];
  isDone: Scalars['Boolean'];
}>;


export type AddQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { addQuestionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
  )> }
);

export type AddQuoteMutationVariables = Exact<{
  quote: Scalars['String'];
  author: Scalars['String'];
  theme: Scalars['ID'];
}>;


export type AddQuoteMutation = (
  { __typename?: 'Mutation' }
  & { addQuote?: Maybe<(
    { __typename?: 'Theme' }
    & { quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'author' | 'quote'>
    )>>> }
  )> }
);

export type AddReflectionToThemeMutationVariables = Exact<{
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
}>;


export type AddReflectionToThemeMutation = (
  { __typename?: 'Mutation' }
  & { addReflectionToTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { reflection?: Maybe<Array<Maybe<(
      { __typename?: 'Reflection' }
      & Pick<Reflection, 'id' | 'question'>
    )>>> }
  )> }
);

export type AddSessionsMutationVariables = Exact<{
  date: Scalars['DateISO'];
  group: Scalars['ID'];
  theme: Scalars['ID'];
  freq: Scalars['String'];
}>;


export type AddSessionsMutation = (
  { __typename?: 'Mutation' }
  & { addSessions?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type AddTagToGroupMutationVariables = Exact<{
  tag: Scalars['String'];
  group: Scalars['ID'];
}>;


export type AddTagToGroupMutation = (
  { __typename?: 'Mutation' }
  & { addTagToGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type AddThemeMutationVariables = Exact<{
  name: Scalars['String'];
  category: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  program: Scalars['String'];
}>;


export type AddThemeMutation = (
  { __typename?: 'Mutation' }
  & { addTheme?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id'>
  )> }
);

export type AddUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  optOut?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateISO']>;
}>;


export type AddUserMutation = (
  { __typename?: 'Mutation' }
  & { addUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type AddUserMessageToErrorLogMutationVariables = Exact<{
  id: Scalars['ID'];
  userMessage?: Maybe<Scalars['String']>;
}>;


export type AddUserMessageToErrorLogMutation = (
  { __typename?: 'Mutation' }
  & { addUserMessageToErrorLog?: Maybe<(
    { __typename?: 'ErrorLog' }
    & Pick<ErrorLog, 'id' | 'userMessage'>
  )> }
);

export type AddUserToGroupMutationVariables = Exact<{
  user: Scalars['ID'];
  as: Scalars['String'];
  group: Scalars['ID'];
}>;


export type AddUserToGroupMutation = (
  { __typename?: 'Mutation' }
  & { addUserToGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type AddUserToSessionMutationVariables = Exact<{
  user: Scalars['ID'];
  session: Scalars['ID'];
  as: Scalars['String'];
}>;


export type AddUserToSessionMutation = (
  { __typename?: 'Mutation' }
  & { addUserToSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
      )> }
    )>>>, facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
      )> }
    )>>> }
  )> }
);

export type AddUserToWaitingRoomMutationVariables = Exact<{
  user: Scalars['ID'];
  session: Scalars['ID'];
}>;


export type AddUserToWaitingRoomMutation = (
  { __typename?: 'Mutation' }
  & { addUserToWaitingRoom?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type AmIApprovedQueryVariables = Exact<{
  session: Scalars['ID'];
}>;


export type AmIApprovedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'amIApproved'>
);

export type AnswerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AnswerQuery = (
  { __typename?: 'Query' }
  & { answer?: Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
  )> }
);

export type AnswerListQueryVariables = Exact<{ [key: string]: never; }>;


export type AnswerListQuery = (
  { __typename?: 'Query' }
  & { answerList?: Maybe<Array<Maybe<(
    { __typename?: 'Answer' }
    & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
  )>>> }
);

export type ChangeDateTimeMutationVariables = Exact<{
  group: Scalars['ID'];
  date: Scalars['DateISO'];
}>;


export type ChangeDateTimeMutation = (
  { __typename?: 'Mutation' }
  & { changeDateTime?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ChangePasswordByResetMutationVariables = Exact<{
  newPassword: Scalars['String'];
  token: Scalars['String'];
}>;


export type ChangePasswordByResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePasswordByReset'>
);

export type ChangeThemeMutationVariables = Exact<{
  session: Scalars['ID'];
  theme: Scalars['ID'];
}>;


export type ChangeThemeMutation = (
  { __typename?: 'Mutation' }
  & { changeTheme?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type CheckQuestionnaireExistsQueryVariables = Exact<{
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
}>;


export type CheckQuestionnaireExistsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkQuestionnaireExists'>
);

export type CloseSessionsQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type CloseSessionsQuery = (
  { __typename?: 'Query' }
  & { closeSessions?: Maybe<Array<Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program' | 'status'>
    & { group: (
      { __typename?: 'Group' }
      & Pick<Group, 'name'>
    ), theme: (
      { __typename?: 'Theme' }
      & Pick<Theme, 'name' | 'imageUrl'>
    ) }
  )>>> }
);

export type DeleteAnswerMutationVariables = Exact<{
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
}>;


export type DeleteAnswerMutation = (
  { __typename?: 'Mutation' }
  & { deleteAnswer?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  )> }
);

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNotificationMutation = (
  { __typename?: 'Mutation' }
  & { deleteNotification?: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id'>
  )> }
);

export type DeleteQuestionnaireMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuestionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'nickname'>
  )> }
);

export type DenyRequestMutationVariables = Exact<{
  user: Scalars['ID'];
  group: Scalars['ID'];
}>;


export type DenyRequestMutation = (
  { __typename?: 'Mutation' }
  & { denyRequest?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type EditAllMuteStateMutationVariables = Exact<{
  session: Scalars['ID'];
  muteState: Scalars['Boolean'];
}>;


export type EditAllMuteStateMutation = (
  { __typename?: 'Mutation' }
  & { editAllMuteState?: Maybe<(
    { __typename?: 'Session' }
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'sessionRole' | 'muteState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'sessionRole' | 'muteState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>> }
  )> }
);

export type EditAllRoomStateMutationVariables = Exact<{
  session: Scalars['ID'];
}>;


export type EditAllRoomStateMutation = (
  { __typename?: 'Mutation' }
  & { editAllRoomState?: Maybe<(
    { __typename?: 'Session' }
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'sessionRole' | 'muteState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'sessionRole' | 'muteState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>> }
  )> }
);

export type EditAllVidStateMutationVariables = Exact<{
  session: Scalars['ID'];
  vidState: Scalars['Boolean'];
}>;


export type EditAllVidStateMutation = (
  { __typename?: 'Mutation' }
  & { editAllVidState?: Maybe<(
    { __typename?: 'Session' }
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'sessionRole' | 'vidState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'sessionRole' | 'vidState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
      )> }
    )>>> }
  )> }
);

export type EditAnswerMutationVariables = Exact<{
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
  number: Scalars['Int'];
  body: Scalars['String'];
  value: Scalars['Int'];
}>;


export type EditAnswerMutation = (
  { __typename?: 'Mutation' }
  & { editAnswer?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  )> }
);

export type EditDiscussionFromThemeMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
}>;


export type EditDiscussionFromThemeMutation = (
  { __typename?: 'Mutation' }
  & { editDiscussionFromTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { discussions?: Maybe<Array<Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id' | 'question'>
    )>>> }
  )> }
);

export type EditGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateISO']>;
  frequency?: Maybe<Scalars['String']>;
  room?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type EditGroupMutation = (
  { __typename?: 'Mutation' }
  & { editGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
  )> }
);

export type EditNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['ID']>;
  group?: Maybe<Scalars['ID']>;
  session?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
  seenEh?: Maybe<Scalars['Boolean']>;
  actionedEh?: Maybe<Scalars['Boolean']>;
}>;


export type EditNotificationMutation = (
  { __typename?: 'Mutation' }
  & { editNotification?: Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'type' | 'message' | 'seenEh' | 'actionedEh' | 'createdAt'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id'>
    )>, session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id'>
    )> }
  )> }
);

export type EditQuestionnaireMutationVariables = Exact<{
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage: Scalars['String'];
  optOut?: Maybe<Scalars['Boolean']>;
  isDone?: Maybe<Scalars['Boolean']>;
}>;


export type EditQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { editQuestionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
    )>>> }
  )> }
);

export type EditQuoteInThemeMutationVariables = Exact<{
  quoteId: Scalars['ID'];
  quote: Scalars['String'];
  author: Scalars['String'];
  theme: Scalars['ID'];
}>;


export type EditQuoteInThemeMutation = (
  { __typename?: 'Mutation' }
  & { editQuoteInTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'author' | 'quote'>
    )>>> }
  )> }
);

export type EditReflectionFromThemeMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: Scalars['ID'];
  question?: Maybe<Scalars['String']>;
}>;


export type EditReflectionFromThemeMutation = (
  { __typename?: 'Mutation' }
  & { editReflectionFromTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { reflection?: Maybe<Array<Maybe<(
      { __typename?: 'Reflection' }
      & Pick<Reflection, 'id' | 'question'>
    )>>> }
  )> }
);

export type EditRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  role: Scalars['String'];
}>;


export type EditRoleMutation = (
  { __typename?: 'Mutation' }
  & { editRole?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'phone' | 'email' | 'dateOfBirth' | 'role' | 'address' | 'status' | 'validUntil'>
  )> }
);

export type EditRunningSessionMutationVariables = Exact<{
  id: Scalars['ID'];
  currentScreen?: Maybe<Scalars['Int']>;
  songPlaying?: Maybe<Scalars['String']>;
  videoChatStatus?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  contentId?: Maybe<Scalars['String']>;
  globalMessage?: Maybe<Scalars['String']>;
}>;


export type EditRunningSessionMutation = (
  { __typename?: 'Mutation' }
  & { editRunningSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'status' | 'currentScreen' | 'songPlaying' | 'videoChatStatus' | 'contentId' | 'contentType' | 'globalMessage'>
  )> }
);

export type EditSessionMemberMuteStateMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  muteState: Scalars['Boolean'];
  room: Scalars['Boolean'];
  role: Scalars['String'];
}>;


export type EditSessionMemberMuteStateMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberMuteState?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type EditSessionMemberPalamiStateMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  palamiState: Scalars['Boolean'];
  role: Scalars['String'];
}>;


export type EditSessionMemberPalamiStateMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberPalamiState?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type EditSessionMemberRoleMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  role: Scalars['String'];
}>;


export type EditSessionMemberRoleMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberRole?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'id' | 'sessionRole'>
    )>>> }
  )> }
);

export type EditSessionMemberRoomStateMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  roomState: Scalars['Boolean'];
  muteState: Scalars['Boolean'];
  role: Scalars['String'];
}>;


export type EditSessionMemberRoomStateMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberRoomState?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type EditSessionMemberVidStateMutationVariables = Exact<{
  session: Scalars['ID'];
  member: Scalars['ID'];
  vidState: Scalars['Boolean'];
  role: Scalars['String'];
}>;


export type EditSessionMemberVidStateMutation = (
  { __typename?: 'Mutation' }
  & { editSessionMemberVidState?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type EditThemeMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
}>;


export type EditThemeMutation = (
  { __typename?: 'Mutation' }
  & { editTheme?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id'>
  )> }
);

export type EditUserMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalcode?: Maybe<Scalars['String']>;
  ageGroup?: Maybe<Scalars['String']>;
  living?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  optOut?: Maybe<Scalars['Boolean']>;
  validUntil?: Maybe<Scalars['DateISO']>;
}>;


export type EditUserMutation = (
  { __typename?: 'Mutation' }
  & { editUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'phone' | 'email' | 'nickname' | 'country' | 'postalcode' | 'ageGroup' | 'living' | 'status' | 'optOut' | 'validUntil'>
  )> }
);

export type ErrorLogsQueryVariables = Exact<{ [key: string]: never; }>;


export type ErrorLogsQuery = (
  { __typename?: 'Query' }
  & { errorLogs?: Maybe<Array<Maybe<(
    { __typename?: 'ErrorLog' }
    & Pick<ErrorLog, 'id' | 'error' | 'componentStack' | 'platform' | 'userAgent' | 'userMessage' | 'createdAt'>
  )>>> }
);

export type FollowSessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FollowSessionQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'status' | 'currentScreen' | 'songPlaying' | 'videoChatStatus' | 'contentId' | 'contentType' | 'globalMessage'>
  )> }
);

export type FollowSessionAsSessionMasterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FollowSessionAsSessionMasterQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'status' | 'currentScreen' | 'songPlaying' | 'videoChatStatus' | 'contentId' | 'contentType' | 'globalMessage'>
    & { waitingRoom?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
    )>>>, sessionMaster?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type FollowWaitingRoomQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FollowWaitingRoomQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'status'>
    & { waitingRoom?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )> }
);

export type FutureSessionsQueryVariables = Exact<{
  nth: Scalars['Int'];
}>;


export type FutureSessionsQuery = (
  { __typename?: 'Query' }
  & { futureSessions?: Maybe<Array<Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program' | 'status'>
    & { sessionMaster?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, theme: (
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'imageUrl'>
    ), group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'room'>
    ) }
  )>>> }
);

export type GroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GroupQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'startDate' | 'frequency' | 'room' | 'program' | 'isPublic' | 'tags' | 'isDeleted'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl'>
    )>>>, inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePicUrl'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePicUrl'>
    )>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl'>
    )>>>, usedThemes?: Maybe<Array<Maybe<(
      { __typename?: 'UsedThemes' }
      & Pick<UsedThemes, 'theme' | 'lastUsed'>
    )>>> }
  )> }
);

export type GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsQuery = (
  { __typename?: 'Query' }
  & { groups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'startDate' | 'frequency' | 'room' | 'program' | 'isPublic' | 'tags' | 'isDeleted'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'profilePicUrl'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'profilePicUrl'>
    )>, facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl'>
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl'>
    )>>> }
  )>>> }
);

export type GroupsByTagQueryVariables = Exact<{
  tags: Array<Maybe<Scalars['String']>>;
}>;


export type GroupsByTagQuery = (
  { __typename?: 'Query' }
  & { groupsByTag?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'startDate' | 'frequency' | 'room' | 'program' | 'isPublic' | 'tags' | 'isDeleted'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>>, inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>>, usedThemes?: Maybe<Array<Maybe<(
      { __typename?: 'UsedThemes' }
      & Pick<UsedThemes, 'theme' | 'lastUsed'>
    )>>> }
  )>>> }
);

export type HandoverMasterMutationVariables = Exact<{
  session: Scalars['ID'];
  newMaster: Scalars['ID'];
}>;


export type HandoverMasterMutation = (
  { __typename?: 'Mutation' }
  & { handoverMaster?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date'>
    & { sessionMaster?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id'>
    ) }
  )> }
);

export type LeaveSessionMutationVariables = Exact<{
  session: Scalars['ID'];
}>;


export type LeaveSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'leaveSession'>
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type MarkSeenNotificationsMutationVariables = Exact<{
  notifs: Array<Maybe<Scalars['ID']>>;
}>;


export type MarkSeenNotificationsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markSeenNotifications'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'email' | 'nickname' | 'postalcode' | 'ageGroup' | 'living' | 'country' | 'role' | 'phone' | 'dateOfBirth' | 'address' | 'parentUser' | 'profilePicUrl' | 'optOut'>
  )> }
);

export type MyFutureSessionsQueryVariables = Exact<{
  nth: Scalars['Int'];
}>;


export type MyFutureSessionsQuery = (
  { __typename?: 'Query' }
  & { myFutureSessions?: Maybe<Array<Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program' | 'status'>
    & { sessionMaster?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, theme: (
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'imageUrl'>
    ), group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'room'>
    ) }
  )>>> }
);

export type MyGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyGroupsQuery = (
  { __typename?: 'Query' }
  & { myGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'startDate' | 'frequency' | 'room' | 'isPublic' | 'tags' | 'isDeleted'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>> }
  )>>> }
);

export type MyManagedGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyManagedGroupsQuery = (
  { __typename?: 'Query' }
  & { myManagedGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'startDate' | 'frequency' | 'room' | 'isPublic' | 'tags' | 'isDeleted'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>> }
  )>>> }
);

export type MyRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyRequestsQuery = (
  { __typename?: 'Query' }
  & { myRequests?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>> }
  )>>> }
);

export type NotificationsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications?: Maybe<Array<Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'type' | 'message' | 'seenEh' | 'actionedEh' | 'createdAt' | 'updatedAt'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id'>
    )> }
  )>>> }
);

export type PublicGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type PublicGroupsQuery = (
  { __typename?: 'Query' }
  & { publicGroups?: Maybe<Array<Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name' | 'startDate' | 'frequency' | 'room' | 'program' | 'isPublic' | 'tags' | 'isDeleted'>
    & { inviteRequests?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>>, owner?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName'>
    )>, facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>> }
  )>>> }
);

export type QuestionnaireQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type QuestionnaireQuery = (
  { __typename?: 'Query' }
  & { questionnaire?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
    )>>> }
  )> }
);

export type QuestionnaireUserSessionQueryVariables = Exact<{
  member: Scalars['ID'];
  session: Scalars['ID'];
  stage?: Maybe<Scalars['String']>;
}>;


export type QuestionnaireUserSessionQuery = (
  { __typename?: 'Query' }
  & { questionnaireUserSession?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
    )>>> }
  )> }
);

export type QuestionnairesQueryVariables = Exact<{ [key: string]: never; }>;


export type QuestionnairesQuery = (
  { __typename?: 'Query' }
  & { questionnaires?: Maybe<Array<Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'member' | 'session' | 'stage' | 'optOut' | 'isDone'>
    & { answers?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'number' | 'body' | 'value' | 'createdAt'>
    )>>> }
  )>>> }
);

export type RemoveAnswerMutationVariables = Exact<{
  id: Scalars['ID'];
  questionnaire: Scalars['ID'];
}>;


export type RemoveAnswerMutation = (
  { __typename?: 'Mutation' }
  & { removeAnswer?: Maybe<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  )> }
);

export type RemoveDiscussionMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: Scalars['ID'];
}>;


export type RemoveDiscussionMutation = (
  { __typename?: 'Mutation' }
  & { removeDiscussion?: Maybe<(
    { __typename?: 'Theme' }
    & { discussions?: Maybe<Array<Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id' | 'question'>
    )>>> }
  )> }
);

export type RemoveJavaTipMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: Scalars['ID'];
}>;


export type RemoveJavaTipMutation = (
  { __typename?: 'Mutation' }
  & { removeJavaTip?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id' | 'isDeleted'>
    & { javaTips?: Maybe<Array<Maybe<(
      { __typename?: 'JavaTip' }
      & Pick<JavaTip, 'id'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )> }
);

export type RemoveQuoteFromThemeMutationVariables = Exact<{
  quote: Scalars['ID'];
  theme: Scalars['ID'];
}>;


export type RemoveQuoteFromThemeMutation = (
  { __typename?: 'Mutation' }
  & { removeQuoteFromTheme?: Maybe<(
    { __typename?: 'Theme' }
    & { quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'author' | 'quote'>
    )>>> }
  )> }
);

export type RemoveReflectionMutationVariables = Exact<{
  id: Scalars['ID'];
  theme: Scalars['ID'];
}>;


export type RemoveReflectionMutation = (
  { __typename?: 'Mutation' }
  & { removeReflection?: Maybe<(
    { __typename?: 'Theme' }
    & { reflection?: Maybe<Array<Maybe<(
      { __typename?: 'Reflection' }
      & Pick<Reflection, 'id' | 'question'>
    )>>> }
  )> }
);

export type RemoveTagFromGroupMutationVariables = Exact<{
  tag: Scalars['String'];
  group: Scalars['ID'];
}>;


export type RemoveTagFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeTagFromGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type RemoveUserFromGroupMutationVariables = Exact<{
  user: Scalars['ID'];
  group: Scalars['ID'];
  as: Scalars['String'];
}>;


export type RemoveUserFromGroupMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromGroup?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>> }
  )> }
);

export type RemoveUserFromSessionMutationVariables = Exact<{
  user: Scalars['ID'];
  session: Scalars['ID'];
  as: Scalars['String'];
}>;


export type RemoveUserFromSessionMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
      )> }
    )>>>, facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
      )> }
    )>>> }
  )> }
);

export type RemoveUserFromWaitingRoomMutationVariables = Exact<{
  user: Scalars['ID'];
  session: Scalars['ID'];
}>;


export type RemoveUserFromWaitingRoomMutation = (
  { __typename?: 'Mutation' }
  & { removeUserFromWaitingRoom?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { waitingRoom?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>>> }
  )> }
);

export type RequestInviteMutationVariables = Exact<{
  user: Scalars['ID'];
  group: Scalars['ID'];
}>;


export type RequestInviteMutation = (
  { __typename?: 'Mutation' }
  & { requestInvite?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id' | 'name'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  passReset: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetResponse' }
    & Pick<ResetResponse, 'token'>
  )> }
);

export type ResourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type ResourcesQuery = (
  { __typename?: 'Query' }
  & { resources?: Maybe<Array<Maybe<(
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'title' | 'category' | 'thumb' | 'text'>
  )>>> }
);

export type SendResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type SendResetEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendResetEmail'>
);

export type SessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program'>
    & { sessionMaster?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'startDate' | 'frequency' | 'room'>
      & { usedThemes?: Maybe<Array<Maybe<(
        { __typename?: 'UsedThemes' }
        & Pick<UsedThemes, 'theme' | 'lastUsed'>
      )>>>, facilitators?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role' | 'profilePicUrl'>
      )>>>, members?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl' | 'role'>
        & { notes?: Maybe<Array<Maybe<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'content'>
          & { author?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )>>> }
      )>>> }
    ), facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & Pick<SessionFacilitator, 'nickname' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl'>
      )> }
    )>>>, sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'nickname' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl'>
        & { notes?: Maybe<Array<Maybe<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'content'>
          & { author?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )>>> }
      )> }
    )>>>, theme: (
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
      & { songs?: Maybe<Array<Maybe<(
        { __typename?: 'Song' }
        & Pick<Song, 'id' | 'title' | 'lyrics' | 'url' | 'imageUrl'>
      )>>>, discussions?: Maybe<Array<Maybe<(
        { __typename?: 'Discussion' }
        & Pick<Discussion, 'id' | 'question'>
      )>>>, reflection?: Maybe<Array<Maybe<(
        { __typename?: 'Reflection' }
        & Pick<Reflection, 'id' | 'question'>
      )>>>, quotes?: Maybe<Array<Maybe<(
        { __typename?: 'Quote' }
        & Pick<Quote, 'id' | 'author' | 'quote'>
      )>>>, sensoryCues?: Maybe<Array<Maybe<(
        { __typename?: 'SensoryCue' }
        & Pick<SensoryCue, 'id' | 'title' | 'body' | 'imageUrl'>
      )>>>, javaTips?: Maybe<Array<Maybe<(
        { __typename?: 'JavaTip' }
        & Pick<JavaTip, 'id' | 'body'>
        & { author?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'firstName' | 'lastName'>
        )> }
      )>>> }
    ) }
  )> }
);

export type SessionFacilitatorsSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionFacilitatorsSubscription = (
  { __typename?: 'Subscription' }
  & { sessionFacilitators?: Maybe<Array<Maybe<(
    { __typename?: 'miniUser' }
    & Pick<MiniUser, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role' | 'muteState' | 'vidState' | 'palamiState' | 'roomState' | 'twilioName'>
  )>>> }
);

export type SessionMembersSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionMembersSubscription = (
  { __typename?: 'Subscription' }
  & { sessionMembers?: Maybe<Array<Maybe<(
    { __typename?: 'miniUser' }
    & Pick<MiniUser, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role' | 'muteState' | 'vidState' | 'palamiState' | 'roomState' | 'twilioName'>
  )>>> }
);

export type SessionSessionMembersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionSessionMembersQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & { sessionMembers?: Maybe<Array<Maybe<(
      { __typename?: 'SessionMember' }
      & Pick<SessionMember, 'nickname' | 'sessionRole' | 'muteState' | 'vidState' | 'palamiState' | 'roomState' | 'twilioName'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl'>
        & { notes?: Maybe<Array<Maybe<(
          { __typename?: 'Note' }
          & Pick<Note, 'id' | 'content'>
          & { author?: Maybe<(
            { __typename?: 'User' }
            & Pick<User, 'id' | 'firstName' | 'lastName'>
          )> }
        )>>> }
      )> }
    )>>> }
  )> }
);

export type SessionMixedUsersSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionMixedUsersSubscription = (
  { __typename?: 'Subscription' }
  & { sessionMixedUsers?: Maybe<Array<Maybe<(
    { __typename?: 'miniUserMixed' }
    & Pick<MiniUserMixed, 'id' | 'firstName' | 'lastName' | 'nickname' | 'profilePicUrl' | 'role' | 'muteState' | 'vidState' | 'palamiState' | 'roomState' | 'twilioName'>
  )>>> }
);

export type SessionModifiedSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionModifiedSubscription = (
  { __typename?: 'Subscription' }
  & { sessionModified?: Maybe<(
    { __typename?: 'RunningSessionSubscriptionPayload' }
    & Pick<RunningSessionSubscriptionPayload, 'currentScreen' | 'status' | 'songPlaying' | 'videoChatStatus' | 'contentType' | 'contentId' | 'globalMessage' | 'sessionMaster'>
  )> }
);

export type SessionStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionStatusQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'status'>
    & { group: (
      { __typename?: 'Group' }
      & { facilitators?: Maybe<Array<Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>>> }
    ) }
  )> }
);

export type SessionWaitingRoomSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SessionWaitingRoomSubscription = (
  { __typename?: 'Subscription' }
  & { sessionWaitingRoom?: Maybe<Array<Maybe<(
    { __typename?: 'miniUser' }
    & Pick<MiniUser, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role' | 'muteState'>
  )>>> }
);

export type SessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionsQuery = (
  { __typename?: 'Query' }
  & { sessions?: Maybe<Array<Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'date' | 'program'>
    & { theme: (
      { __typename?: 'Theme' }
      & Pick<Theme, 'id' | 'name' | 'imageUrl'>
    ), group: (
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'room'>
    ), facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'role'>
      )> }
    )>>>, members?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>>> }
  )>>> }
);

export type StartSessionMutationVariables = Exact<{
  session: Scalars['ID'];
}>;


export type StartSessionMutation = (
  { __typename?: 'Mutation' }
  & { startSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )> }
);

export type StopSessionMutationVariables = Exact<{
  session: Scalars['ID'];
}>;


export type StopSessionMutation = (
  { __typename?: 'Mutation' }
  & { stopSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { facilitators?: Maybe<Array<Maybe<(
      { __typename?: 'SessionFacilitator' }
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>>> }
  )> }
);

export type SubUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type SubUsersQuery = (
  { __typename?: 'Query' }
  & { subUsers?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname' | 'email' | 'dateOfBirth' | 'profilePicUrl'>
  )>>> }
);

export type SwitchUserMutationVariables = Exact<{
  user: Scalars['ID'];
}>;


export type SwitchUserMutation = (
  { __typename?: 'Mutation' }
  & { switchUser?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type ThemeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ThemeQuery = (
  { __typename?: 'Query' }
  & { theme?: Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
    & { discussions?: Maybe<Array<Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id' | 'question' | 'isDeleted'>
    )>>>, reflection?: Maybe<Array<Maybe<(
      { __typename?: 'Reflection' }
      & Pick<Reflection, 'id' | 'question' | 'isDeleted'>
    )>>>, songs?: Maybe<Array<Maybe<(
      { __typename?: 'Song' }
      & Pick<Song, 'id' | 'title' | 'lyrics' | 'url' | 'imageUrl' | 'isDeleted'>
    )>>>, quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'author' | 'quote' | 'isDeleted'>
    )>>>, sensoryCues?: Maybe<Array<Maybe<(
      { __typename?: 'SensoryCue' }
      & Pick<SensoryCue, 'id' | 'title' | 'body' | 'imageUrl' | 'isDeleted'>
    )>>>, javaTips?: Maybe<Array<Maybe<(
      { __typename?: 'JavaTip' }
      & Pick<JavaTip, 'id' | 'body' | 'isDeleted'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    )>>> }
  )> }
);

export type ThemePhotosQueryVariables = Exact<{ [key: string]: never; }>;


export type ThemePhotosQuery = (
  { __typename?: 'Query' }
  & { themePhotos?: Maybe<Array<Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'imageUrl'>
  )>>> }
);

export type ThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type ThemesQuery = (
  { __typename?: 'Query' }
  & { themes?: Maybe<Array<Maybe<(
    { __typename?: 'Theme' }
    & Pick<Theme, 'id' | 'name' | 'category' | 'imageUrl' | 'program'>
    & { discussions?: Maybe<Array<Maybe<(
      { __typename?: 'Discussion' }
      & Pick<Discussion, 'id' | 'question' | 'isDeleted'>
    )>>>, reflection?: Maybe<Array<Maybe<(
      { __typename?: 'Reflection' }
      & Pick<Reflection, 'id' | 'question' | 'isDeleted'>
    )>>>, songs?: Maybe<Array<Maybe<(
      { __typename?: 'Song' }
      & Pick<Song, 'id' | 'title' | 'lyrics' | 'url' | 'imageUrl' | 'isDeleted'>
    )>>>, quotes?: Maybe<Array<Maybe<(
      { __typename?: 'Quote' }
      & Pick<Quote, 'id' | 'author' | 'quote' | 'isDeleted'>
    )>>>, sensoryCues?: Maybe<Array<Maybe<(
      { __typename?: 'SensoryCue' }
      & Pick<SensoryCue, 'id' | 'title' | 'body' | 'imageUrl' | 'isDeleted'>
    )>>>, javaTips?: Maybe<Array<Maybe<(
      { __typename?: 'JavaTip' }
      & Pick<JavaTip, 'id' | 'body' | 'isDeleted'>
    )>>> }
  )>>> }
);

export type UnseenNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnseenNotificationsQuery = (
  { __typename?: 'Query' }
  & { unseenNotifications?: Maybe<Array<Maybe<(
    { __typename?: 'Notification' }
    & Pick<Notification, 'id' | 'type' | 'message' | 'seenEh' | 'actionedEh' | 'createdAt' | 'updatedAt'>
    & { owner: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ), user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'nickname'>
    )>, group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name'>
    )>, session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id'>
    )> }
  )>>> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'firstName' | 'lastName' | 'profilePicUrl' | 'optOut'>
    & { notes?: Maybe<Array<Maybe<(
      { __typename?: 'Note' }
      & Pick<Note, 'content'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
      )> }
    )>>> }
  )> }
);

export type UserByFirstNameQueryVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
}>;


export type UserByFirstNameQuery = (
  { __typename?: 'Query' }
  & { userByFirstName?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'firstName' | 'lastName'>
  )> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'firstName' | 'lastName' | 'phone' | 'email' | 'role' | 'nickname' | 'country' | 'postalcode' | 'ageGroup' | 'living' | 'profilePicUrl' | 'parentUser' | 'createdAt' | 'status' | 'validUntil' | 'optOut'>
  )>>> }
);

export type FuzzyUsersQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type FuzzyUsersQuery = (
  { __typename?: 'Query' }
  & { usersByFuzzyName?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'nickname' | 'firstName' | 'lastName' | 'email' | 'profilePicUrl' | 'role'>
  )>>> }
);


export const SessionSessionFacilitatorsDocument = gql`
    query sessionSessionFacilitators($id: ID!) {
  session(id: $id) {
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
        profilePicUrl
      }
      nickname
      sessionRole
      muteState
      vidState
      palamiState
      roomState
      twilioName
    }
  }
}
    `;

/**
 * __useSessionSessionFacilitatorsQuery__
 *
 * To run a query within a React component, call `useSessionSessionFacilitatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSessionFacilitatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSessionFacilitatorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionSessionFacilitatorsQuery(baseOptions: Apollo.QueryHookOptions<SessionSessionFacilitatorsQuery, SessionSessionFacilitatorsQueryVariables>) {
        return Apollo.useQuery<SessionSessionFacilitatorsQuery, SessionSessionFacilitatorsQueryVariables>(SessionSessionFacilitatorsDocument, baseOptions);
      }
export function useSessionSessionFacilitatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionSessionFacilitatorsQuery, SessionSessionFacilitatorsQueryVariables>) {
          return Apollo.useLazyQuery<SessionSessionFacilitatorsQuery, SessionSessionFacilitatorsQueryVariables>(SessionSessionFacilitatorsDocument, baseOptions);
        }
export type SessionSessionFacilitatorsQueryHookResult = ReturnType<typeof useSessionSessionFacilitatorsQuery>;
export type SessionSessionFacilitatorsLazyQueryHookResult = ReturnType<typeof useSessionSessionFacilitatorsLazyQuery>;
export type SessionSessionFacilitatorsQueryResult = Apollo.QueryResult<SessionSessionFacilitatorsQuery, SessionSessionFacilitatorsQueryVariables>;
export const AddAnswerDocument = gql`
    mutation addAnswer($member: ID!, $session: ID!, $stage: String!, $number: Int!, $body: String!, $value: Int!) {
  addAnswer(
    member: $member
    session: $session
    stage: $stage
    number: $number
    body: $body
    value: $value
  ) {
    id
  }
}
    `;
export type AddAnswerMutationFn = Apollo.MutationFunction<AddAnswerMutation, AddAnswerMutationVariables>;

/**
 * __useAddAnswerMutation__
 *
 * To run a mutation, you first call `useAddAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnswerMutation, { data, loading, error }] = useAddAnswerMutation({
 *   variables: {
 *      member: // value for 'member'
 *      session: // value for 'session'
 *      stage: // value for 'stage'
 *      number: // value for 'number'
 *      body: // value for 'body'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddAnswerMutation(baseOptions?: Apollo.MutationHookOptions<AddAnswerMutation, AddAnswerMutationVariables>) {
        return Apollo.useMutation<AddAnswerMutation, AddAnswerMutationVariables>(AddAnswerDocument, baseOptions);
      }
export type AddAnswerMutationHookResult = ReturnType<typeof useAddAnswerMutation>;
export type AddAnswerMutationResult = Apollo.MutationResult<AddAnswerMutation>;
export type AddAnswerMutationOptions = Apollo.BaseMutationOptions<AddAnswerMutation, AddAnswerMutationVariables>;
export const AddDiscussionToThemeDocument = gql`
    mutation addDiscussionToTheme($theme: ID!, $question: String) {
  addDiscussionToTheme(theme: $theme, question: $question) {
    discussions {
      id
      question
    }
  }
}
    `;
export type AddDiscussionToThemeMutationFn = Apollo.MutationFunction<AddDiscussionToThemeMutation, AddDiscussionToThemeMutationVariables>;

/**
 * __useAddDiscussionToThemeMutation__
 *
 * To run a mutation, you first call `useAddDiscussionToThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDiscussionToThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDiscussionToThemeMutation, { data, loading, error }] = useAddDiscussionToThemeMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useAddDiscussionToThemeMutation(baseOptions?: Apollo.MutationHookOptions<AddDiscussionToThemeMutation, AddDiscussionToThemeMutationVariables>) {
        return Apollo.useMutation<AddDiscussionToThemeMutation, AddDiscussionToThemeMutationVariables>(AddDiscussionToThemeDocument, baseOptions);
      }
export type AddDiscussionToThemeMutationHookResult = ReturnType<typeof useAddDiscussionToThemeMutation>;
export type AddDiscussionToThemeMutationResult = Apollo.MutationResult<AddDiscussionToThemeMutation>;
export type AddDiscussionToThemeMutationOptions = Apollo.BaseMutationOptions<AddDiscussionToThemeMutation, AddDiscussionToThemeMutationVariables>;
export const AddErrorLogDocument = gql`
    mutation addErrorLog($error: String, $componentStack: String, $platform: String, $userAgent: String, $userMessage: String) {
  addErrorLog(
    error: $error
    componentStack: $componentStack
    platform: $platform
    userAgent: $userAgent
    userMessage: $userMessage
  ) {
    id
  }
}
    `;
export type AddErrorLogMutationFn = Apollo.MutationFunction<AddErrorLogMutation, AddErrorLogMutationVariables>;

/**
 * __useAddErrorLogMutation__
 *
 * To run a mutation, you first call `useAddErrorLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddErrorLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addErrorLogMutation, { data, loading, error }] = useAddErrorLogMutation({
 *   variables: {
 *      error: // value for 'error'
 *      componentStack: // value for 'componentStack'
 *      platform: // value for 'platform'
 *      userAgent: // value for 'userAgent'
 *      userMessage: // value for 'userMessage'
 *   },
 * });
 */
export function useAddErrorLogMutation(baseOptions?: Apollo.MutationHookOptions<AddErrorLogMutation, AddErrorLogMutationVariables>) {
        return Apollo.useMutation<AddErrorLogMutation, AddErrorLogMutationVariables>(AddErrorLogDocument, baseOptions);
      }
export type AddErrorLogMutationHookResult = ReturnType<typeof useAddErrorLogMutation>;
export type AddErrorLogMutationResult = Apollo.MutationResult<AddErrorLogMutation>;
export type AddErrorLogMutationOptions = Apollo.BaseMutationOptions<AddErrorLogMutation, AddErrorLogMutationVariables>;
export const AddGroupDocument = gql`
    mutation addGroup($name: String!, $startDate: String!, $frequency: String!, $room: String!, $program: String!, $isPublic: Boolean!, $tags: [String]) {
  addGroup(
    name: $name
    startDate: $startDate
    frequency: $frequency
    room: $room
    program: $program
    isPublic: $isPublic
    tags: $tags
  ) {
    id
  }
}
    `;
export type AddGroupMutationFn = Apollo.MutationFunction<AddGroupMutation, AddGroupMutationVariables>;

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      frequency: // value for 'frequency'
 *      room: // value for 'room'
 *      program: // value for 'program'
 *      isPublic: // value for 'isPublic'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useAddGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddGroupMutation, AddGroupMutationVariables>) {
        return Apollo.useMutation<AddGroupMutation, AddGroupMutationVariables>(AddGroupDocument, baseOptions);
      }
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = Apollo.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = Apollo.BaseMutationOptions<AddGroupMutation, AddGroupMutationVariables>;
export const AddJavaTipToThemeDocument = gql`
    mutation addJavaTipToTheme($theme: ID!, $author: ID, $body: String!) {
  addJavaTipToTheme(theme: $theme, author: $author, body: $body) {
    id
    javaTips {
      id
      author {
        id
      }
    }
  }
}
    `;
export type AddJavaTipToThemeMutationFn = Apollo.MutationFunction<AddJavaTipToThemeMutation, AddJavaTipToThemeMutationVariables>;

/**
 * __useAddJavaTipToThemeMutation__
 *
 * To run a mutation, you first call `useAddJavaTipToThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJavaTipToThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJavaTipToThemeMutation, { data, loading, error }] = useAddJavaTipToThemeMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *      author: // value for 'author'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useAddJavaTipToThemeMutation(baseOptions?: Apollo.MutationHookOptions<AddJavaTipToThemeMutation, AddJavaTipToThemeMutationVariables>) {
        return Apollo.useMutation<AddJavaTipToThemeMutation, AddJavaTipToThemeMutationVariables>(AddJavaTipToThemeDocument, baseOptions);
      }
export type AddJavaTipToThemeMutationHookResult = ReturnType<typeof useAddJavaTipToThemeMutation>;
export type AddJavaTipToThemeMutationResult = Apollo.MutationResult<AddJavaTipToThemeMutation>;
export type AddJavaTipToThemeMutationOptions = Apollo.BaseMutationOptions<AddJavaTipToThemeMutation, AddJavaTipToThemeMutationVariables>;
export const AddMemberFeedbackDocument = gql`
    mutation addMemberFeedback($session: ID!, $user: ID!, $emotion: String!, $currentScreen: Int, $songPlaying: String, $contentType: String, $contentId: String) {
  addMemberFeedback(
    session: $session
    user: $user
    emotion: $emotion
    currentScreen: $currentScreen
    songPlaying: $songPlaying
    contentType: $contentType
    contentId: $contentId
  ) {
    id
  }
}
    `;
export type AddMemberFeedbackMutationFn = Apollo.MutationFunction<AddMemberFeedbackMutation, AddMemberFeedbackMutationVariables>;

/**
 * __useAddMemberFeedbackMutation__
 *
 * To run a mutation, you first call `useAddMemberFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberFeedbackMutation, { data, loading, error }] = useAddMemberFeedbackMutation({
 *   variables: {
 *      session: // value for 'session'
 *      user: // value for 'user'
 *      emotion: // value for 'emotion'
 *      currentScreen: // value for 'currentScreen'
 *      songPlaying: // value for 'songPlaying'
 *      contentType: // value for 'contentType'
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useAddMemberFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<AddMemberFeedbackMutation, AddMemberFeedbackMutationVariables>) {
        return Apollo.useMutation<AddMemberFeedbackMutation, AddMemberFeedbackMutationVariables>(AddMemberFeedbackDocument, baseOptions);
      }
export type AddMemberFeedbackMutationHookResult = ReturnType<typeof useAddMemberFeedbackMutation>;
export type AddMemberFeedbackMutationResult = Apollo.MutationResult<AddMemberFeedbackMutation>;
export type AddMemberFeedbackMutationOptions = Apollo.BaseMutationOptions<AddMemberFeedbackMutation, AddMemberFeedbackMutationVariables>;
export const AddNoteForUserDocument = gql`
    mutation addNoteForUser($user: ID!, $author: ID!, $content: String!) {
  addNoteForUser(user: $user, author: $author, content: $content) {
    id
  }
}
    `;
export type AddNoteForUserMutationFn = Apollo.MutationFunction<AddNoteForUserMutation, AddNoteForUserMutationVariables>;

/**
 * __useAddNoteForUserMutation__
 *
 * To run a mutation, you first call `useAddNoteForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteForUserMutation, { data, loading, error }] = useAddNoteForUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      author: // value for 'author'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useAddNoteForUserMutation(baseOptions?: Apollo.MutationHookOptions<AddNoteForUserMutation, AddNoteForUserMutationVariables>) {
        return Apollo.useMutation<AddNoteForUserMutation, AddNoteForUserMutationVariables>(AddNoteForUserDocument, baseOptions);
      }
export type AddNoteForUserMutationHookResult = ReturnType<typeof useAddNoteForUserMutation>;
export type AddNoteForUserMutationResult = Apollo.MutationResult<AddNoteForUserMutation>;
export type AddNoteForUserMutationOptions = Apollo.BaseMutationOptions<AddNoteForUserMutation, AddNoteForUserMutationVariables>;
export const AddNotificationDocument = gql`
    mutation addNotification($owner: ID!, $type: String!, $user: ID, $group: ID, $session: ID, $message: String!) {
  addNotification(
    owner: $owner
    type: $type
    user: $user
    group: $group
    session: $session
    message: $message
  ) {
    id
    owner {
      id
    }
    type
    user {
      id
    }
    group {
      id
    }
    session {
      id
    }
    message
    seenEh
    actionedEh
    createdAt
  }
}
    `;
export type AddNotificationMutationFn = Apollo.MutationFunction<AddNotificationMutation, AddNotificationMutationVariables>;

/**
 * __useAddNotificationMutation__
 *
 * To run a mutation, you first call `useAddNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNotificationMutation, { data, loading, error }] = useAddNotificationMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      type: // value for 'type'
 *      user: // value for 'user'
 *      group: // value for 'group'
 *      session: // value for 'session'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AddNotificationMutation, AddNotificationMutationVariables>) {
        return Apollo.useMutation<AddNotificationMutation, AddNotificationMutationVariables>(AddNotificationDocument, baseOptions);
      }
export type AddNotificationMutationHookResult = ReturnType<typeof useAddNotificationMutation>;
export type AddNotificationMutationResult = Apollo.MutationResult<AddNotificationMutation>;
export type AddNotificationMutationOptions = Apollo.BaseMutationOptions<AddNotificationMutation, AddNotificationMutationVariables>;
export const AddProfilePicDocument = gql`
    mutation addProfilePic($pic: Upload!) {
  addProfilePic(pic: $pic) {
    id
  }
}
    `;
export type AddProfilePicMutationFn = Apollo.MutationFunction<AddProfilePicMutation, AddProfilePicMutationVariables>;

/**
 * __useAddProfilePicMutation__
 *
 * To run a mutation, you first call `useAddProfilePicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfilePicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfilePicMutation, { data, loading, error }] = useAddProfilePicMutation({
 *   variables: {
 *      pic: // value for 'pic'
 *   },
 * });
 */
export function useAddProfilePicMutation(baseOptions?: Apollo.MutationHookOptions<AddProfilePicMutation, AddProfilePicMutationVariables>) {
        return Apollo.useMutation<AddProfilePicMutation, AddProfilePicMutationVariables>(AddProfilePicDocument, baseOptions);
      }
export type AddProfilePicMutationHookResult = ReturnType<typeof useAddProfilePicMutation>;
export type AddProfilePicMutationResult = Apollo.MutationResult<AddProfilePicMutation>;
export type AddProfilePicMutationOptions = Apollo.BaseMutationOptions<AddProfilePicMutation, AddProfilePicMutationVariables>;
export const AddQuestionnaireDocument = gql`
    mutation addQuestionnaire($member: ID!, $session: ID!, $stage: String!, $optOut: Boolean!, $isDone: Boolean!) {
  addQuestionnaire(
    member: $member
    session: $session
    stage: $stage
    optOut: $optOut
    isDone: $isDone
  ) {
    id
    member
    session
    stage
    optOut
    isDone
  }
}
    `;
export type AddQuestionnaireMutationFn = Apollo.MutationFunction<AddQuestionnaireMutation, AddQuestionnaireMutationVariables>;

/**
 * __useAddQuestionnaireMutation__
 *
 * To run a mutation, you first call `useAddQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionnaireMutation, { data, loading, error }] = useAddQuestionnaireMutation({
 *   variables: {
 *      member: // value for 'member'
 *      session: // value for 'session'
 *      stage: // value for 'stage'
 *      optOut: // value for 'optOut'
 *      isDone: // value for 'isDone'
 *   },
 * });
 */
export function useAddQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionnaireMutation, AddQuestionnaireMutationVariables>) {
        return Apollo.useMutation<AddQuestionnaireMutation, AddQuestionnaireMutationVariables>(AddQuestionnaireDocument, baseOptions);
      }
export type AddQuestionnaireMutationHookResult = ReturnType<typeof useAddQuestionnaireMutation>;
export type AddQuestionnaireMutationResult = Apollo.MutationResult<AddQuestionnaireMutation>;
export type AddQuestionnaireMutationOptions = Apollo.BaseMutationOptions<AddQuestionnaireMutation, AddQuestionnaireMutationVariables>;
export const AddQuoteDocument = gql`
    mutation addQuote($quote: String!, $author: String!, $theme: ID!) {
  addQuote(quote: $quote, author: $author, theme: $theme) {
    quotes {
      id
      author
      quote
    }
  }
}
    `;
export type AddQuoteMutationFn = Apollo.MutationFunction<AddQuoteMutation, AddQuoteMutationVariables>;

/**
 * __useAddQuoteMutation__
 *
 * To run a mutation, you first call `useAddQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuoteMutation, { data, loading, error }] = useAddQuoteMutation({
 *   variables: {
 *      quote: // value for 'quote'
 *      author: // value for 'author'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useAddQuoteMutation(baseOptions?: Apollo.MutationHookOptions<AddQuoteMutation, AddQuoteMutationVariables>) {
        return Apollo.useMutation<AddQuoteMutation, AddQuoteMutationVariables>(AddQuoteDocument, baseOptions);
      }
export type AddQuoteMutationHookResult = ReturnType<typeof useAddQuoteMutation>;
export type AddQuoteMutationResult = Apollo.MutationResult<AddQuoteMutation>;
export type AddQuoteMutationOptions = Apollo.BaseMutationOptions<AddQuoteMutation, AddQuoteMutationVariables>;
export const AddReflectionToThemeDocument = gql`
    mutation addReflectionToTheme($theme: ID!, $question: String) {
  addReflectionToTheme(theme: $theme, question: $question) {
    reflection {
      id
      question
    }
  }
}
    `;
export type AddReflectionToThemeMutationFn = Apollo.MutationFunction<AddReflectionToThemeMutation, AddReflectionToThemeMutationVariables>;

/**
 * __useAddReflectionToThemeMutation__
 *
 * To run a mutation, you first call `useAddReflectionToThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReflectionToThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReflectionToThemeMutation, { data, loading, error }] = useAddReflectionToThemeMutation({
 *   variables: {
 *      theme: // value for 'theme'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useAddReflectionToThemeMutation(baseOptions?: Apollo.MutationHookOptions<AddReflectionToThemeMutation, AddReflectionToThemeMutationVariables>) {
        return Apollo.useMutation<AddReflectionToThemeMutation, AddReflectionToThemeMutationVariables>(AddReflectionToThemeDocument, baseOptions);
      }
export type AddReflectionToThemeMutationHookResult = ReturnType<typeof useAddReflectionToThemeMutation>;
export type AddReflectionToThemeMutationResult = Apollo.MutationResult<AddReflectionToThemeMutation>;
export type AddReflectionToThemeMutationOptions = Apollo.BaseMutationOptions<AddReflectionToThemeMutation, AddReflectionToThemeMutationVariables>;
export const AddSessionsDocument = gql`
    mutation addSessions($date: DateISO!, $group: ID!, $theme: ID!, $freq: String!) {
  addSessions(date: $date, group: $group, theme: $theme, freq: $freq) {
    id
  }
}
    `;
export type AddSessionsMutationFn = Apollo.MutationFunction<AddSessionsMutation, AddSessionsMutationVariables>;

/**
 * __useAddSessionsMutation__
 *
 * To run a mutation, you first call `useAddSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSessionsMutation, { data, loading, error }] = useAddSessionsMutation({
 *   variables: {
 *      date: // value for 'date'
 *      group: // value for 'group'
 *      theme: // value for 'theme'
 *      freq: // value for 'freq'
 *   },
 * });
 */
export function useAddSessionsMutation(baseOptions?: Apollo.MutationHookOptions<AddSessionsMutation, AddSessionsMutationVariables>) {
        return Apollo.useMutation<AddSessionsMutation, AddSessionsMutationVariables>(AddSessionsDocument, baseOptions);
      }
export type AddSessionsMutationHookResult = ReturnType<typeof useAddSessionsMutation>;
export type AddSessionsMutationResult = Apollo.MutationResult<AddSessionsMutation>;
export type AddSessionsMutationOptions = Apollo.BaseMutationOptions<AddSessionsMutation, AddSessionsMutationVariables>;
export const AddTagToGroupDocument = gql`
    mutation addTagToGroup($tag: String!, $group: ID!) {
  addTagToGroup(tag: $tag, group: $group) {
    id
    name
  }
}
    `;
export type AddTagToGroupMutationFn = Apollo.MutationFunction<AddTagToGroupMutation, AddTagToGroupMutationVariables>;

/**
 * __useAddTagToGroupMutation__
 *
 * To run a mutation, you first call `useAddTagToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToGroupMutation, { data, loading, error }] = useAddTagToGroupMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAddTagToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddTagToGroupMutation, AddTagToGroupMutationVariables>) {
        return Apollo.useMutation<AddTagToGroupMutation, AddTagToGroupMutationVariables>(AddTagToGroupDocument, baseOptions);
      }
export type AddTagToGroupMutationHookResult = ReturnType<typeof useAddTagToGroupMutation>;
export type AddTagToGroupMutationResult = Apollo.MutationResult<AddTagToGroupMutation>;
export type AddTagToGroupMutationOptions = Apollo.BaseMutationOptions<AddTagToGroupMutation, AddTagToGroupMutationVariables>;
export const AddThemeDocument = gql`
    mutation addTheme($name: String!, $category: String!, $imageUrl: String, $program: String!) {
  addTheme(
    name: $name
    category: $category
    imageUrl: $imageUrl
    program: $program
  ) {
    id
  }
}
    `;
export type AddThemeMutationFn = Apollo.MutationFunction<AddThemeMutation, AddThemeMutationVariables>;

/**
 * __useAddThemeMutation__
 *
 * To run a mutation, you first call `useAddThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addThemeMutation, { data, loading, error }] = useAddThemeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      imageUrl: // value for 'imageUrl'
 *      program: // value for 'program'
 *   },
 * });
 */
export function useAddThemeMutation(baseOptions?: Apollo.MutationHookOptions<AddThemeMutation, AddThemeMutationVariables>) {
        return Apollo.useMutation<AddThemeMutation, AddThemeMutationVariables>(AddThemeDocument, baseOptions);
      }
export type AddThemeMutationHookResult = ReturnType<typeof useAddThemeMutation>;
export type AddThemeMutationResult = Apollo.MutationResult<AddThemeMutation>;
export type AddThemeMutationOptions = Apollo.BaseMutationOptions<AddThemeMutation, AddThemeMutationVariables>;
export const AddUserDocument = gql`
    mutation addUser($firstName: String!, $lastName: String!, $phone: String, $email: String!, $password: String, $role: String!, $nickname: String, $country: String, $postalcode: String, $ageGroup: String, $living: String, $status: Boolean, $optOut: Boolean, $validUntil: DateISO) {
  addUser(
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    email: $email
    password: $password
    role: $role
    nickname: $nickname
    country: $country
    postalcode: $postalcode
    ageGroup: $ageGroup
    living: $living
    status: $status
    optOut: $optOut
    validUntil: $validUntil
  ) {
    id
  }
}
    `;
export type AddUserMutationFn = Apollo.MutationFunction<AddUserMutation, AddUserMutationVariables>;

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      role: // value for 'role'
 *      nickname: // value for 'nickname'
 *      country: // value for 'country'
 *      postalcode: // value for 'postalcode'
 *      ageGroup: // value for 'ageGroup'
 *      living: // value for 'living'
 *      status: // value for 'status'
 *      optOut: // value for 'optOut'
 *      validUntil: // value for 'validUntil'
 *   },
 * });
 */
export function useAddUserMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutation, AddUserMutationVariables>) {
        return Apollo.useMutation<AddUserMutation, AddUserMutationVariables>(AddUserDocument, baseOptions);
      }
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>;
export type AddUserMutationResult = Apollo.MutationResult<AddUserMutation>;
export type AddUserMutationOptions = Apollo.BaseMutationOptions<AddUserMutation, AddUserMutationVariables>;
export const AddUserMessageToErrorLogDocument = gql`
    mutation addUserMessageToErrorLog($id: ID!, $userMessage: String) {
  addUserMessageToErrorLog(id: $id, userMessage: $userMessage) {
    id
    userMessage
  }
}
    `;
export type AddUserMessageToErrorLogMutationFn = Apollo.MutationFunction<AddUserMessageToErrorLogMutation, AddUserMessageToErrorLogMutationVariables>;

/**
 * __useAddUserMessageToErrorLogMutation__
 *
 * To run a mutation, you first call `useAddUserMessageToErrorLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMessageToErrorLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMessageToErrorLogMutation, { data, loading, error }] = useAddUserMessageToErrorLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userMessage: // value for 'userMessage'
 *   },
 * });
 */
export function useAddUserMessageToErrorLogMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMessageToErrorLogMutation, AddUserMessageToErrorLogMutationVariables>) {
        return Apollo.useMutation<AddUserMessageToErrorLogMutation, AddUserMessageToErrorLogMutationVariables>(AddUserMessageToErrorLogDocument, baseOptions);
      }
export type AddUserMessageToErrorLogMutationHookResult = ReturnType<typeof useAddUserMessageToErrorLogMutation>;
export type AddUserMessageToErrorLogMutationResult = Apollo.MutationResult<AddUserMessageToErrorLogMutation>;
export type AddUserMessageToErrorLogMutationOptions = Apollo.BaseMutationOptions<AddUserMessageToErrorLogMutation, AddUserMessageToErrorLogMutationVariables>;
export const AddUserToGroupDocument = gql`
    mutation addUserToGroup($user: ID!, $as: String!, $group: ID!) {
  addUserToGroup(user: $user, as: $as, group: $group) {
    id
    name
  }
}
    `;
export type AddUserToGroupMutationFn = Apollo.MutationFunction<AddUserToGroupMutation, AddUserToGroupMutationVariables>;

/**
 * __useAddUserToGroupMutation__
 *
 * To run a mutation, you first call `useAddUserToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToGroupMutation, { data, loading, error }] = useAddUserToGroupMutation({
 *   variables: {
 *      user: // value for 'user'
 *      as: // value for 'as'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAddUserToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>) {
        return Apollo.useMutation<AddUserToGroupMutation, AddUserToGroupMutationVariables>(AddUserToGroupDocument, baseOptions);
      }
export type AddUserToGroupMutationHookResult = ReturnType<typeof useAddUserToGroupMutation>;
export type AddUserToGroupMutationResult = Apollo.MutationResult<AddUserToGroupMutation>;
export type AddUserToGroupMutationOptions = Apollo.BaseMutationOptions<AddUserToGroupMutation, AddUserToGroupMutationVariables>;
export const AddUserToSessionDocument = gql`
    mutation addUserToSession($user: ID!, $session: ID!, $as: String!) {
  addUserToSession(user: $user, session: $session, as: $as) {
    id
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
        role
      }
    }
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
        role
      }
    }
  }
}
    `;
export type AddUserToSessionMutationFn = Apollo.MutationFunction<AddUserToSessionMutation, AddUserToSessionMutationVariables>;

/**
 * __useAddUserToSessionMutation__
 *
 * To run a mutation, you first call `useAddUserToSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToSessionMutation, { data, loading, error }] = useAddUserToSessionMutation({
 *   variables: {
 *      user: // value for 'user'
 *      session: // value for 'session'
 *      as: // value for 'as'
 *   },
 * });
 */
export function useAddUserToSessionMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToSessionMutation, AddUserToSessionMutationVariables>) {
        return Apollo.useMutation<AddUserToSessionMutation, AddUserToSessionMutationVariables>(AddUserToSessionDocument, baseOptions);
      }
export type AddUserToSessionMutationHookResult = ReturnType<typeof useAddUserToSessionMutation>;
export type AddUserToSessionMutationResult = Apollo.MutationResult<AddUserToSessionMutation>;
export type AddUserToSessionMutationOptions = Apollo.BaseMutationOptions<AddUserToSessionMutation, AddUserToSessionMutationVariables>;
export const AddUserToWaitingRoomDocument = gql`
    mutation addUserToWaitingRoom($user: ID!, $session: ID!) {
  addUserToWaitingRoom(user: $user, session: $session) {
    id
  }
}
    `;
export type AddUserToWaitingRoomMutationFn = Apollo.MutationFunction<AddUserToWaitingRoomMutation, AddUserToWaitingRoomMutationVariables>;

/**
 * __useAddUserToWaitingRoomMutation__
 *
 * To run a mutation, you first call `useAddUserToWaitingRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToWaitingRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToWaitingRoomMutation, { data, loading, error }] = useAddUserToWaitingRoomMutation({
 *   variables: {
 *      user: // value for 'user'
 *      session: // value for 'session'
 *   },
 * });
 */
export function useAddUserToWaitingRoomMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToWaitingRoomMutation, AddUserToWaitingRoomMutationVariables>) {
        return Apollo.useMutation<AddUserToWaitingRoomMutation, AddUserToWaitingRoomMutationVariables>(AddUserToWaitingRoomDocument, baseOptions);
      }
export type AddUserToWaitingRoomMutationHookResult = ReturnType<typeof useAddUserToWaitingRoomMutation>;
export type AddUserToWaitingRoomMutationResult = Apollo.MutationResult<AddUserToWaitingRoomMutation>;
export type AddUserToWaitingRoomMutationOptions = Apollo.BaseMutationOptions<AddUserToWaitingRoomMutation, AddUserToWaitingRoomMutationVariables>;
export const AmIApprovedDocument = gql`
    query amIApproved($session: ID!) {
  amIApproved(session: $session)
}
    `;

/**
 * __useAmIApprovedQuery__
 *
 * To run a query within a React component, call `useAmIApprovedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmIApprovedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmIApprovedQuery({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useAmIApprovedQuery(baseOptions: Apollo.QueryHookOptions<AmIApprovedQuery, AmIApprovedQueryVariables>) {
        return Apollo.useQuery<AmIApprovedQuery, AmIApprovedQueryVariables>(AmIApprovedDocument, baseOptions);
      }
export function useAmIApprovedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AmIApprovedQuery, AmIApprovedQueryVariables>) {
          return Apollo.useLazyQuery<AmIApprovedQuery, AmIApprovedQueryVariables>(AmIApprovedDocument, baseOptions);
        }
export type AmIApprovedQueryHookResult = ReturnType<typeof useAmIApprovedQuery>;
export type AmIApprovedLazyQueryHookResult = ReturnType<typeof useAmIApprovedLazyQuery>;
export type AmIApprovedQueryResult = Apollo.QueryResult<AmIApprovedQuery, AmIApprovedQueryVariables>;
export const AnswerDocument = gql`
    query answer($id: ID!) {
  answer(id: $id) {
    id
    number
    body
    value
    createdAt
  }
}
    `;

/**
 * __useAnswerQuery__
 *
 * To run a query within a React component, call `useAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnswerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnswerQuery(baseOptions: Apollo.QueryHookOptions<AnswerQuery, AnswerQueryVariables>) {
        return Apollo.useQuery<AnswerQuery, AnswerQueryVariables>(AnswerDocument, baseOptions);
      }
export function useAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnswerQuery, AnswerQueryVariables>) {
          return Apollo.useLazyQuery<AnswerQuery, AnswerQueryVariables>(AnswerDocument, baseOptions);
        }
export type AnswerQueryHookResult = ReturnType<typeof useAnswerQuery>;
export type AnswerLazyQueryHookResult = ReturnType<typeof useAnswerLazyQuery>;
export type AnswerQueryResult = Apollo.QueryResult<AnswerQuery, AnswerQueryVariables>;
export const AnswerListDocument = gql`
    query answerList {
  answerList {
    id
    number
    body
    value
    createdAt
  }
}
    `;

/**
 * __useAnswerListQuery__
 *
 * To run a query within a React component, call `useAnswerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnswerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnswerListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAnswerListQuery(baseOptions?: Apollo.QueryHookOptions<AnswerListQuery, AnswerListQueryVariables>) {
        return Apollo.useQuery<AnswerListQuery, AnswerListQueryVariables>(AnswerListDocument, baseOptions);
      }
export function useAnswerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnswerListQuery, AnswerListQueryVariables>) {
          return Apollo.useLazyQuery<AnswerListQuery, AnswerListQueryVariables>(AnswerListDocument, baseOptions);
        }
export type AnswerListQueryHookResult = ReturnType<typeof useAnswerListQuery>;
export type AnswerListLazyQueryHookResult = ReturnType<typeof useAnswerListLazyQuery>;
export type AnswerListQueryResult = Apollo.QueryResult<AnswerListQuery, AnswerListQueryVariables>;
export const ChangeDateTimeDocument = gql`
    mutation changeDateTime($group: ID!, $date: DateISO!) {
  changeDateTime(group: $group, date: $date) {
    id
  }
}
    `;
export type ChangeDateTimeMutationFn = Apollo.MutationFunction<ChangeDateTimeMutation, ChangeDateTimeMutationVariables>;

/**
 * __useChangeDateTimeMutation__
 *
 * To run a mutation, you first call `useChangeDateTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDateTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDateTimeMutation, { data, loading, error }] = useChangeDateTimeMutation({
 *   variables: {
 *      group: // value for 'group'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useChangeDateTimeMutation(baseOptions?: Apollo.MutationHookOptions<ChangeDateTimeMutation, ChangeDateTimeMutationVariables>) {
        return Apollo.useMutation<ChangeDateTimeMutation, ChangeDateTimeMutationVariables>(ChangeDateTimeDocument, baseOptions);
      }
export type ChangeDateTimeMutationHookResult = ReturnType<typeof useChangeDateTimeMutation>;
export type ChangeDateTimeMutationResult = Apollo.MutationResult<ChangeDateTimeMutation>;
export type ChangeDateTimeMutationOptions = Apollo.BaseMutationOptions<ChangeDateTimeMutation, ChangeDateTimeMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $newPassword: String!) {
  changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangePasswordByResetDocument = gql`
    mutation changePasswordByReset($newPassword: String!, $token: String!) {
  changePasswordByReset(newPassword: $newPassword, token: $token)
}
    `;
export type ChangePasswordByResetMutationFn = Apollo.MutationFunction<ChangePasswordByResetMutation, ChangePasswordByResetMutationVariables>;

/**
 * __useChangePasswordByResetMutation__
 *
 * To run a mutation, you first call `useChangePasswordByResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordByResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordByResetMutation, { data, loading, error }] = useChangePasswordByResetMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useChangePasswordByResetMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordByResetMutation, ChangePasswordByResetMutationVariables>) {
        return Apollo.useMutation<ChangePasswordByResetMutation, ChangePasswordByResetMutationVariables>(ChangePasswordByResetDocument, baseOptions);
      }
export type ChangePasswordByResetMutationHookResult = ReturnType<typeof useChangePasswordByResetMutation>;
export type ChangePasswordByResetMutationResult = Apollo.MutationResult<ChangePasswordByResetMutation>;
export type ChangePasswordByResetMutationOptions = Apollo.BaseMutationOptions<ChangePasswordByResetMutation, ChangePasswordByResetMutationVariables>;
export const ChangeThemeDocument = gql`
    mutation changeTheme($session: ID!, $theme: ID!) {
  changeTheme(session: $session, theme: $theme) {
    id
  }
}
    `;
export type ChangeThemeMutationFn = Apollo.MutationFunction<ChangeThemeMutation, ChangeThemeMutationVariables>;

/**
 * __useChangeThemeMutation__
 *
 * To run a mutation, you first call `useChangeThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeThemeMutation, { data, loading, error }] = useChangeThemeMutation({
 *   variables: {
 *      session: // value for 'session'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useChangeThemeMutation(baseOptions?: Apollo.MutationHookOptions<ChangeThemeMutation, ChangeThemeMutationVariables>) {
        return Apollo.useMutation<ChangeThemeMutation, ChangeThemeMutationVariables>(ChangeThemeDocument, baseOptions);
      }
export type ChangeThemeMutationHookResult = ReturnType<typeof useChangeThemeMutation>;
export type ChangeThemeMutationResult = Apollo.MutationResult<ChangeThemeMutation>;
export type ChangeThemeMutationOptions = Apollo.BaseMutationOptions<ChangeThemeMutation, ChangeThemeMutationVariables>;
export const CheckQuestionnaireExistsDocument = gql`
    query checkQuestionnaireExists($member: ID!, $session: ID!, $stage: String!) {
  checkQuestionnaireExists(member: $member, session: $session, stage: $stage)
}
    `;

/**
 * __useCheckQuestionnaireExistsQuery__
 *
 * To run a query within a React component, call `useCheckQuestionnaireExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckQuestionnaireExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckQuestionnaireExistsQuery({
 *   variables: {
 *      member: // value for 'member'
 *      session: // value for 'session'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useCheckQuestionnaireExistsQuery(baseOptions: Apollo.QueryHookOptions<CheckQuestionnaireExistsQuery, CheckQuestionnaireExistsQueryVariables>) {
        return Apollo.useQuery<CheckQuestionnaireExistsQuery, CheckQuestionnaireExistsQueryVariables>(CheckQuestionnaireExistsDocument, baseOptions);
      }
export function useCheckQuestionnaireExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckQuestionnaireExistsQuery, CheckQuestionnaireExistsQueryVariables>) {
          return Apollo.useLazyQuery<CheckQuestionnaireExistsQuery, CheckQuestionnaireExistsQueryVariables>(CheckQuestionnaireExistsDocument, baseOptions);
        }
export type CheckQuestionnaireExistsQueryHookResult = ReturnType<typeof useCheckQuestionnaireExistsQuery>;
export type CheckQuestionnaireExistsLazyQueryHookResult = ReturnType<typeof useCheckQuestionnaireExistsLazyQuery>;
export type CheckQuestionnaireExistsQueryResult = Apollo.QueryResult<CheckQuestionnaireExistsQuery, CheckQuestionnaireExistsQueryVariables>;
export const CloseSessionsDocument = gql`
    query closeSessions($offset: Int, $limit: Int) {
  closeSessions(offset: $offset, limit: $limit) {
    id
    date
    program
    group {
      name
    }
    theme {
      name
      imageUrl
    }
    status
  }
}
    `;

/**
 * __useCloseSessionsQuery__
 *
 * To run a query within a React component, call `useCloseSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloseSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloseSessionsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCloseSessionsQuery(baseOptions?: Apollo.QueryHookOptions<CloseSessionsQuery, CloseSessionsQueryVariables>) {
        return Apollo.useQuery<CloseSessionsQuery, CloseSessionsQueryVariables>(CloseSessionsDocument, baseOptions);
      }
export function useCloseSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CloseSessionsQuery, CloseSessionsQueryVariables>) {
          return Apollo.useLazyQuery<CloseSessionsQuery, CloseSessionsQueryVariables>(CloseSessionsDocument, baseOptions);
        }
export type CloseSessionsQueryHookResult = ReturnType<typeof useCloseSessionsQuery>;
export type CloseSessionsLazyQueryHookResult = ReturnType<typeof useCloseSessionsLazyQuery>;
export type CloseSessionsQueryResult = Apollo.QueryResult<CloseSessionsQuery, CloseSessionsQueryVariables>;
export const DeleteAnswerDocument = gql`
    mutation deleteAnswer($id: ID!, $questionnaire: ID!) {
  deleteAnswer(id: $id, questionnaire: $questionnaire) {
    id
  }
}
    `;
export type DeleteAnswerMutationFn = Apollo.MutationFunction<DeleteAnswerMutation, DeleteAnswerMutationVariables>;

/**
 * __useDeleteAnswerMutation__
 *
 * To run a mutation, you first call `useDeleteAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnswerMutation, { data, loading, error }] = useDeleteAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      questionnaire: // value for 'questionnaire'
 *   },
 * });
 */
export function useDeleteAnswerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnswerMutation, DeleteAnswerMutationVariables>) {
        return Apollo.useMutation<DeleteAnswerMutation, DeleteAnswerMutationVariables>(DeleteAnswerDocument, baseOptions);
      }
export type DeleteAnswerMutationHookResult = ReturnType<typeof useDeleteAnswerMutation>;
export type DeleteAnswerMutationResult = Apollo.MutationResult<DeleteAnswerMutation>;
export type DeleteAnswerMutationOptions = Apollo.BaseMutationOptions<DeleteAnswerMutation, DeleteAnswerMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation deleteGroup($id: ID!) {
  deleteGroup(id: $id) {
    id
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, baseOptions);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($id: ID!) {
  deleteNotification(id: $id) {
    id
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, baseOptions);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export const DeleteQuestionnaireDocument = gql`
    mutation deleteQuestionnaire($id: ID!) {
  deleteQuestionnaire(id: $id) {
    id
  }
}
    `;
export type DeleteQuestionnaireMutationFn = Apollo.MutationFunction<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;

/**
 * __useDeleteQuestionnaireMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionnaireMutation, { data, loading, error }] = useDeleteQuestionnaireMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>) {
        return Apollo.useMutation<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>(DeleteQuestionnaireDocument, baseOptions);
      }
export type DeleteQuestionnaireMutationHookResult = ReturnType<typeof useDeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationResult = Apollo.MutationResult<DeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: ID!) {
  deleteUser(id: $id) {
    id
    firstName
    nickname
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DenyRequestDocument = gql`
    mutation denyRequest($user: ID!, $group: ID!) {
  denyRequest(user: $user, group: $group) {
    id
    name
  }
}
    `;
export type DenyRequestMutationFn = Apollo.MutationFunction<DenyRequestMutation, DenyRequestMutationVariables>;

/**
 * __useDenyRequestMutation__
 *
 * To run a mutation, you first call `useDenyRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenyRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denyRequestMutation, { data, loading, error }] = useDenyRequestMutation({
 *   variables: {
 *      user: // value for 'user'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useDenyRequestMutation(baseOptions?: Apollo.MutationHookOptions<DenyRequestMutation, DenyRequestMutationVariables>) {
        return Apollo.useMutation<DenyRequestMutation, DenyRequestMutationVariables>(DenyRequestDocument, baseOptions);
      }
export type DenyRequestMutationHookResult = ReturnType<typeof useDenyRequestMutation>;
export type DenyRequestMutationResult = Apollo.MutationResult<DenyRequestMutation>;
export type DenyRequestMutationOptions = Apollo.BaseMutationOptions<DenyRequestMutation, DenyRequestMutationVariables>;
export const EditAllMuteStateDocument = gql`
    mutation editAllMuteState($session: ID!, $muteState: Boolean!) {
  editAllMuteState(session: $session, muteState: $muteState) {
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      muteState
    }
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      muteState
    }
  }
}
    `;
export type EditAllMuteStateMutationFn = Apollo.MutationFunction<EditAllMuteStateMutation, EditAllMuteStateMutationVariables>;

/**
 * __useEditAllMuteStateMutation__
 *
 * To run a mutation, you first call `useEditAllMuteStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAllMuteStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAllMuteStateMutation, { data, loading, error }] = useEditAllMuteStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      muteState: // value for 'muteState'
 *   },
 * });
 */
export function useEditAllMuteStateMutation(baseOptions?: Apollo.MutationHookOptions<EditAllMuteStateMutation, EditAllMuteStateMutationVariables>) {
        return Apollo.useMutation<EditAllMuteStateMutation, EditAllMuteStateMutationVariables>(EditAllMuteStateDocument, baseOptions);
      }
export type EditAllMuteStateMutationHookResult = ReturnType<typeof useEditAllMuteStateMutation>;
export type EditAllMuteStateMutationResult = Apollo.MutationResult<EditAllMuteStateMutation>;
export type EditAllMuteStateMutationOptions = Apollo.BaseMutationOptions<EditAllMuteStateMutation, EditAllMuteStateMutationVariables>;
export const EditAllRoomStateDocument = gql`
    mutation editAllRoomState($session: ID!) {
  editAllRoomState(session: $session) {
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      muteState
    }
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      muteState
    }
  }
}
    `;
export type EditAllRoomStateMutationFn = Apollo.MutationFunction<EditAllRoomStateMutation, EditAllRoomStateMutationVariables>;

/**
 * __useEditAllRoomStateMutation__
 *
 * To run a mutation, you first call `useEditAllRoomStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAllRoomStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAllRoomStateMutation, { data, loading, error }] = useEditAllRoomStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useEditAllRoomStateMutation(baseOptions?: Apollo.MutationHookOptions<EditAllRoomStateMutation, EditAllRoomStateMutationVariables>) {
        return Apollo.useMutation<EditAllRoomStateMutation, EditAllRoomStateMutationVariables>(EditAllRoomStateDocument, baseOptions);
      }
export type EditAllRoomStateMutationHookResult = ReturnType<typeof useEditAllRoomStateMutation>;
export type EditAllRoomStateMutationResult = Apollo.MutationResult<EditAllRoomStateMutation>;
export type EditAllRoomStateMutationOptions = Apollo.BaseMutationOptions<EditAllRoomStateMutation, EditAllRoomStateMutationVariables>;
export const EditAllVidStateDocument = gql`
    mutation editAllVidState($session: ID!, $vidState: Boolean!) {
  editAllVidState(session: $session, vidState: $vidState) {
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      vidState
    }
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
      }
      sessionRole
      vidState
    }
  }
}
    `;
export type EditAllVidStateMutationFn = Apollo.MutationFunction<EditAllVidStateMutation, EditAllVidStateMutationVariables>;

/**
 * __useEditAllVidStateMutation__
 *
 * To run a mutation, you first call `useEditAllVidStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAllVidStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAllVidStateMutation, { data, loading, error }] = useEditAllVidStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      vidState: // value for 'vidState'
 *   },
 * });
 */
export function useEditAllVidStateMutation(baseOptions?: Apollo.MutationHookOptions<EditAllVidStateMutation, EditAllVidStateMutationVariables>) {
        return Apollo.useMutation<EditAllVidStateMutation, EditAllVidStateMutationVariables>(EditAllVidStateDocument, baseOptions);
      }
export type EditAllVidStateMutationHookResult = ReturnType<typeof useEditAllVidStateMutation>;
export type EditAllVidStateMutationResult = Apollo.MutationResult<EditAllVidStateMutation>;
export type EditAllVidStateMutationOptions = Apollo.BaseMutationOptions<EditAllVidStateMutation, EditAllVidStateMutationVariables>;
export const EditAnswerDocument = gql`
    mutation editAnswer($id: ID!, $questionnaire: ID!, $number: Int!, $body: String!, $value: Int!) {
  editAnswer(
    id: $id
    questionnaire: $questionnaire
    number: $number
    body: $body
    value: $value
  ) {
    id
  }
}
    `;
export type EditAnswerMutationFn = Apollo.MutationFunction<EditAnswerMutation, EditAnswerMutationVariables>;

/**
 * __useEditAnswerMutation__
 *
 * To run a mutation, you first call `useEditAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAnswerMutation, { data, loading, error }] = useEditAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      questionnaire: // value for 'questionnaire'
 *      number: // value for 'number'
 *      body: // value for 'body'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useEditAnswerMutation(baseOptions?: Apollo.MutationHookOptions<EditAnswerMutation, EditAnswerMutationVariables>) {
        return Apollo.useMutation<EditAnswerMutation, EditAnswerMutationVariables>(EditAnswerDocument, baseOptions);
      }
export type EditAnswerMutationHookResult = ReturnType<typeof useEditAnswerMutation>;
export type EditAnswerMutationResult = Apollo.MutationResult<EditAnswerMutation>;
export type EditAnswerMutationOptions = Apollo.BaseMutationOptions<EditAnswerMutation, EditAnswerMutationVariables>;
export const EditDiscussionFromThemeDocument = gql`
    mutation editDiscussionFromTheme($id: ID!, $theme: ID!, $question: String) {
  editDiscussionFromTheme(id: $id, theme: $theme, question: $question) {
    discussions {
      id
      question
    }
  }
}
    `;
export type EditDiscussionFromThemeMutationFn = Apollo.MutationFunction<EditDiscussionFromThemeMutation, EditDiscussionFromThemeMutationVariables>;

/**
 * __useEditDiscussionFromThemeMutation__
 *
 * To run a mutation, you first call `useEditDiscussionFromThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDiscussionFromThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDiscussionFromThemeMutation, { data, loading, error }] = useEditDiscussionFromThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useEditDiscussionFromThemeMutation(baseOptions?: Apollo.MutationHookOptions<EditDiscussionFromThemeMutation, EditDiscussionFromThemeMutationVariables>) {
        return Apollo.useMutation<EditDiscussionFromThemeMutation, EditDiscussionFromThemeMutationVariables>(EditDiscussionFromThemeDocument, baseOptions);
      }
export type EditDiscussionFromThemeMutationHookResult = ReturnType<typeof useEditDiscussionFromThemeMutation>;
export type EditDiscussionFromThemeMutationResult = Apollo.MutationResult<EditDiscussionFromThemeMutation>;
export type EditDiscussionFromThemeMutationOptions = Apollo.BaseMutationOptions<EditDiscussionFromThemeMutation, EditDiscussionFromThemeMutationVariables>;
export const EditGroupDocument = gql`
    mutation editGroup($id: ID!, $name: String, $startDate: DateISO, $frequency: String, $room: String, $program: String, $isPublic: Boolean, $tags: [String]) {
  editGroup(
    id: $id
    name: $name
    startDate: $startDate
    frequency: $frequency
    room: $room
    program: $program
    isPublic: $isPublic
    tags: $tags
  ) {
    id
  }
}
    `;
export type EditGroupMutationFn = Apollo.MutationFunction<EditGroupMutation, EditGroupMutationVariables>;

/**
 * __useEditGroupMutation__
 *
 * To run a mutation, you first call `useEditGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGroupMutation, { data, loading, error }] = useEditGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      frequency: // value for 'frequency'
 *      room: // value for 'room'
 *      program: // value for 'program'
 *      isPublic: // value for 'isPublic'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useEditGroupMutation(baseOptions?: Apollo.MutationHookOptions<EditGroupMutation, EditGroupMutationVariables>) {
        return Apollo.useMutation<EditGroupMutation, EditGroupMutationVariables>(EditGroupDocument, baseOptions);
      }
export type EditGroupMutationHookResult = ReturnType<typeof useEditGroupMutation>;
export type EditGroupMutationResult = Apollo.MutationResult<EditGroupMutation>;
export type EditGroupMutationOptions = Apollo.BaseMutationOptions<EditGroupMutation, EditGroupMutationVariables>;
export const EditNotificationDocument = gql`
    mutation editNotification($id: ID!, $owner: ID, $type: String, $user: ID, $group: ID, $session: ID, $message: String, $seenEh: Boolean, $actionedEh: Boolean) {
  editNotification(
    id: $id
    owner: $owner
    type: $type
    user: $user
    group: $group
    session: $session
    message: $message
    seenEh: $seenEh
    actionedEh: $actionedEh
  ) {
    id
    owner {
      id
    }
    type
    user {
      id
    }
    group {
      id
    }
    session {
      id
    }
    message
    seenEh
    actionedEh
    createdAt
  }
}
    `;
export type EditNotificationMutationFn = Apollo.MutationFunction<EditNotificationMutation, EditNotificationMutationVariables>;

/**
 * __useEditNotificationMutation__
 *
 * To run a mutation, you first call `useEditNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNotificationMutation, { data, loading, error }] = useEditNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      owner: // value for 'owner'
 *      type: // value for 'type'
 *      user: // value for 'user'
 *      group: // value for 'group'
 *      session: // value for 'session'
 *      message: // value for 'message'
 *      seenEh: // value for 'seenEh'
 *      actionedEh: // value for 'actionedEh'
 *   },
 * });
 */
export function useEditNotificationMutation(baseOptions?: Apollo.MutationHookOptions<EditNotificationMutation, EditNotificationMutationVariables>) {
        return Apollo.useMutation<EditNotificationMutation, EditNotificationMutationVariables>(EditNotificationDocument, baseOptions);
      }
export type EditNotificationMutationHookResult = ReturnType<typeof useEditNotificationMutation>;
export type EditNotificationMutationResult = Apollo.MutationResult<EditNotificationMutation>;
export type EditNotificationMutationOptions = Apollo.BaseMutationOptions<EditNotificationMutation, EditNotificationMutationVariables>;
export const EditQuestionnaireDocument = gql`
    mutation editQuestionnaire($member: ID!, $session: ID!, $stage: String!, $optOut: Boolean, $isDone: Boolean) {
  editQuestionnaire(
    member: $member
    session: $session
    stage: $stage
    optOut: $optOut
    isDone: $isDone
  ) {
    id
    member
    session
    stage
    answers {
      id
      number
      body
      value
      createdAt
    }
    optOut
    isDone
  }
}
    `;
export type EditQuestionnaireMutationFn = Apollo.MutationFunction<EditQuestionnaireMutation, EditQuestionnaireMutationVariables>;

/**
 * __useEditQuestionnaireMutation__
 *
 * To run a mutation, you first call `useEditQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editQuestionnaireMutation, { data, loading, error }] = useEditQuestionnaireMutation({
 *   variables: {
 *      member: // value for 'member'
 *      session: // value for 'session'
 *      stage: // value for 'stage'
 *      optOut: // value for 'optOut'
 *      isDone: // value for 'isDone'
 *   },
 * });
 */
export function useEditQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<EditQuestionnaireMutation, EditQuestionnaireMutationVariables>) {
        return Apollo.useMutation<EditQuestionnaireMutation, EditQuestionnaireMutationVariables>(EditQuestionnaireDocument, baseOptions);
      }
export type EditQuestionnaireMutationHookResult = ReturnType<typeof useEditQuestionnaireMutation>;
export type EditQuestionnaireMutationResult = Apollo.MutationResult<EditQuestionnaireMutation>;
export type EditQuestionnaireMutationOptions = Apollo.BaseMutationOptions<EditQuestionnaireMutation, EditQuestionnaireMutationVariables>;
export const EditQuoteInThemeDocument = gql`
    mutation editQuoteInTheme($quoteId: ID!, $quote: String!, $author: String!, $theme: ID!) {
  editQuoteInTheme(
    quoteId: $quoteId
    quote: $quote
    author: $author
    theme: $theme
  ) {
    quotes {
      id
      author
      quote
    }
  }
}
    `;
export type EditQuoteInThemeMutationFn = Apollo.MutationFunction<EditQuoteInThemeMutation, EditQuoteInThemeMutationVariables>;

/**
 * __useEditQuoteInThemeMutation__
 *
 * To run a mutation, you first call `useEditQuoteInThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditQuoteInThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editQuoteInThemeMutation, { data, loading, error }] = useEditQuoteInThemeMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *      quote: // value for 'quote'
 *      author: // value for 'author'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useEditQuoteInThemeMutation(baseOptions?: Apollo.MutationHookOptions<EditQuoteInThemeMutation, EditQuoteInThemeMutationVariables>) {
        return Apollo.useMutation<EditQuoteInThemeMutation, EditQuoteInThemeMutationVariables>(EditQuoteInThemeDocument, baseOptions);
      }
export type EditQuoteInThemeMutationHookResult = ReturnType<typeof useEditQuoteInThemeMutation>;
export type EditQuoteInThemeMutationResult = Apollo.MutationResult<EditQuoteInThemeMutation>;
export type EditQuoteInThemeMutationOptions = Apollo.BaseMutationOptions<EditQuoteInThemeMutation, EditQuoteInThemeMutationVariables>;
export const EditReflectionFromThemeDocument = gql`
    mutation editReflectionFromTheme($id: ID!, $theme: ID!, $question: String) {
  editReflectionFromTheme(id: $id, theme: $theme, question: $question) {
    reflection {
      id
      question
    }
  }
}
    `;
export type EditReflectionFromThemeMutationFn = Apollo.MutationFunction<EditReflectionFromThemeMutation, EditReflectionFromThemeMutationVariables>;

/**
 * __useEditReflectionFromThemeMutation__
 *
 * To run a mutation, you first call `useEditReflectionFromThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReflectionFromThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReflectionFromThemeMutation, { data, loading, error }] = useEditReflectionFromThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useEditReflectionFromThemeMutation(baseOptions?: Apollo.MutationHookOptions<EditReflectionFromThemeMutation, EditReflectionFromThemeMutationVariables>) {
        return Apollo.useMutation<EditReflectionFromThemeMutation, EditReflectionFromThemeMutationVariables>(EditReflectionFromThemeDocument, baseOptions);
      }
export type EditReflectionFromThemeMutationHookResult = ReturnType<typeof useEditReflectionFromThemeMutation>;
export type EditReflectionFromThemeMutationResult = Apollo.MutationResult<EditReflectionFromThemeMutation>;
export type EditReflectionFromThemeMutationOptions = Apollo.BaseMutationOptions<EditReflectionFromThemeMutation, EditReflectionFromThemeMutationVariables>;
export const EditRoleDocument = gql`
    mutation editRole($id: ID!, $role: String!) {
  editRole(id: $id, role: $role) {
    id
    firstName
    lastName
    nickname
    phone
    email
    dateOfBirth
    role
    address
    status
    validUntil
  }
}
    `;
export type EditRoleMutationFn = Apollo.MutationFunction<EditRoleMutation, EditRoleMutationVariables>;

/**
 * __useEditRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleMutation, { data, loading, error }] = useEditRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditRoleMutation, EditRoleMutationVariables>) {
        return Apollo.useMutation<EditRoleMutation, EditRoleMutationVariables>(EditRoleDocument, baseOptions);
      }
export type EditRoleMutationHookResult = ReturnType<typeof useEditRoleMutation>;
export type EditRoleMutationResult = Apollo.MutationResult<EditRoleMutation>;
export type EditRoleMutationOptions = Apollo.BaseMutationOptions<EditRoleMutation, EditRoleMutationVariables>;
export const EditRunningSessionDocument = gql`
    mutation editRunningSession($id: ID!, $currentScreen: Int, $songPlaying: String, $videoChatStatus: String, $contentType: String, $contentId: String, $globalMessage: String) {
  editRunningSession(
    id: $id
    currentScreen: $currentScreen
    songPlaying: $songPlaying
    videoChatStatus: $videoChatStatus
    contentType: $contentType
    contentId: $contentId
    globalMessage: $globalMessage
  ) {
    status
    currentScreen
    songPlaying
    videoChatStatus
    contentId
    contentType
    globalMessage
  }
}
    `;
export type EditRunningSessionMutationFn = Apollo.MutationFunction<EditRunningSessionMutation, EditRunningSessionMutationVariables>;

/**
 * __useEditRunningSessionMutation__
 *
 * To run a mutation, you first call `useEditRunningSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRunningSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRunningSessionMutation, { data, loading, error }] = useEditRunningSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currentScreen: // value for 'currentScreen'
 *      songPlaying: // value for 'songPlaying'
 *      videoChatStatus: // value for 'videoChatStatus'
 *      contentType: // value for 'contentType'
 *      contentId: // value for 'contentId'
 *      globalMessage: // value for 'globalMessage'
 *   },
 * });
 */
export function useEditRunningSessionMutation(baseOptions?: Apollo.MutationHookOptions<EditRunningSessionMutation, EditRunningSessionMutationVariables>) {
        return Apollo.useMutation<EditRunningSessionMutation, EditRunningSessionMutationVariables>(EditRunningSessionDocument, baseOptions);
      }
export type EditRunningSessionMutationHookResult = ReturnType<typeof useEditRunningSessionMutation>;
export type EditRunningSessionMutationResult = Apollo.MutationResult<EditRunningSessionMutation>;
export type EditRunningSessionMutationOptions = Apollo.BaseMutationOptions<EditRunningSessionMutation, EditRunningSessionMutationVariables>;
export const EditSessionMemberMuteStateDocument = gql`
    mutation editSessionMemberMuteState($session: ID!, $member: ID!, $muteState: Boolean!, $room: Boolean!, $role: String!) {
  editSessionMemberMuteState(
    session: $session
    member: $member
    muteState: $muteState
    room: $room
    role: $role
  ) {
    id
  }
}
    `;
export type EditSessionMemberMuteStateMutationFn = Apollo.MutationFunction<EditSessionMemberMuteStateMutation, EditSessionMemberMuteStateMutationVariables>;

/**
 * __useEditSessionMemberMuteStateMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberMuteStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberMuteStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberMuteStateMutation, { data, loading, error }] = useEditSessionMemberMuteStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      muteState: // value for 'muteState'
 *      room: // value for 'room'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberMuteStateMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberMuteStateMutation, EditSessionMemberMuteStateMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberMuteStateMutation, EditSessionMemberMuteStateMutationVariables>(EditSessionMemberMuteStateDocument, baseOptions);
      }
export type EditSessionMemberMuteStateMutationHookResult = ReturnType<typeof useEditSessionMemberMuteStateMutation>;
export type EditSessionMemberMuteStateMutationResult = Apollo.MutationResult<EditSessionMemberMuteStateMutation>;
export type EditSessionMemberMuteStateMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberMuteStateMutation, EditSessionMemberMuteStateMutationVariables>;
export const EditSessionMemberPalamiStateDocument = gql`
    mutation editSessionMemberPalamiState($session: ID!, $member: ID!, $palamiState: Boolean!, $role: String!) {
  editSessionMemberPalamiState(
    session: $session
    member: $member
    palamiState: $palamiState
    role: $role
  ) {
    id
  }
}
    `;
export type EditSessionMemberPalamiStateMutationFn = Apollo.MutationFunction<EditSessionMemberPalamiStateMutation, EditSessionMemberPalamiStateMutationVariables>;

/**
 * __useEditSessionMemberPalamiStateMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberPalamiStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberPalamiStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberPalamiStateMutation, { data, loading, error }] = useEditSessionMemberPalamiStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      palamiState: // value for 'palamiState'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberPalamiStateMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberPalamiStateMutation, EditSessionMemberPalamiStateMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberPalamiStateMutation, EditSessionMemberPalamiStateMutationVariables>(EditSessionMemberPalamiStateDocument, baseOptions);
      }
export type EditSessionMemberPalamiStateMutationHookResult = ReturnType<typeof useEditSessionMemberPalamiStateMutation>;
export type EditSessionMemberPalamiStateMutationResult = Apollo.MutationResult<EditSessionMemberPalamiStateMutation>;
export type EditSessionMemberPalamiStateMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberPalamiStateMutation, EditSessionMemberPalamiStateMutationVariables>;
export const EditSessionMemberRoleDocument = gql`
    mutation editSessionMemberRole($session: ID!, $member: ID!, $role: String!) {
  editSessionMemberRole(session: $session, member: $member, role: $role) {
    id
    sessionMembers {
      id
      sessionRole
    }
  }
}
    `;
export type EditSessionMemberRoleMutationFn = Apollo.MutationFunction<EditSessionMemberRoleMutation, EditSessionMemberRoleMutationVariables>;

/**
 * __useEditSessionMemberRoleMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberRoleMutation, { data, loading, error }] = useEditSessionMemberRoleMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberRoleMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberRoleMutation, EditSessionMemberRoleMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberRoleMutation, EditSessionMemberRoleMutationVariables>(EditSessionMemberRoleDocument, baseOptions);
      }
export type EditSessionMemberRoleMutationHookResult = ReturnType<typeof useEditSessionMemberRoleMutation>;
export type EditSessionMemberRoleMutationResult = Apollo.MutationResult<EditSessionMemberRoleMutation>;
export type EditSessionMemberRoleMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberRoleMutation, EditSessionMemberRoleMutationVariables>;
export const EditSessionMemberRoomStateDocument = gql`
    mutation editSessionMemberRoomState($session: ID!, $member: ID!, $roomState: Boolean!, $muteState: Boolean!, $role: String!) {
  editSessionMemberRoomState(
    session: $session
    member: $member
    roomState: $roomState
    muteState: $muteState
    role: $role
  ) {
    id
  }
}
    `;
export type EditSessionMemberRoomStateMutationFn = Apollo.MutationFunction<EditSessionMemberRoomStateMutation, EditSessionMemberRoomStateMutationVariables>;

/**
 * __useEditSessionMemberRoomStateMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberRoomStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberRoomStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberRoomStateMutation, { data, loading, error }] = useEditSessionMemberRoomStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      roomState: // value for 'roomState'
 *      muteState: // value for 'muteState'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberRoomStateMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberRoomStateMutation, EditSessionMemberRoomStateMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberRoomStateMutation, EditSessionMemberRoomStateMutationVariables>(EditSessionMemberRoomStateDocument, baseOptions);
      }
export type EditSessionMemberRoomStateMutationHookResult = ReturnType<typeof useEditSessionMemberRoomStateMutation>;
export type EditSessionMemberRoomStateMutationResult = Apollo.MutationResult<EditSessionMemberRoomStateMutation>;
export type EditSessionMemberRoomStateMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberRoomStateMutation, EditSessionMemberRoomStateMutationVariables>;
export const EditSessionMemberVidStateDocument = gql`
    mutation editSessionMemberVidState($session: ID!, $member: ID!, $vidState: Boolean!, $role: String!) {
  editSessionMemberVidState(
    session: $session
    member: $member
    vidState: $vidState
    role: $role
  ) {
    id
  }
}
    `;
export type EditSessionMemberVidStateMutationFn = Apollo.MutationFunction<EditSessionMemberVidStateMutation, EditSessionMemberVidStateMutationVariables>;

/**
 * __useEditSessionMemberVidStateMutation__
 *
 * To run a mutation, you first call `useEditSessionMemberVidStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSessionMemberVidStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSessionMemberVidStateMutation, { data, loading, error }] = useEditSessionMemberVidStateMutation({
 *   variables: {
 *      session: // value for 'session'
 *      member: // value for 'member'
 *      vidState: // value for 'vidState'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useEditSessionMemberVidStateMutation(baseOptions?: Apollo.MutationHookOptions<EditSessionMemberVidStateMutation, EditSessionMemberVidStateMutationVariables>) {
        return Apollo.useMutation<EditSessionMemberVidStateMutation, EditSessionMemberVidStateMutationVariables>(EditSessionMemberVidStateDocument, baseOptions);
      }
export type EditSessionMemberVidStateMutationHookResult = ReturnType<typeof useEditSessionMemberVidStateMutation>;
export type EditSessionMemberVidStateMutationResult = Apollo.MutationResult<EditSessionMemberVidStateMutation>;
export type EditSessionMemberVidStateMutationOptions = Apollo.BaseMutationOptions<EditSessionMemberVidStateMutation, EditSessionMemberVidStateMutationVariables>;
export const EditThemeDocument = gql`
    mutation editTheme($id: ID!, $name: String, $category: String, $imageUrl: String, $program: String) {
  editTheme(
    id: $id
    name: $name
    category: $category
    imageUrl: $imageUrl
    program: $program
  ) {
    id
  }
}
    `;
export type EditThemeMutationFn = Apollo.MutationFunction<EditThemeMutation, EditThemeMutationVariables>;

/**
 * __useEditThemeMutation__
 *
 * To run a mutation, you first call `useEditThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editThemeMutation, { data, loading, error }] = useEditThemeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      imageUrl: // value for 'imageUrl'
 *      program: // value for 'program'
 *   },
 * });
 */
export function useEditThemeMutation(baseOptions?: Apollo.MutationHookOptions<EditThemeMutation, EditThemeMutationVariables>) {
        return Apollo.useMutation<EditThemeMutation, EditThemeMutationVariables>(EditThemeDocument, baseOptions);
      }
export type EditThemeMutationHookResult = ReturnType<typeof useEditThemeMutation>;
export type EditThemeMutationResult = Apollo.MutationResult<EditThemeMutation>;
export type EditThemeMutationOptions = Apollo.BaseMutationOptions<EditThemeMutation, EditThemeMutationVariables>;
export const EditUserDocument = gql`
    mutation editUser($id: ID!, $firstName: String, $lastName: String, $phone: String, $email: String, $password: String, $nickname: String, $country: String, $postalcode: String, $ageGroup: String, $living: String, $status: Boolean, $optOut: Boolean, $validUntil: DateISO) {
  editUser(
    id: $id
    firstName: $firstName
    lastName: $lastName
    phone: $phone
    email: $email
    nickname: $nickname
    country: $country
    postalcode: $postalcode
    ageGroup: $ageGroup
    living: $living
    password: $password
    status: $status
    optOut: $optOut
    validUntil: $validUntil
  ) {
    id
    firstName
    lastName
    phone
    email
    nickname
    country
    postalcode
    ageGroup
    living
    status
    optOut
    validUntil
  }
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      nickname: // value for 'nickname'
 *      country: // value for 'country'
 *      postalcode: // value for 'postalcode'
 *      ageGroup: // value for 'ageGroup'
 *      living: // value for 'living'
 *      status: // value for 'status'
 *      optOut: // value for 'optOut'
 *      validUntil: // value for 'validUntil'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, baseOptions);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const ErrorLogsDocument = gql`
    query errorLogs {
  errorLogs {
    id
    error
    componentStack
    platform
    userAgent
    userMessage
    createdAt
  }
}
    `;

/**
 * __useErrorLogsQuery__
 *
 * To run a query within a React component, call `useErrorLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useErrorLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useErrorLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useErrorLogsQuery(baseOptions?: Apollo.QueryHookOptions<ErrorLogsQuery, ErrorLogsQueryVariables>) {
        return Apollo.useQuery<ErrorLogsQuery, ErrorLogsQueryVariables>(ErrorLogsDocument, baseOptions);
      }
export function useErrorLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ErrorLogsQuery, ErrorLogsQueryVariables>) {
          return Apollo.useLazyQuery<ErrorLogsQuery, ErrorLogsQueryVariables>(ErrorLogsDocument, baseOptions);
        }
export type ErrorLogsQueryHookResult = ReturnType<typeof useErrorLogsQuery>;
export type ErrorLogsLazyQueryHookResult = ReturnType<typeof useErrorLogsLazyQuery>;
export type ErrorLogsQueryResult = Apollo.QueryResult<ErrorLogsQuery, ErrorLogsQueryVariables>;
export const FollowSessionDocument = gql`
    query followSession($id: ID!) {
  session(id: $id) {
    status
    currentScreen
    songPlaying
    videoChatStatus
    contentId
    contentType
    globalMessage
  }
}
    `;

/**
 * __useFollowSessionQuery__
 *
 * To run a query within a React component, call `useFollowSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFollowSessionQuery(baseOptions: Apollo.QueryHookOptions<FollowSessionQuery, FollowSessionQueryVariables>) {
        return Apollo.useQuery<FollowSessionQuery, FollowSessionQueryVariables>(FollowSessionDocument, baseOptions);
      }
export function useFollowSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowSessionQuery, FollowSessionQueryVariables>) {
          return Apollo.useLazyQuery<FollowSessionQuery, FollowSessionQueryVariables>(FollowSessionDocument, baseOptions);
        }
export type FollowSessionQueryHookResult = ReturnType<typeof useFollowSessionQuery>;
export type FollowSessionLazyQueryHookResult = ReturnType<typeof useFollowSessionLazyQuery>;
export type FollowSessionQueryResult = Apollo.QueryResult<FollowSessionQuery, FollowSessionQueryVariables>;
export const FollowSessionAsSessionMasterDocument = gql`
    query followSessionAsSessionMaster($id: ID!) {
  session(id: $id) {
    status
    currentScreen
    songPlaying
    videoChatStatus
    contentId
    contentType
    globalMessage
    waitingRoom {
      id
      firstName
      lastName
      nickname
      role
    }
    sessionMaster {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useFollowSessionAsSessionMasterQuery__
 *
 * To run a query within a React component, call `useFollowSessionAsSessionMasterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowSessionAsSessionMasterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowSessionAsSessionMasterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFollowSessionAsSessionMasterQuery(baseOptions: Apollo.QueryHookOptions<FollowSessionAsSessionMasterQuery, FollowSessionAsSessionMasterQueryVariables>) {
        return Apollo.useQuery<FollowSessionAsSessionMasterQuery, FollowSessionAsSessionMasterQueryVariables>(FollowSessionAsSessionMasterDocument, baseOptions);
      }
export function useFollowSessionAsSessionMasterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowSessionAsSessionMasterQuery, FollowSessionAsSessionMasterQueryVariables>) {
          return Apollo.useLazyQuery<FollowSessionAsSessionMasterQuery, FollowSessionAsSessionMasterQueryVariables>(FollowSessionAsSessionMasterDocument, baseOptions);
        }
export type FollowSessionAsSessionMasterQueryHookResult = ReturnType<typeof useFollowSessionAsSessionMasterQuery>;
export type FollowSessionAsSessionMasterLazyQueryHookResult = ReturnType<typeof useFollowSessionAsSessionMasterLazyQuery>;
export type FollowSessionAsSessionMasterQueryResult = Apollo.QueryResult<FollowSessionAsSessionMasterQuery, FollowSessionAsSessionMasterQueryVariables>;
export const FollowWaitingRoomDocument = gql`
    query followWaitingRoom($id: ID!) {
  session(id: $id) {
    waitingRoom {
      id
    }
    sessionMembers {
      user {
        id
      }
    }
    status
  }
}
    `;

/**
 * __useFollowWaitingRoomQuery__
 *
 * To run a query within a React component, call `useFollowWaitingRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowWaitingRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowWaitingRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFollowWaitingRoomQuery(baseOptions: Apollo.QueryHookOptions<FollowWaitingRoomQuery, FollowWaitingRoomQueryVariables>) {
        return Apollo.useQuery<FollowWaitingRoomQuery, FollowWaitingRoomQueryVariables>(FollowWaitingRoomDocument, baseOptions);
      }
export function useFollowWaitingRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowWaitingRoomQuery, FollowWaitingRoomQueryVariables>) {
          return Apollo.useLazyQuery<FollowWaitingRoomQuery, FollowWaitingRoomQueryVariables>(FollowWaitingRoomDocument, baseOptions);
        }
export type FollowWaitingRoomQueryHookResult = ReturnType<typeof useFollowWaitingRoomQuery>;
export type FollowWaitingRoomLazyQueryHookResult = ReturnType<typeof useFollowWaitingRoomLazyQuery>;
export type FollowWaitingRoomQueryResult = Apollo.QueryResult<FollowWaitingRoomQuery, FollowWaitingRoomQueryVariables>;
export const FutureSessionsDocument = gql`
    query futureSessions($nth: Int!) {
  futureSessions(nth: $nth) {
    id
    date
    program
    sessionMaster {
      id
    }
    theme {
      id
      name
      imageUrl
    }
    group {
      id
      name
      room
    }
    status
  }
}
    `;

/**
 * __useFutureSessionsQuery__
 *
 * To run a query within a React component, call `useFutureSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFutureSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFutureSessionsQuery({
 *   variables: {
 *      nth: // value for 'nth'
 *   },
 * });
 */
export function useFutureSessionsQuery(baseOptions: Apollo.QueryHookOptions<FutureSessionsQuery, FutureSessionsQueryVariables>) {
        return Apollo.useQuery<FutureSessionsQuery, FutureSessionsQueryVariables>(FutureSessionsDocument, baseOptions);
      }
export function useFutureSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FutureSessionsQuery, FutureSessionsQueryVariables>) {
          return Apollo.useLazyQuery<FutureSessionsQuery, FutureSessionsQueryVariables>(FutureSessionsDocument, baseOptions);
        }
export type FutureSessionsQueryHookResult = ReturnType<typeof useFutureSessionsQuery>;
export type FutureSessionsLazyQueryHookResult = ReturnType<typeof useFutureSessionsLazyQuery>;
export type FutureSessionsQueryResult = Apollo.QueryResult<FutureSessionsQuery, FutureSessionsQueryVariables>;
export const GroupDocument = gql`
    query group($id: ID!) {
  group(id: $id) {
    id
    name
    startDate
    frequency
    room
    program
    facilitators {
      id
      firstName
      lastName
      nickname
      profilePicUrl
    }
    inviteRequests {
      id
      firstName
      lastName
      profilePicUrl
    }
    owner {
      id
      firstName
      lastName
      profilePicUrl
    }
    members {
      id
      firstName
      lastName
      nickname
      profilePicUrl
    }
    isPublic
    tags
    isDeleted
    usedThemes {
      theme
      lastUsed
    }
  }
}
    `;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupQuery(baseOptions: Apollo.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        return Apollo.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, baseOptions);
      }
export function useGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          return Apollo.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, baseOptions);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupQueryResult = Apollo.QueryResult<GroupQuery, GroupQueryVariables>;
export const GroupsDocument = gql`
    query groups {
  groups {
    id
    name
    startDate
    frequency
    room
    program
    inviteRequests {
      id
      profilePicUrl
    }
    owner {
      id
      firstName
      lastName
      profilePicUrl
    }
    facilitators {
      id
      firstName
      lastName
      nickname
      profilePicUrl
    }
    members {
      id
      firstName
      lastName
      nickname
      profilePicUrl
    }
    isPublic
    tags
    isDeleted
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, baseOptions);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, baseOptions);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const GroupsByTagDocument = gql`
    query groupsByTag($tags: [String]!) {
  groupsByTag(tags: $tags) {
    id
    name
    startDate
    frequency
    room
    program
    facilitators {
      id
      firstName
      lastName
      nickname
    }
    inviteRequests {
      id
    }
    owner {
      id
      firstName
      lastName
    }
    members {
      id
      firstName
      lastName
      nickname
    }
    isPublic
    tags
    isDeleted
    usedThemes {
      theme
      lastUsed
    }
  }
}
    `;

/**
 * __useGroupsByTagQuery__
 *
 * To run a query within a React component, call `useGroupsByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsByTagQuery({
 *   variables: {
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useGroupsByTagQuery(baseOptions: Apollo.QueryHookOptions<GroupsByTagQuery, GroupsByTagQueryVariables>) {
        return Apollo.useQuery<GroupsByTagQuery, GroupsByTagQueryVariables>(GroupsByTagDocument, baseOptions);
      }
export function useGroupsByTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsByTagQuery, GroupsByTagQueryVariables>) {
          return Apollo.useLazyQuery<GroupsByTagQuery, GroupsByTagQueryVariables>(GroupsByTagDocument, baseOptions);
        }
export type GroupsByTagQueryHookResult = ReturnType<typeof useGroupsByTagQuery>;
export type GroupsByTagLazyQueryHookResult = ReturnType<typeof useGroupsByTagLazyQuery>;
export type GroupsByTagQueryResult = Apollo.QueryResult<GroupsByTagQuery, GroupsByTagQueryVariables>;
export const HandoverMasterDocument = gql`
    mutation handoverMaster($session: ID!, $newMaster: ID!) {
  handoverMaster(session: $session, newMaster: $newMaster) {
    id
    date
    sessionMaster {
      id
    }
    group {
      id
    }
  }
}
    `;
export type HandoverMasterMutationFn = Apollo.MutationFunction<HandoverMasterMutation, HandoverMasterMutationVariables>;

/**
 * __useHandoverMasterMutation__
 *
 * To run a mutation, you first call `useHandoverMasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandoverMasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handoverMasterMutation, { data, loading, error }] = useHandoverMasterMutation({
 *   variables: {
 *      session: // value for 'session'
 *      newMaster: // value for 'newMaster'
 *   },
 * });
 */
export function useHandoverMasterMutation(baseOptions?: Apollo.MutationHookOptions<HandoverMasterMutation, HandoverMasterMutationVariables>) {
        return Apollo.useMutation<HandoverMasterMutation, HandoverMasterMutationVariables>(HandoverMasterDocument, baseOptions);
      }
export type HandoverMasterMutationHookResult = ReturnType<typeof useHandoverMasterMutation>;
export type HandoverMasterMutationResult = Apollo.MutationResult<HandoverMasterMutation>;
export type HandoverMasterMutationOptions = Apollo.BaseMutationOptions<HandoverMasterMutation, HandoverMasterMutationVariables>;
export const LeaveSessionDocument = gql`
    mutation leaveSession($session: ID!) {
  leaveSession(session: $session)
}
    `;
export type LeaveSessionMutationFn = Apollo.MutationFunction<LeaveSessionMutation, LeaveSessionMutationVariables>;

/**
 * __useLeaveSessionMutation__
 *
 * To run a mutation, you first call `useLeaveSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveSessionMutation, { data, loading, error }] = useLeaveSessionMutation({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useLeaveSessionMutation(baseOptions?: Apollo.MutationHookOptions<LeaveSessionMutation, LeaveSessionMutationVariables>) {
        return Apollo.useMutation<LeaveSessionMutation, LeaveSessionMutationVariables>(LeaveSessionDocument, baseOptions);
      }
export type LeaveSessionMutationHookResult = ReturnType<typeof useLeaveSessionMutation>;
export type LeaveSessionMutationResult = Apollo.MutationResult<LeaveSessionMutation>;
export type LeaveSessionMutationOptions = Apollo.BaseMutationOptions<LeaveSessionMutation, LeaveSessionMutationVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MarkSeenNotificationsDocument = gql`
    mutation markSeenNotifications($notifs: [ID]!) {
  markSeenNotifications(notifs: $notifs)
}
    `;
export type MarkSeenNotificationsMutationFn = Apollo.MutationFunction<MarkSeenNotificationsMutation, MarkSeenNotificationsMutationVariables>;

/**
 * __useMarkSeenNotificationsMutation__
 *
 * To run a mutation, you first call `useMarkSeenNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSeenNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSeenNotificationsMutation, { data, loading, error }] = useMarkSeenNotificationsMutation({
 *   variables: {
 *      notifs: // value for 'notifs'
 *   },
 * });
 */
export function useMarkSeenNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<MarkSeenNotificationsMutation, MarkSeenNotificationsMutationVariables>) {
        return Apollo.useMutation<MarkSeenNotificationsMutation, MarkSeenNotificationsMutationVariables>(MarkSeenNotificationsDocument, baseOptions);
      }
export type MarkSeenNotificationsMutationHookResult = ReturnType<typeof useMarkSeenNotificationsMutation>;
export type MarkSeenNotificationsMutationResult = Apollo.MutationResult<MarkSeenNotificationsMutation>;
export type MarkSeenNotificationsMutationOptions = Apollo.BaseMutationOptions<MarkSeenNotificationsMutation, MarkSeenNotificationsMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    firstName
    lastName
    email
    nickname
    postalcode
    ageGroup
    living
    country
    role
    phone
    dateOfBirth
    address
    parentUser
    profilePicUrl
    optOut
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyFutureSessionsDocument = gql`
    query myFutureSessions($nth: Int!) {
  myFutureSessions(nth: $nth) {
    id
    date
    program
    sessionMaster {
      id
    }
    theme {
      id
      name
      imageUrl
    }
    group {
      id
      name
      room
    }
    status
  }
}
    `;

/**
 * __useMyFutureSessionsQuery__
 *
 * To run a query within a React component, call `useMyFutureSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFutureSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFutureSessionsQuery({
 *   variables: {
 *      nth: // value for 'nth'
 *   },
 * });
 */
export function useMyFutureSessionsQuery(baseOptions: Apollo.QueryHookOptions<MyFutureSessionsQuery, MyFutureSessionsQueryVariables>) {
        return Apollo.useQuery<MyFutureSessionsQuery, MyFutureSessionsQueryVariables>(MyFutureSessionsDocument, baseOptions);
      }
export function useMyFutureSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyFutureSessionsQuery, MyFutureSessionsQueryVariables>) {
          return Apollo.useLazyQuery<MyFutureSessionsQuery, MyFutureSessionsQueryVariables>(MyFutureSessionsDocument, baseOptions);
        }
export type MyFutureSessionsQueryHookResult = ReturnType<typeof useMyFutureSessionsQuery>;
export type MyFutureSessionsLazyQueryHookResult = ReturnType<typeof useMyFutureSessionsLazyQuery>;
export type MyFutureSessionsQueryResult = Apollo.QueryResult<MyFutureSessionsQuery, MyFutureSessionsQueryVariables>;
export const MyGroupsDocument = gql`
    query myGroups {
  myGroups {
    id
    name
    startDate
    frequency
    room
    inviteRequests {
      id
    }
    owner {
      id
      firstName
      lastName
    }
    facilitators {
      id
      firstName
      lastName
      nickname
    }
    members {
      id
      firstName
      lastName
      nickname
    }
    isPublic
    tags
    isDeleted
  }
}
    `;

/**
 * __useMyGroupsQuery__
 *
 * To run a query within a React component, call `useMyGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyGroupsQuery(baseOptions?: Apollo.QueryHookOptions<MyGroupsQuery, MyGroupsQueryVariables>) {
        return Apollo.useQuery<MyGroupsQuery, MyGroupsQueryVariables>(MyGroupsDocument, baseOptions);
      }
export function useMyGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyGroupsQuery, MyGroupsQueryVariables>) {
          return Apollo.useLazyQuery<MyGroupsQuery, MyGroupsQueryVariables>(MyGroupsDocument, baseOptions);
        }
export type MyGroupsQueryHookResult = ReturnType<typeof useMyGroupsQuery>;
export type MyGroupsLazyQueryHookResult = ReturnType<typeof useMyGroupsLazyQuery>;
export type MyGroupsQueryResult = Apollo.QueryResult<MyGroupsQuery, MyGroupsQueryVariables>;
export const MyManagedGroupsDocument = gql`
    query myManagedGroups {
  myManagedGroups {
    id
    name
    startDate
    frequency
    room
    inviteRequests {
      id
    }
    owner {
      id
      firstName
      lastName
    }
    facilitators {
      id
      firstName
      lastName
      nickname
    }
    members {
      id
      firstName
      lastName
      nickname
    }
    isPublic
    tags
    isDeleted
  }
}
    `;

/**
 * __useMyManagedGroupsQuery__
 *
 * To run a query within a React component, call `useMyManagedGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyManagedGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyManagedGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyManagedGroupsQuery(baseOptions?: Apollo.QueryHookOptions<MyManagedGroupsQuery, MyManagedGroupsQueryVariables>) {
        return Apollo.useQuery<MyManagedGroupsQuery, MyManagedGroupsQueryVariables>(MyManagedGroupsDocument, baseOptions);
      }
export function useMyManagedGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyManagedGroupsQuery, MyManagedGroupsQueryVariables>) {
          return Apollo.useLazyQuery<MyManagedGroupsQuery, MyManagedGroupsQueryVariables>(MyManagedGroupsDocument, baseOptions);
        }
export type MyManagedGroupsQueryHookResult = ReturnType<typeof useMyManagedGroupsQuery>;
export type MyManagedGroupsLazyQueryHookResult = ReturnType<typeof useMyManagedGroupsLazyQuery>;
export type MyManagedGroupsQueryResult = Apollo.QueryResult<MyManagedGroupsQuery, MyManagedGroupsQueryVariables>;
export const MyRequestsDocument = gql`
    query myRequests {
  myRequests {
    id
    name
    inviteRequests {
      id
      firstName
      lastName
      nickname
    }
  }
}
    `;

/**
 * __useMyRequestsQuery__
 *
 * To run a query within a React component, call `useMyRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRequestsQuery(baseOptions?: Apollo.QueryHookOptions<MyRequestsQuery, MyRequestsQueryVariables>) {
        return Apollo.useQuery<MyRequestsQuery, MyRequestsQueryVariables>(MyRequestsDocument, baseOptions);
      }
export function useMyRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRequestsQuery, MyRequestsQueryVariables>) {
          return Apollo.useLazyQuery<MyRequestsQuery, MyRequestsQueryVariables>(MyRequestsDocument, baseOptions);
        }
export type MyRequestsQueryHookResult = ReturnType<typeof useMyRequestsQuery>;
export type MyRequestsLazyQueryHookResult = ReturnType<typeof useMyRequestsLazyQuery>;
export type MyRequestsQueryResult = Apollo.QueryResult<MyRequestsQuery, MyRequestsQueryVariables>;
export const NotificationsDocument = gql`
    query notifications($offset: Int!, $limit: Int!) {
  notifications(offset: $offset, limit: $limit) {
    id
    owner {
      id
    }
    type
    user {
      id
      firstName
      lastName
      nickname
    }
    group {
      id
      name
    }
    session {
      id
    }
    message
    seenEh
    actionedEh
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, baseOptions);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const PublicGroupsDocument = gql`
    query publicGroups {
  publicGroups {
    id
    name
    startDate
    frequency
    room
    program
    inviteRequests {
      id
    }
    owner {
      id
      firstName
      lastName
    }
    facilitators {
      id
      firstName
      lastName
      nickname
    }
    members {
      id
      firstName
      lastName
      nickname
    }
    isPublic
    tags
    isDeleted
  }
}
    `;

/**
 * __usePublicGroupsQuery__
 *
 * To run a query within a React component, call `usePublicGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicGroupsQuery(baseOptions?: Apollo.QueryHookOptions<PublicGroupsQuery, PublicGroupsQueryVariables>) {
        return Apollo.useQuery<PublicGroupsQuery, PublicGroupsQueryVariables>(PublicGroupsDocument, baseOptions);
      }
export function usePublicGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicGroupsQuery, PublicGroupsQueryVariables>) {
          return Apollo.useLazyQuery<PublicGroupsQuery, PublicGroupsQueryVariables>(PublicGroupsDocument, baseOptions);
        }
export type PublicGroupsQueryHookResult = ReturnType<typeof usePublicGroupsQuery>;
export type PublicGroupsLazyQueryHookResult = ReturnType<typeof usePublicGroupsLazyQuery>;
export type PublicGroupsQueryResult = Apollo.QueryResult<PublicGroupsQuery, PublicGroupsQueryVariables>;
export const QuestionnaireDocument = gql`
    query questionnaire($id: ID!) {
  questionnaire(id: $id) {
    id
    member
    session
    stage
    answers {
      id
      number
      body
      value
      createdAt
    }
    optOut
    isDone
  }
}
    `;

/**
 * __useQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireQuery, QuestionnaireQueryVariables>) {
        return Apollo.useQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(QuestionnaireDocument, baseOptions);
      }
export function useQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireQuery, QuestionnaireQueryVariables>) {
          return Apollo.useLazyQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(QuestionnaireDocument, baseOptions);
        }
export type QuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireQuery>;
export type QuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireLazyQuery>;
export type QuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireQuery, QuestionnaireQueryVariables>;
export const QuestionnaireUserSessionDocument = gql`
    query questionnaireUserSession($member: ID!, $session: ID!, $stage: String) {
  questionnaireUserSession(member: $member, session: $session, stage: $stage) {
    id
    member
    session
    stage
    answers {
      id
      number
      body
      value
      createdAt
    }
    optOut
    isDone
  }
}
    `;

/**
 * __useQuestionnaireUserSessionQuery__
 *
 * To run a query within a React component, call `useQuestionnaireUserSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireUserSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireUserSessionQuery({
 *   variables: {
 *      member: // value for 'member'
 *      session: // value for 'session'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useQuestionnaireUserSessionQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireUserSessionQuery, QuestionnaireUserSessionQueryVariables>) {
        return Apollo.useQuery<QuestionnaireUserSessionQuery, QuestionnaireUserSessionQueryVariables>(QuestionnaireUserSessionDocument, baseOptions);
      }
export function useQuestionnaireUserSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireUserSessionQuery, QuestionnaireUserSessionQueryVariables>) {
          return Apollo.useLazyQuery<QuestionnaireUserSessionQuery, QuestionnaireUserSessionQueryVariables>(QuestionnaireUserSessionDocument, baseOptions);
        }
export type QuestionnaireUserSessionQueryHookResult = ReturnType<typeof useQuestionnaireUserSessionQuery>;
export type QuestionnaireUserSessionLazyQueryHookResult = ReturnType<typeof useQuestionnaireUserSessionLazyQuery>;
export type QuestionnaireUserSessionQueryResult = Apollo.QueryResult<QuestionnaireUserSessionQuery, QuestionnaireUserSessionQueryVariables>;
export const QuestionnairesDocument = gql`
    query questionnaires {
  questionnaires {
    id
    member
    session
    stage
    answers {
      id
      number
      body
      value
      createdAt
    }
    optOut
    isDone
  }
}
    `;

/**
 * __useQuestionnairesQuery__
 *
 * To run a query within a React component, call `useQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnairesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionnairesQuery(baseOptions?: Apollo.QueryHookOptions<QuestionnairesQuery, QuestionnairesQueryVariables>) {
        return Apollo.useQuery<QuestionnairesQuery, QuestionnairesQueryVariables>(QuestionnairesDocument, baseOptions);
      }
export function useQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnairesQuery, QuestionnairesQueryVariables>) {
          return Apollo.useLazyQuery<QuestionnairesQuery, QuestionnairesQueryVariables>(QuestionnairesDocument, baseOptions);
        }
export type QuestionnairesQueryHookResult = ReturnType<typeof useQuestionnairesQuery>;
export type QuestionnairesLazyQueryHookResult = ReturnType<typeof useQuestionnairesLazyQuery>;
export type QuestionnairesQueryResult = Apollo.QueryResult<QuestionnairesQuery, QuestionnairesQueryVariables>;
export const RemoveAnswerDocument = gql`
    mutation removeAnswer($id: ID!, $questionnaire: ID!) {
  removeAnswer(id: $id, questionnaire: $questionnaire) {
    id
  }
}
    `;
export type RemoveAnswerMutationFn = Apollo.MutationFunction<RemoveAnswerMutation, RemoveAnswerMutationVariables>;

/**
 * __useRemoveAnswerMutation__
 *
 * To run a mutation, you first call `useRemoveAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAnswerMutation, { data, loading, error }] = useRemoveAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      questionnaire: // value for 'questionnaire'
 *   },
 * });
 */
export function useRemoveAnswerMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAnswerMutation, RemoveAnswerMutationVariables>) {
        return Apollo.useMutation<RemoveAnswerMutation, RemoveAnswerMutationVariables>(RemoveAnswerDocument, baseOptions);
      }
export type RemoveAnswerMutationHookResult = ReturnType<typeof useRemoveAnswerMutation>;
export type RemoveAnswerMutationResult = Apollo.MutationResult<RemoveAnswerMutation>;
export type RemoveAnswerMutationOptions = Apollo.BaseMutationOptions<RemoveAnswerMutation, RemoveAnswerMutationVariables>;
export const RemoveDiscussionDocument = gql`
    mutation removeDiscussion($id: ID!, $theme: ID!) {
  removeDiscussion(id: $id, theme: $theme) {
    discussions {
      id
      question
    }
  }
}
    `;
export type RemoveDiscussionMutationFn = Apollo.MutationFunction<RemoveDiscussionMutation, RemoveDiscussionMutationVariables>;

/**
 * __useRemoveDiscussionMutation__
 *
 * To run a mutation, you first call `useRemoveDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDiscussionMutation, { data, loading, error }] = useRemoveDiscussionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useRemoveDiscussionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDiscussionMutation, RemoveDiscussionMutationVariables>) {
        return Apollo.useMutation<RemoveDiscussionMutation, RemoveDiscussionMutationVariables>(RemoveDiscussionDocument, baseOptions);
      }
export type RemoveDiscussionMutationHookResult = ReturnType<typeof useRemoveDiscussionMutation>;
export type RemoveDiscussionMutationResult = Apollo.MutationResult<RemoveDiscussionMutation>;
export type RemoveDiscussionMutationOptions = Apollo.BaseMutationOptions<RemoveDiscussionMutation, RemoveDiscussionMutationVariables>;
export const RemoveJavaTipDocument = gql`
    mutation removeJavaTip($id: ID!, $theme: ID!) {
  removeJavaTip(id: $id, theme: $theme) {
    id
    isDeleted
    javaTips {
      id
      author {
        id
      }
    }
  }
}
    `;
export type RemoveJavaTipMutationFn = Apollo.MutationFunction<RemoveJavaTipMutation, RemoveJavaTipMutationVariables>;

/**
 * __useRemoveJavaTipMutation__
 *
 * To run a mutation, you first call `useRemoveJavaTipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveJavaTipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeJavaTipMutation, { data, loading, error }] = useRemoveJavaTipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useRemoveJavaTipMutation(baseOptions?: Apollo.MutationHookOptions<RemoveJavaTipMutation, RemoveJavaTipMutationVariables>) {
        return Apollo.useMutation<RemoveJavaTipMutation, RemoveJavaTipMutationVariables>(RemoveJavaTipDocument, baseOptions);
      }
export type RemoveJavaTipMutationHookResult = ReturnType<typeof useRemoveJavaTipMutation>;
export type RemoveJavaTipMutationResult = Apollo.MutationResult<RemoveJavaTipMutation>;
export type RemoveJavaTipMutationOptions = Apollo.BaseMutationOptions<RemoveJavaTipMutation, RemoveJavaTipMutationVariables>;
export const RemoveQuoteFromThemeDocument = gql`
    mutation removeQuoteFromTheme($quote: ID!, $theme: ID!) {
  removeQuoteFromTheme(quote: $quote, theme: $theme) {
    quotes {
      id
      author
      quote
    }
  }
}
    `;
export type RemoveQuoteFromThemeMutationFn = Apollo.MutationFunction<RemoveQuoteFromThemeMutation, RemoveQuoteFromThemeMutationVariables>;

/**
 * __useRemoveQuoteFromThemeMutation__
 *
 * To run a mutation, you first call `useRemoveQuoteFromThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuoteFromThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuoteFromThemeMutation, { data, loading, error }] = useRemoveQuoteFromThemeMutation({
 *   variables: {
 *      quote: // value for 'quote'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useRemoveQuoteFromThemeMutation(baseOptions?: Apollo.MutationHookOptions<RemoveQuoteFromThemeMutation, RemoveQuoteFromThemeMutationVariables>) {
        return Apollo.useMutation<RemoveQuoteFromThemeMutation, RemoveQuoteFromThemeMutationVariables>(RemoveQuoteFromThemeDocument, baseOptions);
      }
export type RemoveQuoteFromThemeMutationHookResult = ReturnType<typeof useRemoveQuoteFromThemeMutation>;
export type RemoveQuoteFromThemeMutationResult = Apollo.MutationResult<RemoveQuoteFromThemeMutation>;
export type RemoveQuoteFromThemeMutationOptions = Apollo.BaseMutationOptions<RemoveQuoteFromThemeMutation, RemoveQuoteFromThemeMutationVariables>;
export const RemoveReflectionDocument = gql`
    mutation removeReflection($id: ID!, $theme: ID!) {
  removeReflection(id: $id, theme: $theme) {
    reflection {
      id
      question
    }
  }
}
    `;
export type RemoveReflectionMutationFn = Apollo.MutationFunction<RemoveReflectionMutation, RemoveReflectionMutationVariables>;

/**
 * __useRemoveReflectionMutation__
 *
 * To run a mutation, you first call `useRemoveReflectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReflectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReflectionMutation, { data, loading, error }] = useRemoveReflectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *   },
 * });
 */
export function useRemoveReflectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveReflectionMutation, RemoveReflectionMutationVariables>) {
        return Apollo.useMutation<RemoveReflectionMutation, RemoveReflectionMutationVariables>(RemoveReflectionDocument, baseOptions);
      }
export type RemoveReflectionMutationHookResult = ReturnType<typeof useRemoveReflectionMutation>;
export type RemoveReflectionMutationResult = Apollo.MutationResult<RemoveReflectionMutation>;
export type RemoveReflectionMutationOptions = Apollo.BaseMutationOptions<RemoveReflectionMutation, RemoveReflectionMutationVariables>;
export const RemoveTagFromGroupDocument = gql`
    mutation removeTagFromGroup($tag: String!, $group: ID!) {
  removeTagFromGroup(tag: $tag, group: $group) {
    id
    name
  }
}
    `;
export type RemoveTagFromGroupMutationFn = Apollo.MutationFunction<RemoveTagFromGroupMutation, RemoveTagFromGroupMutationVariables>;

/**
 * __useRemoveTagFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromGroupMutation, { data, loading, error }] = useRemoveTagFromGroupMutation({
 *   variables: {
 *      tag: // value for 'tag'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useRemoveTagFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTagFromGroupMutation, RemoveTagFromGroupMutationVariables>) {
        return Apollo.useMutation<RemoveTagFromGroupMutation, RemoveTagFromGroupMutationVariables>(RemoveTagFromGroupDocument, baseOptions);
      }
export type RemoveTagFromGroupMutationHookResult = ReturnType<typeof useRemoveTagFromGroupMutation>;
export type RemoveTagFromGroupMutationResult = Apollo.MutationResult<RemoveTagFromGroupMutation>;
export type RemoveTagFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveTagFromGroupMutation, RemoveTagFromGroupMutationVariables>;
export const RemoveUserFromGroupDocument = gql`
    mutation removeUserFromGroup($user: ID!, $group: ID!, $as: String!) {
  removeUserFromGroup(user: $user, group: $group, as: $as) {
    id
    facilitators {
      id
      firstName
      lastName
      nickname
    }
    members {
      id
      firstName
      lastName
      nickname
    }
  }
}
    `;
export type RemoveUserFromGroupMutationFn = Apollo.MutationFunction<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;

/**
 * __useRemoveUserFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromGroupMutation, { data, loading, error }] = useRemoveUserFromGroupMutation({
 *   variables: {
 *      user: // value for 'user'
 *      group: // value for 'group'
 *      as: // value for 'as'
 *   },
 * });
 */
export function useRemoveUserFromGroupMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>) {
        return Apollo.useMutation<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>(RemoveUserFromGroupDocument, baseOptions);
      }
export type RemoveUserFromGroupMutationHookResult = ReturnType<typeof useRemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationResult = Apollo.MutationResult<RemoveUserFromGroupMutation>;
export type RemoveUserFromGroupMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromGroupMutation, RemoveUserFromGroupMutationVariables>;
export const RemoveUserFromSessionDocument = gql`
    mutation removeUserFromSession($user: ID!, $session: ID!, $as: String!) {
  removeUserFromSession(user: $user, session: $session, as: $as) {
    id
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
        role
      }
    }
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
        role
      }
    }
  }
}
    `;
export type RemoveUserFromSessionMutationFn = Apollo.MutationFunction<RemoveUserFromSessionMutation, RemoveUserFromSessionMutationVariables>;

/**
 * __useRemoveUserFromSessionMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromSessionMutation, { data, loading, error }] = useRemoveUserFromSessionMutation({
 *   variables: {
 *      user: // value for 'user'
 *      session: // value for 'session'
 *      as: // value for 'as'
 *   },
 * });
 */
export function useRemoveUserFromSessionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromSessionMutation, RemoveUserFromSessionMutationVariables>) {
        return Apollo.useMutation<RemoveUserFromSessionMutation, RemoveUserFromSessionMutationVariables>(RemoveUserFromSessionDocument, baseOptions);
      }
export type RemoveUserFromSessionMutationHookResult = ReturnType<typeof useRemoveUserFromSessionMutation>;
export type RemoveUserFromSessionMutationResult = Apollo.MutationResult<RemoveUserFromSessionMutation>;
export type RemoveUserFromSessionMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromSessionMutation, RemoveUserFromSessionMutationVariables>;
export const RemoveUserFromWaitingRoomDocument = gql`
    mutation removeUserFromWaitingRoom($user: ID!, $session: ID!) {
  removeUserFromWaitingRoom(user: $user, session: $session) {
    id
    waitingRoom {
      id
    }
  }
}
    `;
export type RemoveUserFromWaitingRoomMutationFn = Apollo.MutationFunction<RemoveUserFromWaitingRoomMutation, RemoveUserFromWaitingRoomMutationVariables>;

/**
 * __useRemoveUserFromWaitingRoomMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromWaitingRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromWaitingRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromWaitingRoomMutation, { data, loading, error }] = useRemoveUserFromWaitingRoomMutation({
 *   variables: {
 *      user: // value for 'user'
 *      session: // value for 'session'
 *   },
 * });
 */
export function useRemoveUserFromWaitingRoomMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserFromWaitingRoomMutation, RemoveUserFromWaitingRoomMutationVariables>) {
        return Apollo.useMutation<RemoveUserFromWaitingRoomMutation, RemoveUserFromWaitingRoomMutationVariables>(RemoveUserFromWaitingRoomDocument, baseOptions);
      }
export type RemoveUserFromWaitingRoomMutationHookResult = ReturnType<typeof useRemoveUserFromWaitingRoomMutation>;
export type RemoveUserFromWaitingRoomMutationResult = Apollo.MutationResult<RemoveUserFromWaitingRoomMutation>;
export type RemoveUserFromWaitingRoomMutationOptions = Apollo.BaseMutationOptions<RemoveUserFromWaitingRoomMutation, RemoveUserFromWaitingRoomMutationVariables>;
export const RequestInviteDocument = gql`
    mutation requestInvite($user: ID!, $group: ID!) {
  requestInvite(user: $user, group: $group) {
    id
    name
  }
}
    `;
export type RequestInviteMutationFn = Apollo.MutationFunction<RequestInviteMutation, RequestInviteMutationVariables>;

/**
 * __useRequestInviteMutation__
 *
 * To run a mutation, you first call `useRequestInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestInviteMutation, { data, loading, error }] = useRequestInviteMutation({
 *   variables: {
 *      user: // value for 'user'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useRequestInviteMutation(baseOptions?: Apollo.MutationHookOptions<RequestInviteMutation, RequestInviteMutationVariables>) {
        return Apollo.useMutation<RequestInviteMutation, RequestInviteMutationVariables>(RequestInviteDocument, baseOptions);
      }
export type RequestInviteMutationHookResult = ReturnType<typeof useRequestInviteMutation>;
export type RequestInviteMutationResult = Apollo.MutationResult<RequestInviteMutation>;
export type RequestInviteMutationOptions = Apollo.BaseMutationOptions<RequestInviteMutation, RequestInviteMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($passReset: String!) {
  resetPassword(passReset: $passReset) {
    token
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      passReset: // value for 'passReset'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResourcesDocument = gql`
    query resources {
  resources {
    id
    title
    category
    thumb
    text
  }
}
    `;

/**
 * __useResourcesQuery__
 *
 * To run a query within a React component, call `useResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useResourcesQuery(baseOptions?: Apollo.QueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
        return Apollo.useQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, baseOptions);
      }
export function useResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
          return Apollo.useLazyQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, baseOptions);
        }
export type ResourcesQueryHookResult = ReturnType<typeof useResourcesQuery>;
export type ResourcesLazyQueryHookResult = ReturnType<typeof useResourcesLazyQuery>;
export type ResourcesQueryResult = Apollo.QueryResult<ResourcesQuery, ResourcesQueryVariables>;
export const SendResetEmailDocument = gql`
    mutation sendResetEmail($email: String!) {
  sendResetEmail(email: $email)
}
    `;
export type SendResetEmailMutationFn = Apollo.MutationFunction<SendResetEmailMutation, SendResetEmailMutationVariables>;

/**
 * __useSendResetEmailMutation__
 *
 * To run a mutation, you first call `useSendResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetEmailMutation, { data, loading, error }] = useSendResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetEmailMutation, SendResetEmailMutationVariables>) {
        return Apollo.useMutation<SendResetEmailMutation, SendResetEmailMutationVariables>(SendResetEmailDocument, baseOptions);
      }
export type SendResetEmailMutationHookResult = ReturnType<typeof useSendResetEmailMutation>;
export type SendResetEmailMutationResult = Apollo.MutationResult<SendResetEmailMutation>;
export type SendResetEmailMutationOptions = Apollo.BaseMutationOptions<SendResetEmailMutation, SendResetEmailMutationVariables>;
export const SessionDocument = gql`
    query session($id: ID!) {
  session(id: $id) {
    id
    date
    sessionMaster {
      id
    }
    program
    group {
      id
      name
      startDate
      frequency
      room
      usedThemes {
        theme
        lastUsed
      }
      facilitators {
        id
        firstName
        lastName
        nickname
        role
        profilePicUrl
      }
      members {
        id
        firstName
        lastName
        nickname
        profilePicUrl
        notes {
          id
          author {
            id
            firstName
            lastName
          }
          content
        }
        role
      }
    }
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
        profilePicUrl
      }
      nickname
      sessionRole
      muteState
      vidState
      palamiState
      roomState
    }
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
        profilePicUrl
        notes {
          id
          content
          author {
            id
            firstName
            lastName
          }
        }
      }
      nickname
      sessionRole
      muteState
      vidState
      palamiState
      roomState
    }
    theme {
      id
      name
      category
      imageUrl
      program
      songs {
        id
        title
        lyrics
        url
        imageUrl
      }
      discussions {
        id
        question
      }
      reflection {
        id
        question
      }
      quotes {
        id
        author
        quote
      }
      sensoryCues {
        id
        title
        body
        imageUrl
      }
      javaTips {
        id
        body
        author {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionQuery(baseOptions: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>) {
        return Apollo.useQuery<SessionQuery, SessionQueryVariables>(SessionDocument, baseOptions);
      }
export function useSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>) {
          return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(SessionDocument, baseOptions);
        }
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionQueryResult = Apollo.QueryResult<SessionQuery, SessionQueryVariables>;
export const SessionFacilitatorsDocument = gql`
    subscription sessionFacilitators($id: ID!) {
  sessionFacilitators(id: $id) {
    id
    firstName
    lastName
    nickname
    role
    muteState
    vidState
    palamiState
    roomState
    twilioName
  }
}
    `;

/**
 * __useSessionFacilitatorsSubscription__
 *
 * To run a query within a React component, call `useSessionFacilitatorsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionFacilitatorsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionFacilitatorsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionFacilitatorsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionFacilitatorsSubscription, SessionFacilitatorsSubscriptionVariables>) {
        return Apollo.useSubscription<SessionFacilitatorsSubscription, SessionFacilitatorsSubscriptionVariables>(SessionFacilitatorsDocument, baseOptions);
      }
export type SessionFacilitatorsSubscriptionHookResult = ReturnType<typeof useSessionFacilitatorsSubscription>;
export type SessionFacilitatorsSubscriptionResult = Apollo.SubscriptionResult<SessionFacilitatorsSubscription>;
export const SessionMembersDocument = gql`
    subscription sessionMembers($id: ID!) {
  sessionMembers(id: $id) {
    id
    firstName
    lastName
    nickname
    role
    muteState
    vidState
    palamiState
    roomState
    twilioName
  }
}
    `;

/**
 * __useSessionMembersSubscription__
 *
 * To run a query within a React component, call `useSessionMembersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionMembersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionMembersSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionMembersSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionMembersSubscription, SessionMembersSubscriptionVariables>) {
        return Apollo.useSubscription<SessionMembersSubscription, SessionMembersSubscriptionVariables>(SessionMembersDocument, baseOptions);
      }
export type SessionMembersSubscriptionHookResult = ReturnType<typeof useSessionMembersSubscription>;
export type SessionMembersSubscriptionResult = Apollo.SubscriptionResult<SessionMembersSubscription>;
export const SessionSessionMembersDocument = gql`
    query sessionSessionMembers($id: ID!) {
  session(id: $id) {
    sessionMembers {
      user {
        id
        firstName
        lastName
        nickname
        profilePicUrl
        notes {
          id
          content
          author {
            id
            firstName
            lastName
          }
        }
      }
      nickname
      sessionRole
      muteState
      vidState
      palamiState
      roomState
      twilioName
    }
  }
}
    `;

/**
 * __useSessionSessionMembersQuery__
 *
 * To run a query within a React component, call `useSessionSessionMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSessionMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSessionMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionSessionMembersQuery(baseOptions: Apollo.QueryHookOptions<SessionSessionMembersQuery, SessionSessionMembersQueryVariables>) {
        return Apollo.useQuery<SessionSessionMembersQuery, SessionSessionMembersQueryVariables>(SessionSessionMembersDocument, baseOptions);
      }
export function useSessionSessionMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionSessionMembersQuery, SessionSessionMembersQueryVariables>) {
          return Apollo.useLazyQuery<SessionSessionMembersQuery, SessionSessionMembersQueryVariables>(SessionSessionMembersDocument, baseOptions);
        }
export type SessionSessionMembersQueryHookResult = ReturnType<typeof useSessionSessionMembersQuery>;
export type SessionSessionMembersLazyQueryHookResult = ReturnType<typeof useSessionSessionMembersLazyQuery>;
export type SessionSessionMembersQueryResult = Apollo.QueryResult<SessionSessionMembersQuery, SessionSessionMembersQueryVariables>;
export const SessionMixedUsersDocument = gql`
    subscription sessionMixedUsers($id: ID!) {
  sessionMixedUsers(id: $id) {
    id
    firstName
    lastName
    nickname
    profilePicUrl
    role
    muteState
    vidState
    palamiState
    roomState
    twilioName
  }
}
    `;

/**
 * __useSessionMixedUsersSubscription__
 *
 * To run a query within a React component, call `useSessionMixedUsersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionMixedUsersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionMixedUsersSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionMixedUsersSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionMixedUsersSubscription, SessionMixedUsersSubscriptionVariables>) {
        return Apollo.useSubscription<SessionMixedUsersSubscription, SessionMixedUsersSubscriptionVariables>(SessionMixedUsersDocument, baseOptions);
      }
export type SessionMixedUsersSubscriptionHookResult = ReturnType<typeof useSessionMixedUsersSubscription>;
export type SessionMixedUsersSubscriptionResult = Apollo.SubscriptionResult<SessionMixedUsersSubscription>;
export const SessionModifiedDocument = gql`
    subscription sessionModified($id: ID!) {
  sessionModified(id: $id) {
    currentScreen
    status
    songPlaying
    videoChatStatus
    contentType
    contentId
    globalMessage
    sessionMaster
  }
}
    `;

/**
 * __useSessionModifiedSubscription__
 *
 * To run a query within a React component, call `useSessionModifiedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionModifiedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionModifiedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionModifiedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionModifiedSubscription, SessionModifiedSubscriptionVariables>) {
        return Apollo.useSubscription<SessionModifiedSubscription, SessionModifiedSubscriptionVariables>(SessionModifiedDocument, baseOptions);
      }
export type SessionModifiedSubscriptionHookResult = ReturnType<typeof useSessionModifiedSubscription>;
export type SessionModifiedSubscriptionResult = Apollo.SubscriptionResult<SessionModifiedSubscription>;
export const SessionStatusDocument = gql`
    query sessionStatus($id: ID!) {
  session(id: $id) {
    id
    date
    status
    group {
      facilitators {
        id
      }
    }
  }
}
    `;

/**
 * __useSessionStatusQuery__
 *
 * To run a query within a React component, call `useSessionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionStatusQuery(baseOptions: Apollo.QueryHookOptions<SessionStatusQuery, SessionStatusQueryVariables>) {
        return Apollo.useQuery<SessionStatusQuery, SessionStatusQueryVariables>(SessionStatusDocument, baseOptions);
      }
export function useSessionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionStatusQuery, SessionStatusQueryVariables>) {
          return Apollo.useLazyQuery<SessionStatusQuery, SessionStatusQueryVariables>(SessionStatusDocument, baseOptions);
        }
export type SessionStatusQueryHookResult = ReturnType<typeof useSessionStatusQuery>;
export type SessionStatusLazyQueryHookResult = ReturnType<typeof useSessionStatusLazyQuery>;
export type SessionStatusQueryResult = Apollo.QueryResult<SessionStatusQuery, SessionStatusQueryVariables>;
export const SessionWaitingRoomDocument = gql`
    subscription sessionWaitingRoom($id: ID!) {
  sessionWaitingRoom(id: $id) {
    id
    firstName
    lastName
    nickname
    role
    muteState
  }
}
    `;

/**
 * __useSessionWaitingRoomSubscription__
 *
 * To run a query within a React component, call `useSessionWaitingRoomSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSessionWaitingRoomSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionWaitingRoomSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSessionWaitingRoomSubscription(baseOptions: Apollo.SubscriptionHookOptions<SessionWaitingRoomSubscription, SessionWaitingRoomSubscriptionVariables>) {
        return Apollo.useSubscription<SessionWaitingRoomSubscription, SessionWaitingRoomSubscriptionVariables>(SessionWaitingRoomDocument, baseOptions);
      }
export type SessionWaitingRoomSubscriptionHookResult = ReturnType<typeof useSessionWaitingRoomSubscription>;
export type SessionWaitingRoomSubscriptionResult = Apollo.SubscriptionResult<SessionWaitingRoomSubscription>;
export const SessionsDocument = gql`
    query sessions {
  sessions {
    id
    date
    theme {
      id
      name
      imageUrl
    }
    program
    group {
      id
      name
      room
    }
    facilitators {
      user {
        id
        firstName
        lastName
        nickname
        role
      }
    }
    members {
      id
      firstName
      lastName
      nickname
    }
  }
}
    `;

/**
 * __useSessionsQuery__
 *
 * To run a query within a React component, call `useSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionsQuery(baseOptions?: Apollo.QueryHookOptions<SessionsQuery, SessionsQueryVariables>) {
        return Apollo.useQuery<SessionsQuery, SessionsQueryVariables>(SessionsDocument, baseOptions);
      }
export function useSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionsQuery, SessionsQueryVariables>) {
          return Apollo.useLazyQuery<SessionsQuery, SessionsQueryVariables>(SessionsDocument, baseOptions);
        }
export type SessionsQueryHookResult = ReturnType<typeof useSessionsQuery>;
export type SessionsLazyQueryHookResult = ReturnType<typeof useSessionsLazyQuery>;
export type SessionsQueryResult = Apollo.QueryResult<SessionsQuery, SessionsQueryVariables>;
export const StartSessionDocument = gql`
    mutation startSession($session: ID!) {
  startSession(session: $session) {
    id
    facilitators {
      user {
        id
      }
    }
  }
}
    `;
export type StartSessionMutationFn = Apollo.MutationFunction<StartSessionMutation, StartSessionMutationVariables>;

/**
 * __useStartSessionMutation__
 *
 * To run a mutation, you first call `useStartSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSessionMutation, { data, loading, error }] = useStartSessionMutation({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useStartSessionMutation(baseOptions?: Apollo.MutationHookOptions<StartSessionMutation, StartSessionMutationVariables>) {
        return Apollo.useMutation<StartSessionMutation, StartSessionMutationVariables>(StartSessionDocument, baseOptions);
      }
export type StartSessionMutationHookResult = ReturnType<typeof useStartSessionMutation>;
export type StartSessionMutationResult = Apollo.MutationResult<StartSessionMutation>;
export type StartSessionMutationOptions = Apollo.BaseMutationOptions<StartSessionMutation, StartSessionMutationVariables>;
export const StopSessionDocument = gql`
    mutation stopSession($session: ID!) {
  stopSession(session: $session) {
    id
    facilitators {
      user {
        id
      }
    }
  }
}
    `;
export type StopSessionMutationFn = Apollo.MutationFunction<StopSessionMutation, StopSessionMutationVariables>;

/**
 * __useStopSessionMutation__
 *
 * To run a mutation, you first call `useStopSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopSessionMutation, { data, loading, error }] = useStopSessionMutation({
 *   variables: {
 *      session: // value for 'session'
 *   },
 * });
 */
export function useStopSessionMutation(baseOptions?: Apollo.MutationHookOptions<StopSessionMutation, StopSessionMutationVariables>) {
        return Apollo.useMutation<StopSessionMutation, StopSessionMutationVariables>(StopSessionDocument, baseOptions);
      }
export type StopSessionMutationHookResult = ReturnType<typeof useStopSessionMutation>;
export type StopSessionMutationResult = Apollo.MutationResult<StopSessionMutation>;
export type StopSessionMutationOptions = Apollo.BaseMutationOptions<StopSessionMutation, StopSessionMutationVariables>;
export const SubUsersDocument = gql`
    query subUsers {
  subUsers {
    id
    firstName
    lastName
    nickname
    email
    dateOfBirth
    profilePicUrl
  }
}
    `;

/**
 * __useSubUsersQuery__
 *
 * To run a query within a React component, call `useSubUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubUsersQuery(baseOptions?: Apollo.QueryHookOptions<SubUsersQuery, SubUsersQueryVariables>) {
        return Apollo.useQuery<SubUsersQuery, SubUsersQueryVariables>(SubUsersDocument, baseOptions);
      }
export function useSubUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubUsersQuery, SubUsersQueryVariables>) {
          return Apollo.useLazyQuery<SubUsersQuery, SubUsersQueryVariables>(SubUsersDocument, baseOptions);
        }
export type SubUsersQueryHookResult = ReturnType<typeof useSubUsersQuery>;
export type SubUsersLazyQueryHookResult = ReturnType<typeof useSubUsersLazyQuery>;
export type SubUsersQueryResult = Apollo.QueryResult<SubUsersQuery, SubUsersQueryVariables>;
export const SwitchUserDocument = gql`
    mutation switchUser($user: ID!) {
  switchUser(user: $user) {
    token
  }
}
    `;
export type SwitchUserMutationFn = Apollo.MutationFunction<SwitchUserMutation, SwitchUserMutationVariables>;

/**
 * __useSwitchUserMutation__
 *
 * To run a mutation, you first call `useSwitchUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchUserMutation, { data, loading, error }] = useSwitchUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useSwitchUserMutation(baseOptions?: Apollo.MutationHookOptions<SwitchUserMutation, SwitchUserMutationVariables>) {
        return Apollo.useMutation<SwitchUserMutation, SwitchUserMutationVariables>(SwitchUserDocument, baseOptions);
      }
export type SwitchUserMutationHookResult = ReturnType<typeof useSwitchUserMutation>;
export type SwitchUserMutationResult = Apollo.MutationResult<SwitchUserMutation>;
export type SwitchUserMutationOptions = Apollo.BaseMutationOptions<SwitchUserMutation, SwitchUserMutationVariables>;
export const ThemeDocument = gql`
    query theme($id: ID!) {
  theme(id: $id) {
    id
    name
    category
    imageUrl
    program
    discussions {
      id
      question
      isDeleted
    }
    reflection {
      id
      question
      isDeleted
    }
    songs {
      id
      title
      lyrics
      url
      imageUrl
      isDeleted
    }
    quotes {
      id
      author
      quote
      isDeleted
    }
    sensoryCues {
      id
      title
      body
      imageUrl
      isDeleted
    }
    javaTips {
      id
      body
      author {
        id
        firstName
        lastName
      }
      isDeleted
    }
  }
}
    `;

/**
 * __useThemeQuery__
 *
 * To run a query within a React component, call `useThemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThemeQuery(baseOptions: Apollo.QueryHookOptions<ThemeQuery, ThemeQueryVariables>) {
        return Apollo.useQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, baseOptions);
      }
export function useThemeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeQuery, ThemeQueryVariables>) {
          return Apollo.useLazyQuery<ThemeQuery, ThemeQueryVariables>(ThemeDocument, baseOptions);
        }
export type ThemeQueryHookResult = ReturnType<typeof useThemeQuery>;
export type ThemeLazyQueryHookResult = ReturnType<typeof useThemeLazyQuery>;
export type ThemeQueryResult = Apollo.QueryResult<ThemeQuery, ThemeQueryVariables>;
export const ThemePhotosDocument = gql`
    query themePhotos {
  themePhotos {
    imageUrl
  }
}
    `;

/**
 * __useThemePhotosQuery__
 *
 * To run a query within a React component, call `useThemePhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemePhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemePhotosQuery({
 *   variables: {
 *   },
 * });
 */
export function useThemePhotosQuery(baseOptions?: Apollo.QueryHookOptions<ThemePhotosQuery, ThemePhotosQueryVariables>) {
        return Apollo.useQuery<ThemePhotosQuery, ThemePhotosQueryVariables>(ThemePhotosDocument, baseOptions);
      }
export function useThemePhotosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemePhotosQuery, ThemePhotosQueryVariables>) {
          return Apollo.useLazyQuery<ThemePhotosQuery, ThemePhotosQueryVariables>(ThemePhotosDocument, baseOptions);
        }
export type ThemePhotosQueryHookResult = ReturnType<typeof useThemePhotosQuery>;
export type ThemePhotosLazyQueryHookResult = ReturnType<typeof useThemePhotosLazyQuery>;
export type ThemePhotosQueryResult = Apollo.QueryResult<ThemePhotosQuery, ThemePhotosQueryVariables>;
export const ThemesDocument = gql`
    query themes {
  themes {
    id
    name
    category
    imageUrl
    program
    discussions {
      id
      question
      isDeleted
    }
    reflection {
      id
      question
      isDeleted
    }
    songs {
      id
      title
      lyrics
      url
      imageUrl
      isDeleted
    }
    quotes {
      id
      author
      quote
      isDeleted
    }
    sensoryCues {
      id
      title
      body
      imageUrl
      isDeleted
    }
    javaTips {
      id
      body
      isDeleted
    }
  }
}
    `;

/**
 * __useThemesQuery__
 *
 * To run a query within a React component, call `useThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useThemesQuery(baseOptions?: Apollo.QueryHookOptions<ThemesQuery, ThemesQueryVariables>) {
        return Apollo.useQuery<ThemesQuery, ThemesQueryVariables>(ThemesDocument, baseOptions);
      }
export function useThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemesQuery, ThemesQueryVariables>) {
          return Apollo.useLazyQuery<ThemesQuery, ThemesQueryVariables>(ThemesDocument, baseOptions);
        }
export type ThemesQueryHookResult = ReturnType<typeof useThemesQuery>;
export type ThemesLazyQueryHookResult = ReturnType<typeof useThemesLazyQuery>;
export type ThemesQueryResult = Apollo.QueryResult<ThemesQuery, ThemesQueryVariables>;
export const UnseenNotificationsDocument = gql`
    query unseenNotifications {
  unseenNotifications {
    id
    owner {
      id
    }
    type
    user {
      id
      firstName
      lastName
      nickname
    }
    group {
      id
      name
    }
    session {
      id
    }
    message
    seenEh
    actionedEh
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useUnseenNotificationsQuery__
 *
 * To run a query within a React component, call `useUnseenNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnseenNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnseenNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnseenNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UnseenNotificationsQuery, UnseenNotificationsQueryVariables>) {
        return Apollo.useQuery<UnseenNotificationsQuery, UnseenNotificationsQueryVariables>(UnseenNotificationsDocument, baseOptions);
      }
export function useUnseenNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnseenNotificationsQuery, UnseenNotificationsQueryVariables>) {
          return Apollo.useLazyQuery<UnseenNotificationsQuery, UnseenNotificationsQueryVariables>(UnseenNotificationsDocument, baseOptions);
        }
export type UnseenNotificationsQueryHookResult = ReturnType<typeof useUnseenNotificationsQuery>;
export type UnseenNotificationsLazyQueryHookResult = ReturnType<typeof useUnseenNotificationsLazyQuery>;
export type UnseenNotificationsQueryResult = Apollo.QueryResult<UnseenNotificationsQuery, UnseenNotificationsQueryVariables>;
export const UserDocument = gql`
    query user($id: ID!) {
  user(id: $id) {
    id
    nickname
    firstName
    lastName
    profilePicUrl
    optOut
    notes {
      author {
        id
        firstName
        lastName
      }
      content
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, baseOptions);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserByFirstNameDocument = gql`
    query userByFirstName($firstName: String) {
  userByFirstName(firstName: $firstName) {
    id
    nickname
    firstName
    lastName
  }
}
    `;

/**
 * __useUserByFirstNameQuery__
 *
 * To run a query within a React component, call `useUserByFirstNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByFirstNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByFirstNameQuery({
 *   variables: {
 *      firstName: // value for 'firstName'
 *   },
 * });
 */
export function useUserByFirstNameQuery(baseOptions?: Apollo.QueryHookOptions<UserByFirstNameQuery, UserByFirstNameQueryVariables>) {
        return Apollo.useQuery<UserByFirstNameQuery, UserByFirstNameQueryVariables>(UserByFirstNameDocument, baseOptions);
      }
export function useUserByFirstNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByFirstNameQuery, UserByFirstNameQueryVariables>) {
          return Apollo.useLazyQuery<UserByFirstNameQuery, UserByFirstNameQueryVariables>(UserByFirstNameDocument, baseOptions);
        }
export type UserByFirstNameQueryHookResult = ReturnType<typeof useUserByFirstNameQuery>;
export type UserByFirstNameLazyQueryHookResult = ReturnType<typeof useUserByFirstNameLazyQuery>;
export type UserByFirstNameQueryResult = Apollo.QueryResult<UserByFirstNameQuery, UserByFirstNameQueryVariables>;
export const UsersDocument = gql`
    query users {
  users {
    id
    firstName
    lastName
    phone
    email
    role
    nickname
    country
    postalcode
    ageGroup
    living
    profilePicUrl
    parentUser
    createdAt
    status
    validUntil
    optOut
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const FuzzyUsersDocument = gql`
    query fuzzyUsers($name: String!) {
  usersByFuzzyName(name: $name) {
    id
    nickname
    firstName
    lastName
    email
    profilePicUrl
    role
  }
}
    `;

/**
 * __useFuzzyUsersQuery__
 *
 * To run a query within a React component, call `useFuzzyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFuzzyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFuzzyUsersQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFuzzyUsersQuery(baseOptions: Apollo.QueryHookOptions<FuzzyUsersQuery, FuzzyUsersQueryVariables>) {
        return Apollo.useQuery<FuzzyUsersQuery, FuzzyUsersQueryVariables>(FuzzyUsersDocument, baseOptions);
      }
export function useFuzzyUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FuzzyUsersQuery, FuzzyUsersQueryVariables>) {
          return Apollo.useLazyQuery<FuzzyUsersQuery, FuzzyUsersQueryVariables>(FuzzyUsersDocument, baseOptions);
        }
export type FuzzyUsersQueryHookResult = ReturnType<typeof useFuzzyUsersQuery>;
export type FuzzyUsersLazyQueryHookResult = ReturnType<typeof useFuzzyUsersLazyQuery>;
export type FuzzyUsersQueryResult = Apollo.QueryResult<FuzzyUsersQuery, FuzzyUsersQueryVariables>;