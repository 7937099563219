import React, { useState, useEffect } from "react";
import { useRemoveUserFromSessionMutation } from "../../../graphql/generated/graphql";
import { toast } from "react-toastify";

const RemoveUserFromSession = props => {
  const {
    session,
    setModalIsOpen,
    isFacilitator,
    deleteMembers,
    setDeleteMembers,
    deleteFacilitators,
    setDeleteFacilitators,
  } = props;

  const [removeUserFromSessionMutation] = useRemoveUserFromSessionMutation();
  const handleSubmit = e => {
    e.preventDefault();
    if (isFacilitator) {
      for (let i = 0; i < deleteFacilitators.length; i++) {
        console.log(deleteFacilitators[i])
        removeUserFromSessionMutation({
          variables: {
            user: deleteFacilitators[i].id,
            as: "facilitator",
            session: session.id,
          },
        })
          .then(res => {
            toast.success("Facilitator removed from session");
            setDeleteFacilitators(
              deleteFacilitators.filter(
                facilitator => facilitator.id !== deleteFacilitators[i].id
              )
            );
          })
          .catch(err => {
            toast.error("Oops, something went wrong");
          });
      }
    } else {
      for (let i = 0; i < deleteMembers.length; i++) {
        removeUserFromSessionMutation({
          variables: {
            user: deleteMembers[i].id,
            as: "member",
            session: session.id,
          },
        })
          .then(res => {
            toast.success("Member removed from session");
            setDeleteMembers(
              deleteMembers.filter(member => {
                return member.id !== deleteMembers[i].id;
              })
            );
          })
          .catch(err => {
            toast.error("Oops, something went wrong");
          });
      }
    }
    setModalIsOpen(false);
  };


  return (
    <div className="round-box box-shadow align-self-center p-5">
      <h4>
        Confirmation: {isFacilitator ? "Facilitators" : "Members"} to be deleted
      </h4>
      {isFacilitator &&
        deleteFacilitators.map(facilitator => {
          return <li key={facilitator.id}>{facilitator.name}</li>;
        })}
      {!isFacilitator &&
        deleteMembers.map(member => {
          return <li key={member.id}>{member.name}</li>;
        })}

      <div className="form-group d-flex justify-content-center mt-3">
        <button
          disabled={
            isFacilitator
              ? deleteFacilitators.length === 0
              : deleteMembers.length === 0
          }
          className="btn btn-extra-radius px-5 bg-info text-white"
          onClick={handleSubmit}
        >
          Remove {isFacilitator ? "facilitator" : "member"}
        </button>
      </div>
    </div>
  );
};

export default RemoveUserFromSession;
