import React from "react";

export default function ThemeQuoteCard(props) {
  const {
    quote: { id, quote, author },
    onThemeCardClick,
  } = props;

  return (
    <div
      className="border round-box my-5 p-3 box-shadow clickable-card"
      onClick={onThemeCardClick}
      data-content="quote"
      data-id={id}
    >
      <div>"{quote}"</div>
      <div>{author}</div>
    </div>
  );
}
