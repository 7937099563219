import React, { useState, useContext, useEffect } from "react";
import ZoomVideo from "./ZoomVideo";
import UserFeedbackBar from "./UserFeedbackBar";
import VideoChat from "../Twilio/VideoChat";
import {
  FaCheck,
  FaHandPaper,
  FaRegSquare,
  FaMicrophone,
  FaQuestion,
  FaSquare,
  FaHome,
} from "react-icons/fa";
import { FaVideo } from "react-icons/fa";
import { FaWindowMinimize } from "react-icons/fa";
import { RiUserLocationFill } from "react-icons/ri";
import { UserContext } from "../../../context/UserContext";
import { ACTIONS, RunSessContext } from "../../../context/RunSessContext";
import {
  useAddMemberFeedbackMutation,
  SessionDocument,
  useSessionMembersSubscription,
  useSessionMixedUsersSubscription,
  useEditSessionMemberMuteStateMutation,
  useEditAllMuteStateMutation,
  useEditSessionMemberVidStateMutation,
  useEditAllVidStateMutation,
  useEditAllRoomStateMutation,
  useSessionFacilitatorsSubscription,
  useEditSessionMemberPalamiStateMutation,
  useEditSessionMemberRoomStateMutation,
  // useSessionQuery,
} from "../../../graphql/generated/graphql";
import { toast } from "react-toastify";
import data from "@iconify-icons/emojione-v1/wind-chime";
import { resolveProjectReferencePath } from "typescript";
import { GiConsoleController } from "react-icons/gi";
import GenericModal from "../../GenericModal";
import ErrorBoundary from "../../ErrorBoundary";
import Draggable from "react-draggable";

export default function ZoomContainer(props) {
  const {
    zoomData,
    bigMansMembers,
    members,
    VideoChatCluster,
    isSilent,
    setIsSilent,
    sessionId,
    previousModalContent,
    master,
    session,
    handleMusicPlay,
    isSyncOn,
    modalSync,
    currentPage,
    setUserList,
    masterId,
    blastMessage,
    setBlastMessage,
    blastOutMessage,
  } = props;
  const { state } = useContext(UserContext);
  const { dispatch, state: sessState } = useContext(RunSessContext);
  const [
    editSessionMemberPalamiStateMutation,
  ] = useEditSessionMemberPalamiStateMutation();
  const [
    editSessionMemberRoomStateMutation,
  ] = useEditSessionMemberRoomStateMutation();
  const handlePalami = user => {
    editSessionMemberPalamiStateMutation({
      variables: {
        session: sessionId,
        member: user.id,
        palamiState: false,
        role: user.role,
      },
    }).catch(err => {
      console.log(err);
    });
  };
  const handleRoom = user => {
    // console.log(user)
    let newMuteState = {
      id: user.id,
      name: user.name,
      muteState: user.muteState,
      vidState: user.vidState,
      palamiState: user.palamiState,
      roomState: !user.roomState,
      role: user.role,
    };
    editSessionMemberRoomStateMutation({
      variables: {
        session: sessionId,
        member: user.id,
        roomState: !user.roomState,
        muteState: user.muteState,
        role: user.role,
      },
    }).catch(err => {
      console.log(err);
    });
    //  setRemoteMuteList([
    //   ...remoteMuteList.filter(remoteUser => remoteUser.id != user.id),
    //   newMuteState,
    // ]);
  };
  const [addMemberFeedback] = useAddMemberFeedbackMutation();
  const [
    editSessionMemberMuteStateMutation,
  ] = useEditSessionMemberMuteStateMutation();
  const [editAllMuteStateMutation] = useEditAllMuteStateMutation();
  const [
    editSessionMemberVidStateMutation,
  ] = useEditSessionMemberVidStateMutation();
  const [editAllVidStateMutation] = useEditAllVidStateMutation();
  const [editAllRoomStateMutation] = useEditAllRoomStateMutation();

  const [userFeedback, setUserFeedback] = useState();
  const [isMini, setIsMini] = useState(false);
  const [palamiCount, setPalamiCount] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [remoteMuteList, setRemoteMuteList] = useState([]);
  const [myRemoteMuteState, setMyRemoteMuteState] = useState(false);
  const [myRemoteVidState, setMyRemoteVidState] = useState(false);
  const [myRemotePalamiState, setMyRemotePalamiState] = useState(false);
  const [myRemoteRoomState, setMyRemoteRoomState] = useState(false);
  const [reRender, setRerender] = useState(false);
  // The type of user will be globally available, to mock the functionality we need to store the user type (membert or facilitator) inside the state
  const [user, setUser] = useState(state.user);
  const [everybody, setEverybody] = useState([]);
  const [participantList, setParticipantList] = useState([]);
  let initialFeedbackMember = {};
  const [selectedMember, setSelectedMember] = useState(initialFeedbackMember);

  const { data, error, loading } = useSessionFacilitatorsSubscription({
    variables: { id: sessionId },
  });
  const {
    data: sessionMixedUserSubscriptionData,
    error: sessionMixedUserSubscriptionError,
    loading: sessionMixedUserSubscriptionLoading,
  } = useSessionMixedUsersSubscription({
    variables: { id: sessionId },
  });
  const {
    data: sessionMembersSubscriptionData,
    error: sessionMembersSubscriptionError,
    loading: sessionMembersSubscriptionLoading,
  } = useSessionMembersSubscription({ variables: { id: sessionId } });
  // const {  data: session, refetch } = useSessionQuery({
  //   variables: {
  //     id: sessionId,
  //   },
  //   fetchPolicy: "network-only",
  // });

  const handleRemoteMute = user => {
    //console.log(user)
    setDisabled(true);
    let newMuteState = {
      id: user.id,
      name: user.name,
      muteState: !user.muteState,
      vidState: user.vidState,
      palamiState: user.palamiState,
      roomState: user.roomState,
      role: user.role,
    };

    if (user.roomState == true) {
      // [*] ensure latency affect unmute and rather than mute to avoid the brief mic feedback loop
      if (user.muteState == true) {
        let prev = remoteMuteList.filter(
          mem => mem.roomState == true && mem.muteState == false
        );
        if (prev.length > 0) {
          //console.log(prev)

          editSessionMemberMuteStateMutation({
            variables: {
              session: sessionId,
              member: prev[0].id,
              muteState: !prev[0].muteState,
              room: prev[0].roomState,
              role: prev[0].role,
            },
          }).catch(err => {
            console.log(err);
          });
        }
      }
      // [/*]
      editSessionMemberMuteStateMutation({
        variables: {
          session: sessionId,
          member: user.id,
          muteState: !user.muteState,
          room: user.roomState,
          role: user.role,
        },
      }).catch(err => {
        console.log(err);
      });
    }
    editSessionMemberMuteStateMutation({
      variables: {
        session: sessionId,
        member: user.id,
        muteState: !user.muteState,
        room: user.roomState,
        role: user.role,
      },
    }).catch(err => {
      console.log(err);
    });
    // setRemoteMuteList([
    //   ...remoteMuteList.filter(remoteUser => remoteUser.id != user.id),
    //   newMuteState,
    // ]);
    setDisabled(false);
  };
  const handleRemoteVid = user => {
    setDisabled(true);
    let newVidState = {
      id: user.id,
      name: user.name,
      muteState: user.muteState,
      vidState: !user.vidState,
      palamiState: user.palamiState,
      roomState: user.roomState,
      role: user.role,
    };

    editSessionMemberVidStateMutation({
      variables: {
        session: sessionId,
        member: user.id,
        vidState: !user.vidState,
        role: user.role,
      },
    }).catch(err => {
      console.log(err);
    });
    editSessionMemberVidStateMutation({
      variables: {
        session: sessionId,
        member: user.id,
        vidState: !user.vidState,
        role: user.role,
      },
    }).catch(err => {
      console.log(err);
    });
    // setRemoteMuteList([
    //   ...remoteMuteList.filter(remoteUser => remoteUser.id != user.id),
    //   newVidState,
    // ]);
    setDisabled(false);
  };
  const [clear, setClear] = useState(true);
  const handleRemoteMuteAll = async howYouWantIt => {
    //setClear(false)
    setDisabled(true);

    // LET ME BE CLEAR! I FUCKING HATE THIS... but it's the only way I can make it work
    await editAllMuteStateMutation({
      variables: {
        session: sessionId,

        muteState: howYouWantIt,
      },
    }).catch(err => {
      console.log(err);
    });
    await editAllMuteStateMutation({
      variables: {
        session: sessionId,

        muteState: howYouWantIt,
      },
    }).catch(err => {
      console.log(err);
    });
    //       .then(async(res)=>{
    // console.log(res)
    // setEverybody();
    // let newEverybody = [];
    // newEverybody.push(
    //     ...res.data.editAllMuteState.facilitators.map(member => {
    //       return {
    //         firstName: member.user.firstName,
    //         id: member.user.id,
    //         lastName: member.user.lastName,
    //         muteState: member.muteState,
    //         role: member.role,
    //       };
    //     })
    //   );
    // newEverybody.push(
    //       ...res.data.editAllMuteState.sessionMembers.map(member => {

    //         return {
    //           firstName: member.user.firstName,
    //           id:  member.user.id,
    //           lastName: member.user.lastName,
    //           muteState: member.muteState,
    //           role: member.role,
    //         };
    //       })
    //     );
    //     console.log(newEverybody)
    //     setEverybody([...newEverybody])

    //         // refetch()
    //       })
    //     let newMuteState = {
    //       id: user.id,
    //       name: user.name,
    //       muteState: howYouWantIt,
    //       role: user.role,
    //     };
    //  setRemoteMuteList([
    //       ...remoteMuteList.filter(remoteUser => remoteUser.id != user.id),
    //       newMuteState,
    //     ]);

    setClear(true);
    setDisabled(false);
  };
  useEffect(() => {
    if (sessionMixedUserSubscriptionData) {
      console.log(sessionMixedUserSubscriptionData);
    }
  }, [sessionMixedUserSubscriptionData]);
  const handleRemoteVidAll = async howYouWantIt => {
    //setClear(false)

    setDisabled(true);

    // LET ME BE CLEAR! I FUCKING HATE THIS... but it's the only way I can make it work
    await editAllVidStateMutation({
      variables: {
        session: sessionId,

        vidState: howYouWantIt,
      },
    }).catch(err => {
      console.log(err);
    });
    await editAllVidStateMutation({
      variables: {
        session: sessionId,

        vidState: howYouWantIt,
      },
    }).catch(err => {
      console.log(err);
    });
    setClear(true);
    setDisabled(false);
  };
  const [info, setInfo] = useState(false);
  const [f2fMode, setF2fMode] = useState(false);
  const [f2fModalIsOpen, setF2fModalIsOpen] = useState(false);
  const toggleInfo = () => {
    if (!info) {
      toast.dark(
        `Face2Face Mode is intended for mixed participation groups where some members are in the same room while others are joing remotely. `,
        { autoClose: false, toastId: 1 }
      );
      toast.dark(
        `Once activated mark the members who are in the room and unmute the one speaking. When someone else wants to speak just click to unmute them and the previous speaker will automatically be muted. This is to avoid microphone feedback and duplication of voices in the room. `,
        { autoClose: false, toastId: 2 }
      );
      toast.dark(
        `This is to avoid microphone feedback and duplication of voices in the room. `,
        { autoClose: false, toastId: 3 }
      );
    } else {
      toast.update(1, { autoClose: 1 });
      toast.update(2, { autoClose: 0.00001 });
      toast.update(3, { autoClose: 1 });
    }
    setInfo(!info);
  };
  const toggleF2F = async special => {
    if (f2fMode || special) {
      await editAllRoomStateMutation({
        variables: {
          session: sessionId,
        },
      }).catch(err => {
        console.log(err);
      });
      await editAllRoomStateMutation({
        variables: {
          session: sessionId,
        },
      }).catch(err => {
        console.log(err);
      });
    }
    setF2fMode(!f2fMode);
  };
  const handleClear = async howYouWantIt => {
    await setRemoteMuteList([
      ...remoteMuteList.map(user => {
        return {
          id: user.id,
          name: user.name,
          muteState: howYouWantIt,
          role: user.role,
        };
      }),
    ]);
  };
  const handleChangeMessage = e => {
    setBlastMessage(e.target.value);
  };
  useEffect(() => {
    setEverybody();
    let newEverybody = [];

    if (
      sessionMixedUserSubscriptionData &&
      sessionMixedUserSubscriptionData.sessionMixedUsers
    ) {
      newEverybody.push(
        ...sessionMixedUserSubscriptionData.sessionMixedUsers.map(member => {
          return {
            firstName: member.firstName,
            id: member.id,
            lastName: member.lastName,
            nickname: member.nickname,
            muteState: member.muteState,
            vidState: member.vidState,
            palamiState: member.palamiState,
            roomState: member.roomState,
            role: member.role,
          };
        })
      );
    }
    //this literally triggers a refresh on the member side
    //     if(sessionMembersSubscriptionData && state.user.role== "ROLE_MEMBER"){

    //         newEverybody.push(
    //           ...sessionMembersSubscriptionData.sessionMembers.map(member => {

    //             return {
    //               firstName: member.firstName,
    //               id: member.id,
    //               lastName: member.lastName,
    //               nickname: member.nickname,
    //               muteState: member.muteState,
    //               vidState: member.vidState,
    //               palamiState: member.palamiState,
    //               roomState: member.roomState,
    //               role: member.role,
    //             };
    //           })
    //           );
    //     }
    //     else  {
    //       // console.log(bigMansMembers)
    //       newEverybody.push(
    //         ...bigMansMembers.map(member => {
    // // console.log(member)

    //           return {
    //             firstName: member.user.firstName,
    //             id: member.user.id,
    //             lastName: member.user.lastName,
    //             nickname: member.user.nickname,
    //             muteState: member.muteState,
    //             vidState: member.vidState,
    //             palamiState: member.palamiState,
    //             roomState: member.roomState,
    //             role: member.sessionRole,
    //           };
    //         })
    //         );
    //       }
    //      else if (members) {
    // console.log(members)
    //         newEverybody.push(
    //           ...members.map(member => {

    //             console.log(member)
    //             return {
    //               firstName: member.firstName,
    //               id: member.id,
    //               lastName: member.lastName,
    //               muteState: member.muteState,
    //               vidState: member.vidState,
    //               palamiState: member.palamiState,
    //               role: member.role,
    //             };
    //           })
    //         );
    //       }
    //  console.log(newEverybody)
    setEverybody(newEverybody);
  }, [
    sessionMixedUserSubscriptionData,
    bigMansMembers,
    session,
    data,
    members,
  ]);
  useEffect(() => {
    if (everybody && everybody.length > 0) {
      setRemoteMuteList([]);
      let temp = [];

      everybody.map(user => {
        temp.push({
          id: user.id,
          name: user.nickname
            ? user.nickname
            : `${user.firstName} ${user.lastName}`,
          muteState: user.muteState,
          vidState: user.vidState,
          palamiState: user.palamiState,
          roomState: user.roomState,
          role: user.role,
        });
      });
      if (state.user.role == "ROLE_FACILITATOR") {
        if (!(participantList.length > 0)) {
          temp = temp.filter(user => user.id === state.user.id);
        } else if (
          participantList.length == 1 &&
          participantList[0].state == "disconnected"
        ) {
          //filter the mute menu by matching session users with their presence on twilio

          temp = temp.filter(user => user.id === state.user.id);
          //temp = temp.filter(user=> (participantList.filter(participant => (participant.identity ===`${user.name} ${user.id.substring(user.id.length - 6, user.id.length)}`)) ).length > 0  || user.id === state.user.id)
        } else if (participantList && remoteMuteList) {
          //filter the mute menu by matching session users with their presence on twilio

          temp = temp.filter(
            user =>
              participantList.filter(
                participant =>
                  participant.identity ===
                    `${user.name} ${user.id.substring(
                      user.id.length - 6,
                      user.id.length
                    )}` && participant.state !== "disconnected"
              ).length > 0 || user.id === state.user.id
          );
        }
      }
      setRemoteMuteList([...temp]);
    }
  }, [everybody, participantList]);
  useEffect(() => {
    setUserList([...remoteMuteList]);
  }, [remoteMuteList]);
  useEffect(() => {
    if (remoteMuteList.length > 0) {
      let count = 0;
      remoteMuteList.map(per => {
        if (per.palamiState === true) {
          count++;
        }
      });
      //  console.log(count)
      setPalamiCount(count);
    }
  }, [remoteMuteList]);

  useEffect(() => {
    if (zoomData && zoomData.muteList) {
      setMyRemoteMuteState(
        zoomData.muteList.filter(muteUser => muteUser.id == user.id)[0]
      );
    }
  }, [zoomData]);
  const handleOnChangeSelectMember = e => {
    setSelectedMember(
      members.find(member => member.user.id === e.target.value)
    );
  };
  useEffect(() => {
    if (everybody && remoteMuteList.length > 0) {
      if (user.role == "ROLE_MEMBER") {
        let possiblyMe = everybody.filter(
          couldBeMe => couldBeMe.id === user.id
        );
        if (possiblyMe.length) {
          setMyRemoteMuteState(possiblyMe[0].muteState);
          setMyRemoteVidState(possiblyMe[0].vidState);
          setMyRemotePalamiState(possiblyMe[0].palamiState);
          setMyRemoteRoomState(possiblyMe[0].roomState);
          dispatch({
            type: ACTIONS.UPDATE_ALL,
            payload: {
              MyRemoteMuteState: possiblyMe[0].muteState,
              MyRemoteVidState: possiblyMe[0].vidState,
              MyRemotePalamiState: possiblyMe[0].palamiState,
              MyRemoteRoomState: possiblyMe[0].roomState,
              hardMute: possiblyMe[0].muteState && possiblyMe[0].roomState,
            },
          });
        }
      } else {
        let possiblyMe = everybody.filter(
          couldBeMe => couldBeMe.id === user.id
        );
        if (possiblyMe.length) {
          setMyRemoteMuteState(possiblyMe[0].muteState);
          setMyRemoteVidState(possiblyMe[0].vidState);
          setMyRemotePalamiState(possiblyMe[0].palamiState);
          setMyRemoteRoomState(possiblyMe[0].roomState);

          dispatch({
            type: ACTIONS.UPDATE_ALL,
            payload: {
              MyRemoteMuteState: possiblyMe[0].muteState,
              MyRemoteVidState: possiblyMe[0].vidState,
              MyRemotePalamiState: possiblyMe[0].palamiState,
              MyRemoteRoomState: possiblyMe[0].roomState,
              hardMute: possiblyMe[0].muteState && possiblyMe[0].roomState,
            },
          });
        }
        // console.log(sessState)
        // let possiblyMe = remoteMuteList.filter(couldBeMe=>(couldBeMe.id === user.id))
        // if(possiblyMe.length){
        //   console.log(possiblyMe[0].muteState)
        //   setMyRemoteMuteState(possiblyMe[0].muteState)
        //   console.log("Is dis right? " +possiblyMe[0] )
        // }
      }
    }
  }, [remoteMuteList, everybody]);
  const handleNewUserFeedback = newFeedbackObject => {
    if (user.role === "ROLE_FACILITATOR") {
      if (Object.keys(selectedMember).length > 0) {
        addMemberFeedback({
          variables: {
            session: sessionId,
            user: selectedMember.user.id,
            emotion: newFeedbackObject.emotion,
            currentScreen: previousModalContent.page,
            songPlaying: previousModalContent.songPlaying,
            contentType: previousModalContent.contentType,
            contentId: previousModalContent.contentId,
          },
        })
          .then(res => {
            // console.log(res);
            toast.success(
              `You have added feedback: ${newFeedbackObject.emotion} for use id: ${selectedMember.user.id}`
            );
          })
          .catch(err => console.log(err));
      }
    } else {
      addMemberFeedback({
        variables: {
          session: sessionId,
          user: state.user.id,
          emotion: newFeedbackObject.emotion,
          currentScreen: previousModalContent.page,
          songPlaying: previousModalContent.songPlaying,
          contentType: previousModalContent.contentType,
          contentId: previousModalContent.contentId,
        },
      })
        .then(res => {
          toast.success(`Thank you for your feedback`);
        })
        .catch(err => console.log(err));
    }
  };

  const [username, setUsername] = useState("");
  const [roomName, setRoomName] = useState("");
  const [token, setToken] = useState(null);
  useEffect(() => {
    if (state) {
      let salt = state.user.id;
      salt = salt.substring(salt.length - 6, salt.length);
      setUsername(
        state.user.nickname
          ? `${state.user.nickname} ${salt}`
          : `${state.user.firstName} ${state.user.lastName} ${salt}`
      );
      setRoomName(sessionId);
    }
    fetch(process.env.REACT_APP_VIDEO_TOKEN, {
      method: "POST",
      body: JSON.stringify({
        identity: username,
        room: roomName,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(json => {
        setToken(json.token);
      });
    return () => {};
  }, [roomName, username, state]);
  const getWord = flag => {
    if (flag) return "unmute";
    else {
      return "mute";
    }
  };
  return (
    <aside
      className={`d-flex flex-column h-90 w-100 ${
        zoomData.viewMode === "horizontal" ? "pl-1" : ""
      } ${zoomData.viewMode === "fullscreen" ? "px-5" : ""}`}
      style={{
        marginTop: "90px",
        paddingLeft: `${zoomData.viewMode === "vertical" ? "3.25em" : ""}`,
        paddingRight: `${zoomData.viewMode === "vertical" ? "3.25em" : ""}`,
      }}
    >
      <div
        style={{ height: "85vh", overflowY: "auto", overflowX: "hidden" }}
        className={
          "mr-2 my-4 bg-zero border useBorder box-shadow round-box shadow"
        }
      >
        <main className="w-100 d-flex  justify-content-center">
          <ErrorBoundary>
            <VideoChatCluster
              username={username}
              roomName={roomName}
              token={token}
              setParticipantList={setParticipantList}
              setToken={setToken}
              isSilent={isSilent}
              sessionId={sessionId}
              myRemoteMuteState={myRemoteMuteState}
              myRemoteVidState={myRemoteVidState}
              myRemotePalamiState={myRemotePalamiState}
              myRemoteRoomState={myRemoteRoomState}
              zoomData={zoomData}
            />{" "}
          </ErrorBoundary>
        </main>

        {/* <div className="my-auto p-2 d-flex justify-content-center">
          {user.role === "ROLE_FACILITATOR" && (
            <>
              {members.length > 0 ? (
                <>
                  <div className="form-group w-50 px-3">
                    <label htmlFor="member">Members</label>
                    <select
                      id="member"
                      className="form-control"
                      value={"default"}
                      onChange={handleOnChangeSelectMember}
                    >
                      {members.map(member => (
                        <option value={member.user.id} key={member.user.id}>
                          {member.user.firstName} {member.user.lastName}
                        </option>
                      ))}
                    </select>
                  </div>

                  {Object.keys(selectedMember).length > 0 && (
                    <div className="w-50">
                      Selected user
                      <div
                        className="box-shadow mt-2 p-2"
                        style={{ borderRadius: "7px" }}
                      >
                        {Object.keys(selectedMember).length !== 0 && (
                          <>
                            {selectedMember.user.firstName}{" "}
                            {selectedMember.user.lastName}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <h4>We do not have member who joined this session yet</h4>
              )}
            </>
          )}
        </div> */}
        <footer className="w-100 align-self-bottom mb-1 mt-auto">
          <div className="my-auto p-2 d-flex justify-content-center">
            {/*change this*/}
            {remoteMuteList &&
              remoteMuteList.length > 0 &&
              isSyncOn === true &&
              state.user.role === "ROLE_FACILITATOR" && (

              
                <div
                  style={{
                    minWidth: "400px",
                    bottom: ".15em",
                    right: ".45em",
                    opacity: 1,
                    position:"fixed",
                    zIndex: 9999,
                  }}
                  className={` bg-dark text-light round-box box-shadow p-4 d-flex flex-column-reverse`}
                >
                  <GenericModal
                    modalIsOpen={f2fModalIsOpen}
                    setModalIsOpen={setF2fModalIsOpen}
                  >
                    <div
                      className={`   round-box box-shadow mx-1 bg-clear border useBorder m-2 px-5
                          `}
                    >
                      <div className={` my-2   mx-5`}>
                        <h4>Face2Face Menu</h4>
                        <div>
                          Please mark the members who are in the room and unmute
                          the one speaking.
                        </div>
                      </div>
                      {remoteMuteList
                        .sort(function compareNumbers(a, b) {
                          var nameA = a.name.toUpperCase();
                          var nameB = b.name.toUpperCase();
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }

                          return 0;
                        })
                        .map(member => (
                          <div
                            key={member.id}
                            className={`col col-6 mx-auto  d-flex round-box box-shadow mx-1 bg-clear border useBorder my-1
                          `}
                          >
                            <div
                              className={` w-100 d-flex justify-content-start align-items-center
                               text-center my-2 px-2 p-1`}
                            >
                              <div
                                role="button"
                                onClick={() => handleRoom(member)}
                                className={` btn border box-shadow ${
                                  !member.roomState
                                    ? "bg-clearl h-100"
                                    : "bg-info text-white"
                                } useBorder mr-3 
                            `}
                              >
                                {member.roomState ? (
                                  <FaCheck></FaCheck>
                                ) : (
                                  <FaRegSquare />
                                )}
                              </div>

                              <span>
                                {member.name}{" "}
                                {member.role == "ROLE_FACILITATOR"
                                  ? member.id == masterId
                                    ? " - Facilitator"
                                    : "- Co-Facilitator"
                                  : member.role
                                  ? "- " + member.role
                                  : ""}
                              </span>
                            </div>
                          </div>
                        ))}
                      <div
                        className={` my-2  d-flex justify-content-center  mx-5`}
                      >
                        <div
                          role="button"
                          onClick={() => toggleF2F(true)}
                          className={`btn btn-danger mr-2 `}
                        >
                          Clear all
                        </div>
                        <div
                          role="button"
                          onClick={() => setF2fModalIsOpen(false)}
                          className={`btn btn-success ml-2 `}
                        >
                          Done
                        </div>
                      </div>
                    </div>
                  </GenericModal>
                  <div className={`d-flex justify-content-start flex-column`}>
                    <div
                      className={`${
                        isMini ? "d-none" : "d-flex"
                      }  mt-2 py-1 round-box bg-clear border useBorder justify-content-center align-items-center`}
                    >
                      <span
                        className={` ${
                          isMini ? "d-none" : ""
                        } text-align-center row align-self-center col col-6`}
                      >
                        Mute all
                      </span>
                      <button
                        className={`col-2 cam-control  align-self-center btn  mr-2 px-1 ${
                          isMini || isSilent ? "d-none" : ""
                        } ${"btn-danger"}`}
                        onClick={() => {
                          handleRemoteMuteAll(true);
                        }}
                      >
                        {" "}
                        <div>
                          <FaMicrophone />{" "}
                        </div>
                      </button>
                      <button
                        className={`col-2 cam-control  align-self-center btn  mr-2 px-1 ${
                          isMini ? "d-none" : ""
                        } ${"btn-danger"}`}
                        onClick={() => {
                          handleRemoteVidAll(true);
                        }}
                      >
                        <div>
                          <FaVideo />
                        </div>
                      </button>
                    </div>
                    <div
                      className={`  mt-2 py-1 round-box bg-clear border useBorder ${
                        isMini ? "d-none" : " d-flex"
                      } justify-content-center`}
                    >
                      <span
                        className={`${
                          isMini ? "d-none" : ""
                        }  text-align-center row align-self-center col col-6`}
                      >
                        Unmute all
                      </span>
                      <button
                        className={`col-2 cam-control   align-self-center btn mr-2 px-1 ${
                          isMini || isSilent ? "d-none" : ""
                        } ${"btn-success"}`}
                        onClick={() => {
                          handleRemoteMuteAll(false);
                        }}
                      >
                        {" "}
                        <div>
                          <FaMicrophone />{" "}
                        </div>
                      </button>
                      <button
                        className={`col-2 cam-control  btn mr-2 px-1 ${
                          isMini ? "d-none" : ""
                        } ${"btn-success"}`}
                        onClick={() => {
                          handleRemoteVidAll(false);
                        }}
                      >
                        <div>
                          <FaVideo />
                        </div>
                      </button>
                    </div>
                    <div
                      className={`  mt-2 py-1 round-box bg-clear border useBorder ${
                        isMini ? "d-none" : " d-flex"
                      } justify-content-center`}
                    >
                      <span
                        className={`${
                          isMini ? "d-none" : ""
                        }  text-align-center row align-self-center col col-6`}
                      >
                        Face2Face Mode
                      </span>
                      <button
                        className={`col-2 cam-control   align-self-center btn mr-2 px-1 ${
                          isMini || isSilent ? "d-none" : ""
                        } ${f2fMode ? "btn-info" : "btn-info"}`}
                        onClick={() => {
                          setF2fModalIsOpen(true);
                        }}
                      >
                        {" "}
                        <div>Menu </div>
                      </button>
                      <button
                        className={`col-2 cam-control  btn mr-2 px-1 ${
                          isMini ? "d-none" : ""
                        } ${"btn-info"}`}
                        onClick={() => {
                          toggleInfo();
                        }}
                      >
                        <div>
                          <FaQuestion />
                        </div>
                      </button>
                    </div>
                    {state.user.id == masterId && (
                      <div
                        className={`  mt-2 py-1 round-box bg-clear border useBorder ${
                          isMini ? "d-none" : " d-flex"
                        } justify-content-center`}
                      >
                        <div
                          className={`row w-50 d-flex justify-content-center`}
                        >
                          <div className={` d-flex justify-content-center`}>
                            <h6 className={``}>Broadcast message to group</h6>
                          </div>

                          <div className={` d-flex justify-content-center`}>
                            <input
                              type="text"
                              value={blastMessage}
                              onChange={e => {
                                handleChangeMessage(e);
                              }}
                            ></input>
                            <button
                              onClick={() => {
                                if (blastMessage !== "") blastOutMessage();
                              }}
                              className={`btn btn-info`}
                            >
                              Send
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div
                    style={{ maxHeight: "45vh", overflowY: "auto" }}
                    className={"justify-content-center  muteMenuScroll"}
                  >
                    <button
                      className={`position-absolute p-0 pb-2   d-flex flex-column justify-content-center align-items-center  rounded-circle btn  ${
                        !isMini ? "btn-danger" : "btn-outline-danger bg-light"
                      }  `}
                      style={{
                        width: "2em",
                        height: "2em",
                        top: "-0.5em",
                        right: "0",
                        zIndex: 10,
                      }}
                      onClick={() => setIsMini(!isMini)}
                    >
                      {" "}
                      <FaWindowMinimize className="align-self-center"></FaWindowMinimize>{" "}
                    </button>
                    <div
                      className={`position-absolute p-0 pb-2  d-flex  justify-content-center align-items-end  rounded-circle   ${
                        !isMini || palamiCount == 0
                          ? " d-none"
                          : "bg-info text-white"
                      }  `}
                      style={{
                        width: "2.2em",
                        height: "2em",
                        top: "-0.5em",
                        left: "0em",
                        zIndex: 10,
                      }}
                    >
                      <span
                        className={`align-self-center d-flex pt-2 justify-content-center align-items-center`}
                      >
                        {palamiCount != 0 && isMini ? palamiCount : ""}
                        <FaHandPaper
                          className={`  ${
                            !isMini || palamiCount == 0 ? "d-none" : ""
                          }  `}
                        />{" "}
                      </span>
                    </div>
                    {remoteMuteList && remoteMuteList.length > 0 && (
                      <>
                        {remoteMuteList
                          .sort(function compareNumbers(a, b) {
                            var nameA = a.name.toUpperCase();
                            var nameB = b.name.toUpperCase();
                            if (nameA < nameB) {
                              return -1;
                            }
                            if (nameA > nameB) {
                              return 1;
                            }

                            return 0;
                          })
                          .map(member => (
                            <div
                              key={member.id}
                              className={` ${
                                isMini ? "d-none" : ""
                              } round-box box-shadow mx-1 bg-clear border useBorder my-1
                          `}
                            >
                              <div
                                className={` ${isMini ? "d-none" : ""} 
                               text-center my-2 px-2 p-1`}
                              >
                                {member.roomState && <FaHome />} {member.name}{" "}
                                {member.role == "ROLE_FACILITATOR"
                                  ? member.id == masterId
                                    ? " - Facilitator"
                                    : "- Co-Facilitator"
                                  : member.role
                                  ? "- " + member.role
                                  : ""}
                              </div>
                              <div
                                className={` ${isMini ? "d-none" : ""} 
                               text-center my-2 px-2 p-1`}
                              >
                                <div
                                  role="button"
                                  onClick={() => handlePalami(member)}
                                  className={`btn border box-shadow ${
                                    !member.palamiState
                                      ? "bg-clearl "
                                      : "bg-info text-white"
                                  } useBorder   mr-2 btn-extra-radius
                                 `}
                                >
                                  <FaHandPaper />
                                </div>

                                <button
                                  className={`btn   ${
                                    isMini || isSilent ? "d-none" : ""
                                  } ${disabled ? "btn-dark disabled" : ""}  ${
                                    !member.muteState
                                      ? "btn-success"
                                      : "btn-danger"
                                  }`}
                                  onClick={() => handleRemoteMute(member)}
                                >
                                  <FaMicrophone />{" "}
                                  {!member.muteState ? " live " : " muted "}
                                </button>
                                <button
                                  className={`btn  mx-2 ${
                                    isMini ? "d-none" : ""
                                  }  ${disabled ? "btn-dark disabled" : ""} ${
                                    !member.vidState
                                      ? "btn-success"
                                      : "btn-danger"
                                  }`}
                                  onClick={() => handleRemoteVid(member)}
                                >
                                  <FaVideo />
                                  {!member.vidState ? " live " : " off "}
                                </button>
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                  </div>
                  <h6>Mute Menu</h6>
                </div>
                 
               
         
              )}
          </div>
          {/* {state.user.role === "ROLE_MEMBER" && (
            <UserFeedbackBar handleNewUserFeedback={handleNewUserFeedback} />
          )}

          {state.user.role === "ROLE_FACILITATOR" && members.length > 0 && (
            <UserFeedbackBar
              selectedMember={selectedMember}
              handleNewUserFeedback={handleNewUserFeedback}
            />
          )} */}
        </footer>
      </div>
    </aside>
  );
}
