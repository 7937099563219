import React,{useContext} from "react";
import { UserContext } from "../../../../../context/UserContext";
import ThemeQuoteCard from "../../components/ThemeQuoteCard";
import { GiSaveArrow } from "react-icons/gi";
const Quotes = props => {
  const { quotes, isRunningSession, onThemeCardClick,currentPage } = props;  
  const { state } = useContext(UserContext);
  return (
    <>
      <div className="d-flex">
        <h3>{state.user.role == "ROLE_FACILITATOR"&&( currentPage?`${currentPage+3}.`:`8.`)} Quotes</h3>

        {!isRunningSession ? (
          <button
            className="ml-2 bg-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 2)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
        )}
      </div>
      {quotes.map(quote => (
        <ThemeQuoteCard
          key={quote.id}
          quote={quote}
          onThemeCardClick={() => onThemeCardClick("quote", quote.id)}
        />
      ))}
    </>
  );
};

export default Quotes;
