import Modal from "react-modal";
import { MdClose } from "react-icons/md";

export default function GenericModal(props) {
  const { modalIsOpen, setModalIsOpen, addStyle, addClass, keepAlive } = props;
  const safetyStyle = addStyle?addStyle:""
  const safeClass = addClass?addClass:""
  const afterOpenModal = () => {};

  const closeModal = () => {
    setModalIsOpen(false);
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      className={`generic-modal bg-cleard-modal box-shadow round-box  position-relative ${safeClass}`}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={keepAlive?()=>{}:closeModal}
      htmlOpenClassName={"ReactModal__Html--open"}
      
      closeTimeoutMS={300}
      style={{ zIndex: 2000, border: 0, maxHeight:"98vh", ...safetyStyle}}
    >
     {!keepAlive&& <div
        onClick={closeModal}
        className="d-flex align-items-center round-box justify-content-center position-absolute modal-close-button"
      >
        <MdClose className="d-flex text-white m-0" />
      </div>}
      <div className="w-100 h-100 round-box content">{props.children}</div>
    </Modal>
  );
}
