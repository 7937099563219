import React, { useRef, useEffect } from "react";

import SongPlayerRefProp from "./SongPlayerRefProp"
export default function SyncEmbeddedMusicController(props) {
  const {
    handleMusicPlay,
    musicUrl,
    contentType,
    songLocation,
    isSyncOn,
  } = props;

  const audioRef = useRef(null);

  useEffect(() => {
    if (isSyncOn) {
      if (songLocation !== "") {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [songLocation]);


  if (!isSyncOn) {
    return (
      <div className="">
        <SongPlayerRefProp audioRef={audioRef}  songurl={musicUrl} isSyncFlag={false} funcProp={contentType} handleMusicPlay={console.log} ></SongPlayerRefProp>
      </div>
    );
  } else if (songLocation === "" || songLocation === undefined) {
    // when the facilitator plays music
    return (
      <div className="">
        <SongPlayerRefProp audioRef={audioRef}  songurl={musicUrl} isSyncFlag={true} funcProp={contentType} handleMusicPlay={handleMusicPlay} ></SongPlayerRefProp>
      </div>
    );
  } else {
    // when the music is played in member side by sync
    return (
      <div className="">
         <SongPlayerRefProp audioRef={audioRef}  songurl={songLocation} isSyncFlag={false} funcProp={contentType} handleMusicPlay={console.log} ></SongPlayerRefProp>
      </div>
    );
  }

  // return (
  //   <div className="col">
  //     <audio
  //       className="w-100"
  //       controls
  //       onPlay={() => handleMusicPlay(true, contentType)}
  //       onPause={() => handleMusicPlay(false, contentType)}
  //       src={musicUrl}
  //     />
  //   </div>
  // )
}
