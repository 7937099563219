import React, {useState, useEffect, useContext} from "react";
import {useHistory} from "react-router-dom";
import moment from "moment";
import { useThemesQuery } from "../../graphql/generated/graphql";
import {FiCalendar} from "react-icons/fi";
import {FaMapMarkerAlt} from "react-icons/fa";
import {BsHouseDoor, BsClock} from "react-icons/bs";

import {UserContext} from "../../context/UserContext";

import {
  SessionDocument,
  useChangeThemeMutation,
} from "../../graphql/generated/graphql";

import GenericModal from "./../GenericModal";
import ThemePicker from "./ThemePicker";

export default function SessionCardMedium(props) {

  const { isPopup, session, renderVertically, fallbackThemesList, special, hideCTB, setHideShtuff } = props;
  const { theme, data, group } = session;
  const { state } = useContext(UserContext);


  const history = useHistory();

  const [themeModalIsOpen, setThemeModalIsOpen] = useState(false, []);
  const getURL = (url)=>{
    return url.slice(0,14)+"/400/"+url.slice(15) 
  }
  const [changeTheme] = useChangeThemeMutation({
    refetchQueries: [
      {
        query: SessionDocument,
        variables: {
          id: session.id,
        },
      },
    ],
  });
useEffect(() => {
 if(special){
   setHideShtuff(themeModalIsOpen)
 }
}, [themeModalIsOpen])
  const handleThemeSelection = newTheme => {
    changeTheme({
      variables: {
        session: session.id,
        theme: newTheme.id,
      },
    })
      .then(res => console.log(res))
      .catch(err => console.log(err));

    setThemeModalIsOpen(false);
  };
  const [themesList, setThemesList] = useState(null)
  const { data: themes, loadingThemes, error } = useThemesQuery();
  useEffect(() => {
    if(fallbackThemesList){
      // console.log("poifect")
      setThemesList(fallbackThemesList)  

    }else if(!loadingThemes){
      setThemesList(themes)  
    }
    return () => {
      
    }
  }, [loadingThemes, fallbackThemesList])
  const sessionButton = () => {
   
    const text =
      state.user.role === "ROLE_FACILITATOR"
        ? session.status === "joinable"
        ? "Join session (waiting room)"
        : "Go to session"
        : session.status === "joinable"
        ? "Join session (waiting room)"
        : "Session did not start yet";
    const path =
      state.user.role === "ROLE_FACILITATOR"
        ? session.status === "joinable"
        ? `/waitingroom/${session.id}`
        : `/session/${session.id}`
        : `/waitingroom/${session.id}`;

    const style =
      state.user.role === "ROLE_FACILITATOR"
        ? session.status === "joinable"
        ? "btn-success"
        : "btn-info text-white"
        : session.status === "joinable"
        ? "btn-success"
        : "btn-danger";

    return (
      <button
        disabled={text === "Session did not start yet"}
        onClick={() => history.push(path, {details: group.name , isRunningSession: true, themes:themesList, sessionMaster: (session.sessionMaster && session.sessionMaster.id)})}
        className={`btn px-5 btn-extra-radius ${style}`}
      >
        {text}
      </button>
    );
  };
  // console.log(session.group)

  return (
    <div
      className={`round-box ${special?"border useBorder box-shadow ":""} bg-zero text-white`}
    >
      <h4
        style={{borderRadius: "15px 15px 0 0"}}
        className="py-3 text-center text-white bg-info w-100"
      >
        {group.name} 
      <br></br> Theme: {theme.name} 
      </h4>
      <div className="container">
        <div className="row">
          {/* left */}
          <div className={renderVertically ? "col-12" : "col-lg-6 col-md-12"}>
            <div className="my-2">
              <FiCalendar className="mr-2"/>
              {moment(session.date).format("MMMM DD")}
            </div>
            {" "}
            {/* date */}
            <div className="my-2">
              <BsClock className="mr-2"/>
              {moment(session.date).format("h:mm a")}
            </div>
            {" "}
            {/* time */}
            
            {/* addressline 1 */}
            {group.room!=="n/a"&&
            <div className="my-2">
              <BsHouseDoor className="mr-2"/>
              Room: {group.room}
            </div>
            }
            {" "}
          </div>

          {/* right */}
          <div
            className={
              "d-flex justify-content-center align-items-center" +
              (renderVertically ? " col-12 my-2" : " col-lg-6 col-md-12")
            }
          >
            <img
              className="round-box"
              style={{maxWidth: "90%", maxHeight: 600}}
              src={
                "https://d30mg0zmvh01j5.cloudfront.net" + getURL(session.theme.imageUrl)
              }
              alt="img"
            />
          </div>
        </div>
      </div>
      <div className="text-center my-3">
        {isPopup && sessionButton()}

        {!hideCTB &&!isPopup && state.user.role === "ROLE_FACILITATOR" ? (
          <button
            onClick={() => setThemeModalIsOpen(true)}
            className="btn btn-warning text-white px-5 btn-extra-radius"
          >
            Change Theme
          </button>
        ) : (
          <></>
        )}
      </div>
      <GenericModal
        modalIsOpen={themeModalIsOpen}
        setModalIsOpen={setThemeModalIsOpen}
      >
        {themes && <ThemePicker handleThemeSelection={handleThemeSelection} themes={themes.themes}/>}
      </GenericModal>
    </div>
  );
}
