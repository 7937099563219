import React, { useState, useMemo } from "react";
import NewMemberLine from "./NewMemberLine";
import moment from "moment";
import matchSorter, { rankings, caseRankings } from "match-sorter";
import SearchBar from "./SearchBar";
import {
  FaArrowDown,
  FaArrowUp,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import { BsFileArrowUp } from "react-icons/bs";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = key => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export default function NewMemberTable(props) {
  const { users, title, hasApprove, hasDeny, updateList, editRole } = props;
  const [searchText, setSearchText] = useState("", []);

  const handleOnChange = e => {
    setSearchText(e.target.value);
  };
  const filterBySearchText = () => {
    if (!searchText) {
      return users;
    } else {
      return matchSorter(users, searchText, {
        keys: ["firstName", "lastName", "email"],
      });
    }
  };
  const { items, requestSort, sortConfig } = useSortableData(
    filterBySearchText()
  );
  const [alert, setAlert] = useState([], []);
  const getClassNamesFor = name => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const handleChangeStatus = (changedUser, newStatus, validUntil) => {
    updateList(changedUser, newStatus, validUntil);

    if (newStatus) {
      setAlert(
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          {changedUser.firstName} {changedUser.lastName} was approved!
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              setAlert("");
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    } else {
      setAlert(
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {" "}
          {changedUser.firstName} {changedUser.lastName} was denied!
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              setAlert("");
            }}
          >
            <span aria-hidden="true">&times;</span>{" "}
          </button>
        </div>
      );
    }
    //push change to db
  };

  return (
    <div className="col bg-zero col-12 mb-4">
      <div className="card border useBorder shadow">
        <div className="bg-cleard card-header">
          <div className="row ">
            <div className="col col-4">
              <h1>{title}</h1>
            </div>
            <div className="col col-8">
              <SearchBar
                handleOnChange={handleOnChange}
                searchText={searchText}
              ></SearchBar>
            </div>
          </div>
        </div>
        <div className="bg-dark card-body">
          <div>
            {alert}
            <table class="table bg-clear border useBorder shadow">
              <thead class="border useBorder shadow ">
                <tr className={` col-12`}>
                  <th
                    scope="col"
                    role="button"
                    onClick={() => requestSort("role")}
                    className={getClassNamesFor("role") + " admin-label "}
                  >
                    <div className={`row d-flex justify-content-center`}>
                    <div className={`col col-auto mx-3 px-0`}>  Role</div>
                    {sortConfig && sortConfig.key == "role" && (
                      <div className={`col col-auto mx-0 px-0`}>
                        {sortConfig.direction == "ascending" ? (
                          <FaSortAmountUp />
                        ) : (
                          <FaSortAmountDown />
                        )}
                      </div>
                    )}

                    </div>
                  </th>
                  <th
                    scope="col"
                    role="button"
                    onClick={() => requestSort("firstName")}
                    className={getClassNamesFor("firstName") + " admin-label  "}
                  >
                    <div className={`row d-flex justify-content-center`}>
                    <div className={`col col-auto mx-auto px-0`}>  First Name</div>
                    {sortConfig && sortConfig.key =="firstName" && (
                      <div className={`col col-auto mx-0 px-0`}>
                        {sortConfig.direction == "ascending" ? (
                          <FaSortAmountUp />
                        ) : (
                          <FaSortAmountDown />
                        )}
                      </div>
                    )}

                    </div>
                  </th>
                  <th
                    scope="col"
                    role="button"
                    onClick={() => requestSort("lastName")}
                    className={getClassNamesFor("lastName") + " admin-label  "}
                  >
                     <div className={`row d-flex justify-content-center`}>
                    <div className={`col col-auto mx-3 px-0`}> Last Name</div>
                    {sortConfig && sortConfig.key =="lastName" && (
                      <div className={`col col-auto mx-0 px-0`}>
                        {sortConfig.direction == "ascending" ? (
                          <FaSortAmountUp />
                        ) : (
                          <FaSortAmountDown />
                        )}
                      </div>
                    )}

                    </div>
                  </th>
                  <th
                    scope="col"
                    role="button"
                    onClick={() => requestSort("email")}
                    className={getClassNamesFor("email") + " admin-label  "}
                  >
                   <div className={`row d-flex justify-content-center`}>
                    <div className={`col col-auto mx-3 px-0`}> Email</div>
                    {sortConfig && sortConfig.key == "email"  && (
                      <div className={`col col-3 mx-0 px-0`}>
                        {sortConfig.direction == "ascending" ? (
                          <FaSortAmountUp />
                        ) : (
                          <FaSortAmountDown />
                        )}
                      </div>
                    )}

                    </div>
                  </th>
                  <th
                    scope="col"
                    role="button"
                    onClick={() => requestSort("createdAt")}
                    className={getClassNamesFor("createdAt") + " admin-label  "}
                  >
                   <div className={`row d-flex justify-content-around align-items-center`}>
                    <div className={`col col-8 mx-0 px-0`}> Date Created</div>
                    {sortConfig && sortConfig.key == "createdAt"  && (
                      <div className={`col col-3 mx-0 px-0`}>
                        {sortConfig.direction == "ascending" ? (
                          <FaSortAmountUp />
                        ) : (
                          <FaSortAmountDown />
                        )}
                      </div>
                    )}

                    </div>
                  </th>
                  <th
                    scope="col"
                    role="button"
                    onClick={() => requestSort("validUntil")}
                    className={getClassNamesFor("validUntil") + " admin-label "}
                  >
                    <div className={`row d-flex justify-content-around align-items-center`}>
                    <div className={`col col-8 mx-0 px-0`}>Valid Until</div>
                    {sortConfig && sortConfig.key == "validUntil" && (
                      <div className={`col col-3 mx-0 px-0`}>
                        {sortConfig.direction == "ascending" ? (
                          <FaSortAmountUp />
                        ) : (
                          <FaSortAmountDown />
                        )}
                      </div>
                    )}

                    </div>
                  </th>
                  <th scope="col">Approve?</th>
                </tr>
              </thead>
              <tbody>
                {console.log(users)}
                {searchText &&
                  filterBySearchText().map(user => (
                    <NewMemberLine
                      id={user.id}
                      user={user}
                      hasApprove={hasApprove}
                      editRole={editRole}
                      hasDeny={hasDeny}
                      handleChangeStatus={handleChangeStatus}
                    ></NewMemberLine>
                  ))}
                {!searchText &&
                  items.map(user => (
                    <NewMemberLine
                      id={user.id}
                      user={user}
                      hasApprove={hasApprove}
                      editRole={editRole}
                      hasDeny={hasDeny}
                      handleChangeStatus={handleChangeStatus}
                    ></NewMemberLine>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
