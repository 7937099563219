import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SessionCardSmall from "./SessionCardSmall";
import SessionCardLarge from "./SessionCardLarge";
import {
  useMyFutureSessionsQuery,
  useThemesQuery,
} from "../../graphql/generated/graphql";
import LoadingSpinner from "../LoadingSpinner/index";
import MainLoading from "./../MainLoading";
import ErrorBoundary from "../ErrorBoundary";

export default function SessionContainer() {

  const { data, error, loading, startPolling, stopPolling } = useMyFutureSessionsQuery({
    variables: {
      nth: 4,
    },
    pollInterval: 30000,

  });

  const [themesList, setThemesList] = useState(null);
  const { data: themes, loadingThemes, errorThemes } = useThemesQuery();
  const [state, setState] = useState([]);
  const [triggerError, setTriggerError] = useState(false);
  useEffect(() => {
    if (!loadingThemes) {
      setThemesList(themes);
    }
    return () => {};
  }, [loadingThemes]);
  useEffect(() => {
    if ( data && data.myFutureSessions) {
      if (data.myFutureSessions.length > 0) {
        //console.log(data);
        setState([...data.myFutureSessions]);
      } else {
      }
    }
    return () => {
      setState(null);
    };
  }, [data]);
  console.log(error);
  console.log(loading);
  startPolling()
useEffect(() => {
  return () => {
    stopPolling()
  }
}, [])
useEffect(() => {
  if(triggerError === true){

    throw new Error("test")
  }
}, [triggerError])
if (loading) {
  return (
    <MainLoading
    isCollapsed={false}
    type={"sessCont"}
    isLoading={true}
    ></MainLoading>
    );
  }
  return (
    <div>
      {/* <div style={{position: "absolute",top:"0", right: "0"}}>
         <button  onClick={()=>{setTriggerError(true)}}>create error</button>
        </div> */}
      <h4 className="mb-3 text-light">Sessions</h4>

      {state && state.length > 0 && state[0] && (
        <div>
          <SessionCardLarge
            session={state[0]}
            showButton={true}
            fallbackThemesList={themesList}
            />
        </div>
      )}

      <div>
        {state &&
          state.length > 1 &&
          state.slice(1).map((session, i) => {
            if (!session) return <div key={i}></div>;
            return (
              <SessionCardSmall
                showButton={true}
                key={session.id}
                session={session}
                fallbackThemesList={themesList}
              />
            );
          })}
        {data && state == null && (
          <>
            <div className={"d-flex mt-0 pt-0 justify-content-center"}>
              <div
                className={
                  "col col-12 mt-0 p-3 round-box box-shadow align-self-center"
                }
              >
                <div className={""}>
                  <h2 className={"text-center"}>Nice to see you!</h2>
                </div>
                <div>
                  <h6 className={"text-center pt-3"}>
                    It looks like your groups don't have any sessions available
                    right now.
                  </h6>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="d-flex flex-row justify-content-center my-4">
          <Link
            className="btn btn-primary btn-extra-radius px-4"
            to="/schedule"
          >
            See All Sessions
          </Link>
        </div>
      </div>
    </div>
  );
}
