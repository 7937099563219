import React, { useState } from "react";
import moment from "moment";
import logo from "./../Login/logo.png";
import Jslogo from "./../Login/JSClogo.png";
import GenericModal from "../GenericModal";
import SessionCardMedium from "./SessionCardMedium";

export default function Image(props) {
  const { session, showButton, fallbackThemesList } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false, []);

  const onButtonClick = e => {
    setModalIsOpen(true);
  };
  const getURL = (url)=>{
    return url.slice(0,14)+"/600/"+url.slice(15) 
  }
  const { date, title, theme } = session;

  return (
    <div className="position-relative round-box border useBorder d-flex align-items-end justify-content-center bg-clear">
      {/* <img src={theme.image} alt={theme.name} /> */}
      <img
        className="round-box my-3 align-self-center"
        style={{
          height: "auto",
          width: "auto",
          maxWidth: "94%",
          maxHeight:600,
        }}
        src={"https://d30mg0zmvh01j5.cloudfront.net" + getURL(session.theme.imageUrl)}
        // src="https://picsum.photos/400/400"
        alt={session.theme.name}
      />
      <div
        style={{
          backgroundColor: "rgba(0,0,0,.5)",
          borderRadius: "0 0 15px 15px",
        }}
        className="position-absolute  text-white my-auto mx-auto w-100 p-3"
      >
        <div className="py-2 ml-1 row">
        <div className={`col-1 pl-0 d-flex align-items-center`}>
        <img
              src={session.program=="music"?logo:Jslogo}
              
              id="icon"
              alt="Java Group"
              className={``}
              width="65px"
              />
        </div>
          <div className={`${showButton ? "col-8" : "col-11"} pl-4`}>
            <h5>
              <span className="mr-3 text-capitalize">{session.group.name}</span>
              {session.status === "joinable" && (
                <span
                  className={`badge ${
                    session.status === "joinable"
                      ? "badge-success"
                      : "badge-danger"
                  }`}
                >
                  {session.status === "joinable" ? "In-progress" : "Pending"}
                </span>
              )}
            </h5>
            <p className="mb-0 font-weight-light">
              <span className="d-block d-lg-inline">
                {moment(date).format("MMMM Do YYYY, h:mm:ss a")}
              </span>
              <span className="d-none d-lg-inline "> - </span>
              <span className="text-capitalize">
                {theme ? theme.name.toLowerCase() : "No theme chosen"}
              </span>
            </p>
          </div>
          {showButton && (
            <div className="col-3 my-xs-5 d-flex flex-row align-items-center justify-content-center">
              <button
                onClick={onButtonClick}
                className="btn px-3 mr-2 px-lg-4 btn-sm btn-info btn-extra-radius"
              >
                Open
              </button>
              <GenericModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
              >
                <SessionCardMedium
                  isPopup={true}
                  renderVertically={false}
                  session={session}
                  fallbackThemesList={fallbackThemesList}
                />
              </GenericModal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
