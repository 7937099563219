import React, { useState, useEffect } from "react";
import matchSorter from "match-sorter";
import SearchBar from "../../ThemeLibrary/SearchBar";

import ThemeCard from "./ThemeCard";
import { useThemesQuery }from "../../../graphql/generated/graphql";

// This is supposed to be used as the content of a modal
export default function ThemeSelector(props) {
  const { handleThemeSelection } = props;

  const [searchText, setSearchText] = useState("", []);
  const [byLen, setByLen] = useState(false);

  // Fetch themes from DB
  //const [themes, setThemes] = useState([], []);

  const { data, loading, error } = useThemesQuery();

  const handleOnChange = e => {
    setSearchText(e.target.value);
  };

  const getThemesFromCategory = category => {
    return data.themes.filter(theme => theme.category === category);
  };
  const [themesByLen, setThemesByLen] = useState([])
useEffect(() => {
  if(data){
    console.time("start time")
    let tmp = []
    data.themes.map(theme =>{
      let max = 0
      theme.quotes.map(q=>{
        if(q.quote.length>max){
          max = q.quote.length
        }
      })
      tmp.push({...theme, score: max})
    })
    setThemesByLen(tmp)
    console.timeEnd("start time")
    console.log(themesByLen)
    console.log(tmp)
  }
}, [data])
// const doItJustDoIT = () =>{
//   ThemeDocument,
//   useThemesQuery({refetchQueries: [{ query: ThemeDocument }],})
// }
  // Match sorter
  const filterBySearchText = () =>{
  if(themesByLen.length >0 &&byLen ){
  //   themesByLen.sort((a,b)=>{
    
  // })
   return matchSorter(themesByLen, searchText, { keys: ["name", "category"]} ).sort((a,b)=>{return b.score - a.score});
  }
  else return matchSorter(data.themes, searchText, { keys: ["name", "category"] });
  }
  
  
  
  // useEffect(() => {
  //   if (!loading) {
  //     setThemes(data.themes);
  //   }
  // }, [loading]);

  if (!data) {
    return <h1>Loading</h1>;
  }

  return (
    <div className="p-3  overflow-hidden" >

      <header className={"px-3"}>
        <h4 className={"px-1"}>Select a theme</h4>
        <SearchBar handleOnChange={handleOnChange} searchText={searchText} />
        <button onClick={()=>setByLen(!byLen)} className={`btn  ml-5 btn-info`} >{!byLen?"Sort by Longest Quote":"Sort byAlphabetic"}</button>
      </header>
      <div className={"overflow-auto "} style={{ height: "60vh" }}>
      <main className="container">
        <div className="row d-flex justify-content-around">
          {data && themesByLen.length > 0  && filterBySearchText(themesByLen).map(theme => (
            <ThemeCard
              handleThemeSelection={handleThemeSelection}
              selectableTheme={true}
              theme={theme}
              key={theme.id}
            />
          ))}
        </div>
      </main>
      </div>
    </div>
  );
}
