import React, { useState, useEffect } from "react";
import ResourceList from "./ResourceList";
import { MdArrowBack } from "react-icons/md";
import { useResourcesQuery } from "../../graphql/generated/graphql.tsx";
import LoadingSpinner from "../LoadingSpinner";

export default function ResourceLibrary() {
  const [selectedCategory, setSelectedCategory] = useState("", []);
  const [defVal, setDefVal] = useState("no-reply@javasocial.club", []);

  const { loading, error, data } = useResourcesQuery();
  useEffect(() => {
    if(data){
      console.log(data)
      
    }
  }, [data])

  const handleResourceClick = e => {
    e.preventDefault();
    setSelectedCategory({value:e.target.id, name:e.target.name});
  };
  console.log(selectedCategory)
  const handleOnChange = e => {
    setDefVal( e.target.value);}
  const handleBack = e => {
    e.preventDefault();
    setSelectedCategory("");
  };
const handleSend= () =>{
    fetch(process.env.REACT_APP_MAIL_API, {
              method: "POST",
              body: JSON.stringify({

                      to: defVal,
                      subject: "this is a test",
                      emailBody: "<p>thi</p><p>thi</p><p>thi</p><p>this is a test</p></p><p>right about now youre probably beggining to wonder how you got here...</p>"

              }),
              headers: {
                'Content-Type': 'application/json',
              },
            }).then((response) => response.json())
            .then((json) =>console.log(json)
            )}
  if (loading) {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className="d-flex align-items-center h-100">
      {!selectedCategory && (
        <div className="container mt-3 px-5">
          <div className="row" style={{ maxHeight: "100vh" }}>
            {/* Card 1 */}
            <div
              className="col-12 col-md-6 p-2 position-relative text-center d-flex align-items-center justify-content-center theme-card"
              role="button"
            >
              <img
                id="Getting started"
                className="w-100 p-2 not-draggable round-box"
                src="https://picsum.photos/400/400"
                onClick={handleResourceClick}
                alt=""
              ></img>
              <h5 className="position-absolute text-white my-auto mx-auto">
                Getting Started
              </h5>
            </div>
            {/* Card 2 */}
            <div
              className="col-12 col-md-6 p-2 position-relative text-center d-flex align-items-center justify-content-center theme-card"
              role="button"
            >
              <img
                id="Working with groups"
                className="w-100 p-2 not-draggable round-box"
                src="https://picsum.photos/400/400"
                onClick={handleResourceClick}
                alt=""
              ></img>
              <h5 className="position-absolute text-white my-auto mx-auto">
                Working With Groups
              </h5>
            </div>
            {/* Card 3 */}
            <div
              className="col-12 col-md-6 p-2 position-relative text-center d-flex align-items-center justify-content-center theme-card"
              role="button"
            >
              <img
                id="fg"
                name= "Facilitation Guide"
                className="w-100 p-2 not-draggable round-box"
                src="https://picsum.photos/400/400"
                onClick={handleResourceClick}
                alt=""
              ></img>
              <h5 className="position-absolute text-white my-auto mx-auto">
                Facilitation Guide
              </h5>
            </div>
            {/* Card 4 */}
            <div
              className="col-12 col-md-6 p-2 position-relative text-center d-flex align-items-center justify-content-center theme-card"
              role="button"
            >
              <img
                id="FAQ"
                className="w-100 p-2 not-draggable round-box"
                src="https://picsum.photos/400/400"
                onClick={handleResourceClick}
                alt=""
              ></img>
              <h5 className="position-absolute text-white my-auto mx-auto">
                FAQ
              </h5>
            </div>
          </div>
        </div>
      )}

      {selectedCategory && (
        <div className="container pt-2">
          <div className="d-flex align-items-center">
            <button
              onClick={handleBack}
              className="btn px-3 px-lg-4 btn-primary btn-extra-radius my-3 icon-text"
            >
              <span>
                <MdArrowBack />
              </span>
              <span>Back</span>
            </button>
            <h4
              className="mt-2 mb-3 ml-5"
              style={{ textTransform: "capitalize" }}
            >
              {selectedCategory.name}
            </h4>
          </div>
          <ResourceList
            category={selectedCategory}
            resources={data.resources.filter(
              resource => resource.category == selectedCategory.value
              )}
          />
        </div>
      )}
    </div>
  );
}
