import React from "react";
import ReactDOM from "react-dom";

// CSS and SASS
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/zoom-ui.scss";
import "./scss/bootstrap-override.scss";
import "./scss/style.scss";
import "./index.css";
import "animate.css/animate.min.css";
import App from "./components/App";
import { UserProvider } from "./context/UserContext";
import { RunSessProvider } from "./context/RunSessContext";
import Apollo from "./components/Apollo";
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
    <RunSessProvider>
      <Apollo>
        <App />
      </Apollo>
    </RunSessProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
