import React, { useRef, useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import SongPlayerRefProp from "./SongPlayerRefProp";
import { Icon, InlineIcon } from '@iconify/react';
import windChime from '@iconify/icons-emojione-v1/wind-chime';

export default function SyncedNonMusic(props) {
  const {
    handleMusicPlay,
    musicUrl,
    contentType,
    songLocation,
    isSyncOn,
    
  } = props;
  const [play, setPlay] = useState(false);
  const audioRef = useRef(null);
  const handleClick = (e) => {
    e.stopPropagation();
 console.log(audioRef.current.ended  )
      if (audioRef.current.paused){
          audioRef.current.play();
          handleMusicPlay(true, contentType);
          
        }else {
            audioRef.current.pause();
            handleMusicPlay(false, contentType);

    }
    
    
    setPlay(!play);
  };
  useEffect(() => {
      
    if (isSyncOn) {
      if (songLocation !== "") {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  }, [songLocation]);
  useEffect(() => {

      if(audioRef.current){
        ReactDOM.findDOMNode(audioRef.current).addEventListener("ended", function(){
            handleMusicPlay(false, contentType);
            console.log("ended");
       });
          

      }
  }, [audioRef]);

  if (!isSyncOn) {
    return (
      <div className="col">
        <div
        role="button"
          onClick={(e) => handleClick(e)}
          className={"text-center btn btn-outline-success text-white  round-box  box-shadow col offset-4 col-3 mb-4"}
        >
          
          Play Windchimes
        </div>
        <div className={"d-none"}>
          <SongPlayerRefProp
            audioRef={audioRef}
            songurl={musicUrl}
            isSyncFlag={false}
            funcProp={contentType}
            handleMusicPlay={console.log}
          ></SongPlayerRefProp>
        </div>
      </div>
    );
  } else if (songLocation === "" || songLocation === undefined) {
    // when the facilitator plays music
    return (
      <div className="col">
        <div
        role="button"
          onClick={(e) => handleClick(e)}
          className={"text-center btn btn-outline-success text-white  round-box  box-shadow col offset-4 col-3 mb-4"}
        >
           
          Play Windchimes
        </div>
        <div className={"d-none"}>
          <SongPlayerRefProp
            audioRef={audioRef}
            songurl={musicUrl}
            isSyncFlag={true}
            funcProp={contentType}
            handleMusicPlay={handleMusicPlay}
          ></SongPlayerRefProp>
        </div>
      </div>
    );
  } else {
    // when the music is played in member side by sync
    return (
      <div className="col">
        <div
        role="button"
          onClick={(e) => handleClick(e)}
          className={"text-center btn btn-outline-success text-white round-box box-shadow col offset-4 col-3 mb-4"}
        >
           
          Play Windchimes
        </div>
        <div className={"d-none"}>
          <SongPlayerRefProp
            audioRef={audioRef}
            songurl={songLocation}
            isSyncFlag={false}
            funcProp={contentType}
            handleMusicPlay={console.log}
          ></SongPlayerRefProp>
        </div>
      </div>
    );
  }

  // return (
  //   <div className="col">
  //     <audio
  //       className="w-100"
  //       controls
  //       onPlay={() => handleMusicPlay(true, contentType)}
  //       onPause={() => handleMusicPlay(false, contentType)}
  //       src={musicUrl}
  //     />
  //   </div>
  // )
}
