import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import {
  useAddUserToWaitingRoomMutation,
  useSessionFacilitatorsSubscription,
  useSessionMembersSubscription,
  useSessionStatusQuery,
  useSessionWaitingRoomSubscription,
} from "../graphql/generated/graphql";
import { UserContext } from "../context/UserContext";
import moment from "moment"


const WaitingRoom = () => {
  const id = useParams().id;
  const location = useLocation();
  const history = useHistory();

  const { state } = useContext(UserContext);



  const [shouldQuery, setShouldQuery] = useState(false);
  const [group, setGroup] = useState(false);
  
  const [themes, setThemes] = useState(location.state?location.state.themes:null)
  const [addUserToWaitingRoom] = useAddUserToWaitingRoomMutation();

  const { data: sessionStatus } = useSessionStatusQuery({
    variables: {
      id: id,
    },
  });

  const { data: sessionMembersData } = useSessionMembersSubscription({
    variables: {
      id: id,
    },
    skip: !shouldQuery || state.user.role !== "ROLE_MEMBER",
  });

  const { data: sessionWaitingRoomData } = useSessionWaitingRoomSubscription({
    variables: {
      id: id,
    },
    skip: !shouldQuery,
  });

  const { data: sessionFacilitatorsData } = useSessionFacilitatorsSubscription({
    variables: {
      id: id,
    },
    skip: !shouldQuery || state.user.role !== "ROLE_FACILITATOR",
  });

  useEffect(() => {
    if (id || id !== "") {
      addUserToWaitingRoom({
        variables: {
          user: state.user.id,
          session: id,
        },
      })
        .then(res => {
          if (res.errors) {
            toast.error("Oops, something went wrong");
            history.push("/home");
          } else {
            setShouldQuery(true);
          }
        })
        .catch(err => {
          toast.error("Oops, something went wrong");
          console.log(err);
        })
        .finally();
    }
  }, []);

  useEffect(() => {
    if (shouldQuery && sessionWaitingRoomData) {
      console.log("check 1... 2...")
      console.log(sessionWaitingRoomData)
      console.log(sessionMembersData)
      console.log(sessionStatus)
      // prettier-ignore
      const isUserInWaitingRoom = sessionWaitingRoomData.sessionWaitingRoom.find(u=>u.id===state.user.id);

      // prettier-ignore
      const isUserInSessionMembers = sessionMembersData ? sessionMembersData.sessionMembers.find(sm=>sm.id===state.user.id) : false

      // prettier-ignore
      const isUserInSessionFacilitators =  sessionFacilitatorsData ? sessionFacilitatorsData.sessionFacilitators.find(sf => sf.id === state.user.id)||sessionStatus.session.group.facilitators.find(sf => sf.id === state.user.id) : false;

      if (sessionStatus && sessionStatus.session.status !== "joinable") {
        toast.error("This session is not joinable");
        history.push(`/home`);
      } else {
        if (state.user.role === "ROLE_FACILITATOR") {
          if (sessionFacilitatorsData) {
            if (isUserInSessionFacilitators) {
              toast.success("Approved! Joining session...");
              history.push(`/session/${id}`, {themes:themes});
            } else if (isUserInWaitingRoom) {
              console.log("not approved yet");
            } else if (!isUserInWaitingRoom && !isUserInSessionFacilitators) {
              toast.error("Your entrance was not approved");
              history.push("/home");
            }
          } else {
            console.log("Still waiting for approval");
          }
        } else if (state.user.role === "ROLE_MEMBER") {
          if (sessionMembersData) {
            if (isUserInSessionMembers) {
              toast.success("Approved! Joining session...");
              history.push(`/session/${id}`, {themes:themes});
            } else if (isUserInWaitingRoom) {
              console.log("not approved yet");
            } else if (!isUserInWaitingRoom && !isUserInSessionMembers) {
              toast.error("Your entrance was not approved");
              history.push("/home");
            }
          } else {
            console.log("Still waiting for approval");
          }
        } else {
          // Maybe in the future there will be an additional role
          toast.error("Your role is not allowed to access a running session");
          history.push(`/home`);
        }
      }
    }
  }, [
    sessionFacilitatorsData,
    sessionMembersData,
    sessionWaitingRoomData,
    sessionStatus,themes
  ]);
  useEffect(() => {
    if(location.state){

  setGroup(location.state.details)
  setThemes(location.state.themes)
}
 }, [location]);

  // useEffect(() => {
  //   if (
  //     shouldQuery &&
  //     sessionWaitingRoomData &&
  //     sessionMembersData &&
  //     sessionFacilitatorsData
  //   ) {
  //     // prettier-ignore
  //     // const isUserInWaitingRoom = data.session.waitingRoom.find(u=>u.id===state.user.id)
  //     const isUserInWaitingRoom = sessionWaitingRoomData.sessionWaitingRoom.find(u=>u.id===state.user.id)
  //     // prettier-ignore
  //     // const isUserInSessionMembers = data.session.sessionMembers.find(sm=>sm.user.id===state.user.id)
  //     const isUserInSessionMembers = sessionMembersData.sessionMembers.find(sm=>sm.id===state.user.id)
  //     // prettier-ignore
  //     console.log("sf",sessionFacilitatorsData);
  //     const isUserInSessionFacilitators = sessionFacilitatorsData.sessionFacilitators.find(
  //       sf => sf.id === state.user.id
  //     );

  //     if (sessionStatus.session.status !== "joinable") {
  //       toast.error("This session is not joinable");
  //       history.push(`/home`);
  //     } else {
  //       if (state.user.role === "ROLE_FACILITATOR") {
  //         if (isUserInSessionFacilitators) {
  //           toast.success("Approved! Joining session...");
  //           history.push(`/session/${id}`);
  //         } else if (isUserInWaitingRoom) {
  //           console.log("not approved yet");
  //         } else if (!isUserInWaitingRoom && !isUserInSessionFacilitators) {
  //           toast.error("Your entrance was not approved");
  //           history.push("/home");
  //         }
  //       } else if (state.user.role === "ROLE_MEMBER") {
  //         if (isUserInSessionMembers) {
  //           toast.success("Approved! Joining session...");
  //           history.push(`/session/${id}`);
  //         } else if (isUserInWaitingRoom) {
  //           console.log("not approved yet");
  //         } else if (!isUserInWaitingRoom && !isUserInSessionMembers) {
  //           toast.error("Your entrance was not approved");
  //           history.push("/home");
  //         }
  //       } else {
  //         // Maybe in the future there will be an additional role
  //         toast.error("Your role is not allowed to access a running session");
  //         history.push(`/home`);
  //       }
  //     }
  //   }
  // }, [
  //   sessionFacilitatorsData,
  //   sessionMembersData,
  //   sessionWaitingRoomData,
  //   sessionStatus,
  // ]);

  if (!id || id === "") {
    toast.error("Oops, something went wrong");
    history.push("/home");
  }

  return (
    <div className="container">
      <h1 className="text-center my-4 mt-5">Waiting Room</h1>
      <h2 className="text-center my-3">

        You're about to join the fun! Please wait a moment the facilitator is
        just about to let you in.
      </h2>
      <div className="d-flex justify-content-center align-items-center my-5 p-5">
        <ScaleLoader width={40} height={100} margin={5} color={"#0872e4bf"} />
      </div>
     {group && <h2 className="text-center my-3">
      {group}

      </h2>}
      <h2 className="text-center my-3">
      {shouldQuery&&sessionStatus&& sessionStatus.session?"Start time: "+moment(sessionStatus.session.date).format("dddd, MMM Do - hh:mm A"):""}

      </h2>
    </div>
  );
};

export default WaitingRoom;
