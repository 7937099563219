import React, { useState, useCallback, useEffect } from "react";
import Lobby from "./Lobby";
import Room from "./Room";
import QuietRoom from "./QuietRoom";

const VideoChat = props => {
  const { roomName, username, token, isSilent, setToken,myRemoteMuteState,setParticipantList,myRemoteVidState,myRemotePalamiState,myRemoteRoomState,sessionId, zoomData } = props;
  // const [username, setUsername] = useState('alex');
  // const [roomName, setRoomName] = useState('alex');
  // const [token, setToken] = useState(null);

  const handleUsernameChange = useCallback(event => {
    //setUsername(event.target.value);
  }, []);

  const handleRoomNameChange = useCallback(event => {
    //setRoomName(event.target.value);
  }, []);
  // useEffect(() => {
  //       fetch('/video/token', {
  //         method: 'POST',
  //         body: JSON.stringify({
  //           identity: username,
  //           room: roomName
  //         }),
  //         headers: {
  //           'Content-Type': 'application/json'
  //         }
  //       }).then((response) => response.json())
  //       .then((json) =>setToken(json.token)

  //       )
  //     },
  //     [roomName, username]
  //   );

  const handleLogout = useCallback(event => {
    //setToken(null);
  }, []);

  let render;
  if (token) {
    render = (
      // isSilent?<QuietRoom roomName={roomName} token={token} handleLogout={handleLogout} />:
      <Room roomName={roomName} zoomData={zoomData} isSilent={isSilent} setToken={setToken} token={token} sessionId={sessionId} setParticipantList={setParticipantList} myRemoteMuteState={myRemoteMuteState} myRemoteVidState={myRemoteVidState} myRemotePalamiState={myRemotePalamiState} myRemoteRoomState={myRemoteRoomState} handleLogout={handleLogout} />
    );
  } else {
    render = <></>;
  }
  return render;
};

export default VideoChat;
