import React from "react";
import { MdLightbulbOutline } from "react-icons/md";
import { BsCircleFill } from "react-icons/bs";
import discussionTips from "./discussionTips.json";

export default function ThemeBigCard(props) {
  const { discussions, isJavaTips, onThemeCardClick } = props;

  return (
    <div
      className={`border round-box p-3 ${
        !isJavaTips ? "clickable-card box-shadow" : "gray-filled-1"
      }`}
      onClick={onThemeCardClick}
      data-content="question"
    >
      {isJavaTips && (
        <>
          <div className="text-info d-flex align-items-center mb-2">
            <MdLightbulbOutline className="mr-2" />
            <span style={{ fontWeight: "bold" }}>Java Tips</span>
          </div>

          <span>
            Invite group members to share using the talking stick. Here are some
            tips:
          </span>
        </>
      )}

      <div>
        {isJavaTips &&
          discussionTips.discussionTips.map(tip => {
            return (
              <p key={tip.id} className="py-2 mb-0 d-flex align-items-center">
                <span className="mr-2">
                  <BsCircleFill
                    className="d-flex"
                    style={{ fontSize: ".5em" }}
                  />
                </span>
                <span className="ml-2">{tip.tip}</span>
              </p>
            );
          })}

        {!isJavaTips &&
          discussions.map(question => (
            <p
              key={question.id}
              className="py-1 mb-0 d-flex align-items-center"
            >
              <span className="mr-2">
                <BsCircleFill className="d-flex" style={{ fontSize: ".5em" }} />
              </span>
              <span className="ml-2">{question.question}</span>
            </p>
          ))}
      </div>
    </div>
  );
}
