import React, { useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { MdArrowForward, MdArrowBack } from "react-icons/md";

export default function HorizontalScroller(props) {
  const [selected, setSelected] = useState("", []);

  const onSelect = key => {
    setSelected(key);
  };

  // Navigation arrows
  const Arrow = ({ text }) => {
    return <div className={`bg-dark border use`} role="button">{text}</div>;
  };

  const ArrowRight = Arrow({ text: <MdArrowForward /> });
  const ArrowLeft = Arrow({ text: <MdArrowBack /> });

  return (
    <ScrollMenu
      wheel={false}

      transition={0.8}
      data={props.children}
      arrowLeft={ArrowLeft}
      arrowRight={ArrowRight}
      selected={selected}
      onSelect={onSelect}
      
      // scrollToSelected={true}
    />
  );
}
