import React from "react";

import { Link, useHistory } from "react-router-dom";

export default function ThemePreview(props) {
  const { theme, selectableTheme, handleOnClickThemeSelection } = props;

  const { songs, discussions } = theme;

  const history = useHistory();

  const handleOnSelectTheme = () => {
    if (handleOnClickThemeSelection) {
      handleOnClickThemeSelection();
    }
  };

  const onCardClick = () => {
    history.push({
      pathname: `/theme/${theme.id}`,
      editable: true,
      theme: theme,
    });
  };
  const getURL = (url)=>{
    return url.slice(0,14)+"/400/"+url.slice(15) 
  }
  return (
    <div className="container">
      <h4 className="mt-4 mb-2 mb-md-4 text-center">Theme Preview</h4>
      <div className="row">
        {/* left */}
        <div className="px-5 col-12 col-md-6 d-flex justify-content-center my-3 align-items-center">
          <div className="box-shadow round-box">
            <div>
              <img
                style={{ borderRadius: "15px 15px 0 0" }}
                className="img-fluid"
                src={"https://d30mg0zmvh01j5.cloudfront.net" + getURL(theme.imageUrl)}
                alt="img"
                width="400"
                height="400"
              />
            </div>
            <div className="text-center my-3 h5">{theme.name}</div>
          </div>
        </div>

        {/* right */}
        <div className="col-lg-6 col-md-6 my-3">
          {/* Discussions */}
          <h5 className="my-2">Discussion Questions</h5>
          <div className="row">
            <ul>
              {discussions.map((discussion, i) => (
                <li key={i}>{discussion.question}</li>
              ))}
            </ul>
          </div>

          {/* Songs */}
          <h5 className="my-2">Songs</h5>
          <div className="d-flex flex-row flex-wrap">
            {songs.map((song, i) => (
              <span
                key={i}
                className="btn btn-sm btn-extra-radius py-0 btn-info m-2"
              >
                {song.title}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="text-center my-5">
        {!selectableTheme && (
          <button
            onClick={onCardClick}
            className="btn btn-primary px-5 btn-extra-radius mx-5"
          >
            View Full Theme
          </button>
        )}
        {selectableTheme && (
          <button
            className="btn btn-warning px-5 btn-extra-radius mx-5"
            onClick={handleOnSelectTheme}
          >
            Select this theme
          </button>
        )}
        {/* <div className="text-center mt-5 mb-4">
        <div onClick={onCardClick} className="btn btn-primary px-5 btn-extra-radius">
          View Full Theme
        </div> */}
      </div>
    </div>
  );
}
