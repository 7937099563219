import React, { Profiler, useEffect,useContext, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { 
  MeDocument,
  SubUsersDocument,
  useEditUserMutation, 
  useSubUsersQuery,
  useMeQuery,
  useDeleteUserMutation,
  useAddProfilePicMutation
} from "../../graphql/generated/graphql";
import { TiUserDelete } from "react-icons/ti";
import GenericModal from "../GenericModal";
import { toast } from "react-toastify";
import Compress from 'compress.js'
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { ReactNativeFile } from 'apollo-upload-client';
import { UserContext } from "../../context/UserContext";
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow:"0px 0px 0px 0px rgba(0, 0, 0, 0.0) ",
    border: "none",
    backgroundColor:"rgba(0, 0, 0, 0.0)",
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    backgroundColor:"rgba(0,0,0,0.5)",
    width: "250px",
    height: "250px",
  },
}));
export default function MemberProfile() {  
  const { state } = useContext(UserContext);
  const [role, setRole] = useState(state.user.role)
  // The use information will probably be available in a global state context or will be fetched from db based on id that is stored in a global context
  const { error: subError,loading: subLoading, data: subData } = useSubUsersQuery({
    skip:
      role == "ROLE_MEMBER" 
    
  });
  const [file, setFile] = useState({name: "", photo: null, tempUrl: null})
    const classes = useStyles();
    const [avatar, setAvatar] = useState(<Avatar className={classes.large}  ></Avatar>)
  
  async function resizeImageFn(file) {
    const compress = new Compress()
    const resizedImage = await compress.compress([file], {
      size: 2, // the max size in MB, defaults to 2MB
      quality: 1, // the quality of the image, max is 1,
      maxWidth: 300, // the max width of the output image, defaults to 1920px
      maxHeight: 300, // the max height of the output image, defaults to 1920px
      resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    const base64str = img.data
    const imgExt = img.ext
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    return resizedFiile;
  }
  const [addPic] = useAddProfilePicMutation();



  const { error, loading, data } = useMeQuery();
useEffect(() => {
 console.log(file)
}, [file])
const handleFile = async (e)=>{
if(e.target.files[0].type.match(/image\/*/)){
  setFile({...file, name: e.target.value})
  let val = e.target.value
  console.log(val)
  let resize = await resizeImageFn(e.target.files[0])
  let x = URL.createObjectURL(resize)
  setFile({name: `${val}`, photo: resize, tempUrl: x})

addPic({variables:{
  pic: resize
}}).then(res=>{
  if(res.errors){
    console.log(res.errors)
  }else{
    setFile({...file, tempUrl: null})
  }
})


}
}
  const [profile, setProfile] = useState({
    firstName: "Loading...",
    lastName: "Loading...",
     phone: "Loading...",
    email: "Loading...",
    nickname: "Loading...",
    ageGroup: "Loading...",
country: "Loading...",
    postalCode: "Loading...",
    living: "Loading...",
    profilePicUrl: "Loading..."
  });
  const [checked, setChecked] = useState();
  const [updateProfile] = useEditUserMutation();
  const [deleteUser] = useDeleteUserMutation();
const handleOptOut =()=>{
  updateProfile({
    variables: {
      id: data.me.id,
      optOut: !profile.optOut
},
refetchQueries: [{ query: MeDocument }],
}).then(res=>{

  setProfile({...res.data.editUser})
})


}
const [selectedSub, setSelectedSub] = useState(null)
const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)

const showWarning = (user)=>{
setSelectedSub(user)
setWarningModalIsOpen(true)
}
const handleDeleteUser = ()=>{
  setWarningModalIsOpen(false)
  deleteUser({
    variables: {
      id: selectedSub.id
      
},
refetchQueries: [{ query: SubUsersDocument }],
}).then(res=>{
if(res.errors){
  toast.error("Oops, something went wrong...")
}else{
  console.log(res.data)
  toast.info(`The member ${res.data.deleteUser.firstName} was removed successfully.`)
}
 
})

}
  const loadData = () => {
    setProfile({

      ...data.me,
      
     
    });
    
  };

  useEffect(() => {
    if (!loading) {
      console.log(data.me)
      loadData();
      setChecked(profile.optOut)
    }
  }, [loading]);
  useEffect(() => {
    if(profile.profilePicUrl != "Loading...")setAvatar(<Avatar className={classes.large}  src={"https://d30mg0zmvh01j5.cloudfront.net/"+profile.profilePicUrl} ></Avatar>)
  }, [profile]);

  if (error) {
    return (
      <div>
        <h1>Oops, something went wrong...</h1>
      </div>
    );
  }

  return (
    <div className="container py-5 px-3 my-5 memberProfileCard round-box box-shadow border">
      <div className="row d-flex justify-content-center">
        {/* left */}
        <div className="col-lg-6 col-md-12">
        <div className="w-100 d-flex justify-content-center my-3">
              {/* <img
                src={file.tempUrl}
                alt="image1"
                className="img-responsive rounded-circle"
                style={{ minWidth: "200px", maxWidth: "200px" }}
              /> */}
            {avatar}
            </div>
          <div className="h5 w-100 text-center">
            {profile.firstName} {profile.lastName} 
            {profile.dateOfBirth&&`(${moment().diff(moment(profile.dateOfBirth), "year")})`}
              <div>
                <button className={`btn btn-info position-relative mx-auto my-2`}>
Change Photo
              <input  style={{opacity: 0,position:"absolute",top:0,left:0, padding: 5, maxWidth:"100%",minHeight:"105% !important"}} type='file' accept="image/*" preview="false" value={file.name} onChange={(e)=>{handleFile(e)}}></input>
              </button>
                </div>
          </div>
          {profile.parentUser != null && subData && subData.subUsers && subData.subUsers.length >0 &&
          <div className=" w-50 mx-auto p-1">
<h5>Co-Members</h5>
            {subData.subUsers.map(user=>{return(
               <div className={`d-flex rounded bg-clear px-2 useBorder my-1 align-items-center justify-content-between`}>
                 <p className={`my-auto`}>{user.nickname?user.nickname:`${user.firstName} ${user.lastName}`}</p> 
               <span onClick={()=>showWarning(user)} className={`btn my-2 btn-sm btn-danger`}><TiUserDelete/></span>
               </div>
            )})} 
          </div>
          }
        </div>

        {/* right */}
        <div className="col-lg-6 my-auto col-md-12">
          <div>
            <strong>First Name: </strong>
            <span>{profile.firstName}</span>
          </div>
          <hr />
          <div>
            <strong>Last Name: </strong>
            <span>{profile.lastName}</span>
          </div>
          <hr />
          <div>
            <strong>Nickname: </strong>
            <span>{profile.nickname}</span>
          </div>
          <hr />
          <div>
            <strong>Phone Number: </strong>
            <span>{profile.phone}</span>
          </div>
          <hr />
          <div className={`d-flex justify-content-start align-items-center`}>
            <strong className={`mr-1 ml-0 pl-0 `}>Email: </strong>
            <span className={`pl-0 ml-0 mr-3 `}>{profile.email}
            </span>
           { profile.optOut!= null && <span className={`  d-flex align-items-center justify-content-between ml-4 px-2 `}>{" "} Opt Out of Emails {" "}              <input
                  type="checkbox"
                  
                  checked={profile.optOut}
                  onChange={(e) =>{
                    setChecked(!profile.optOut)
                    handleOptOut()
                  }
                }
                className="mr-1 ml-2"
                style={{ width: "1em", height: "1em" }}
                /></span>
              }
                          </div>
          <hr />
          <div>
            <strong>Country: </strong>
            <span>{profile.country}</span>
          </div>
          <hr />
          <div>
            <strong>Postalcode: </strong>
            <span>{profile.postalcode}</span>
          </div>
          <hr />
          <div>
            <strong>Age Range: </strong>
            <span>{profile.ageGroup}</span>
          </div>
          <hr />
          <div>
            <strong>Living Arrangement: </strong>
            <span>{profile.living}</span>
          </div>
        </div>
      </div>
      <GenericModal
      style={{width:"100px !important"}}
      className={"col-6 w-25"}
      addClass={" bg-clear gray-filled"}
        modalIsOpen={warningModalIsOpen}
        setModalIsOpen={setWarningModalIsOpen}
      >
          <div className="round-box  box-shadow align-self-center p-5">
            

              <h5>
              Removing a Co-member is permenant and cannot be reversed. 
              </h5>
              <h5>
              Are you sure you want to remove {selectedSub!==null && (selectedSub.nickname?`${selectedSub.nickname}`:`${selectedSub.firstName} ${selectedSub.lastName}`)}? 
              </h5>
         
         
            <div className={"row mt-4"}>
            <div className={"col d-flex justify-content-center"}>
              <button onClick={()=>{
                setWarningModalIsOpen(false)
                handleDeleteUser()
                
                }} className={"btn btn-success w-25 mx-3 box-shadow "}>
                Yes
              </button>
              <button onClick={()=>{
                setWarningModalIsOpen(false) 
                setSelectedSub(null)
                }} className={"btn btn-danger  w-25 mx-3 box-shadow"}>
                No
              </button>
            </div>
           
            </div>
          </div>
      </GenericModal>
      <div className="row mt-5 d-flex justify-content-center">
        <Link to="/" className="btn btn-primary mr-2 px-5 btn-extra-radius">
          Back To Main
        </Link>
        <Link
          to="/profile-edit"
          className="btn btn-success ml-2 px-5 btn-extra-radius"
        >
          Edit
        </Link>
      </div>
    </div>
  );
}
