import jwtDecode from "jwt-decode";
import React, { createContext, useReducer } from "react";

const initialState = {
  accessToken: null,
  user: null,
};

const CLEAR = "CLEAR";
const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
const SET_PASS_RESET_TOKEN = "SET_PASS_RESET_TOKEN";

const userFromToken = token => {
  const {
    firstName,
    lastName,
    nickname,
    email,
    userId,
    role,
    validUntil,
    status,
  } = jwtDecode(token);
  return { firstName, lastName, nickname, email, id: userId, role, validUntil, status };
};
const infoFromToken = token => {
  const {
    userId,
email,
passResetExpire,
  } = jwtDecode(token);
  return {  email, id: userId, passResetExpire  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case CLEAR:
      return initialState;
    case SET_ACCESS_TOKEN:
      const user = userFromToken(action.payload.accessToken);
      return { ...state, user, accessToken: action.payload.accessToken };
    case SET_PASS_RESET_TOKEN:
      const info = infoFromToken(action.payload.resetToken);
      return { ...state, info, resetToken: action.payload.resetToken };
    default:
      return;
  }
};

const UserContext = createContext(initialState);

const UserProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider, CLEAR, SET_ACCESS_TOKEN, SET_PASS_RESET_TOKEN };
