import React from "react";
import { MdClose, MdAdd, MdCheck } from "react-icons/md";
import { toast } from "react-toastify";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow:"0px 0px 0px 0px rgba(0, 0, 0, 0.0) ",
    border: "none",
    backgroundColor:"rgba(0, 0, 0, 0.0)",
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    backgroundColor:"rgba(0,0,0,0.5)",
    width: "70px",
    height: "70px",
  },
}));
export default function Role(props) {
  const { name, id, facilitator, image, emptyCard, onClickDelete, isOwner, onClickAdd, isRequest,onClickDeny, onClickAccept } = props;
  const classes = useStyles();
  const onClickDeleteButton = () => {
    if(!isOwner&&!isRequest){
      console.log(isOwner)
      toast.success(`User ${name} was removed from this group`);
      onClickDelete(id, facilitator);
    }else if(isRequest){
      onClickDeny(id)
    }
  };
  const onClickAcceptButton = () => {

    onClickAccept(id)
    
  };

  if (emptyCard)
    return (
      <div className="col-12 d-flex mt-4">
        <div
          role="button"
          className="rounded h-100 mx-auto not-draggable role-card-add"
          onClick={onClickAdd}
        >
          <div className="d-flex py-2 px-5">
            <div className="font-size-1 icon-text mx-auto">
              <span>
                <MdAdd />
              </span>
              <span>Add {(facilitator && "facilitator") || "member"}</span>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className={`col-12 col-md-6 ${isOwner?"m-0":"my-3"} `}>
      <div className={`round-box box-shadow ${isOwner?"bg-clear":""}  h-100`}>
        <div className="d-flex flex-row align-items-center">
          {/* <img
            className="rounded-circle mr-1 ml-0 ml-md-1"
            src="https://is1-ssl.mzstatic.com/image/thumb/Purple117/v4/f0/cc/cc/f0cccc81-2e9c-01dd-bb0b-55cdfa9b493b/source/512x512bb.jpg"
            alt="icon"
            width="70"
            height="70"
            style={{ width: "70px", height: "70px" }}
          /> */}
<Avatar className={classes.large}  src={"https://d30mg0zmvh01j5.cloudfront.net/"+image} ></Avatar>
          <span
            className="font-size-1 mb-0 ml-1"
            style={{ verticalAlign: "middle" }}
          >
            {name}
          </span>
          {isRequest &&
                    <MdCheck
                      onClick={onClickAcceptButton}
                      className="btn btn-success btn-sm ml-auto mr-2 mr-md-3 p-0 d-flex mx-auto"
                      style={{ fontSize: "2em" }}
                    />}
{!isOwner &&
          <MdClose
            onClick={onClickDeleteButton}
            className="btn btn-danger btn-sm ml-auto mr-2 mr-md-3 p-0 d-flex mx-auto"
            style={{ fontSize: "2em" }}
          />}
        </div>
      </div>
    </div>
  );
}
