import React,{useContext} from "react";
import { UserContext } from "../../../../../context/UserContext";
import ThemeSongCard from "../../components/ThemeSongCard";
import { GiSaveArrow } from "react-icons/gi";

const Songs = props => {
  const { songs, playedSongs, onThemeCardClick, isRunningSession, role } = props;
  const { state } = useContext(UserContext);
  return (
    <>
      <div className="d-flex mb-5">
        <h3>{state.user.role == "ROLE_FACILITATOR"&&"10."} Songs</h3>

        {!isRunningSession ? (
          <button
            className="ml-2 bg-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 4)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
          // <button
          //   className="ml-2 bg-info btn-extra-radius border-0 px-3 text-white align-items-center"
          //   onClick={() => onThemeCardClick("songs", "_", 4)}
          // >
          //   <span>Present Choices</span>
          // </button>
        )}
      </div>
      <div className="align-items-center">
        {/* songs-list: zoom-ui.scss */}
        <div className="songs-list row">
          {songs.map((song, i) => (
            <ThemeSongCard
              key={song.id}
              song={song}
              songCard={true}
              onThemeCardClick={() =>{
                if(role !== "ROLE_MEMBER"){
                  onThemeCardClick("song", song.id, "_", song.url)
                }
              }
              }
              playedSongs={playedSongs}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Songs;
