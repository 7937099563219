import React, { useState, useEffect } from "react";
import matchSorter, { rankings, caseRankings } from "match-sorter";

import HorizontalThemeList from "./HorizontalThemeList";
import FullscreenThemeList from "./FullscreenThemeList";
import SearchBar from "./SearchBar";
import LoadingSpinner from "../LoadingSpinner";

import { MdClose } from "react-icons/md";
import { useThemesQuery } from "../../graphql/generated/graphql";

export default function ThemeLibrary() {
  const categories = [
    { id: 1, title: "Life and Events", alias: "LifeandEvents" },
    { id: 2, title: "Actions", alias: "Actions" },
    { id: 3, title: "Seasonal", alias: "SeasonsSeasonals" },
    { id: 4, title: "Other Feelings", alias: "OtherFeelings" },
    { id: 5, title: "Emotions", alias: "Emotions" },
    { id: 6, title: "Java Social", alias: "reflections" },
  ];

  const [categorySections, setCategorySections] = useState("", []);

  // Search bar
  const [searchText, setSearchText] = useState("", []);

  const handleOnChange = e => {
    setSearchText(e.target.value);
  };

  // Selected category
  const [selectedCategory, setSelectedCategory] = useState("", []);

  const handleSelectCategory = category => {
    setSelectedCategory(category);
  };

  const { loading, error, data } = useThemesQuery();

  const getThemesFromCategory = category => {
    return data.themes.filter(theme => theme.category === category);
  };

  // Match sorter
  const filterBySearchText = () =>
    matchSorter(data.themes, searchText, { keys: ["name", "category"] });

  // Create a function to get all the available theme categories
  // Render one HorizontalThemeList per category, passing only the correct themes as prop
  useEffect(() => {
    if (!loading && data.themes) {
      const catMap = [];
      categories.map(category => {
        catMap.push(
          <HorizontalThemeList
            handleSelectCategory={handleSelectCategory}
            enableViewAll={true}
            key={category.id}
            categoryTitle={category.title}
            categoryAlias={category.alias}
            themes={getThemesFromCategory(category.alias)}
          />
        );
      });
      setCategorySections([...catMap]);
      console.log(categorySections);
    }
  }, [loading, data ? data.themes : ""]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container my-3">
      <header>
        <h4>Theme Library</h4>
        {/* Search bar */}
        {!selectedCategory && (
          <SearchBar handleOnChange={handleOnChange} searchText={searchText} />
        )}
      </header>

      {/* Button to deselect category */}
      {selectedCategory && (
        <button
          className="px-3 btn btn-danger btn-extra-radius my-3 py-1 d-flex align-items-center"
          onClick={() => handleSelectCategory("")}
        >
          <span
            className="d-flex align-items-center mr-2"
            style={{ fontSize: "1.25em" }}
          >
            <MdClose />
          </span>
          <span className="mr-2">{selectedCategory.title}</span>
        </button>
      )}

      {/* Screen with all themes from selected category */}
      {selectedCategory && (
        <FullscreenThemeList
          title={selectedCategory.title}
          themes={getThemesFromCategory(selectedCategory.alias)}
        />
      )}

      {/* Screen with themes filtered from text search */}
      {!selectedCategory && searchText && (
        <main>
          {console.log("not here")}
          <FullscreenThemeList
            title={`Result for search "${searchText}"`}
            themes={filterBySearchText()}
          />
        </main>
      )}

      {/* All Themes by category */}
      {!selectedCategory && !searchText && (
        <main>
          {categorySections}
          {console.log(categorySections)}
          {/* <HorizontalThemeList
            handleSelectCategory={handleSelectCategory}
            enableViewAll={true}
            categoryName={"Winter"}
            themes={data.themes}
          />
          <HorizontalThemeList
            handleSelectCategory={handleSelectCategory}
            enableViewAll={true}
            categoryName={"Summer"}
            themes={data.themes}
          />
          <HorizontalThemeList
            handleSelectCategory={handleSelectCategory}
            enableViewAll={true}
            categoryName={"Another category"}
            themes={data.themes}
          />
          <HorizontalThemeList
            handleSelectCategory={handleSelectCategory}
            enableViewAll={true}
            categoryName={"Amazing category"}
            themes={data.themes}
          /> */}
        </main>
      )}
    </div>
  );
}
