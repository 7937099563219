import React, { useEffect, useState } from "react";
import MusicController from "../MusicController";
import SyncEmbeddedMusicController from "../SyncEmbeddedMusicController";
import SyncedNonMusic from "../SyncedNonMusic";
import VideoChat from "../Twilio/VideoChat";
import { Icon, InlineIcon } from "@iconify/react";
import windChime from "@iconify-icons/emojione-v1/wind-chime";
import GenericModal from "../../GenericModal";
import ThemeSongCard from "../Theme/components/ThemeSongCard";
import BlackoutModal from "../../BlackoutModal";
export {
  OpeningWelcome,
  OpeningSong,
  GroupGuidelines,
  GettingCentered,
  WrapUp,
  ClosingAffirmation,
  ClosingSong,
  GroupEnding,
  VideoChatCluster,
  OpeningWelcomeSoc,
GroupGuidelinesSoc,
GettingCenteredSoc,
MeetingFormat,
GroupAffirm,
Shanx,
SerenityPage,
AfterShare,
GratitudePractice,
Introductions,
JavaSocialIntro
};

const OpeningWelcome = () => {
  return (
    <div>
      <p>
        Welcome everyone to the Java Music Club! We are glad you are all here.
        Is there anyone here for the first time? Do we have any guests of honor
        here today? We get together every week at this time. We hope you enjoy
        it and come back again next week.
      </p>
      <p>
        As William Butler Yeats (the poet) said: There are no strangers here
        only friends you haven't met yet. For those who wish, please join us in
        our opening song called "The Java Music Club".
      </p>
    </div>
  );
};
const OpeningWelcomeSoc = () => {
  return (
    <div>
      <p>
      Hello everyone!
      </p>
      <p>
      Welcome to Java Social—I’m glad you are all here.
      </p>
      <p>
      Could someone please volunteer to read our introduction?
      </p>
    </div>
  );
};
const VideoChatCluster = props => {
  const {
    username,
    roomName,
    token,
    isSilent,
    setToken,
    myRemoteMuteState,
    myRemoteVidState,
    myRemotePalamiState,
    myRemoteRoomState,
    setParticipantList,
    sessionId,
    zoomData
  } = props;

  return (
    <VideoChat
      username={username}
      roomName={roomName}
      token={token}
      setParticipantList={setParticipantList}
      setToken={setToken}
      isSilent={isSilent}
      sessionId={sessionId}
      myRemoteMuteState={myRemoteMuteState}
      myRemoteVidState={myRemoteVidState}
      myRemotePalamiState={myRemotePalamiState}
      myRemoteRoomState={myRemoteRoomState}
      zoomData={zoomData}
    ></VideoChat>
  );
};

const OpeningSong = props => {
  const {
    isSessionMaster,
    modalSync,
    handleMusicPlay,
    isSyncOn,
    setIsSilent,
  } = props;
  const [open, setOpen] = useState(false);
  const [songLocation, setSongLocation] = useState("");
  useEffect(() => {
    console.log(modalSync)
    if (!isSessionMaster) {
      if (modalSync.contentType !== "" && modalSync.contentType !== "open modal"&& modalSync.contentType !== "close modal") {
        setIsSilent(true);
        setOpen(true);
        setSongLocation("/assets/songs/OPENOLD.mp3");
      } else if(modalSync.contentType == "open modal") {
        setOpen(true)
      }
     else if(modalSync.contentType == "close modal") {
        setOpen(false);

      }
       else {
        setIsSilent(false);
        setSongLocation("");
      }
    }
  }, [modalSync.contentType]);
  useEffect(() => {
    if (isSessionMaster) {
      console.log(modalSync)
      if (open==true) {
        handleMusicPlay(true, "open modal")
        
        
      } else {
        handleMusicPlay(true, "close modal")
        setIsSilent(false)
        
      }
    }
  }, [open]);
  return (
    <div className="music my-auto d-md-flex">
      <ThemeSongCard
        song={{ id:true, title: "The Java Music Club" }}
        songCard={false}
        cssClassName={"w-50"}
        setSelectedSongId={setOpen}
      ></ThemeSongCard>
      <GenericModal modalIsOpen={open} setModalIsOpen={setOpen}>
        <div
          className="round-box box-shadow p-5 container"
          style={{
            maxWidth: "1000px",
            minWidth: "900px",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <div className="row">
            <div className="col-12 col-md-6 text-center ">
              <h4>The Java Music Club</h4>

              <img
                src="https://picsum.photos/400/400"
                className="round-box box-shadow"
                alt=""
              />
              <div className={"pr-2 "}>
                <SyncEmbeddedMusicController
                  musicUrl={"/assets/songs/OPENOLD.mp3"}
                  handleMusicPlay={handleMusicPlay}
                  contentType={"Opening song"}
                  songLocation={songLocation}
                  isSyncOn={isSyncOn}
                />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="my-4">
                <div>A little music, keeps me feeling good</div>
                <div>A little coffee, like I knew I could.</div>
                <div>A great big hug and I'm OK,</div>
                <div>I can make it through this day.</div>
                <div>A little singing, would be right on time</div>
                <div>A little lovin', and I'm feeling fine.</div>
                <div>Sunshine through my window pane</div>
                <div>And I'm can make it through this day.</div>
                <div>Here's the kind of people I like</div>
                <div>Ones that are kind and treat me right.</div>
                <div>And that's why I think I love</div>
                <div>Our little Java Music Club, Oh yeah,</div>
                <div>Our little Java Music Club.</div>
                <div>[Repeat]</div>
              </div>
            </div>
          </div>
        </div>
      </GenericModal>
    </div>
  );
};

const GroupGuidelines = (props) => {
  const {
    isSessionMaster,
    modalSync,
    handleMusicPlay,
    isSyncOn,
    setIsSilent,
  } = props;
  const [commandments, setCommandments] = useState("");
  const [songLocation, setSongLocation] = useState("");
  useEffect(() => {
    console.log(modalSync)
    if (!isSessionMaster) {
     if(modalSync.contentType == "open modal - c1") {
      
        setCommandments("c1")
      }
      else if(modalSync.contentType == "open modal - c2") {
       setCommandments("c2")
        

      }
      else if(modalSync.contentType == "open modal - c3") {
       setCommandments("c3")
       
       
      }
      else if(modalSync.contentType == "open modal - c4") {
       setCommandments("c4")
       
       
      }
      else {
         setCommandments("")
        // setIsSilent(false);
        // setSongLocation("");
      }
    }
  }, [modalSync.contentType]);
 const handleCommandments = (thing, selectedThing) => {
    if (isSessionMaster) {
    
        handleMusicPlay(true, thing)
        
        setCommandments(selectedThing)
     
    }
  }
  return (
    <div style={{minHeight: "40vh", maxWidth:"40vw"}}>
      <div className={`mb-5 pb-2`}>

      {commandments=="c1"?<h2><span>(i)</span> The primary purpose of this group is to share our
        experience, strength and hope, to support one another and to have fun.</h2>:<p className={`${commandments==""?"":"d-none"}`}>
        <span>(i)</span> The primary purpose of this group is to share our
        experience, strength and hope, to support one another and to have fun.
      </p>}
      {commandments=="c2"?<h2><span>(ii)</span> We honour each person here and remember that all of us possess wisdom and courage. We do our best to listen closely with an open mind while others are sharing.</h2>:<p className={`${commandments==""?"":"d-none"}`}>
        <span>(ii)</span> We honour each person here and remember that all of us possess wisdom and courage. We do our best to listen closely with an open mind while others are sharing.
      </p>}
      {commandments=="c3"?<h2><span>(iii)</span> We keep things we hear confidential and we respect each
        person's right to their opinion. We keep our sharing to a few minutes so
        that all who wish have a chance to share.</h2>:<p className={`${commandments==""?"":"d-none"}`}>
        <span>(iii)</span> We keep things we hear confidential and we respect each
        person's right to their opinion. We keep our sharing to a few minutes so
        that all who wish have a chance to share.
      </p>}
      {commandments=="c4"?<h2> <span>(iv)</span> We have but one guiding principle, and that is loving
        kindness.</h2>:<p className={`${commandments==""?"":"d-none"}`}>
        <span>(iv)</span> We have but one guiding principle, and that is loving
        kindness.
      </p>}
      </div>
     
     
     
      <div style={{position: "absolute", bottom: 0}} className={`btn-group`} role="group" aria-label="Basic example">
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("open modal - c1", "c1")}} className={`btn btn-secondary`}>i</button>
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("open modal - c2", "c2")}} className={`btn btn-secondary`}>ii</button>
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("open modal - c3", "c3")}} className={`btn btn-secondary`}>iii</button>
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("open modal - c4", "c4")}} className={`btn btn-secondary`}>iv</button>
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("close modal - coman", "")}} className={`btn btn-secondary`}>show all</button>
</div>
    </div>
  );
};
const GroupGuidelinesSoc = (props) => {
  const {
    isSessionMaster,
    modalSync,
    handleMusicPlay,
    isSyncOn,
    setIsSilent,
  } = props;
  const [commandments, setCommandments] = useState("");
  const [songLocation, setSongLocation] = useState("");
  useEffect(() => {
    console.log(modalSync)
    if (!isSessionMaster) {
     if(modalSync.contentType == "open modal - c1") {
      
        setCommandments("c1")
      }
      else if(modalSync.contentType == "open modal - c2") {
       setCommandments("c2")
        

      }
      else if(modalSync.contentType == "open modal - c3") {
       setCommandments("c3")
       
       
      }
      else if(modalSync.contentType == "open modal - c4") {
       setCommandments("c4")
       
       
      }
      else if(modalSync.contentType == "open modal - c5") {
       setCommandments("c5")
       
       
      }
      else {
         setCommandments("")
        // setIsSilent(false);
        // setSongLocation("");
      }
    }
  }, [modalSync.contentType]);
 const handleCommandments = (thing, selectedThing) => {
    if (isSessionMaster) {
    
        handleMusicPlay(true, thing)
        
        setCommandments(selectedThing)
     
    }
  }
  return (
    <div>
      <div style={{minHeight: "40vh", maxWidth:"40vw"}}>
      <div className={`mb-5 pb-2`}>

      {commandments=="c1"?<h2><span>(i)</span> The primary purpose of this group is to support one another. Through our participation, we can connect, heal and find peace.</h2>:<p className={`${commandments==""?"":"d-none"}`}>
      <span>(i)</span> The primary purpose of this group is to support one another. Through our participation, we can connect, heal and find peace.
      </p>}
      {commandments=="c2"?<h2>
      <span>(ii)</span> We keep things we hear confidential and respect each person’s right to their opinion.
        </h2>:<p className={`${commandments==""?"":"d-none"}`}>
        <span>(ii)</span> We keep things we hear confidential and respect each person’s right to their opinion.
      </p>}
      {commandments=="c3"?<h2>
       <span>(iii)</span> We keep our sharing to a few minutes so that as many as possible have a chance to share. 
        </h2>:<p className={`${commandments==""?"":"d-none"}`}>
        <span>(iii)</span> We keep our sharing to a few minutes so that as many as possible have a chance to share. 
      </p>}
      {commandments=="c4"?<h2> 
        <span>(iv)</span> We do our best not to interrupt, comment, or give advice while others are sharing.
        </h2>:<p className={`${commandments==""?"":"d-none"}`}>
        <span>(iv)</span> We do our best not to interrupt, comment, or give advice while others are sharing.
      </p>}
      {commandments=="c5"?<h2> 
         <span>(v)</span> We have one guiding principle and that is — loving kindness.
        </h2>:<p className={`${commandments==""?"":"d-none"}`}>
         <span>(v)</span> We have one guiding principle and that is — loving kindness.
      </p>}
      </div>
     
     
     
      <div style={{position: "absolute", bottom: 0}} className={`btn-group`} role="group" aria-label="Basic example">
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("open modal - c1", "c1")}} className={`btn btn-secondary`}>i</button>
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("open modal - c2", "c2")}} className={`btn btn-secondary`}>ii</button>
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("open modal - c3", "c3")}} className={`btn btn-secondary`}>iii</button>
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("open modal - c4", "c4")}} className={`btn btn-secondary`}>iv</button>
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("open modal - c5", "c5")}} className={`btn btn-secondary`}>v</button>
  <button type="button" onClick={(e)=>{
        e.stopPropagation();
        handleCommandments("close modal - coman", "")}} className={`btn btn-secondary`}>show all</button>
</div>
    </div>
      <p>
        
      </p>
      <p>
        
      </p>
      <p>
        
      </p>
      <p>
        

      </p>
      <p>
       

      </p>
    </div>
  );
};

const GettingCentered = props => {
  const {
    isSessionMaster,
    modalSync,
    handleMusicPlay,
    isSyncOn,
    setIsSilent,
  } = props;
  const [songLocation, setSongLocation] = useState("");
  useEffect(() => {
    if (!isSessionMaster) {
      if (modalSync.contentType !== "") {
        if(modalSync.contentType == "song Windchimesa"){
          setIsSilent(true);
          setSongLocation("/assets/songs/WINDCHIMESA.wav");
        }
        else if(modalSync.contentType == "song Windchimesb"){
         setIsSilent(true);
        setSongLocation("/assets/songs/WINDCHIMESB.wav");
      }
      } else {
        setIsSilent(false);
        setSongLocation("");
      }
    }
  }, [modalSync.contentType]);
  return (
    <div>
      <p>
        Getting centered means putting all our worries aside for a little while
        and becoming present. It helps us to have understanding and compassion
        for ourselves, and for one another. People who are centered and present
        are happier.
      </p>{!isSessionMaster&&<div className="d-none">
      <SyncedNonMusic
        musicUrl={"/assets/songs/WINDCHIMESA.wav"}
        handleMusicPlay={handleMusicPlay}
        contentType={"song Windchimesa"}
        songLocation={songLocation}
        isSyncOn={isSyncOn}
      />
        
        </div>}
        {isSessionMaster && <SyncedNonMusic
        musicUrl={"/assets/songs/WINDCHIMESA.wav"}
        handleMusicPlay={handleMusicPlay}
        contentType={"song Windchimesa"}
        songLocation={songLocation}
        isSyncOn={isSyncOn}
      />
      }
      <p className={"mt-3"}>
        I now invite you to close your eyes for just a few moments.
      </p>
      <ul>
        <li>Take a deep breath in - and breathe out.</li>
        <li>Breathe in calm - breathe out worry.</li>
        <li>Breathe in peace - breathe out fear.</li>
        <li>Breathe in happiness - and relax.</li>
      </ul>
      <p>For those of you who wish, please join me in saying:</p>
      <blockquote>
        I am still, present and at peace.<br></br> (together) I am still, present and at
        peace.
      </blockquote>
      <p>Thank you, you may open your eyes.</p>{" "}
    {!isSessionMaster&&<div className="d-none">  <SyncedNonMusic
        musicUrl={"/assets/songs/WINDCHIMESB.wav"}
        handleMusicPlay={handleMusicPlay}
        contentType={"song Windchimesb"}
        songLocation={songLocation}
        isSyncOn={isSyncOn}
      />
         </div>}
        {isSessionMaster &&
      <SyncedNonMusic
        musicUrl={"/assets/songs/WINDCHIMESB.wav"}
        handleMusicPlay={handleMusicPlay}
        contentType={"song Windchimesb"}
        songLocation={songLocation}
        isSyncOn={isSyncOn}
      /> }
    </div>
  );
};
const GettingCenteredSoc = (props) => {
  const {
    isSessionMaster,
    modalSync,
    handleMusicPlay,
    isSyncOn,
    setIsSilent,
  } = props;
  const [songLocation, setSongLocation] = useState("");
  const [blackout, setBlackout] = useState("");
  const [guided, setGuided] = useState("");

  const handleGuided = ()=>{
    if(songLocation=="")
    {
      handleMusicPlay(true,"song get center")
      setBlackout(true)
    setSongLocation("/assets/songs/Getting Centered.wav");
    setIsSilent(true);
  }else{
    handleMusicPlay(false,"song get center")
    setIsSilent(false);
    setSongLocation("");
  }

  }
  useEffect(() => {
    if(isSessionMaster){
      if(!blackout&&songLocation){
        
handleMusicPlay(false,"song get center")
setSongLocation("");
      }
    }
  }, [blackout])
  useEffect(() => {
    if (!isSessionMaster) {
      if (modalSync.contentType !== "") {
        if(modalSync.contentType == "song get center"){
          setIsSilent(true);
          setBlackout(true)
          setSongLocation("/assets/songs/Getting Centered.wav");
        }
      } else {
        setBlackout(false)
        setIsSilent(false);
        setSongLocation("");
      }
    }
  }, [modalSync.contentType]);
  return (
    <div onClick={(e)=>{if(blackout)e.stopPropagation()}}>
      {isSessionMaster && <button className={`btn btn-info`} onClick={(e)=>{e.stopPropagation()
      handleGuided()}}>
        Play Guided Meditation
        
        </button>}
     
     
      {/* <p>
      I now invite you to close your eyes for a short getting centered practice followed by an affirmation.
    </p> */}
    <BlackoutModal
    modalIsOpen={blackout}
    setModalIsOpen={
      setBlackout
      }
      
    >
    <div className="d-none">
    
  <SyncedNonMusic
    musicUrl={"/assets/songs/Getting Centered.wav"}
    handleMusicPlay={handleMusicPlay}
    contentType={"song get center"}
    songLocation={songLocation}
    isSyncOn={isSyncOn}
  />
    </div>
    <h5 className={`text-center `}>
    <p>
        Getting centered means putting all our worries aside for a little while
        and becoming present. 
      </p>
      <p>
        It helps us to have understanding and compassion
        for ourselves, and for one another. 
      </p>
      <p>
        People who are centered and present
        are happier.
      </p>
 <p className={"mt-3"}>
        I now invite you to close your eyes for just a few moments.
      </p>
      <br/>
        <p>Take a deep breath in - and breathe out.</p>
        <p>Breathe in calm - breathe out worry.</p>
        <p>Breathe in peace - breathe out fear.</p>
        <p>Breathe in happiness - and relax.</p>
            <p>For those of you who wish, please join me in saying:</p>
      <br/>
      <blockquote>
        I am still, present and at peace.<br></br> (together) I am still, present and at
        peace.
      </blockquote>
      <br/>
      <p>Thank you, you may open your eyes.</p>{" "}
    </h5>
    <div className={`col mt-5 pt-5 col-12 d-flex justify-content-center`}>

    <button onClick={()=>setBlackout(false)} className={`btn btn-outline-light mx-auto`}>End meditation</button>
    </div>
    </BlackoutModal>
     
      <GettingCentered
       modalSync={modalSync}
       isSessionMaster={isSessionMaster}
       isSyncOn={isSyncOn}
       setIsSilent={setIsSilent}
       handleMusicPlay={handleMusicPlay}
      />
    </div>
  );
};
const MeetingFormat = () => {
  
  return (
    <div>
      <p>
      This meeting consists of:
      </p>
      
      <ul>
        <li>a short meditation</li>
        <li>reflections on a topic</li>
        <li>sharing</li>
        <li>closing readings</li>
      </ul>
      <p>Could someone please read our group guidelines?</p>
    </div>
  );
};
const GroupAffirm = () => {
  
  return (
    <div>
      <p>
      Could someone volunteer to lead us in our group affirmation.
      </p>
      
      <ul>
        <li>I love, accept and appreciate all of me, just as I am today.
</li>
        <li>I bring understanding and compassion, and I receive understanding and compassion.
</li>
        <li>I bring love and I receive love.
</li>
        <li>I bring peace, and I am at peace.
</li>
      </ul>

    </div>
  );
};
const Shanx = () => {
  
  return (
    <div>
      <p>
      Thank you to everyone for joining us. 
      </p>
      <p>
      See you all next week!  
      </p>
      

    </div>
  );
};
const SerenityPage = (props) => {
  const {
    isSessionMaster,
    modalSync,
    handleMusicPlay,
    isSyncOn,
    setIsSilent,
  } = props;
  const [seren, setSeren] = useState("");
  const [songLocation, setSongLocation] = useState("");
  useEffect(() => {
    console.log(modalSync)
    if (!isSessionMaster) {
     if(modalSync.contentType == "open modal - q1") {
      
        setSeren("q1")
      }
      else if(modalSync.contentType == "open modal - q2") {
       setSeren("q2")
        

      }
      else if(modalSync.contentType == "open modal - q3") {
       setSeren("q3")
       
       
      }
      else {
         setSeren("")
        // setIsSilent(false);
        // setSongLocation("");
      }
    }
  }, [modalSync.contentType]);
 const handleSerenity = (thing, selectedThing) => {
    if (isSessionMaster) {
    
        handleMusicPlay(true, thing)
        
        setSeren(selectedThing)
     
    }
  }
  return (
    <div>
      <p>
      Thank you to all who shared.
      </p>
      <p>
      Could the person who volunteered please read one of the three readings on serenity to close our group.
      </p>
      <div
      className={`${seren=="q1"||seren==""?"":"d-none"} border round-box my-5 p-3 box-shadow clickable-card"`}
      onClick={(e)=>{
        e.stopPropagation();
        handleSerenity("open modal - q1", "q1")}}
      data-content="quote"
      data-id={1}
    >
      <div>"We can be serene even in the midst of calamities and, by our serenity, make others more tranquil. Serenity is contagious. "</div>
      <div>Sri S. Satchidananda, The Yoga Sutras of Patanjali (compiled by Patanjali AD 400)</div>
    </div>
    <div
     className={`${seren=="q2"||seren==""?"":"d-none"} border round-box my-5 p-3 box-shadow clickable-card"`}
      onClick={(e)=>{
        e.stopPropagation();
        handleSerenity("open modal - q2","q2")}}
      data-content="quote"
      data-id={2}
    >
      <div>"Peace is present right here and now, in ourselves and in everything we do and see. Every breath we take, every step we take, can be filled with peace, joy, and serenity—we need only to be awake, alive in the present moment."</div>
      <div>Thích Nhất Hạnh, Peace is Every Step: The Path of Mindfulness in Everyday Life (1990)</div>
    </div>
    
    <div
     className={`${seren=="q3"||seren==""?"":"d-none"} border round-box my-5 p-3 box-shadow clickable-card"`}
      onClick={(e)=>{
        e.stopPropagation();
        handleSerenity("open modal - q3","q3")}}
      data-content="quote"
      data-id={3}
    >
      <div>"Thank you for the serenity to accept the things I cannot change, courage to change the things I can, and the wisdom to know the difference."</div>
      <div>Reinhold Niebuhr (1932)</div>
    </div>
    <div className={`col col-12 d-flex justify-content-center`}>
      <div role="button" className={`${seren!==""&&isSessionMaster?"d-flex":"d-none"} btn  justify-content-center  btn-info my-5  box-shadow useBorder`} onClick={(e)=>{
 e.stopPropagation();
 handleSerenity("close modal","")
      }}>
        show all
      </div>

    </div>

    </div>
  );
};
const AfterShare = () => {
  
  return (
    <div>
      <p>
      We will stop the sharing now as we are almost out of time.

      </p>
      <p>
      If you didn’t get a chance to share today and need a bit of support, please connect with someone after this meeting. 


      </p>
      <p>
      Those willing to provide support, please offer your phone number in the chat window.


      </p>
      <p>
      Next I would like to complete our gratitude practice. 


      </p>
      
      
    </div>
  );
};
const GratitudePractice = () => {
  
  return (
    <div>
      <p>
      I’d like to invite someone to share one thing or person they are grateful for in their life and why. 

      </p>
      <p>
      Gratitude is an action word. Is there someone in our community that could use support? Could we invite them to Java Social next week?
       </p>
     
    </div>
  );
};
const Introductions = (props) => {
  const {userList, master} = props
  return (
    <div>
      <p>
      We will introduce ourselves; please say hello and your name.

      </p>
      <div className={`round-box bg-dark box-shadow py-1 my-1 ml-1 w-50 row d-flex justify-content-center border useBorder`}>

      {userList.map(user=>{
        return(
          <div className={`col col-12`}>
        <div className={`round-box box-shadow py-1 my-1 col-12 bg-clear  d-flex justify-content-center border useBorder`}>
          {user.name}  {user.role=="ROLE_FACILITATOR"?user.id==master?" - Facilitator":"- Co-Facilitator":user.role?"- "+user.role:""}
        </div>
            </div>
        
        )
      })}
      </div>
    {/* list members here :) */}
    </div>
  );
};
const JavaSocialIntro = () => {
  
  return (
    <div>

      

        <div className={`round-box box-shadow  my-4 px-2 py-3 border useBorder`}>Java Social is an online community of people who meet regularly to connect and get to know one another.
</div>
        <div className={`round-box box-shadow  my-4 px-2 py-3 border useBorder`}>We are not affiliated with any religious or political organization. We do not participate in any controversy and do not endorse or oppose any causes.

</div>
        <div className={`round-box box-shadow  my-4 px-2 py-3 border useBorder`}>Our primary purpose here is to support one another.

</div>
        
      
    </div>
  );
};

const WrapUp = () => {
  return (
    <div>
      <p>Invite a Guest of Honor</p>
     <div>
        "Before we do our closing, who can we invite as a guest next week - is
        there someone who is new here or who may be feeling lonely or not
        included? Or should we invite a staff or family member?"
      </div>
      {/* <p>Plan the next Java party</p>
      <blockquote>
        "Let's plan a Java party. We do this every 2-3 months to help those who
        feel isolated join in and feel more a part of our community"
      </blockquote> */}
    </div>
  );
};

const ClosingAffirmation =props => {
  const {
    isSessionMaster,
    modalSync,
    handleMusicPlay,
    isSyncOn,
    setIsSilent,
  } = props;

  return (
    <div>
       {/* <SyncedNonMusic
        musicUrl={"/assets/songs/WINDCHIMES.mp3"}
        handleMusicPlay={handleMusicPlay}
        contentType={"Windchimes"}
        songLocation={songLocation}
        isSyncOn={isSyncOn}
      /> */}
      <p>I love, accept and appreciate all of me, just as I am today.</p>
      <p>
        I bring understanding and compassion, and I receive understanding and
        compassion.
      </p>
      <p>I bring love and I receive love.</p>
      <p>I bring peace and I am at peace.</p> 
      {/* <SyncedNonMusic
        musicUrl={"/assets/songs/WINDCHIMES.mp3"}
        handleMusicPlay={handleMusicPlay}
        contentType={"Windchimes"}
        songLocation={songLocation}
        isSyncOn={isSyncOn}
      /> */}
    </div>
  );
};

const ClosingSong = props => {
  const {
    isSessionMaster,
    modalSync,
    handleMusicPlay,
    isSyncOn,
    setIsSilent,
  } = props;

  const [songLocation, setSongLocation] = useState("");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    console.log(modalSync)
    if (!isSessionMaster) {
      if (modalSync.contentType !== "" && modalSync.contentType !== "open modal"&& modalSync.contentType !== "close modal") {
        setIsSilent(true);
        setOpen(true);
        setSongLocation("/assets/songs/CLOSEOLD.mp3");
      } else if(modalSync.contentType == "open modal") {
        setOpen(true)
      }
     else if(modalSync.contentType == "close modal") {
        setOpen(false);

      }
       else {
        setIsSilent(false);
        setSongLocation("");
      }
    }
  }, [modalSync.contentType]);
  useEffect(() => {
    if (isSessionMaster) {
      console.log(modalSync)
      if (!open) {
        handleMusicPlay(true, "close modal")
        setIsSilent(false)
        
      } 
    }
  }, [open]);
  return (
    <div className="music my-auto d-md-flex">
      <ThemeSongCard
        song={{ id: true, title: "I’ll be there for you" }}
        songCard={false}
        cssClassName={"w-50"}
        setSelectedSongId={()=>{
          setOpen(true)
          handleMusicPlay(true, "open modal")
        }}
      ></ThemeSongCard>
      <GenericModal modalIsOpen={open} setModalIsOpen={setOpen}>
        <div
          className="round-box box-shadow p-5 container"
          style={{
            maxWidth: "1000px",
            minWidth: "900px",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <div className="row ">
            <div className="col-12 col-md-6 text-center">
              <h4>I’ll be there for you</h4>

              <img
                src="https://picsum.photos/400/400"
                className="round-box box-shadow"
                alt=""
              />
              <div className={"pr-2  "}>
                <SyncEmbeddedMusicController
                  musicUrl={"/assets/songs/CLOSEOLD.mp3"}
                  handleMusicPlay={handleMusicPlay}
                  contentType={"Closing song"}
                  songLocation={songLocation}
                  isSyncOn={isSyncOn}
                />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="my-4">
                <div>May you have time to rest</div>
                <div>And may your fortune be the best.</div>
                <div>Meet some kindness on your way</div>
                <div>And have sunshine most every day.</div>
              </div>
              <div className="my-4">
                <div>May you always do your part</div>
                <div>And have a song in your heart.</div>
                <div>And may every day be blessed</div>
                <div>For I wish you all the best.</div>
              </div>
              <div className="my-4">
                <div>Enjoy every day don't forget</div>
                <div>Like it's the last one you'll get</div>
                <div>And remember this in all you do</div>
                <div>I'll be there for you.</div>
              </div>
              <div className="my-4">
                <div>When you're lonely or you're blue</div>
                <div>Know that I will have time tor you.</div>
                <div>Through the Cloudy days and rain</div>
                <div>Our friendship stays the same.</div>
              </div>
              <div className="my-4">
                <div>And enjoy every day don't forget</div>
                <div>Like it's the last one you'll get,</div>
                <div>And remember this in all you do</div>
                <div>I'll be there for you.</div>
              </div>
            </div>
          </div>
        </div>
      </GenericModal>
    </div>
  );
};

const GroupEnding = () => {
  return (
    <div>
<blockquote>
  What happens at Java, <br></br>stays at Java! 
</blockquote>
    </div>
  );
};
