import React, { useEffect, useState } from "react";
import GenericModal from "./../GenericModal";
import ThemePreview from "./ThemePreview";

export default function ThemeCard(props) {
  const { theme, selectableTheme, handleThemeSelection, disabler, scroller } = props;

  const [themePreviewIsOpen, setThemePreviewIsOpen] = useState(false, []);
  const [isActive, setIsActive] = useState(false, []);
  const [style, setStyle] = useState("theme-card-disabled");

  const openThemePreview = () => {
    if (disabler) {
      setIsActive(!isActive);
      return false;
    }
    setThemePreviewIsOpen(true);
  };
  const handleTablet = () => {
    if (disabler) {
      setStyle("tcA");
      console.log("here start");
      breath("theme-card-disabled");
      setIsActive(true);

      return false;
    }
  };
  const getURL = url => {
    return url.slice(0, 14) + "/thumb/" + url.slice(15);
  };
  const breath = look => {
    setStyle("tcA");
    setTimeout(function () {
      console.log("wait");
      setStyle(look);
    }, 3000);
  };

  const handleOnClickThemeSelection = () => {
    if (selectableTheme && handleThemeSelection) {
      console.log(theme);
      handleThemeSelection(theme);
      setThemePreviewIsOpen(false);
    }
  };

  return (
    <div className={`p-2`} key={theme.id}>
      {!disabler && (
        <div
          className="position-relative text-center d-flex align-items-center justify-content-center theme-card"
          role="button"
        >
          <img
            className="not-draggable round-box"
            src={
              "https://d30mg0zmvh01j5.cloudfront.net" + getURL(theme.imageUrl)
            }
            height={150}
            width={200}
            onClick={openThemePreview}
            alt=""
          />
          <h5 className="position-absolute text-white my-auto mx-auto">
            {theme.name}
          </h5>{" "}
        </div>
      )}
      {disabler && (
        <div
          onTouchEnd={handleTablet}
          className={`position-relative text-center d-flex align-items-center justify-content-center  ${style} `}
          role="button"
        >
          <img
            className="not-draggable round-box"
            src={
              "https://d30mg0zmvh01j5.cloudfront.net" + getURL(theme.imageUrl)
            }
            height={150}
            width={200}
            alt=""
          />
          <div className="text-white px-2 theme-card-disabled-div round-box position-absolute text-white my-auto mx-auto">
            <h5 className="text-white my-auto mx-auto round-box">
              {theme.name}
            </h5>

            <p className={"m-0 " + style ? "show" : "show"}>Theme last Used:</p>
            <p className="m-0 show">{" 9 November 1989 "}</p>
          </div>
        </div>
      )}

      {/* Theme Preview modal */}

      <GenericModal
        modalIsOpen={themePreviewIsOpen}
        setModalIsOpen={setThemePreviewIsOpen}
      >
        <ThemePreview
          selectableTheme={selectableTheme}
          handleOnClickThemeSelection={handleOnClickThemeSelection}
          theme={theme}
        />
      </GenericModal>
    </div>
  );
}
