import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { FaRegHandPointDown } from "react-icons/fa";
import InputForm from "./InputForm";
import { useAddUserMutation } from "../../graphql/generated/graphql";
import { SET_ACCESS_TOKEN, UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import moment from "moment";
import { getAccessToken, setAccessToken } from "../../auth/accessToken";
import { useLoginMutation } from "../../graphql/generated/graphql";

   
export default function RegistrationForm() {
  const [registrationType, setRegistrationType] = useState("Member", []);
  const history = useHistory();
  const { state } = useContext(UserContext);
  
const { dispatch } = useContext(UserContext);

  const [login] = useLoginMutation();

  const [addUser] = useAddUserMutation();
useEffect(() => {
  if(state.user){
    setRegistrationType("Co-Member")
  }

}, [state])
  const initialState = {
    firstName: "",
    lastName: "",
    nickname: "",
    email: "",
    password: "",
    repeat: "",
    phone: "",
    country: "",
    postalcode: "",
    ageGroup:"",
    living:""
  };
  const [style, setStyle] = useState(initialState)

  const handleOnChange = e => {
    console.log("erere")
    //[ e.target.name=="firstName"||e.target.name=="lastName"?e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1):e.target.value ] autocapitalizes first and last name fields
    setData({
      ...data,
      [e.target.name]:
        e.target.name == "firstName" || e.target.name == "lastName"
          ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
          : e.target.value,
    });
   if(e.target.name!== "addressLine2"&&e.target.name!== "country") {setStyle({ ...style, [e.target.name]: data[e.target.name]!==""&&e.target.value===""?"box-shadow border border-danger":"" })};
  };
  const handleAutoNick = () => {
      setData({
      ...data,
      nickname:
       `${data.firstName.charAt(0).toUpperCase()}${data.firstName.slice(1)} ${data.lastName.charAt(0).toUpperCase()}.`
    });
  };

  const [data, setData] = useState(initialState);
const errorStyle ="box-shadow border border-danger"
  const handleSubmit = e => {
    e.preventDefault();
    if(data.nickname==""){
      handleAutoNick()
    }
    //console.log(data);
    //console.log(registrationType);
    if (
      registrationType === "Co-Member" ||
      data.repeat === data.password
    ) {
      console.log("password is match");
      const role =
        registrationType === "Facilitator" ? "ROLE_FACILITATOR" : "ROLE_MEMBER";

      let variables = {
        firstName: data.firstName,
        lastName: data.lastName,
        nickname: data.nickname,
        phone: data.phone==""?null:data.phone,
        
        role: role,
        country: data. country,
    postalcode: data.postalcode,
    ageGroup:data.ageGroup,
    living:data.living
      };

      if (registrationType === "Co-Member") {
        Object.assign(variables, {
          email: state.user.email,
          status: true,
          validUntil: moment(state.user.validUntil)
          .add(60, "years")
          .toISOString(),
          parentUser: state.user.id,
        });
        //console.log(variables);
      } else {
        Object.assign(variables, {
          email: data.email,
          password: data.password,
          status: true,
          validUntil: moment()
            .add(1, "years")
            .toISOString(),
        });
        console.log(moment()
        .add(1, "years")
        .toISOString())
      }

      addUser({
        variables: variables,
      })
        .then(async res => {
          if (res.errors) {
            if (res.errors[0].message === "Invalid arguments") {
              toast.error(
                `Some boxes were left empty please chesk for boxes that turned red and fill them.`
              );

              setStyle({
                firstName: data.firstName=== ""? errorStyle:"",
                lastName: data.lastName=== ""? errorStyle:"",
                email: data.email=== ""? errorStyle:"",
                phone: data.phone=== ""? errorStyle:"",

                password: data.password=== ""? errorStyle:"",
                repeat: data.repeat=== ""? errorStyle:"",

            })

            } else {
              toast.error(`Oops, ${res.errors[0].message}`);
            }
            console.log("res.errors[0].message", res.errors[0].message);
          } else {
            try {
              const loginResponse = await login({ variables: {email: data.email, password: data.password } });
              //console.log(loginResponse);
              if (loginResponse && loginResponse.data.login.token) {
                dispatch({
                  type: SET_ACCESS_TOKEN,
                  payload: { accessToken: loginResponse.data.login.token },
                });
                setAccessToken(loginResponse.data.login.token);
                history.push("/");
              }
            } catch (err) {
              console.log(err);
            }
            setData(initialState);
            toast.success("User registered successfully");
            history.push("/")
          }
        })
        .catch(err => {
          toast.error("Oops, something went wrong");
          console.log("ERR", err);
        });
    } else {
      console.log("Password not match");
      toast.error("Passwords do not match");
      setStyle({...style, password: errorStyle,
        repeat: errorStyle})
              
    }
  };
console.log(data)
  const RegistrationTab = ({ type }) => {
    return (
      <button
        className={`btn btn-outline-info px-3 w-100 rounded-0 ${
          registrationType === type ? "active" : ""
        }`}
        onClick={() => setRegistrationType(type)}
      >
        {type}
      </button>
    );
  };

  return (
    <div className="container px-0 px-md-5">
      <h3 className="mt-5 mb-4 text-center">Register {registrationType}</h3>

      <div className="d-block d-md-flex justify-content-center my-3 px-3">

         

        
      </div>

      <form>
        <div className="row mx-auto mt-4">
          {Object.keys(initialState).map((field, i) => {
            if (
              registrationType === "Co-Member" &&
              (field === "password" || field === "repeat" || field === "email")
            ) {
              //console.log("no?");
              return <div key={i} />; // Co-Member does not require password field to register. they will be registered under the current facilitator account
            }

            return (
              <InputForm
                key={i}
                handleOnChange={handleOnChange}
                handleAutoNick={handleAutoNick}
                data={data}
                styleValue={style}
                field={field}
              />
            );
          })}
        </div>

        <div className="d-flex my-3">
          <button onClick={handleSubmit} className="btn btn-info w-100 mx-3">
            Register
            <span className="ml-2">
              <FaRegHandPointDown />
            </span>
          </button>
        </div>

        {!state.user && (
          <p className="text-center my-2">
            Have an account? <Link to="/login">Log In</Link>
          </p>
        )}
      </form>
    </div>
  );
}
