import React, { useEffect, useState, useContext } from "react";
import { FiUserMinus, FiUserPlus } from "react-icons/fi";
import SessionCardMedium from "../../Session/SessionCardMedium";
import PersonCard from "./PersonCard";
import CommentBox from "../../CommentBox";
import GenericModal from "../../GenericModal";
import RemoveUserFromSession from "./RemoveUserFromSession";
import { UserContext } from "../../../context/UserContext";
import SyncEmbeddedMusicController from "../SyncEmbeddedMusicController";
import { useStartSessionMutation, useAddQuestionnaireMutation, useCheckQuestionnaireExistsQuery } from "../../../graphql/generated/graphql";
import HighlightedThemes from "./../../Session/HighlightedThemes";
import { toast } from "react-toastify";
import QuestionModal from "../../Question/QuestionModal";
import moment from "moment"
import { MdClose } from "react-icons/md";
import { WiSunrise } from "react-icons/wi";
import { HiThumbUp } from "react-icons/hi";
import {
  FaRegHandshake,
  FaMapPin,
  FaQuoteRight,
  FaBookmark,
  FaWind,
  FaChalkboardTeacher,
  FaHandHoldingHeart,
} from "react-icons/fa";
import {
  BsMusicNote,
  BsPeopleFill,
} from "react-icons/bs";
import { AiOutlineCloseSquare, AiOutlinePlaySquare } from "react-icons/ai";
import { RiSurroundSoundLine } from "react-icons/ri";
import Role from "../../Manage/Role";
import AddRole from "./AddRole";

export default function PreparationContainer(props) {
  const {
    handleGoToPage,
    session,
    handleMusicPlay,
    modalSync,
    isSessionMaster,
    isSyncOn,
    themesList,
    status,
    sessionMasterId,
    sessionMembers,
    sessionTriggered,
    setIsSilent,
    sessionFacilitators,
    handleContextAnswers,
    answers,
    isReflections
  } = props;
  console.log("REFLECTIONS? ", isReflections);
  const makeSeed = () => {
    let seedArray = session.id
      .split("")
      .map(x => (isNaN(x) ? x.charCodeAt(0) : parseInt(x)));
    let newSeed = 0;
    seedArray.map(num => {
      newSeed += num;
    });
    return newSeed % 51;
  };
  const [seed, setSeed] = useState(makeSeed());

  const { state } = useContext(UserContext);
  const [themeModalIsOpen, setThemeModalIsOpen] = useState(false, []);
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false, []);
  const [todModal, setTodModal] = useState(false, []);
  const [roleModal, setRoleModal] = useState(false, []);

  const [isFacilitator, setIsFacilitator] = useState(true, []);

  // used to collect id of users to be deleted. store user's first/last Name and id.
  const [deleteFacilitators, setDeleteFacilitators] = useState([]);
  const [deleteMembers, setDeleteMembers] = useState([]);
  const [showCards, setShowCards] = useState(true);

  // for delete member checkbox
  const handleUserId = (id, name, type, value) => {
    //console.log(value)
    let doesIdExist;
    
    // check the user is already selected in order to determine if the user needs to be added or taken out

    if (value == "true") {
      // when the checkbox is unchecked
      //console.log("value was found true")
     // console.log("type")
      if (type === "facilitator") {
        let temp = [...deleteFacilitators.filter(facilitator => facilitator.id !== id)]
        setDeleteFacilitators([...temp])
        
      } else {
        let temp = [...deleteMembers.filter(facilitator => facilitator.id !== id)]
        setDeleteMembers([...temp]);
      }
    } else if (value == "false") {
      //console.log("value was found true")
      // when the checkbox is checked
      if (type === "facilitator") {
        let temp = [...deleteFacilitators.filter(facilitator => facilitator.id !== id)]
        temp.push({ id, name })
        setDeleteFacilitators([...temp])

        //console.log(deleteFacilitators)
      } else {
        let temp = [...deleteMembers.filter(member => member.id !== id)]
        temp.push({ id, name })
        setDeleteMembers([...temp]);
      }
    }
  };
//console.log(deleteFacilitators)
  const [songLocation, setSongLocation] = useState("");
  useEffect(() => {
    if (!isSessionMaster) {

      if (modalSync.contentType !== "" && modalSync.contentType !== " " && modalSync.contentType !== "silence") {
        if(modalSync.contentType=="Preparation song"){
          // console.log("silent: "+modalSync.contentType)
          setIsSilent(true)
          setSongLocation("/assets/songs/MEDITATION.mp3");
        }
        else if(modalSync.contentType == "open modal tod"){
          // console.log("content type: "+modalSync.contentType)
          setIsSilent(false)
          setRoleModal(false)
          setTodModal(true)
        }
        else if(modalSync.contentType == "close modal tod"){
          // console.log("content type: "+modalSync.contentType)
          setIsSilent(false)
          setTodModal(false)
        }
        else if(modalSync.contentType == "open modal role"){
          // console.log("content type: "+modalSync.contentType)
          setIsSilent(false)
          setTodModal(false)
          setRoleModal(true)
        }
        else if(modalSync.contentType == "close modal role"){
          // console.log("content type: "+modalSync.contentType)
          setIsSilent(false)
          setRoleModal(false)
        }
      } else {
        // console.log("content type else: "+modalSync.contentType)
        setIsSilent(false)
        setSongLocation("");
      }
    }
  }, [modalSync.contentType]);
  useEffect(() => {
    if (isSessionMaster) {
      
      if (showCards == true) {
        // console.log("ping")
        handleMusicPlay(true, "close modal")
      }
    }
  }, [showCards]);
  
  const [memberCards,setMemberCards] = useState()
  const [facilitatorCards,setFacilitatorCards] = useState()
  
  
  useEffect(() => {
    if(session && session.group){
      // console.log(session.group.usedThemes)
      let tmp = []
      session.group.facilitators.map(facilitator => {
        //console.log(session.group.facilitators)
        
        tmp.push(
          <PersonCard
          isSessionMaster={isSessionMaster}
          type={"facilitator"}
          person={facilitator}
          key={facilitator.id}
          startVal={false}
          image={facilitator.profilePicUrl}
          inSession={status === "joinable"?sessionFacilitators.filter(fac => fac.id === facilitator.id).length>0:true}
          sessionId={session.id}
          sessionState={session.followSessionData}
          handleUserId={handleUserId}
          sessionMasterId={sessionMasterId}
          />)
        })
        
        
        if(sessionFacilitators){
          // console.log(sessionFacilitators)
          
      sessionFacilitators.map(facilitator => {
        if(!session.group.facilitators.filter(fac => fac.id === facilitator.id).length>0) {
          tmp.push(<PersonCard
          isSessionMaster={isSessionMaster}
          type={"facilitator"}
          person={facilitator}
          key={facilitator.id}
          image={facilitator.profilePicUrl}
          startVal={false}
          inSession={true}
          sessionId={session.id}
          sessionState={session.followSessionData}
          handleUserId={handleUserId}
          sessionMasterId={sessionMasterId}
        />)
      }})


    }
    tmp.sort(function compareNumbers(a, b) {
    
      var nameA = a.props.person.user?a.props.person.user.firstName.toUpperCase():a.props.person.firstName.toUpperCase();
      var nameB = b.props.person.user?b.props.person.user.firstName.toUpperCase():b.props.person.firstName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
  
      return 0;
    })
    setFacilitatorCards(tmp)
    }
    


    return () => {
      setFacilitatorCards()
    }
  }, [session, session.group, sessionFacilitators])
  useEffect(() => {
    if(session && session.group && session.sessionMembers){
      //console.log("we here?")
      let tmp = []
      session.group.members.map(obj => {
      if(!session.sessionMembers.filter(mem => mem.user.id === obj.id).length>0){

       tmp.push(

         <PersonCard
           isSessionMaster={isSessionMaster}
           type={"member"}
           key={obj.id}
           person={obj}
           startVal={false}
           inSession={false}
           image={obj.profilePicUrl}
           sessionRole={obj.sessionRole}
           handleUserId={handleUserId}
           sessionId={session.id}
         />
       )
      }
      })
session.sessionMembers.map(obj => {
// console.log(obj)
tmp.push(<PersonCard
  isSessionMaster={isSessionMaster}
  type={"member"}
  key={obj.user.id}
  startVal={false}
  person={{...obj.user,...obj, nickname:obj.user.nickname}}
  inSession={true}
  image={obj.user.profilePicUrl}
  isReflections={isReflections}
  getRoleDict={getRoleDict(allRoles,session.sessionMembers)}
  sessionRole={obj.sessionRole}
  handleUserId={handleUserId}
  sessionId={session.id}
/>)
}
      
    )
  tmp.sort(function compareNumbers(a, b) {
    
    var nameA = a.props.person.user?a.props.person.user.firstName.toUpperCase():a.props.person.firstName.toUpperCase();
    var nameB = b.props.person.user?b.props.person.user.firstName.toUpperCase():b.props.person.firstName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  })
 setMemberCards(tmp)
  
  }
    return () => {
      //console.log("no no perro afuera")
      setMemberCards()
    }
  }, [session, session.group, session.sessionMembers])


  const allRoles =  isReflections==true? [
   
  
    { name: "Read Serenity Saying", icon: <WiSunrise className="d-flex" /> },
  { name: "Read Guidelines", icon: <FaMapPin className="d-flex" /> },
  { name: "Choose Reflections Topic", icon: <FaBookmark className="d-flex" /> },
  { name: "Read Group Affirmation", icon: <HiThumbUp className="d-flex" /> },
  { name: "Read Reflection", icon: <FaChalkboardTeacher className="d-flex" /> },
  { name: "Share Gratitude", icon: <FaHandHoldingHeart className="d-flex" /> },
]:[
    { name: "Read welcome", icon: <FaRegHandshake className="d-flex" /> },
    { name: "Read guidelines", icon: <FaMapPin className="d-flex" /> },
    { name: "Choose theme", icon: <BsPeopleFill className="d-flex" /> },
    { name: "Read Getting Centered", icon: <BsMusicNote className="d-flex" /> },
    { name: "Read quote", icon: <FaQuoteRight className="d-flex" /> },
    { name: "Choose song", icon: <FaBookmark className="d-flex" /> },
    
  ];

const [rolesServed, setRolesServed] = useState([])
const getRoleDict = (full, partial) =>{
  let filtered = []
  partial.map(member=>{
    if(member.sessionRole !== "" && member.sessionRole !== undefined && member.sessionRole !== null){
      filtered.push(member.sessionRole)
    }
  })
  const dict = {};
  for(let i = 0; i< full.length; i++){
    dict[full[i].name] = filtered.indexOf(full[i].name) !== -1;
}
return dict;
}


  useEffect(() => {
    if(session  && session.sessionMembers){
      let tmp = []
      let roleFlags = getRoleDict(allRoles,session.sessionMembers)
      // console.log(roleFlags)
      allRoles.map((role, i) => {
        
        let inUse = roleFlags[role.name]
        // console.log(inUse)
         
      tmp.push(
          <div key={i} className={` col col-5 py-2 ${ inUse !== false ? "d-none" : "" }  `}>
            <div
             
              className={`border useBorder box-shadow round-box font-size-1 px-2 py-3 d-flex align-items-center  ${ inUse !== false ? "bg-black" : "bg-cleard" } `}
            >
              <span className="ml-2 mr-3 font-size-2">{role.icon}</span>
              <p className="mb-0">{role.name}</p>
            </div>
          </div>
        );
      })
      setRolesServed([...tmp])
      // console.log(rolesServed)
  }

 
    return () => {

    }
  }, [session, session.sessionMembers])
const handleShowCards=(flag)=>{
setShowCards(flag)

}
  const [startSession] = useStartSessionMutation();
  const [addQuestionnaire] = useAddQuestionnaireMutation();
  const {data:checkQuestionnaireExists} = useCheckQuestionnaireExistsQuery({
    variables: {
      member: state.user.id,
      session:session.id,
      stage:"pre"
    },
    skip: state.user.role === "ROLE_FACILITATOR"

  });

  const handleSessionStart = () => {
    if (status !== "joinable") {
      startSession({
        variables: {
          session: session.id,
          status: "joinable",
          sessionMaster: state.user.id,
        },
      })
        .then(res => {
          if (res.errors) {
            if (res.errors[0].extensions.code === "FORBIDDEN") {
              toast.error(
                "You are not allowed to start this session since you are not part of the group that this session is associated with",
                { autoClose: 6000 }
              );
            }
          } else {
            toast.success("Session started!");
            sessionTriggered();
          }
        })
        .catch(err => {
          // toast.error("Oops, something went wrong");
          console.log(err);
        });
    } else {
      toast.error("Session already started");
    }
  };
  const [facilitatorModalIsOpen, setFacilitatorModalIsOpen] = useState(false);
  const [memberModalIsOpen, setMemberModalIsOpen] = useState(false, []);
  // useEffect(() => {
  
  //   if(state.user.role!== "ROLE_FACILITATOR"){
  //   if(checkQuestionnaireExists === false){
  //     //setpre(questionnaireUserSession)
  //     console.log(checkQuestionnaireExists)
  //     console.log("checkQuestionnaireExists")
  //   }
  //   else{
  //     //make questionnaire; setpre(res.data.q.id)
  //     console.log(checkQuestionnaireExists)
  //     console.log("NO checkQuestionnaireExists")
    
  //     addQuestionnaire({
  //       variables: {
  //     member: state.user.id,
  //     session:session.id,
  //     stage:"pre",
  //     isDone: false,
  //     optOut:false

  //   }, 
  //   })
    
     
    
  // }  
  //   }
  // }, [checkQuestionnaireExists])
  const handleAnswersSubmit = answers => {
    handleContextAnswers(answers);
   
    //give this to the great MANGO GODS
  };
const checkTimeBeforeStart = ()=>{
  var now = moment(new Date()); //todays date
  var start = moment(new Date(session.date)) // another date
  var duration = moment.duration(start.diff(now));
  var minutes = duration.asMinutes();
  // console.log(now)
  // console.log(start)
  // console.log(duration)
  if(minutes > 15){
    setWarningModalIsOpen(true)
  }else{
    handleSessionStart()
  }                   
}
  return (
    <div className="preparation-container mb-5">
      <div className="row">
         
        <main className="main-panel">

          
          
 <div className="border  useBorder box-shadow round-box my-4">
           <SessionCardMedium session={session} hideCTB={true} renderVertically={false} />
          </div> 
          
    
          {state.user.role === "ROLE_FACILITATOR" && (
            <section className="mb-3">
</section> )
}
          <div>
            <h5 className="mx-3">Background Music</h5>
            <div className="mb-3 d-flex justify-content-center row ">
              <div className={" col col-11 col-xl-8 pr-4"}>
                <SyncEmbeddedMusicController
                  musicUrl={"/assets/songs/MEDITATION.mp3"}
                  handleMusicPlay={handleMusicPlay}
                  contentType={"Preparation song"}
                  songLocation={songLocation}
                  isSyncOn={isSyncOn}
                  />
              </div>
            </div>
          </div>
          {state.user.role === "ROLE_MEMBER" && (
            <div  className={`person-card col-12 my-3`}>
              {session.sessionMembers
                .filter(mem => mem.user.id == state.user.id)
                .map(mem => (
                  <div key={mem.user.id} >
                    <div
                      className={`round-box border ${
                        mem.sessionRole == "None" ? "" : ""
                      } box-shadow ${mem.sessionRole?"border-success":"row useBorder"} justify-content-center align-items-center`}
                      >
                     
                         { mem.sessionRole
                              ?( <div
                        className={`row  justify-content-center align-items-center`}
                        >
                        <div
                          className={
                            " col col-8  justify-content-center align-self-center"
                          }
                          >
                              <h5 className={"text-center"}>Your Role:</h5>
                          <h5 className={"text-center"}>
                            {mem.sessionRole
                              ? mem.sessionRole == "None"
                              ? "You have not received a role yet"
                              : mem.sessionRole
                              : ""}
                          </h5></div>
                      </div>
                          ):<>
                          <h5 className={"col col-12 pt-1 text-center"}>Available Roles:</h5>
                          {rolesServed}
                          </>}
                        
                    </div>
                  </div>
                ))}
                      <GenericModal
        modalIsOpen={todModal}
        setModalIsOpen={setTodModal}
      >
              <HighlightedThemes displayType={"mem"} session={session} isReflections={isReflections} themesList={themesList}  seed={seed} />
 </GenericModal>
                      <GenericModal
                     
        modalIsOpen={roleModal}
        setModalIsOpen={setRoleModal}
      >
              <div className={`
border useBorder box-shadow`} >
              {session.sessionMembers
                .filter(mem => mem.user.id == state.user.id)
                .map(mem => (
                  <div key={mem.user.id} className={``}>
                    <div
                      className={`round-box col  p-0 m-0 w-100  ${
                        mem.sessionRole == "None" ? "" : ""
                      }  ${mem.sessionRole?"":"row "} justify-content-center align-items-center`}
                      >
                     
                         { mem.sessionRole
                              ?( <div
                        className={`row m-2 justify-content-center align-items-center`}
                        >
                        <div
                          className={
                            " col col-12  justify-content-center align-self-center"
                          }
                          >
                              <h5 className={"text-center"}>Your Role:</h5>
                          <h5 className={"text-center"}>
                            {mem.sessionRole
                              ? mem.sessionRole == "None"
                              ? "You have not received a role yet"
                              : mem.sessionRole
                              : ""}
                          </h5></div>
                      </div>
                          ):<div className={"col pt-1 text-center"}>
                          <h5 className={"col pt-1 text-center"}>Available Roles:</h5>
                          <div className={" row d-flex justify-content-center"}>

                          {rolesServed}
                          </div>
                          </div>}
                        
                    </div>
                  </div>
                ))}
              </div>
 </GenericModal>

              { answers && !answers.isDone && !answers.optOut && (
                <QuestionModal
                handleSubmit={handleAnswersSubmit}
                stage={"pre"}
                ></QuestionModal>
                )}
            </div>
          )}
                     {state.user.role === "ROLE_FACILITATOR" && <div className="text-center mb-4 btn-group col px-0 col-12 px-3 mt-3">


<button
  disabled={status === "joinable"}
  className="btn btn-success  w-100"
  onClick={()=>{
    checkTimeBeforeStart()              
  }}
>
  {status === "joinable"
    ? " Session already started"
    : status === "done"
    ? "1. Restart session"
    : "1. Session start"}
</button>

         <button className={`btn btn-info ${!showCards?"btn-outline-info disabled bg-cleard text-white ":""} text-white col-6`} onClick={()=>{
           handleShowCards(true)
           handleMusicPlay(true, "close modal tod")
          }}>2. Roles & Attendance</button>
         <button className={`btn btn-info ${showCards?"btn-outline-info  disabled bg-cleard text-white ":""}  text-white col-6`} onClick={()=>{
           handleShowCards(false)
           handleMusicPlay(true, "open modal tod")
          }}>3. Choose Theme</button>
         </div>}
   
          {state.user.role === "ROLE_FACILITATOR" && (
            <div className={`col ${showCards?"d-none":""}`}>

              <HighlightedThemes displayType={"fac"} session={session} isReflections={isReflections} themesList={themesList} seed={seed} />
            </div>
            
            )}
            {state.user.role === "ROLE_FACILITATOR" && (
             <>
            <div className={`col ${!showCards?"d-none":""} position-relative py-3 my-4 round-box box-shadow bored useBorder`}>
                    
                           <div className="row d-flex justify-content-center"><h4 className="mx-3">Attendance & Roles</h4></div>
           
                         <section className="mb-3 ">
                           <h5 className="mx-3">Facilitators</h5>
                           <div className="row mx-auto">
                           {session&& facilitatorCards &&
                           facilitatorCards
                           }
                           </div>
                          
                           <div className="add-remove-buttons-container d-flex justify-content-center row mx-3">
                             <button
                               onClick={() => setFacilitatorModalIsOpen(true)}
                               className="mx-1 col-5 btn btn-outline-success bg-cleard d-flex align-items-center"
                             >
                               <FiUserPlus
                                 style={{ fontSize: "1.25em" }}
                                 className="d-flex ml-auto mr-3"
                               />
                               <span className="mr-auto">Add facilitator</span>
                             </button>
                             <button
                               onClick={() => {
                                 setThemeModalIsOpen(true);
                                 setIsFacilitator(true);
                               }}
                               className="mx-1 col-5 btn btn-outline-danger bg-cleard d-flex align-items-center"
                             >
                               <FiUserMinus
                                 style={{ fontSize: "1.25em" }}
                                 className="d-flex ml-auto mr-3"
                               />
                               <span className="mr-auto">Remove facilitator</span>
                             </button>
                           </div>
                         </section>
                         <section className="mb-3">
                           <h5 className="mx-3">Members</h5>
                           <div className="row mx-auto">
                             {  session && memberCards &&
                             memberCards
                             }
                           </div>
                         </section>
           
                         <div className="add-remove-buttons-container d-flex justify-content-center mx-3">
                         <button
                               onClick={() => setMemberModalIsOpen(true)}
                               className="mx-1 col-5 btn btn-outline-success bg-cleard d-flex align-items-center"
                             >
                               <FiUserPlus
                                 style={{ fontSize: "1.25em" }}
                                 className="d-flex ml-auto mr-3"
                               />
                               <span className="mr-auto">Add member</span>
                             </button>
                           <button
                             onClick={() => {
                               setThemeModalIsOpen(true);
                               setIsFacilitator(false);
                             }}
                             className="mx-1 col-5 btn btn-outline-danger bg-cleard d-flex align-items-center"
                           >
                             <FiUserMinus
                               style={{ fontSize: "1.25em" }}
                               className="d-flex ml-auto mr-3"
                             />
                             <span className="mr-auto">Remove member</span>
                           </button>
                         </div>
                       
                        </div>
                       </>
                     )}
        </main>


       {state.user.role === "ROLE_FACILITATOR" && ( 
      
        <div className="row mx-auto my-5 d-flex w-100">
          <button
            className="btn btn-primary py-1 px-5 ml-auto mr-5 font-size-2 shadow"
            onClick={() => handleGoToPage.next()}
          >
            Next
          </button>
        </div>
      )}
      </div>



 {session&& session.group && <GenericModal
                  modalIsOpen={facilitatorModalIsOpen}
                  setModalIsOpen={setFacilitatorModalIsOpen}
                  >
                  <AddRole
                  sessId={session.id}
                    isFacilitator={true}
                    groupInfo={session.group}
                    setModalIsOpen={setFacilitatorModalIsOpen}
                  />
                </GenericModal>}
 
 {session&& session.group &&<GenericModal
                  modalIsOpen={memberModalIsOpen}
                  setModalIsOpen={setMemberModalIsOpen}
                >
                  <AddRole
                  sessId={session.id}
                    isFacilitator={false}
                    groupInfo={session.group}
                    setModalIsOpen={setMemberModalIsOpen}
                  />
                </GenericModal>
                }
      {/*  Modal */}
      <GenericModal
        modalIsOpen={themeModalIsOpen}
        setModalIsOpen={setThemeModalIsOpen}
      >
        <RemoveUserFromSession
          deleteMembers={deleteMembers}
          deleteFacilitators={deleteFacilitators}
          setDeleteMembers={setDeleteMembers}
          setDeleteFacilitators={setDeleteFacilitators}
          session={session}
          setModalIsOpen={setThemeModalIsOpen}
          isFacilitator={isFacilitator}
        />
      </GenericModal>
      <GenericModal
      style={{width:"100px !important"}}
      className={"col-6 w-25"}
      addClass={" bg-clear gray-filled"}
        modalIsOpen={warningModalIsOpen}
        setModalIsOpen={setWarningModalIsOpen}
      >
          <div className="round-box  box-shadow align-self-center p-5">
            
           
              <h5>
                This session is schedule to start at {moment(session.date).format("HH:mm A on MMM D")}. 
              </h5>
              <h5>
              Are you sure you want to start it early? 
              </h5>
         
         
            <div className={"row mt-4"}>
            <div className={"col d-flex justify-content-center"}>
              <button onClick={()=>{
                handleSessionStart()
                setWarningModalIsOpen(false)
                }} className={"btn btn-success w-25 mx-3 box-shadow "}>
                Yes
              </button>
              <button onClick={()=>setWarningModalIsOpen(false)} className={"btn btn-danger  w-25 mx-3 box-shadow"}>
                No
              </button>
            </div>
           
            </div>
          </div>
      </GenericModal>
      {/*  Modal */}
    </div>
  );
}
