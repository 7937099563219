import React , {  useContext } from "react";
import { useHistory } from "react-router-dom";
import { BsCalendar, BsHouseDoor, BsPeopleFill, BsTag } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import { FcInspection } from "react-icons/fc";
import moment from "moment";
import { toast } from "react-toastify";
import { useRequestInviteMutation, PublicGroupsDocument  } from "../../graphql/generated/graphql.tsx";
import {UserContext} from "../../context/UserContext";


export default function GroupSummaryCard(props) {
  const { group, clickableCard,requestable } = props;
 
  const history = useHistory();
  const { state } = useContext(UserContext);
  const examineMembership = ()=>{
    if([...group.facilitators,...group.members].filter(mem=> mem.id == state.user.id).length >0){
      return "member"
    }else if(group.inviteRequests.filter(req => req.id == state.user.id).length > 0){
      return "pending"
    }
    else{
      return "nothing"
    }
  }
  const groupMembershipState = examineMembership()
  const [requestInvite] = useRequestInviteMutation({})
  const onCardClick = () => {
    if (clickableCard&&!requestable) {
      history.push({ pathname: `/group/${group.id}` });
      // history.push({ pathname: `/group/${group.id}`, state: group });
    }
  };
  console.log(group)
  const handleRequest = (e) => {
    e.stopPropagation()
    if (requestable) {
      requestInvite({
        variables: {
          user: state.user.id ,
          group: group.id,
        },
        refetchQueries: [{ query: PublicGroupsDocument }],
      }).then(res=>{
        if(res.errors){
          toast.error(`${res.errors[0].message}`, {toastId: group.id})
        }else{

          toast.success(`Your request to join ${group.name} was sent.`, {toastId: group.id})
        }
      })

    }
  };

  return (
    <div className={`${requestable?"":"p-3  col-10  col-md-6 col-lg-4 "}`}>
      <div
        className={
          "border box-shadow bg-clear  round-box px-4 pt-1 pb-2" 
         
          +(clickableCard&&!requestable ? " bg-clearWithHov" : "")
          //  +(clickableCard ? " clickable-card" : "")
        }
        role={clickableCard &&!requestable ? "button":""}
        onClick={onCardClick}
      >
        <h5 className="my-3">{group.name} </h5>

        <div className="icon-text my-3">
          <span>
            <BsCalendar />
          </span>
          <span>Next Session: {moment(group.startDate).format("MMM Do")}</span>
        </div>

        <div className="icon-text my-3">
          <span>
            <AiOutlineReload />
          </span>
          <span>{group.frequency}</span>
        </div>

        <div className="icon-text my-3">
          <span>
            <BsHouseDoor />
          </span>
          <span>{group.room}</span>
        </div>
        <div className="icon-text my-3">
          <span>
            <BsPeopleFill />
          </span>
          <span>
            {group.members.length} Member{group.members.length === 1 ? "" : "s"}
          </span>
        </div>
         {group.tags.length > 0 && 
        <div className="icon-text-tag   my-3">
          <span>
            <BsTag />
          </span>
          <span  className="row ml-1">
            
            {group.tags.map((tag, i)=>(
            <div  key={i}  className="badge badge-info mr-1 my-1 shadow d-flex align-items-center "> 
           
             {tag}  
            
            </div>
            ))}
            
          </span>
        </div>
          }
          {groupMembershipState !== "member" && state.user.role != "ROLE_FACILITATOR" &&
           <div role="button" onClick={(e)=>{
             if(groupMembershipState=="nothing")
             {
               handleRequest(e)
              }else{
                e.stopPropagation()

              }           
            }} 
             disabled={groupMembershipState == "member"} 
             className={`btn ${groupMembershipState=="pending"?"": " btn-outline-success "} border col-6 shadow offset-3 rounded border-success bg-cleard d-flex justify-content-center align-items-center`}>
            <span className="d-flex mx-1 align-items-center">
              <FcInspection/> 
            </span>
            {groupMembershipState=="pending"?"Request Sent": " Join Request "}
            
          </div> 
          }
      </div>
    </div>
  );
}
