import React from "react";
import { MdPerson } from "react-icons/md";
import moment from "moment";

export default function PersonNotesComment(props) {
  const { author, content, date } = props;

  return (
    <div
      style={{ borderLeft: "2px solid black" }}
      className="border round-box p-2 mb-3"
    >
      <div className="d-flex align-items-center mb-3">
        <MdPerson className="d-flex text-primary font-size-5" />
        <span className="ml-1 mr-auto text-primary">
          {author.firstName} {author.lastName}
        </span>
        <span className="mr-1 text-muted">
          {moment(date).format("MMMM Do YYYY")}
        </span>
      </div>
      <p className="w-100">{content}</p>
    </div>
  );
}
