import React, { useRef,useState,useEffect} from 'react'

import {
    useThemeQuery,
    ThemeDocument,
  } from "./../../graphql/generated/graphql";
  import LoadingSpinner from './../LoadingSpinner/index'
  import MediaPlayer from './MediaPlayer'
import SongPlayer from './SongPlayer';

function PlaygroundContainer() {
    const themeId = "5ff79d0f19322d4590b5c975"; 
    const [songs, setsongs] = useState("")
    const [refList,setRefList] = useState("")
    const { data, loading, error } = useThemeQuery({
        variables: {
            id: themeId,
        },
    });
    useEffect(() => {
        if(!loading){
            setsongs( data.theme.songs)
        }
    }, [data])
    const audioRef = useRef(null);

      if(loading || songs == ""){
          return (
              <div>
                  <LoadingSpinner/>
              </div>
          )
      }const handleMutings = (targetAudio)=>{
            console.log( targetAudio.volume<=0.1?targetAudio.volume=0:targetAudio.volume-=0.1)

        }
    return (
        <div>
            {/* {songs.map((song, i) => (  */}
                <div className={"col col-4"}>

                    <SongPlayer audioRef={audioRef} song={songs[0]} />
                </div>

                {/* )) } */}
                {/* //<MediaPlayer  key={song.id} song={song}/>  */}
        
        </div>
    )
}

export default PlaygroundContainer
