import React, { useState, useEffect } from "react";
import matchSorter from "match-sorter";
import SearchBar from "../ThemeLibrary/SearchBar";
import FullscreenThemeList from "../ThemeLibrary/FullscreenThemeList";
import ThemeCard from "../ThemeLibrary/ThemeCard";
import { useThemesQuery } from "../../graphql/generated/graphql";

// This is supposed to be used as the content of a modal
export default function ThemePicker(props) {
  const { handleThemeSelection, themes, isReflections } = props;

  const [searchText, setSearchText] = useState("", []);

  // Fetch themes from DB
  //const [themes, setThemes] = useState([], []);

  const { data, loading, error } = useThemesQuery();

  const handleOnChange = e => {
    setSearchText(e.target.value);
  };

  const getThemesFromCategory = category => {
    return themes.filter(theme => theme.category === category);
  };
  const [themesByProg, setThemesByProg] = useState([])
  useEffect(() => {
    if (themes) {
      console.log(themes)
      if (isReflections === true) setThemesByProg(themes.filter(t => t.program == "reflections"))
      else {
        setThemesByProg(themes.filter(t => t.program != "reflections"))
      }
    }
  }, [themes])

  // Match sorter
  const filterBySearchText = () => {
    if (isReflections == false) return matchSorter(themesByProg, searchText, { keys: ["name", "category"] });
    else return matchSorter(themesByProg, searchText, { keys: ["name", "category"] })
  }

  // useEffect(() => {
  //   if (!loading) {
  //     setThemes(data.themes);
  //   }
  // }, [loading]);

  // if (loading) {
  //   return <h1>Loading</h1>;
  // }

  return (
    <div className="p-3 overflow-hidden" >

      <header className={"px-3"}>
        <h4 className={"px-1"}>Select a theme</h4>
        <SearchBar handleOnChange={handleOnChange} searchText={searchText} />
      </header>
      <div className={"overflow-auto"} style={{ width: "700px", height: "500px" }}>
        <main className="container">
          <div className="row d-flex justify-content-around">
            {filterBySearchText(themesByProg).map(theme => (
              <ThemeCard
                handleThemeSelection={handleThemeSelection}
                selectableTheme={true}
                theme={theme}
                key={theme.id}
              />
            ))}
          </div>
        </main>
      </div>
    </div>
  );
}
