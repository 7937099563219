import React from "react";
import ThemeCard from "./ThemeCard";

export default function FullscreenThemeList(props) {
  const { themes, title } = props;

  return (
    <section className="my-3">
      <h5 className="mx-3 my-3">{title}</h5>
      <div className="d-flex flex-row flex-wrap m-3 justify-content-around">
        {themes &&
          themes.map(theme => (
            <ThemeCard key={theme.id} theme={theme}></ThemeCard>
          ))}
      </div>
    </section>
  );
}
