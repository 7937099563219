import React, { useState,useEffect } from "react";
import ResourceCard from "./ResourceCard";
import { useResourcesQuery } from "../../graphql/generated/graphql.tsx";
import GenericModal from "../GenericModal";

export default function ResourceContainer() {
  const { loading, error, data } = useResourcesQuery();
  const [selectedResource, setSelectedResource] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [resources, setResources] = useState(
    [
      {
        id: 5,
        category: "r",
        dateAdded: "August 1, 2008",
        thumb: "https://picsum.photos/200/200",
        text: ["No resources available yet."],
        isPH: true
      },
    ],
    []
  );
  const handleOnClick=(resource)=>{
setSelectedResource(resource)
setIsModalOpen(true)
  }
    useEffect(() => {
      if(data){
        setResources(data.resources)
      }
    }, [data])

  return (
    <div>
      <div className={"text-light "}>
      <h4 className="mb-3">Resources</h4>
        {data && data.resources && data.resources.map(resource => (
          <ResourceCard key={resource.id} handleOnClick={handleOnClick} resource={resource} isPH={resource.isPH}/>
        ))}
      </div>
      <GenericModal
        modalIsOpen={isModalOpen}
        setModalIsOpen={setIsModalOpen}
      ><div style={{maxHeight: "86vh"}} className={`p-4 m-2 mt-4 `}>
        <ResourceCard resource={selectedResource} bigVersion={true} ></ResourceCard>

      </div>
      </GenericModal>
    </div>
  );
}
