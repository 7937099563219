import React from "react";
import ResourceCard from "./ResourceCard";

export default function ResourceList(props) {
  const { resources } = props;

  return (
    <div className={"text-light "}>
      {resources &&
        resources.map(resource => (
          <div key={resource.id}>
            <ResourceCard bigVersion={true} resource={resource} />
          </div>
        ))}
    </div>
  );
}
