import React, { useState, useContext } from "react";
import PersonNotesComment from "./PersonNotesComment";
import { UserContext } from "../../../context/UserContext";

import {
  useAddNoteForUserMutation,
  UserDocument,
} from "../../../graphql/generated/graphql";

export default function PersonNotes(props) {
  const {
    person: { notes, firstName, lastName, id },
    setNoteModalIsOpen,
  } = props;

  const [note, setNote] = useState("", []);

  const { state } = useContext(UserContext);

  const [addNoteForUser] = useAddNoteForUserMutation({
    refetchQueries: [
      {
        query: UserDocument,
        variables: {
          id: id,
        },
      },
    ],
  });

  const handleAddNote = e => {
    e.preventDefault();
    if (note != "") {
      addNoteForUser({
        variables: {
          user: id,
          author: state.user.id,
          content: note,
        },
      });
    }
    setNoteModalIsOpen(false);
  };

  return (
    <div style={{ maxWidth: "800px" }} className="p-3 row mx-auto">
      <h4 className="col-12 text-center">Member Notes</h4>
      <div className="col-12 row mx-auto mt-3 mb-4">
        <div className="col-12 col-md-5 d-flex align-items-center my-3">
          <div className="mx-auto">
            <img
              className="rounded-circle mx-auto my-2 d-block mx-auto"
              width="175"
              height="175"
              src="https://picsum.photos/175/175"
            />
            <div className="text-center font-size-1">
              {firstName} {lastName}
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-7"
          style={{ maxHeight: "350px", overflowY: "scroll" }}
        >
          {notes &&
            notes.map((note, i) => (
              <PersonNotesComment
                key={i}
                content={note.content}
                author={note.author}
                date={note.date}
              />
            ))}
        </div>
      </div>
      <div className="col-12 col-md-9 mx-auto">
        <h5>Add a note</h5>
        <textarea
          value={note.content}
          onChange={e => setNote(e.target.value)}
          name="content"
          rows="4"
          className="w-100"
        />
        <div className="d-flex">
          <button
            onClick={handleAddNote}
            className="btn btn-primary px-3 mx-auto"
          >
            Add note
          </button>
        </div>
      </div>
    </div>
  );
}
