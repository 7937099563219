import React, { useState, useContext, useEffect } from "react";
import SessionHighlightsCard from "./SessionHighlightsCard";
import PersonCard from "../Preparation/PersonCard";
import { FiUserMinus } from "react-icons/fi";
import GenericModal from "../../GenericModal";
import { UserContext } from "../../../context/UserContext";
import { useStopSessionMutation } from "../../../graphql/generated/graphql";
import RemoveMemberFromSession from "./RemoveMemberFromSession";
import { toast } from "react-toastify";
import QuestionModal from "../../Question/QuestionModal";
export default function ReviewContainer(props) {
  const {
    handleGoToPage,
    session,
    sessionMaster,
    status,
    isSessionMaster,
    handleContextAnswers,
    answers
  } = props;

  const { state } = useContext(UserContext);

  const [themeModalIsOpen, setThemeModalIsOpen] = useState(false, []);

  // used to collect id of members to be deleted. store member's first/last Name and id.
  const [deleteMembers, setDeleteMembers] = useState([]);

  const handleUserId = (id, name) => {
    const doesIdExist = deleteMembers.some(member => member.id === id);
    if (doesIdExist) {
      setDeleteMembers(deleteMembers.filter(memberId => memberId !== id));
    } else {
      setDeleteMembers([...deleteMembers, { id, name }]);
    }
  };

  const [stopSession] = useStopSessionMutation();
  const handleSessionEnd = () => {
    console.log("**** Session End ****");
    if (sessionMaster === state.user.id) {
      stopSession({
        variables: {
          session: session.id,
        },
      })
        .then(res => console.log(res))
        .catch(err => console.log(err));
    } else {
      toast.error("You are not allowed to stop this session");
    }
  };
  const [memberCards,setMemberCards] = useState()
  useEffect(() => {
    if(session && session.group && session.sessionMembers){
      // console.log("we here?")
      let tmp = []
      session.group.members.map(obj => {
      if(!session.sessionMembers.filter(mem => mem.user.id === obj.id).length>0){

       tmp.push(

         <PersonCard
           isSessionMaster={isSessionMaster}
           type={"member"}
           key={obj.id}
           person={obj}
           image={obj.profilePicUrl}
           inSession={false}
           sessionRole={obj.sessionRole}
           handleUserId={handleUserId}
           sessionId={session.id}
         />
       )
      }
      })
session.sessionMembers.map(obj => {

tmp.push(<PersonCard
  isSessionMaster={isSessionMaster}
  type={"member"}
  key={obj.user.id}
  person={obj.user}
  image={obj.user.profilePicUrl}
  inSession={true}
  sessionRole={obj.sessionRole}
  handleUserId={handleUserId}
  sessionId={session.id}
/>)
}
      
    )
  tmp.sort(function compareNumbers(a, b) {
    
    var nameA = a.props.person.user?a.props.person.user.firstName.toUpperCase():a.props.person.firstName.toUpperCase();
    var nameB = b.props.person.user?b.props.person.user.firstName.toUpperCase():b.props.person.firstName.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  })
 setMemberCards(tmp)
  
  }
    return () => {
      // console.log("no no perro afuera")
      setMemberCards()
    }
  }, [session, session.group, session.sessionMembers])
const handleAnswersSubmit = (answers) => {
    handleContextAnswers(answers)
    //give this to the great MANGO GODS 
  }
  return (
    <div className="review-container mb-5">
      <div className="row mx-auto">
        {/* Left Panel */}
        <div className="left-panel">
          <div className="round-box my-4">
            <SessionHighlightsCard session={session} />
          </div>
        </div>{ state.user.role === "ROLE_MEMBER" &&  answers && !answers.isDone && !answers.optOut &&( 

<QuestionModal handleSubmit={handleAnswersSubmit} stage={"post"}></QuestionModal>
                
                  
                  )}
        {/*Right Panel*/}
        {state.user.role === "ROLE_FACILITATOR" && (
          <div className="right-panel">
            <div className="d-block d-md-flex flex-row">
              <button
                disabled={
                  status !== "joinable" // || sessionMaster.id !== state.user.id
                }
                onClick={handleSessionEnd}
                className="btn btn-outline-danger bg-cleard my-4 mx-auto mx-md-3 w-100"
              >
                 {status === "joinable"
       ? "Close Session"
       : "Session is Closed"}
               
              </button>
            </div>

              <div className="my-4">
                {  session && memberCards &&
                  memberCards
                  }
              </div>
            {session.sessionMembers.length > 0 && (
              
              <div className="d-block d-md-flex flex-row">
              <button
                onClick={() => {
                  setThemeModalIsOpen(true);
                }}
                className="mx-auto mx-md-3 w-100 btn btn-outline-danger bg-cleard d-flex align-items-center"
                >
                <FiUserMinus
                  style={{ fontSize: "1.25em" }}
                  className="d-flex ml-auto mr-3"
                  />
                <span className="mr-auto">Remove member</span>
              </button>
            </div>
                  )}
          </div>
        )}
      </div>

      {state.user.role === "ROLE_FACILITATOR" && (
        <div className="row mx-auto mt-5 d-flex">
          <button
            className="btn btn-primary py-1 px-5 ml-5 mr-auto font-size-2"
            onClick={() => handleGoToPage.prev()}
          >
            Prev
          </button>
        </div>
      )}

      <GenericModal
        modalIsOpen={themeModalIsOpen}
        setModalIsOpen={setThemeModalIsOpen}
      >
        <RemoveMemberFromSession
          members={deleteMembers}
          sessionId={session.id}
          setModalIsOpen={setThemeModalIsOpen}
        />
      </GenericModal>
    </div>
  );
}
