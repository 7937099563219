import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import {
useSessionMixedUsersSubscription,
} from "../../../graphql/generated/graphql";
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow:"0px 0px 0px 0px rgba(0, 0, 0, 0.0) ",
    border: "none",
    backgroundColor:"rgba(0, 0, 0, 0.0)",
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    backgroundColor:"rgba(0,0,0,0.5)",
    width: "5vw",
    height: "5vw",
  },
}));

const ParticipantSmall = ({ participant, isMuted, callVol, displayStyle, setDisplayStyleList,  sessionId, participants }) => {
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [dataTracks, setDataTracks] = useState([]);
  const classes = useStyles();
  
  const { data: sessionMixedUserSubscriptionData,error:sessionMixedUserSubscriptionError,loading: sessionMixedUserSubscriptionLoading} = useSessionMixedUsersSubscription({
    variables: { id: sessionId },
    
    
  });
  const [avatar, setAvatar] = useState(<Avatar className={classes.large} />)
  useEffect(() => {
  if(sessionMixedUserSubscriptionData && sessionMixedUserSubscriptionData.sessionMixedUsers){
    sessionMixedUserSubscriptionData.sessionMixedUsers.map(user =>{
      if(user.twilioName == participant.identity )
      {
        setAvatar(<Avatar className={classes.large}  src={"https://d30mg0zmvh01j5.cloudfront.net/"+user.profilePicUrl} />)
      }
    })
  }
  return () => {
    setAvatar(null)
  }
  }, [sessionMixedUserSubscriptionData])



  const name = participant.identity.substring(0, participant.identity.length - 7);
const [displayStyleLoc, setDisplayStyleLoc] = useState(Math.random())
const [redShadowEh, setRedShadowEh] = useState(false)
const [placeHolderEh, setPlaceHolderEh] = useState(false)
  const videoRef = useRef();
  const audioRef = useRef();
  useEffect(() => {
    // console.log("ReRender \n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n")
    setInterval(() => {
      setDisplayStyleLoc(Math.random()+1)
    }, 1000);

  }, [])
// useEffect(() => {
// setRedShadowEh(displayStyleLoc.audio)
// setPlaceHolderEh(displayStyleLoc.video)
// console.log("shadow ehhh \n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n")
// }, [displayStyleLoc])
const trackpubsToTracks = trackMap =>
Array.from(trackMap.values())
.map(publication => publication.track)
.filter(track => track !== null);

useEffect(() => {
  setVideoTracks(trackpubsToTracks(participant.videoTracks));
  setAudioTracks(trackpubsToTracks(participant.audioTracks));
  setDataTracks(trackpubsToTracks(participant.dataTracks));
  
  const trackSubscribed = track => {
    if (track.kind === "video") {
      // console.log(track)
      setVideoTracks(videoTracks => [...videoTracks, track]);
    } else if (track.kind === "audio") {
      setAudioTracks(audioTracks => [...audioTracks, track]);
    }
    else if (track.kind === 'data') {
      track.on('message', data => {
        //setDisplayStyleLoc(!displayStyleLoc)
        // console.log(name)
        // console.log(data)
        // let tmp = []
        // console.log("we are hitting the track event statement")
        // switch (data) {
        //   case "audio true":
        //     setDisplayStyleLoc({video:  displayStyleLoc.video?displayStyleLoc.video:false, audio: true})
        //     // console.log("we are hitting the switch statement")
        //     // tmp = []
        //     // participants.filter(p=> (p.state != "disconnected")).map(p=>{
        //     //   if(p.sid== participant.sid){
        //     //     if(displayStyle.length>0 && displayStyle[0].displayStyle){
        //     //       tmp.push( {...p, displayStyle: {...displayStyle[0].displayStyle,audio: true}}) 
        //     //        setDisplayStyleLoc( {...displayStyle[0].displayStyle,audio: true})
        //     //       console.log(displayStyle[0].displayStyle)
                  
        //     //     }else {
        //     //       tmp.push( {...p, displayStyle: {video: false,audio: true}}) 
        //     //       setDisplayStyleLoc({video: false, audio: true})
        //     //       console.log("displayStyle[0].displayStyle")
        //     //     }
                
        //     //   }
        //     //   else if(!p.displayStyle ){
        //     //      tmp.push({...p, displayStyle: {audio: false,video:false}}) 
        //     //   }else{
        //     //       tmp.push( {...p}) 
        //     //   }
        //     // })
        //     // setDisplayStyleList([...tmp])
            
        //     break;
            
        //     case "audio false":
        //       setDisplayStyleLoc({video:  displayStyleLoc.video?displayStyleLoc.video:false, audio: false})
        //       // tmp = []
        //       // participants.filter(p=> (p.state != "disconnected")).map(p=>{
        //       //   if(p.sid== participant.sid){
        //       //     if(displayStyle.length>0 &&!displayStyle[0].displayStyle){

        //       //        tmp.push( {...p, displayStyle: {video: false,audio: false}}) 
        //       //        setDisplayStyleLoc({video: false, audio: false})
        //       //     }else{
        //       //       tmp.push( {...p, displayStyle: {...p.displayStyle,audio: false}}) 
        //       //       setDisplayStyleLoc( {...p.displayStyle,audio: false})
        //       //     }
                  
        //       //   }
        //       //   else if(!p.displayStyle ){
        //       //      tmp.push({...p, displayStyle: {audio: false,video:false}}) 
        //       //   }else{
        //       //       tmp.push( {...p}) 
        //       //   }
        //       // })
        //       // setDisplayStyleList([...tmp])
              
              
        //       break;
              
        //       case "video true":
        //         setDisplayStyleLoc({video:  true, audio: displayStyleLoc.audio?displayStyleLoc.audio:false})
        //         // tmp =[]
        //         // participants.filter(p=> (p.state != "disconnected")).map(p=>{
        //         //   if(p.sid== participant.sid){
        //         //     console.log("found me true")
        //         //     if(displayStyle.length>0 &&!displayStyle[0].displayStyle){
        //         //       tmp.push(
                        
        //         //         {...p, displayStyle: {video: true, audio: false}}
        //         //         ) 
                        
        //         //         setDisplayStyleLoc({video: true, audio: false})
        //         //       }else{
        //         //         tmp.push({...p, displayStyle: {...p.displayStyle,video: true}}
        //         //           ) 
        //         //           setDisplayStyleLoc( {...p.displayStyle,video: true})
                          
        //         //         }
                        
                        
        //         //       }
        //         //       else if(!p.displayStyle ){
        //         //         tmp.push({...p, displayStyle: {audio: false,video:false}}) 
        //         //       }else{
        //         //         tmp.push( {...p}) 
        //         //       }
        //         //     })
        //         //     console.log(tmp)
        //         //     setDisplayStyleList([...tmp])
              
              
        //       break;
              
        //       case "video false":
        //         setDisplayStyleLoc({video: false, audio:  displayStyleLoc.audio?displayStyleLoc.audio:false})
        //       // tmp =[]
        //       //   participants.filter(p=> (p.state != "disconnected")).map(p=>{
        //       //     if(p.sid== participant.sid){
        //       //       console.log("found me false")
        //       //       if(displayStyle.length>0 &&!displayStyle[0].displayStyle){
        //       //         tmp.push( {...p, displayStyle: {video: false,audio: false}}
        //       //        ) 
        //       //         setDisplayStyleLoc({video: false, audio: false})
                      
        //       //       }else{
        //       //          tmp.push({...p, displayStyle: {...p.displayStyle,video: false}}
                    
        //       //     ) 
        //       //        setDisplayStyleLoc( {...p.displayStyle,video: false})  
        //       //       }
                  
  
        //       //   }
        //       //   else if(!p.displayStyle ){
        //       //       tmp.push({...p, displayStyle: {audio: false,video:false}}
        //       //   ) 
        //       //   }else{
        //       //      tmp.push(  {...p}
        //       //  ) 
        //       //   }
        //       // })
        //       // setDisplayStyleList([...tmp])

        //       break;
          
        //     default:
        //       break;
        //   }
        });
      }
    };
setInterval(() => {
  setDisplayStyleLoc(!displayStyleLoc)
}, 1000);
    const trackUnsubscribed = track => {
      if (track.kind === "video") {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else if (track.kind === "audio") {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      } else if (track.kind === "data") {
      setDataTracks(dataTracks => dataTracks.filter(a => a !== track));
    }
    };

  
    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]); 
      setDataTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      //console.log(videoTracks[0].isEnabled)
      setPlaceHolderEh(!videoTracks[0].isEnabled)
      videoTrack.attach(videoRef.current);
      videoRef.current.style.transform = 'scale(-1, 1)';
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);
  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {

      setPlaceHolderEh(!videoTracks[0].isEnabled)
     
    }
  }, [displayStyleLoc]);
  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      setRedShadowEh(!audioTracks[0].isEnabled)
     
    }
  }, [displayStyleLoc]);

  useEffect(() => {
    if(videoTracks!== []){
      //console.log(videoTracks[0])
    }
    
  }, []);
  
  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      setRedShadowEh(!audioTracks[0].isEnabled)
      audioRef.current.volume = callVol
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  useEffect(() => {
   
    if (audioRef.current) {
      audioRef.current.volume = callVol
    
    }
  }, [callVol]);
  
  // useEffect(() => {
  //   if (audioRef.current){

  //     if (isMuted) {
  //      audioRef.volume = 0
  //      console.log(audioRef)
  //     }else{
  //       audioRef.volume = 1
  //     }
  //   }
  // }, [isMuted,audioRef.current]);

  return (
    <div onClick={()=>{ setVideoTracks(videoTracks)}} className="col p-1 col-2  ">
      <div className={"shadow-sm "}>
        <video
          className={ ` p-1 ${placeHolderEh===false?"  ":" d-none "} ${!redShadowEh?" bg-dark mt-1 shadow ":" mute-box-shadow mt-1 "} mb-1 bg-dark card w-100 rounded-lg`}
          ref={videoRef}
          autoPlay={true}
        />      
      <div className={`w-100 d-flex justify-content-center px-auto`}>
          <div className={classes.root}>
        <div className={ ` ${placeHolderEh===true?"  ":" d-none "}  ${!redShadowEh?"  ":" mute-box-shadow rounded-circle "}`}>

      {avatar}
      </div>
    </div>
          </div>
       
        <audio ref={audioRef} autoPlay={true} muted={isMuted} />
      </div>
      <h6 className={`text-center`}>
        {name}{" "}
      </h6>
      {/* <button onClick={()=> {audioRef.current.volume = 0}}>mute me</button> */}
    </div>
  );
};

export default ParticipantSmall;
