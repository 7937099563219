import React from "react";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";


// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class LoadingSpinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  render() {
    return (
      <div className="d-flex align-items-center" style={{ height: "50vh" }}>
        <PropagateLoader

          css={override}
          size={30}
          color={"rgba(32, 163, 183,80)"}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default LoadingSpinner;
