import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
import Role from "./Role";
import GenericModal from "../GenericModal";
import AddRole from "./AddRole";
import {
  GroupDocument,
  GroupsDocument,
  useGroupQuery,
  useRemoveUserFromGroupMutation,
  useAddGroupMutation,
  useEditGroupMutation,
  useAddSessionsMutation,
  useChangeDateTimeMutation,
  useDeleteGroupMutation,
  useAddTagToGroupMutation,
  useRemoveTagFromGroupMutation,
  useAddUserToGroupMutation,
  useDenyRequestMutation
} from "../../graphql/generated/graphql";
import LoadingSpinner from "../LoadingSpinner";
import moment from "moment";
import { toast } from "react-toastify";
import {UserContext} from "../../context/UserContext";
import logo from "../Login/logo.png";
import { FaLock, FaLockOpen } from "react-icons/fa";
import JSClogo from "../Login/JSClogo.png";
// Create and edit group
export default function Group() {
  const { state } = useContext(UserContext);
  const { id: groupId } = useParams();

  // GraphQL HOOK
  const { data, loading, error } = useGroupQuery({
    variables: {
      id: groupId,
    },
    skip: groupId === "new"
  });
  
  const [addGroup] = useAddGroupMutation({
    refetchQueries: [
      {
        query: GroupsDocument,
      },
    ],
  });
  
  const [editGroup] = useEditGroupMutation({
    refetchQueries: [
      {
        query: GroupDocument,
        variables: {
          id: groupId,
        },
        skip: groupId === "new"
      },
    ],
  });
  const [denyRequest] = useDenyRequestMutation({
    refetchQueries: [
      {
        query: GroupDocument,
        variables: {
          id: groupId,
        },
        skip: groupId === "new"
      },
    ],
  });

  const locationHook = useLocation();
  const history = useHistory();

  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)
  const [tag, setTag] = useState("")
  const [groupInfo, setGroupInfo] = useState({
    name: "",
    startDate: "",
    time:"",
    frequency: "",
    room: "",
    program:"music",
    owner: null,
    isPublic: true,
    inviteRequests: [],
    tags: [],
  });
  const [groupStyle, setGroupStyle] = useState({
    name: "",
    startDate: "",
    time: "",
    frequency: "",
    room: "",
  });
  
  const [removeUser] = useRemoveUserFromGroupMutation();
  const [deleteGroup] = useDeleteGroupMutation();
  const [addSessions] = useAddSessionsMutation();
  const [addTagToGroup] = useAddTagToGroupMutation();
  const [addUserToGroup] = useAddUserToGroupMutation();
  const [RemoveTagFromGroup] = useRemoveTagFromGroupMutation()
  const [changeDateTime] = useChangeDateTimeMutation();
  useEffect(() => {
    if(error){
     history.push("/manage")
    }
    if (groupId === "new") {
      setGroupInfo({ ...groupInfo });
    } else {
      if ( !loading && data  && !data.group  ){
        toast.error("group not found")
        history.push("/manage")
      }
      else if (!loading && data && data.group) {
        //console.log(data.group)
        setGroupInfo({...data.group,isPublic: data.group.isPublic,time:moment(data.group.startDate).format("HH:mm"),room:data.group.room==="n/a"?"":data.group.room,owner:data.group.owner?data.group.owner:null});}
    }
    //console.log(groupInfo);
  }, [loading, error]);

  const { name, startDate, time, frequency, room, facilitators, owner, members, inviteRequests } = groupInfo;
const handleRound = e =>{
  let curValue = e.target.value;
 
    let start = moment(curValue, "HH:mm")
    let remainder = (start.minute() % 15);
    console.log(remainder)
    let dateTime = moment(start).format("HH:mm");

    if(remainder == 15){
      dateTime = moment(start).format("HH:mm");

    }
    else if(remainder > 7)
   { 
      dateTime = moment(start).add(15-remainder, "minutes").format("HH:mm")
     }
   else{
      dateTime = moment(start).subtract(remainder, "minutes").format("HH:mm");
     
  }
  
    console.log(startDate);
    curValue = dateTime
    let timeAndDate = moment(moment(startDate).format("YYYY-MM-DD")).set({"hour": moment(dateTime, "HH:mm").hour(), "minute": moment(dateTime, "HH:mm").minute()});
    console.log(timeAndDate)
 
  setGroupInfo({ ...groupInfo, [e.target.name]: curValue });
}
  const updateState = e => {
    let isTime = e.target.name == "time";
    let curValue = e.target.value;
    // if(isTime){
    //   let start = moment(curValue, "HH:mm")
    //   let remainder = (start.minute() % 15);
    //   console.log(remainder)
    //   let dateTime = moment(start).format("HH:mm");

    //   if(remainder == 15){
    //     dateTime = moment(start).format("HH:mm");

    //   }
    //   else if(remainder > 7)
    //  { 
    //     dateTime = moment(start).add(15-remainder, "minutes").format("HH:mm")
    //    }
    //  else{
    //     dateTime = moment(start).subtract(remainder, "minutes").format("HH:mm");
       
    // }

    //   console.log(dateTime);
    //   curValue = dateTime
    // }
    setGroupInfo({ ...groupInfo, [e.target.name]: curValue });
    if(e.target.name !== "room")setGroupStyle({ ...groupStyle, [e.target.name]: groupInfo[e.target.name]!==""&&curValue===""?"box-shadow border border-danger":"" });
  };
  const handleTag = (e)=>{
    e.preventDefault();
    setGroupInfo({ ...groupInfo, tags: [...groupInfo.tags.filter(gtag => (gtag!=tag)), tag] });
    if(groupId !== "new" && groupInfo.tags.filter(gtag => (gtag==tag))<=0){
      addTagToGroup({
        variables: {
          tag: tag,
          group: groupInfo.id,
        },
       
      })
    }
    setTag("")
    
  }
  const removeTag = (e, tag)=>{
    e.preventDefault();
    if(groupId !== "new"){
      RemoveTagFromGroup({
        variables: {
          tag: tag,
          group: groupInfo.id,
        },
       
      })
    }
    console.log(tag)
  setGroupInfo({ ...groupInfo, tags: groupInfo.tags.filter(gtag => (gtag!=tag)) });
  

}
  const handleDeleteRole = (id, isFacilitator) => {
    removeUser({
      variables: {
        user: id,
        as: isFacilitator ? "facilitator" : "member",
        group: groupInfo.id,
      },
      refetchQueries: [
        { query: GroupDocument, variables: { id: groupInfo.id } },
      ],
    });
  };
  const onClickAccept = (id) => {
    addUserToGroup({
      variables: {
        user: id,
        as: "member",
        group: groupInfo.id,
      },
      refetchQueries: [
        { query: GroupDocument, variables: { id: groupInfo.id } },
      ],
    });
  };
  const onClickDeny = (id) => {
    denyRequest({
      variables: {
        user: id,
        group: groupInfo.id,
      },
      refetchQueries: [
        { query: GroupDocument, variables: { id: groupInfo.id } },
      ],
    });
  };
  const handleDeleteGroup = () => {
   if(state.user.id === owner.id) 
  { deleteGroup({
      variables: {
        id:groupInfo.id,

      },
      
    }).catch(err =>{console.log(err)}).then(()=>{
      toast.success("group was deleted!")
      history.push("/manage")
    })}
    else{
      toast.error("Oops, it looks like you aren't the owner of this group")
    }
  };

  // Facilitator/Member adding Modal
  const [facilitatorModalIsOpen, setFacilitatorModalIsOpen] = useState(false);
  const [memberModalIsOpen, setMemberModalIsOpen] = useState(false, []);

  // Edit Group Info Stuff
  const handleEditGroup = e => {
    e.preventDefault();
    // Validate here
    if (
      !groupInfo.name  ||
      !groupInfo.startDate  ||
      !groupInfo.time  ||
      !groupInfo.frequency 
    ) {
      toast.error("Please fill in all values");
      let errorStyle = "box-shadow border border-danger"
      setGroupStyle({
        name: groupInfo.name?"":errorStyle,
          startDate: groupInfo.startDate?"":errorStyle,
          frequency: groupInfo.frequency?"":errorStyle,
          time: groupInfo.time?"":errorStyle,
          room: "",
      })
     
      
      return null;
    }
    let timeAndDate = moment(moment(groupInfo.startDate).format("YYYY-MM-DD")).set({"hour": moment(groupInfo.time, "HH:mm").hour(), "minute": moment(groupInfo.time, "HH:mm").minute()});
    console.log(timeAndDate)
    editGroup({
      variables: {
        id: groupId,
        name: groupInfo.name,
        startDate: new Date(timeAndDate).toISOString(),
        frequency: groupInfo.frequency,
        room: groupInfo.room?groupInfo.room:"n/a",
        tags: groupInfo.tags,
        isPublic: groupInfo.isPublic,
      },
    })
      .then(() => {
        changeDateTime({
          variables: {
            group: groupId,
            date: new Date(timeAndDate).toISOString(),
          },
        }).catch(err => {
          // if not error msg using react-toastify
          console.log(err);
        });
        history.push("/manage");
        toast.success("Group information successfully updated");
      })
      .catch(err => {
        toast.error("Group information update failed");
      });
  };
  const handleSubmitNewGroup = e => {
    e.preventDefault();
    if (
      !groupInfo.name  ||
      !groupInfo.startDate  ||
      !groupInfo.time  ||
      !groupInfo.frequency
    ) {
      toast.error("Please fill in all values");
      let errorStyle = "box-shadow border border-danger"
      setGroupStyle({
        name: groupInfo.name?"":errorStyle,
          startDate: groupInfo.startDate?"":errorStyle,
          frequency: groupInfo.frequency?"":errorStyle,
          time: groupInfo.time?"":errorStyle,
          room: "",
      })
     
      
      return null;
    }else{
      let timeAndDate = moment(moment(groupInfo.startDate).format("YYYY-MM-DD")).set({"hour": moment(groupInfo.time, "HH:mm").hour(), "minute": moment(groupInfo.time, "HH:mm").minute()});
      addGroup({
        variables: {
          name: groupInfo.name,
          startDate: new Date(timeAndDate).toISOString(),
          frequency: groupInfo.frequency,
          room: groupInfo.room?groupInfo.room:"n/a",
          program: groupInfo.program,
          isPublic: groupInfo.isPublic,
          tags: groupInfo.tags,
        },
      })
      .then(res => {
        if(res.errors && res.errors[0].message=== "You have reached you limit for managed groups"){
          history.push("/manage");
          toast.error("You have reached you limit for managed groups");
        }
        
        let timeAndDate = moment(moment(groupInfo.startDate).format("YYYY-MM-DD")).set({"hour": moment(groupInfo.time, "HH:mm").hour(), "minute": moment(groupInfo.time, "HH:mm").minute()});
          addSessions({
            variables: {
              date: new Date(timeAndDate).toISOString(),
              group: res.data.addGroup.id,
              theme: "5ff79d0f19322d4590b5c975",
              freq: groupInfo.frequency,
              facilitators: groupInfo.facilitators,
            },
          }).catch(err => {
            // if not error msg using react-toastify
            console.log(err);
          });
          // if successfully create a group, navigate a user to edit page to add facilitator & member
          history.push({ pathname: `/group/${res.data.addGroup.id}` });
        })
        .catch(err => {
          // if not error msg using react-toastify
          console.log(err);
        });
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }
if (error) {history.push("/manage")}
else{

  return (
    <div className="container mt-5">
      {
        <h4 className="text-center box-shadow py-3">
          {(name && groupInfo.name) || "Add a new group"}
        </h4>
      }
      {/* Left panel */}
      <div
        className={`row d-flex ${
          groupId === "new"
            ? "justify-content-center"
            : "justify-content-between"
        }`}
      >
        <form className="form-group col-12 col-lg-4">
          <h5 className="my-3">Group Info</h5>
          <div className="form-group">
            <label className="w-100 pt-2 my-2">Group Name</label>
            <input
              type="text"
              className={`form-control ${groupStyle.name}`}
              placeholder="Group Name"
              value={name}
              name="name"
              onChange={updateState}
            />
          </div>
          <div className="form-group d-flex flex-column justify-content-start ">
            <div className="row">
            <label className="col col-6 pt-2 my-2 mr-3  ">Start Date</label>
            <label className="col col-4 pt-2 my-2">Time</label>
            </div>
            <div className="row">
              <input
              type="date"
              className={`form-control col-6 ml-3 mr-3 ${groupStyle.startDate}`}
              placeholder="Start Date"
              value={moment(startDate).format("YYYY-MM-DD")}
              name="startDate"
              onChange={updateState}
            />

            <input type="time" className={`form-control col-4 ${groupStyle.time}`} onChange={updateState} onBlur={handleRound} value={time} id="time" name="time"
 step="15 minutes"  required></input>
 </div>
          </div>
          <div className="form-group">
          </div>
          <div className="form-group">
            <label className="w-100 pt-2 my-2">Repeat</label>
            <select
               className={`form-control ${groupStyle.frequency}`}
              placeholder="Frequency"
              value={frequency}
              name="frequency"
              onChange={updateState}
            >
              <option value={""}>Pick a frequency option</option>
              <option value={"Once a week"}>Once a week</option>
              <option value={"Every other week"}>Every other week</option>
              <option value={"Once a Month"}>Once a Month</option>
            </select>
          </div>
          <div className="form-group">
            <label className="w-100 pt-2 my-2">Room</label>
            <input
              type="text"
              className={`form-control ${groupStyle.room}`}
              placeholder="Room"
              value={room}
              name="room"
              onChange={updateState}
            />
          </div>
          <div className="form-group">
            <label className="w-100 pt-2 my-2">Tags</label>
            <div className={`d-flex justify-content-around`}>
            <input
              type="text"
              className={`form-control ${groupStyle.room}`}
              placeholder="Tag"
              value={tag}
              name="tag"
              onChange={(e)=>setTag(e.target.value)}
            /> <button onClick={(e)=>handleTag(e)} className={`btn btn-info`}>Add</button>

            </div>
            {groupInfo.tags.map(tag=>(
            <span  key={tag} role="button" onClick={(e)=>removeTag(e,tag)} className="badge badge-info my-1 mr-1"> 
           <h6>
             {tag}  <span className={`ml-2`}>
               x
               </span>
             </h6> 
            </span>
            ))}
          </div>
          <div className="form-group">
            <label className="w-100 pt-2 my-2">Discoverability</label>
          <div className={`row d-flex justify-content-around`}>

            <div
            role={"button"}
            className={`rounded box-shadow ${groupInfo.isPublic?" btn bg-clear useBorder   ":" btn bg-clearl useBorder "}  py-2 d- mx-1 col col-5`}
            style={{ boxShadow: `${groupInfo.isPublic ?"rgba(0, 162, 255, .3) 0px 0px  8px 5px" :""}`}}
            onClick={()=>{
              setGroupInfo({ ...groupInfo, isPublic: true})
            }}
            >
             <span className={`d-flex justify-content-around align-items-center`}> <FaLockOpen/> Public Group </span> 
 </div>
            <div
            role={"button"}
            style={{ boxShadow: `${!groupInfo.isPublic?"rgba(0, 162, 255, .3) 0px 0px  8px 5px" :""}`}}
            className={`rounded box-shadow ${!groupInfo.isPublic?" btn bg-clear useBorder   ":" btn bg-clearl useBorder "} py-2 mx-1 col col-5`}
            onClick={()=>{
              setGroupInfo({ ...groupInfo, isPublic: false })
            }}>
             <span className={`d-flex justify-content-around align-items-center`}>
               
               <FaLock/>
              Private Group
               </span> 
 </div>
              </div>
          </div>
         {groupId == "new" &&  <div className="form-group">
            <label className="w-100 pt-2 my-2">Program</label>
          <div className={`row d-flex justify-content-around`}>

            <div
            role={"button"}
            className={`round-box box-shadow ${groupInfo.program == "music"?" bg-white ":" bg-dark "}  py-2 d-flex col col-5`}
            style={{ boxShadow: `${groupInfo.program == "music"?"rgba(0, 162, 255, .5) 0px 0px  8px 5px" :""}`}}
            onClick={()=>{
              setGroupInfo({ ...groupInfo, program: "music" })
            }}
            >
                   <img
              src={logo}
              id="icon"
              alt="Java Group"
              classname={``}
              width="100%"
              />
             
            </div>
            <div
            role={"button"}
            style={{ boxShadow: `${groupInfo.program == "reflections"?"rgba(0, 162, 255, .5) 0px 0px  8px 5px" :""}`}}
            className={`round-box box-shadow ${groupInfo.program == "reflections"?" bg-white ":" bg-dark "} py-2 mx-1 col col-5`}
            onClick={()=>{
              setGroupInfo({ ...groupInfo, program: "reflections" })
            }}>
                    <img
              src={JSClogo}
              id="icon"
              alt="Java Group"
              
              width="100%"
              />
              
            </div>
              </div>
          </div>
}
          {groupId != "new" && (
            <div className="d-flex justify-content-between">
              <Link
                to="/manage"
                className="btn btn-extra-radius px-5 text-white text-dark"
                style={{ background: "lightgrey" }}
              >
                Discard changes
              </Link>

              <button
                onClick={handleEditGroup}
                className="btn btn-extra-radius bg-info px-5 text-white mx-3"
              >
                Save
              </button>
            </div>
          )}
        </form>

        {/* Right panel */}
        {groupId !== "new" ? (
          <div className="col-12 col-lg-8">
                {owner!==null &&
            <section className="mb-2">
              <h5 className="my-3">Group Owner</h5>
              <div className="row d-flex justify-content-between">
                 
                    <Role
                      name={`${owner.firstName} ${owner.lastName}`}
                      id={owner.id}
                      group={owner}
                      facilitator={false}
                      isOwner={true}
                      onClickDelete={console.log}
                      image={owner.profilePicUrl}
                    />
                    <div>
                    <GenericModal
      style={{width:"100px !important"}}
      className={"col-6 w-25"}
      addClass={" bg-clear gray-filled"}
        modalIsOpen={warningModalIsOpen}
        setModalIsOpen={setWarningModalIsOpen}
      >
          <div className="round-box  box-shadow align-self-center p-5">
            

              <h5>
              Deleting a group is permenant and cannot be reversed. 
              </h5>
              <h5>
              Deleting it will also cancel all future sessions linked to {groupInfo.name}
              </h5>
              <h5>
              Are you sure you want to delete this group? 
              </h5>
         
         
            <div className={"row mt-4"}>
            <div className={"col d-flex justify-content-center"}>
              <button onClick={()=>{
                setWarningModalIsOpen(false)
                handleDeleteGroup()
                
                }} className={"btn btn-success w-25 mx-3 box-shadow "}>
                Yes
              </button>
              <button onClick={()=>setWarningModalIsOpen(false)} className={"btn btn-danger  w-25 mx-3 box-shadow"}>
                No
              </button>
            </div>
           
            </div>
          </div>
      </GenericModal>
                      <button onClick={()=>setWarningModalIsOpen(true)} className={`btn btn-outline-danger`}>Delete Group</button>
                    </div>
                  </div>
                </section>
                 }
                {/* <Role
                  emptyCard={true}
                  facilitator={true}
                  onClickAdd={() => setFacilitatorModalIsOpen(true)}
                /> */}
            <section className="mb-4">
              <h5 className="my-3">Facilitators</h5>
              <div className="row d-flex justify-content-between">
                {facilitators &&
                  facilitators.map((facilitator, i) => (
                    <Role
                      key={i}
                      name={`${facilitator.firstName} ${facilitator.lastName}`}
                      id={facilitator.id}
                      group={facilitators}
                      facilitator={true}
                      image={facilitator.profilePicUrl}
                      onClickDelete={handleDeleteRole}
                    />
                  ))}
                <Role
                  emptyCard={true}
                  facilitator={true}
                  onClickAdd={() => setFacilitatorModalIsOpen(true)}
                />
              </div>
            </section>

                <GenericModal
                  modalIsOpen={facilitatorModalIsOpen}
                  setModalIsOpen={setFacilitatorModalIsOpen}
                  >
                  <AddRole
                  
                    isFacilitator={true}
                    groupInfo={groupInfo}
                    setModalIsOpen={setFacilitatorModalIsOpen}
                  />
                </GenericModal>
            <section className="mb-4">
              <h5 className="my-3">Member</h5>
              <div className="row d-flex justify-content-between">
                {members &&
                  members.map((member, i) => (
                    <Role
                      key={i}
                      name={`${member.firstName} ${member.lastName}`}
                      id={member.id}
                      group={members}
                      facilitator={false}
                      image={member.profilePicUrl}
                      onClickDelete={handleDeleteRole}
                    />
                  ))}

                <Role
                  emptyCard={true}
                  facilitator={false}
                  onClickAdd={() => setMemberModalIsOpen(true)}
                />

                <GenericModal
                  modalIsOpen={memberModalIsOpen}
                  setModalIsOpen={setMemberModalIsOpen}
                >
                  <AddRole
                    isFacilitator={false}
                    groupInfo={groupInfo}
                    setModalIsOpen={setMemberModalIsOpen}
                  />
                </GenericModal>
              </div>
            </section>
            <section className="mb-4">
              <h5 className="my-3">Invite Requests</h5>
              <div className="row d-flex justify-content-between">
                {inviteRequests &&
                  inviteRequests.map((member, i) => (
                    <Role
                      key={i}
                      name={`${member.firstName} ${member.lastName}`}
                      id={member.id}
                      group={inviteRequests}
                      isRequest={true}
                      facilitator={false}
                      onClickAccept={onClickAccept}
                      onClickDeny={onClickDeny}
                    />
                  ))}
</div>
            </section>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="mt-4 d-flex justify-content-center align-items-center">
        {(groupId !== "new" && (
          <>
            {/*<Link*/}
            {/*  to="/manage"*/}
            {/*  className="btn btn-extra-radius px-5 text-white text-dark"*/}
            {/*  style={{background: "lightgrey"}}*/}
            {/*>*/}
            {/*  Discard changes*/}
            {/*</Link>*/}

            {/*<button*/}
            {/*  onClick={() => console.log(groupInfo)}*/}
            {/*  // onClick={onSubmit}*/}
            {/*  className="btn btn-extra-radius bg-info px-5 text-white mx-3"*/}
            {/*>*/}
            {/*  Save*/}
            {/*</button>*/}
          </>
        )) || (
          <div className="d-flex">
            <button
              onClick={handleSubmitNewGroup}
              className="btn btn-extra-radius bg-info px-5 text-white mx-3"
            >
              Add Group
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
}
