import React,  {useState,useEffect, useContext} from "react";
import GroupSummaryCard from "./GroupSummaryCard";
import { Link } from "react-router-dom";
import { usePublicGroupsQuery } from "../../graphql/generated/graphql.tsx";
import LoadingSpinner from "../LoadingSpinner";
import { UserContext } from "../../context/UserContext";
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";



export const GroupReqContainer = () => {
    const { loading, error, data } = usePublicGroupsQuery();
    
    
    const [publicGeez, setPublicGeez] = useState([])
    useEffect(() => {
       if(!loading &&  data.publicGroups&&  data.publicGroups.length>0){
           console.log(data)
           setPublicGeez([...data.publicGroups])
       }
       return () => {
        setPublicGeez([]);
      };
    }, [data, loading])
   
   
   
   
   
   
    return (
        <div>
        <div className={"text-light "}>
        <h4 className="mb-3">Public Groups</h4>
        <div style={{height: "70vh", overflowY:"scroll"}} className={`muteMenuScroll p-2`}>

        {!loading && data && publicGeez.length > 0  && publicGeez.map(g=>(
            <div className={`my-2`}  >
                <GroupSummaryCard
            key={g.id}
            clickableCard={true}
            group={g}
            requestable={true}
            />
                </div>
        ))}
        
        </div>
        </div>
        </div>
    )
}

