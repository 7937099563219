import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import { UserContext } from "../context/UserContext";
import moment from "moment";

const OptOut = () => {
  const id = useParams().id;
  const location = useLocation();
  const history = useHistory();
  const [waitFlag, setWaitFlag] = useState(false);
  const handleSend = id => {
    fetch(process.env.REACT_APP_MAIL_OPTOUT, {
      method: "POST",
      body: JSON.stringify({
        identity: id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(json => {
        console.log(json);
        if (json.body.success) {
          toast.success("You have successfully opted out of emails");
          history.push("/home");
        } else {
          toast.error("Oops, that address is invalid");
          history.push("/home");
        }
      });
  };
  useEffect(() => {
    if (!waitFlag && id) {
      setWaitFlag(true);

      let cleanId = id.replace(/[^a-z0-9]/gi, "");

      handleSend(cleanId);
    }
  }, [id, waitFlag]);

  if (!id || id === "") {
    toast.error("Oops, something went wrong");
    history.push("/home");
  }

  return (
    <div className="container">
      <ScaleLoader width={40} height={100} margin={5} color={"#0872e4bf"} />
    </div>
  );
};

export default OptOut;
