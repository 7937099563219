import React, { useState, useEffect } from "react";
import NewMemberTable from "./NewMember/NewMemberTable";
import moment from "moment";
import Navbar from "./Navbar";
import {
  useUsersQuery,
  useEditUserMutation,
  useEditRoleMutation,
  useThemesQuery
} from "../../graphql/generated/graphql";
import QuoteEditor from "./QuoteEditor/QuoteEditor"
import AddTheme from "./ThemeAdd/AddTheme";
export default function AdminContainer() {
  const [users, setUsers] = useState([]);
  const [display, setDisplay] = useState("user");
  const { loading, error, data } = useUsersQuery();
  //const { loading:themesLoading, error:themesError, data: themesData } = useThemesQuery();
  const [editUser] = useEditUserMutation();
  const [editRole] = useEditRoleMutation();
  useEffect(() => {
    if (!loading && data && data.users) {
      setUsers(data.users);
      console.log(data.users);
    }
  }, [loading]);
  const updateList = (changedUser, newStatus, newValidUntil) => {
    console.log(changedUser, newStatus, new Date(newValidUntil).toISOString());
    editUser({
      variables: {
        id: changedUser,
        status: newStatus,
        validUntil: newValidUntil,
      },
    })
      .then(res => {
        console.log(res.errors);
        setUsers([...users.filter(user=> user.id !== res.data.editUser.id ), res.data.editUser])
      })
      .catch(err => {
        console.log(err);
      });
    };
    const handleEditRole = (changedUser, role, isNew) => {
     if(isNew){ editUser({
        variables: {
          id: changedUser,
          status: true,
          validUntil: moment().add(1, "year"),
        },
      })
        .then(res => {
          console.log(res.errors);
          editRole({
            variables: {
              id: changedUser,
              role: role,
            },
          })
          .then(res => {
            console.log(res.errors);
            setUsers([...users.filter(user=> user.id !== res.data.editRole.id ), res.data.editRole])
          })
          .catch(err => {
            console.log(err);
          });
          
        })
        .catch(err => {
          console.log(err);
        });}
      else{
        editRole({
        variables: {
          id: changedUser,
          role: role,
        },
      })
      .then(res => {
        console.log(res.errors);
        setUsers([...users.filter(user=> user.id !== res.data.editRole.id ), res.data.editRole])
      })
      .catch(err => {
        console.log(err);
      });
    }
  };
  if (loading) {
    return <h1>Loading</h1>;
  }

  return (
    <div className="">
      <Navbar setDisplay={setDisplay}></Navbar>

      <div className="container mt-5">
        <div className="row ">
         {display=="quote"&&<QuoteEditor />}
         {display=="theme"&&<AddTheme />}
         { display=="user" &&<div>
            <NewMemberTable
              updateList={updateList}
              hasApprove={true}
              hasDeny={true}
              editRole={handleEditRole}
              users={users.filter(
                user =>
                  user.status === null ||
                  (user.status === true && moment().isAfter(user.validUntil))
              )}
              title={"Pending Members"}
            ></NewMemberTable>
            <NewMemberTable
              updateList={updateList}
              hasDeny={true}
              editRole={handleEditRole}
              users={users.filter(user => user.status === true)}
              title={"Confirmed Members"}
            ></NewMemberTable>
            <NewMemberTable
              updateList={updateList}
              hasApprove={true}
              users={users.filter(user => user.status === false)}
              title={"Denied Members"}
            ></NewMemberTable>
          </div>
       } </div>
      </div>
    </div>
  );
}
