import React,{useContext} from "react";
import { UserContext } from "../../../../../context/UserContext";
import ThemeReflection from "../../components/ThemeReflection";
import { GiSaveArrow } from "react-icons/gi";

const Reflection = props => {
  const { reflection, onThemeCardClick, isRunningSession } = props;
  const { state } = useContext(UserContext);
  return (
    <>
      <div className="d-flex mb-5" >
        <h3>{state.user.role == "ROLE_FACILITATOR"&&`10.`} Reflection</h3>

        {!isRunningSession ? (
          <button
            className="ml-2 bg-info btn-extra-radius border-0 px-3 text-white align-items-center"
            onClick={() => onThemeCardClick("addNote", "_", 1)}
          >
            <GiSaveArrow className="mr-2" />
            <span>Add Note</span>
          </button>
        ) : (
          <></>
        )}
      </div>

      <ThemeReflection
        reflection={reflection}
        isJavaTips={false}
        onThemeCardClick={() => onThemeCardClick("question")}
      />
    </>
  );
};

export default Reflection;