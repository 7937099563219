import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { setAccessToken } from "../auth/accessToken";
import { CLEAR, SET_ACCESS_TOKEN, UserContext } from "../context/UserContext";
import LoadingSpinner from "./LoadingSpinner";
import StyledContentLoader from 'styled-content-loader'
import { Routes } from "./Routes";
import Navbar from "./Navbar"
import MainLoading from './MainLoading'
import ErrorBoundary from "./ErrorBoundary";

function App() {
  const [loading, setLoading] = useState(true);
  const { dispatch } = useContext(UserContext);

  // Attempt to get a new access token using the refresh token
  // If it fails, all the user data currently being held by the frontend will be deleted
  // and user will be redirected to the sign in page
  useEffect(() => {
    fetch(process.env.REACT_APP_REFRESH_TOKEN, {
      method: "POST",
      credentials: "include",
    }).then(async x => {
      const { ok, accessToken } = await x.json();
      if (ok) {
        setAccessToken(accessToken);
        dispatch({ type: SET_ACCESS_TOKEN, payload: { accessToken } });
      } else {
        setAccessToken("");
        dispatch({ type: CLEAR });
      }
      setLoading(false);
    });
  }, []);

  // While waiting for the server response, display some loading message
  // TODO: Replace this loading message with a loading spinner or something more graphical
  if (loading) {
    return (
    <MainLoading isCollapsed={false} type={"home"} isLoading={true}>

      </MainLoading>
      )
  }

  return (
<ErrorBoundary>
  
    <Router>
      <Routes />
    </Router>
</ErrorBoundary>
   
  );
}

export default App;
