import React from "react";

export default function ThemeSharingCard(props) {
  const {
    id, sharing,
    onThemeCardClick,
  } = props;

  return (
    <div
      className="border round-box my-5 p-3 box-shadow clickable-card"
      onClick={onThemeCardClick}
      data-content="sharing"
      data-id={id}
    >
      <div>{sharing}</div>
      
    </div>
  );
}