import React, { useState, useEffect } from "react";
import SectionContainer from "./SectionContainer";
import {
  OpeningWelcome,
  OpeningSong,
  GroupGuidelines,
  GettingCentered,
  WrapUp,
  ClosingAffirmation,
  ClosingSong,
  GroupEnding,
  OpeningWelcomeSoc,
GroupGuidelinesSoc,
GettingCenteredSoc,
MeetingFormat,
GroupAffirm,
Shanx,
SerenityPage,
AfterShare,
GratitudePractice,
Introductions,
JavaSocialIntro
} from "./PageContent";


import runningSession from "./RunningSession.json";
import PageDataReflections from "./PageDataReflections.json";
import ErrorBoundary from "../ErrorBoundary";

export default function MultiSectionContainer(props) {
  const {
    startIndex,
    zoomData,
    isOpening,
    isReflections,
    handleGoToPage,
    currentPage,
    isSessionMaster,
    modalSync,
    handleMusicPlay,
    setIsSilent,
    isSyncOn,
   userList,
   master,
  } = props;

  const [multiData, setMultiData] = useState([], []);

  useEffect(() => {
    if(isReflections){
      setMultiData(PageDataReflections[`${isOpening ? "opening" : "closing"}`]);

    }else{

      setMultiData(runningSession[`${isOpening ? "opening" : "closing"}`]);
    }
  }, []);

  let pageContent;
// console.log(currentPage)
  if (multiData.length == 0) {
    return <></>;
  } else if(isReflections===true) {
    if (isOpening) {
      switch (multiData[currentPage - 1].contentId) {
        case "openingWelcome":
          pageContent = <OpeningWelcomeSoc/>;
          break;
        case "introToJs":
          pageContent = (
            <JavaSocialIntro/>
          );
          break;
        case "format":
          pageContent = <MeetingFormat />;
          break;
        case "groupGuidelines":
          pageContent = <GroupGuidelinesSoc  
          modalSync={modalSync}
          isSessionMaster={isSessionMaster}
          isSyncOn={isSyncOn}
          setIsSilent={setIsSilent}
          handleMusicPlay={handleMusicPlay}
          />;
          break;
        case "gettingCentered":
          pageContent = <GettingCenteredSoc   modalSync={modalSync}
          isSessionMaster={isSessionMaster}
          isSyncOn={isSyncOn}
          setIsSilent={setIsSilent}
          handleMusicPlay={handleMusicPlay}/>;
          break;
        case "introToOurselves":
          pageContent = <Introductions userList={userList} master={master} />;
          break;
      }
    } else {
      switch (multiData[currentPage - 11].contentId) {
        case "afterShare":
          pageContent = <AfterShare />;
          break;
        case "gratitude":
          pageContent = <GratitudePractice
          modalSync={modalSync}
          isSessionMaster={isSessionMaster}
          isSyncOn={isSyncOn}
          setIsSilent={setIsSilent}
          handleMusicPlay={handleMusicPlay}
          />;
          break;
        case "groupAffirmation":
          pageContent = (
            <GroupAffirm />
          );
          break;
        case "serenity":
          pageContent = <SerenityPage 
          modalSync={modalSync}
          isSessionMaster={isSessionMaster}
          isSyncOn={isSyncOn}
          setIsSilent={setIsSilent}
          handleMusicPlay={handleMusicPlay}/>;
          break;
        case "shanx":
          pageContent = <Shanx />;
          break;
      }
    }
  } 
  else {
    if (isOpening) {
      switch (multiData[currentPage - 1].contentId) {
        case "openingWelcome":
          pageContent = <OpeningWelcome />;
          break;
        case "openingSong":
          pageContent = (
            <OpeningSong
              modalSync={modalSync}
              isSessionMaster={isSessionMaster}
              isSyncOn={isSyncOn}
              setIsSilent={setIsSilent}
              handleMusicPlay={handleMusicPlay}
            />
          );
          break;
        case "groupGuidelines":
          pageContent = <GroupGuidelines 
          modalSync={modalSync}
          isSessionMaster={isSessionMaster}
          isSyncOn={isSyncOn}
          setIsSilent={setIsSilent}
          handleMusicPlay={handleMusicPlay}
          />;
          break;
        case "gettingCentered":
          pageContent = <GettingCenteredSoc   modalSync={modalSync}
          isSessionMaster={isSessionMaster}
          isSyncOn={isSyncOn}
          setIsSilent={setIsSilent}
          handleMusicPlay={handleMusicPlay}/>;
          break;
      }
    } else {
      switch (multiData[currentPage - 10].contentId) {
        case "wrapUp":
          pageContent = <WrapUp />;
          break;
        case "closingAffirmation":
          pageContent = <ClosingAffirmation
          modalSync={modalSync}
          isSessionMaster={isSessionMaster}
          isSyncOn={isSyncOn}
          setIsSilent={setIsSilent}
          handleMusicPlay={handleMusicPlay}
          />;
          break;
        case "closingSong":
          pageContent = (
            <ClosingSong
              modalSync={modalSync}
              isSessionMaster={isSessionMaster}
              isSyncOn={isSyncOn}
              setIsSilent={setIsSilent}
              handleMusicPlay={handleMusicPlay}
            />
          );
          break;
        case "groupEnding":
          pageContent = <GroupEnding />;
          break;
      }
    }
    }

    if (isOpening) {
      // console.log(modalSync)
      return (
              <ErrorBoundary><SectionContainer
          handleGoToPage={handleGoToPage}
          // handleNext={currentPage < multiData.length - 1 ? handleGoToPage.handleNext : null}
          handleNext={handleGoToPage.handleNext}
          // handlePrev={currentPage > 0 ? handleGoToPage.handlePrev : null}
          handlePrev={handleGoToPage.handlePrev}
          currentPage={currentPage + (startIndex ? startIndex : 0)}
          hasTips={
            !zoomData.showZoomContainer &&
            multiData[currentPage - 1].tips.length > 0
          }
          tips={multiData[currentPage - 1].tips}
          title={multiData[currentPage - 1].title}
          content={pageContent}
          zoomData={zoomData}
          modalSync={modalSync}
          isSessionMaster={isSessionMaster}
          isSyncOn={isSyncOn}
          handleMusicPlay={handleMusicPlay}
          isReflections={isReflections}
          />      </ErrorBoundary>
          );
        } else if(!isOpening && !isReflections) {
          return (      <ErrorBoundary>
            <SectionContainer
            handleGoToPage={handleGoToPage}
            // handleNext={currentPage < multiData.length - 1 ? handleGoToPage.handleNext : null}
            handleNext={handleGoToPage.handleNext}
            // handlePrev={currentPage > 0 ? handleGoToPage.handlePrev : null}
            handlePrev={handleGoToPage.handlePrev}
            currentPage={currentPage + (startIndex ? startIndex : 0)}
            hasTips={
              !zoomData.showZoomContainer &&
              multiData[currentPage - 10].tips.length > 0
            }
            
            title={multiData[currentPage - 10].title}
            content={pageContent}
            zoomData={zoomData}
            modalSync={modalSync}
            isSessionMaster={isSessionMaster}
            isSyncOn={isSyncOn}
            handleMusicPlay={handleMusicPlay}
            isSessionMaster={isSessionMaster}
            isReflections={isReflections}
            />      </ErrorBoundary>
            );
          }
          else  {
            return (      <ErrorBoundary>
              <SectionContainer
              handleGoToPage={handleGoToPage}
              // handleNext={currentPage < multiData.length - 1 ? handleGoToPage.handleNext : null}
              handleNext={handleGoToPage.handleNext}
              // handlePrev={currentPage > 0 ? handleGoToPage.handlePrev : null}
              handlePrev={handleGoToPage.handlePrev}
              currentPage={currentPage + (startIndex ? startIndex : 0)}
              hasTips={
                !zoomData.showZoomContainer &&
                multiData[currentPage - 10].tips.length > 0
              }
              
              title={multiData[currentPage - 11].title}
              content={pageContent}
              zoomData={zoomData}
              modalSync={modalSync}
              isSessionMaster={isSessionMaster}
              isSyncOn={isSyncOn}
              handleMusicPlay={handleMusicPlay}
              isSessionMaster={isSessionMaster}
              isReflections={isReflections}
        />      </ErrorBoundary>
      );
    }
  }

