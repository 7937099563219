import React, { useContext, useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAccessToken, setAccessToken } from "../../auth/accessToken";
import { SET_ACCESS_TOKEN, UserContext } from "../../context/UserContext";
import { useLoginMutation } from "../../graphql/generated/graphql";
import logo from "./logo.png";

export default function LoginForm(props) {
  const { reason } = props;
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState(false);

  let isSubmitting = false;

  const history = useHistory();

  const { dispatch } = useContext(UserContext);

  const [login] = useLoginMutation();

  const handleOnChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = async e => {
    e.preventDefault();
    isSubmitting = true;
    const { email, password } = state;
    try {
      const loginResponse = await login({ variables: { email, password } });
      //console.log(loginResponse);
      if (loginResponse && loginResponse.data.login.token) {
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: { accessToken: loginResponse.data.login.token },
        });
        setAccessToken(loginResponse.data.login.token);
        history.push("/");
      }
    } catch (err) {
      setError(true);
      console.log(err);
    }
  };

  if (getAccessToken() !== "") {
    toast.error("Log out first before trying to sign in as another user.", {
      toastId: "already signed in",
    });
    history.push("/");
  }
  if (props && reason) {
    toast.error(
      reason == "na"
        ? "You account has not been activated by the admin. If this is a valid account please email the admin"
        : "Your account has expired please update subscription or email admin if subscription is up to date",
      {
        toastId: "Expired account",
      }
    );
  }

  return (
    <div
      className="d-flex flex-row justify-content-center align-items-center vh-100"
      style={{ backgroundColor: "#aaa" }}
    >
      <form
        onSubmit={handleOnSubmit}
        className="border my-5 pt-5 px-5 pb-3 col-xs-12 col-sm-11 col-md-11 col-lg-9 col-xl-6"
        style={{ backgroundColor: "#fff", borderRadius: "20px" }}
      >
        <div className="row mt-5">
          <div className="col-12">
            {error && (
              <h4 className="text-center alert alert-danger mb-4">
                {`Invalid credentials`}
              </h4>
            )}
          </div>
          <div className="col-sm-12 col-md-4 d-flex flex-row justify-content-end mb-5">
            <img
              src={logo}
              id="icon"
              alt="Java Group"
              
              width="160"
            />
          </div>

          <div className="col-sm-12 col-md-6">
            {/* Username */}
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="email"
                  style={{ width: "90px" }}
                >
                  Email:
                </span>
              </div>
              <input
                onChange={handleOnChange}
                value={state.email}
                name="email"
                type="email"
                className="form-control"
                aria-label="Email"
                aria-describedby="email"
              />
            </div>
            {/* Password */}
            <div className="input-group mb-1">
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="password"
                  style={{ width: "90px" }}
                >
                  Password:
                </span>
              </div>
              <input
                onChange={handleOnChange}
                value={state.password}
                name="password"
                type="password"
                className="form-control"
                aria-label="Password"
                aria-describedby="password"
              />
            </div>

            <div className="mb-3">
              <Link to="/recover_password">Forgot Password?</Link>
            </div>
          </div>
        </div>

        {/* Log in button */}
        <div className="row mx-auto d-flex flex-row justify-content-center mt-3">
          <Link to="/register" className="btn btn-primary mx-3">
            REGISTER
          </Link>
          <input
            type="submit"
            className="btn btn-primary mx-3"
            value="LOG IN"
            style={{ width: "100px" }}
          />
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center mt-3">
          <span className="mr-3 h2">
            <AiOutlineInfoCircle />
          </span>
          <span className="text-weight-light">
            If this is your first time, you'll need to wait for the administrator to approve your account.
          </span>
        </div>
      </form>
    </div>
  );
}
